import { DeadLetterLogItem, EventLogDetailItem } from './../models/index';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { EventLogListItem } from '../models';
import * as signalr from '@microsoft/signalr';

@Injectable({
    providedIn: 'root'
})
export class DeadLetterService {

    baseUrl = environment.baseUrl;
    eventHub: signalr.HubConnection;

    constructor(private httpClient: HttpClient) { }

    getDeadLetterLogEntries(startAt: string = '', take: number = 20, search: string = ''): Observable<DeadLetterLogItem[]> {
        let url = `${this.baseUrl}/api/deadletter${startAt !== '' ? `/${startAt}/${take}` : ''}`;

        if (search && search !== '') {
            url = `${url}?search=${search}`;
        }
        return this.httpClient.get<DeadLetterLogItem[]>(url)
            .pipe(
                first()
            );
    }

    getDeadLetterEntry(eventId: string): Observable<DeadLetterLogItem> {
        return this.httpClient.get<DeadLetterLogItem>
            (`${this.baseUrl}/api/deadletter/${eventId}`)
            .pipe(
                first()
            );
    }
    
    clearDeadLetterEntry(eventId: string, isEmitted): Observable<any> {
        const url = isEmitted ? `${this.baseUrl}/api/deadletter/${eventId}?emitted=true` : `${this.baseUrl}/api/deadletter/${eventId}?emitted=false`
        return this.httpClient.put<any>(url, null)
            .pipe(
                first()
            );
    }
}
