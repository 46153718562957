<div class="list-group order-files">
  <div class="list-group-item">
    <div class="row data-label">
      <div class="link col-2"></div>
      <div class="filetype col-2">Type</div>
      <div class="description col-4">Description</div>
      <div class="stitchFileInfo col-4">Stitch File Info</div>
    </div>
  </div>
  <div class="list-group-item" *ngFor="let file of files">
    <div class="row">
      <div class="link col-2">
        <a [attr.href]="file.uploadedUrl">{{ file.fileName || file.description || "Download" }}</a>
      </div>
      <div class="filetype col-2">
        {{ file.fileType || "Unknown" }}/{{ file.extension || "Unknown" }}
      </div>
      <div class="description col-4">
        {{ file.description || "Unknown" }}
      </div>
      <div *ngIf="file.stitchCount && file.stitchCount > 0" class="stitchFileInfo col-4">
        <ul class="list-group">
          <li class="list-group-item">
            Stitches: {{ file.stitchCount }}
          </li>
          <li class="list-group-item">
            Width: {{ file.widthMM / 10 }} mm
          </li>
          <li class="list-group-item">
            Height: {{ file.heightMM / 10 }} mm
          </li>
          <li *ngIf="file.numColors && file.numColors > 0" class="list-group-item">
            # Colors: {{ file.numColors }}
          </li>
        </ul>
      </div>
      <div *ngIf="!file.stitchCount || file.stitchCount < 0" class="stitchFileInfo col-4">
        N/A
      </div>
    </div>
  </div>
</div>