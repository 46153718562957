import { CustomerModel, CustomerNotificationLog } from './../models/index';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  baseUrl = environment.baseUrl;

  constructor(private httpClient: HttpClient) {

  }

  getEnabledCustomers() {
    return this.httpClient.get<{ customers: CustomerModel[] }>
      (`${this.baseUrl}/api/customers/enabled`)
      .pipe(
        map(result => result.customers))
      .pipe(
        first()
      );
  }

  getCustomers(filter: string = '') {
    return this.httpClient.get<{ customers: CustomerModel[] }>
      (`${this.baseUrl}/api/customers${filter === '' ? '' : `?filter=${filter}`}`)
      .pipe(
        map(result => result.customers))
      .pipe(
        first()
      );
  }

  getCustomer(id: number) {
    return this.httpClient.get<CustomerModel>
      (`${this.baseUrl}/api/customers/${id}`)
      .pipe(
        first()
      );
  }

  getCustomerNotifications(customerId: string, skip: number, take: number) {
    return this.httpClient.get<CustomerNotificationLog[]>
      (`${this.baseUrl}/api/customers/${customerId}/notifications/${skip}/${take}`)
      .pipe(
        first()
      );
  }

  getCustomerNotificationsForOrder(customerId: number, orderId: string) {
    return this.httpClient.get<CustomerNotificationLog[]>
      (`${this.baseUrl}/api/customers/${customerId}/notifications/${orderId}`)
      .pipe(
        first()
      );
  }

  retryNotification(log: CustomerNotificationLog) {
    log.bodyJson = JSON.stringify(log.bodyJson);
    return this.httpClient.put<any>
      (`${this.baseUrl}/api/customers/${log.customerId}/retrynotification`, log)
      .pipe(
        first()
      );
  }
}
