import { Component, OnInit } from '@angular/core';
import { EventLogListItem } from 'src/app/models';
import { EventService } from 'src/app/services/event.service';

import * as displayHelpers from '../../displayHelpers';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-event-log',
  templateUrl: './event-log.component.html',
  styleUrls: ['./event-log.component.scss', './../../app.component.scss']
})
export class EventLogComponent implements OnInit {
  eventItems: EventLogListItem[] = [];
  model = {
    orderId: ''
  };
  loading = false;
  noMore = false;
  calendarDate = displayHelpers.calendarDate;
  timeout: number;

  constructor(private eventService: EventService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParamMap.subscribe((pMap) => {
      this.model.orderId = pMap.get('orderId');
    });
    if (this.model.orderId != '') {
      this.fetchLatestEventItems(this.model.orderId);
    } else {
      this.fetchLatestEventItems();
    }
  }

  fetchLatestEventItems(orderId: string = '') {
    this.loading = true;
    this.noMore = false;
    this.eventService.getEventItems('', 20, orderId).subscribe(items => {
      this.eventItems = items;
      this.loading = false;
    });
  }

  fetchLatestEventItemsByEventName(orderId: string = '') {
    this.loading = true;
    this.noMore = false;
    this.eventService.getEventItemsByEventName('', 0, 20, orderId).subscribe(items => {
      this.eventItems = items;
      this.loading = false;
    });
  }

  orderIdChanged() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
    this.timeout = window.setTimeout(t =>  {

      //jvv-searchByEventName
      var checkBox = <HTMLInputElement> document.getElementById("searchByEventName");
      if(checkBox.checked){
        this.fetchLatestEventItemsByEventName(this.model.orderId);
      }else{

      this.fetchLatestEventItems(this.model.orderId);

    }
    }, 2000);
  }

  loadOlder() {
    this.loading = true;
    this.noMore = false;
    let skip = this.eventItems.length;


    //jvv-searchByEventName checkbox validation
    var checkBox = <HTMLInputElement> document.getElementById("searchByEventName");

    if(!checkBox.checked){

    this.eventService.getEventItems(this.eventItems[this.eventItems.length - 1].id,
      20,
      this.model.orderId).subscribe(items => {
      if (!items || items.length === 0) {
        this.noMore = true;
      }
      items.forEach(i => this.eventItems.push(i));
      this.loading = false;
    });

  }else{

    this.eventService.getEventItemsByEventName(
      this.eventItems[this.eventItems.length - 1].id,
      skip,
      20,
      this.model.orderId).subscribe(items => {
      if (!items || items.length === 0) {
        this.noMore = true;
      }
      items.forEach(i => this.eventItems.push(i));
      this.loading = false;
    });

  }
  }

  //comment
}
