import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ImportMonitorDetailItem } from 'src/app/models';
import { ImportMonitorService } from 'src/app/services/import_monitor.service';

import { longDateWithSeconds } from './../../../displayHelpers';

@Component({
  selector: 'app-order-monitor-detail',
  templateUrl: './order-monitor-detail.component.html',
  styleUrls: ['./order-monitor-detail.component.scss', './../../../app.component.scss']
})
export class ImportMonitorDetailComponent implements OnInit {
  model: ImportMonitorDetailItem;
  longDateWithSeconds = longDateWithSeconds;
  isClaiming: boolean;
  claimer: string;

  constructor(private route: ActivatedRoute,
    private importMonitorService: ImportMonitorService,
    private router: Router) { }

  ngOnInit() {
    const { eventId } = this.route.snapshot.params;
    this.importMonitorService.getImportMonitorItem(eventId).subscribe(detail => {
      this.model = detail;
    });
  }


  clearItem() {
    this.importMonitorService.clearImportMonitorItem(this.model).subscribe(item => {
      this.router.navigate(['/monitor']);
    });
  }

  claimItem() {
    this.model.claimedBy = this.claimer;
    this.importMonitorService.claimImportMonitorItem(this.model).subscribe(item => {
      this.isClaiming = false;
    });
  }

  startClaim() {
    this.isClaiming = true;
  }

  getEventContent(): string {
    return JSON.stringify(JSON.parse(this.model.content), null, 1).replace(/\\n/g, '\n').replace(/\\"/g, '"').replace(/,"/g, ',\n"');
  }

}
