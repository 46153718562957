<div>
    <h2 i18n>Unexpected Shipments</h2>
    <div class="col-sm-7">
        <div class="row">
            <div class="table-responsive mt-2 mb-2">
                <table>
                    <thead>
                        <th scope="col" style="width:20%">Keyword</th>
                        <th scope="col" style="width:3%"></th>
                        <th scope="col" style="width:20%">Location</th>
                        <th scope="col" style="width:3%"></th>
                        <th scope="col" style="width: 20%">Status</th>
                        <th scope="col" style="width:3%"></th>
                        <th scope="col" style="width:31%"></th>
                    </thead>
                    <tbody>
                        <td><input name="filterString" [(ngModel)]="filters.filterString"
                                (ngModelChange)="filterStringChanged()" class="form-control mr-sm-2" type="text"
                                placeholder="Type to filter" aria-label="Filter"></td>
                        <td></td>
                        <td><select class="form-control" style="display: inline-block" [(ngModel)]="filters.location"
                                [ngModelOptions]="{standalone: true}" (ngModelChange)="filterStringChanged()">
                                <option></option>
                                <option *ngFor="let location of locationModel" [value]="location.locationCode">
                                    {{location.locationCode}}</option>
                            </select></td>
                        <td></td>
                        <td><select class="form-control" style="display: inline-block" [(ngModel)]="filters.status"
                                (ngModelChange)="filterStringChanged()" [ngModelOptions]="{standalone: true}">
                                <option></option>
                                <option *ngFor="let status of statuses" [value]="status">
                                    {{status}}</option>
                            </select></td>
                            <td></td>
                            <td><button *ngIf="anyFiltersApplied()" type="button" class="btn btn-sm btn-primary" (click)="clearFilters()">Clear Filters</button></td>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="table-responsive" *ngIf="!loading">
        <table class="table table-striped">
            <thead>
                <th scope="col" i19n style="width:4%">View</th>
                <!-- <th scope="col" i19n style="width:4%">Email</th> -->
                <!-- <th scope="col" i19n style="width:4%">Order</th> -->
                <!-- <th scope="col" i19n style="width:4%">Cancel</th>
                <th scope="col" i19n style="width:4%">Dispose</th> -->
                <th scope="col" i18n style="width:7%">
                    <div (click)="sortByNumberColumn('receivingId')">Receiving ID<span class="fa fa-sort"></span></div>
                </th>
                <th scope="col" i19n style="width:4%"><div (click)="sortByStringColumn('companyName')">Status <span class="fa fa-sort"></span></div></th>
                <th scope="col" i18n style="width:5%">
                    <div (click)="sortByNumberColumn('age')">Age<span class="fa fa-sort"></span></div>
                </th>
                <th scope="col" i18n style="width:10%">
                    <div (click)="sortByStringColumn('companyName')">Company Name <span class="fa fa-sort"></span></div>
                </th>
                <th scope="col" i18n style="width:5%">
                    <div (click)="sortByStringColumn('wearer')">Wearer<span class="fa fa-sort"></span></div>
                </th>
                <th scope="col" i18n style="width:6%">
                    <div (click)="sortByDateColumn('createDate')">Date Added <span class="fa fa-sort"></span></div>
                </th>
                <th scope="col" i18n style="width:8%">
                    <div (click)="sortByStringColumn('poNumber')">PO <span class="fa fa-sort"></span></div>
                </th>
                <th scope="col" i18n style="width:8%">
                    <div (click)="sortByStringColumn('clientReferenceNumber')">Customer Ref <span
                            class="fa fa-sort"></span></div>
                </th>
                <th scope="col" i19n style="width:7%">
                    <div (click)="sortByStringColumn('warehouseCode')">Location <span class="fa fa-sort"></span></div>
                </th>
                <th scope="col" i19n style="width: 3%">
                    <div (click)="sortByNumberColumn('totalPieces')">Qty<span class="fa fa-sort"></span></div>
                </th>
                <th scope="col" i19n style="width:25%">Notes</th>
            </thead>
            <tbody>
                <tr *ngFor="let item of shipments">
                    <td><a class="btn btn-sm btn-primary" [routerLink]="['/csgrequests/unexpected', item.id]">View</a>
                    </td>
                    <!-- <td>
                        <button *ngIf="hasActionsLeft(item) && item.emailSentCount > 0" class="btn btn-sm btn-secondary"
                            (click)="sendEmail(item)">Resend</button>
                        <button *ngIf="hasActionsLeft(item) && item.emailSentCount === 0" class="btn btn-sm btn-primary"
                            (click)="sendEmail(item)">Email</button>
                    </td> -->
                    <!-- <td><button *ngIf="hasActionsLeft(item)" class="btn btn-sm btn-primary"
                            (click)="cancelOrder(item.id)">Cancel</button></td>
                    <td><button *ngIf="hasActionsLeft(item)" class="btn btn-sm btn-primary"
                            (click)="disposeOrder(item.id)">Dispose</button></td> -->

                    <td>{{ item.receivingId || 'N/A'}}</td>
                    <td>{{ item.status || 'N/A'}}</td>
                    <td>{{ item.age}}</td>
                    <td>{{ item.companyName || 'N/A'}}</td>
                    <td>{{ item.wearer || 'N/A'}}</td>
                    <td>{{ item.createDateString }}</td>
                    <td>{{ item.poNumber || 'N/A'}}</td>
                    <td>{{ item.clientReferenceNumber || 'N/A'}}</td>
                    <td>{{ item.warehouseCode || 'N/A'}}</td>
                    <td>{{ item.totalPieces}}</td>
                    <td>{{ item.internalNotes || 'N/A'}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div *ngIf="loading || addMoreloading" class="progress">
        <div i18n class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100"
            aria-valuemin="0" aria-valuemax="100" style="width: 100%">Loading</div>
    </div>
    <div *ngIf="noMore" class="alert alert-warning" i18n>
        There are no more requests
    </div>
    <div *ngIf="!loading && !noMore && !addMoreloading" class="mt-2 mb-2">
        <button i18n (click)="loadMoreUnex()" type="button" class="btn btn-primary btn-sm">Load More Requests</button>
    </div>
</div>
