<div>
  <h1 i18n>Redirector Rules</h1>
  <br /><br />
  <div>
    Default Location <select class="select-option  btn btn-outline-info btn-sm" required [(ngModel)]='this.defaultLoc'>
      <option class='option' *ngFor='let option of this.defaultLocs' [ngValue]="option">{{option}}</option>
      </select>
     Product Code <select class="select-option  btn btn-outline-info btn-sm" required [(ngModel)]='this.productCode'>
     <option class='option' *ngFor='let option of this.productCodes' [ngValue]="option">{{option}}</option>
     </select>
     Customer Number <input type="text" [(ngModel)]="this.customerNumber"/>
     Zip Code <input type="text" [(ngModel)]="this.zipCode"/>
     <input type="submit"value="Find Location"  (click)="findLoc()" />
     <br/><br/>
     Rule Id <input type="text" [(ngModel)]="this.ruleid"/>
     Location <input type="text" [(ngModel)]="this.location"/>
  </div>

  

<br /><br />
  <ng2-smart-table [settings]="settings" [source]="this.redirectorRows" (createConfirm)="onCreateConfirm($event)" 
  (editConfirm)="onSaveConfirm($event)" (deleteConfirm)="onDeleteConfirm($event)"></ng2-smart-table>
  <div *ngIf="loading" class="progress">
    <div i18n class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">Loading</div>
  </div>
</div>
