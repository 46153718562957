import { AuthService, LoginStatus } from './../../services/auth.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  paramsub: Subscription;

  model: {
    username: string,
    password: string,
    loginFailed: boolean,
    status: LoginStatus,
    returnRoute: string,
    error?: string,
    errorDescription?: string
  } = {
    username: '',
    password: '',
    loginFailed: false,
    status: '',
    returnRoute: '/landing'
  };

  oAuthLoginUrl = environment.oAuthLoginUrl;
  isLoggingIn: boolean;

  constructor(private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.paramsub = this.route.queryParams.subscribe(params => {
      const { code, status, returnRoute, error, error_description } = this.route.snapshot.queryParams;
      this.model.status = status;
      this.model.returnRoute = returnRoute;

      if (error) {
        this.model.error = error;
        this.model.status = 'error';
        this.model.errorDescription = error_description;
        return;
      }

      if (returnRoute) {
        window.localStorage.setItem('weireturnroute', returnRoute);
      }
      if (code && code !== '') {
        this.isLoggingIn = true;
        this.authService.loginUserFromAdfs(code).subscribe(result => {
          this.isLoggingIn = false;
          if (result.success) {
            this.router.navigateByUrl(window.localStorage.getItem('weireturnroute') || '/landing');
            return;
          }
          this.model.loginFailed = true;
        });
        return;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.paramsub) {
      this.paramsub.unsubscribe();
    }
  }

  login() {
    this.model.loginFailed = false;
    this.authService.login(this.model.username, this.model.password).subscribe(result => {
      if (result.success) {
        this.router.navigateByUrl(this.model.returnRoute || '/landing');
        return;
      }
      this.model.loginFailed = true;
    });
  }
}
