import { Component, OnInit } from '@angular/core';
import { OpenOrdersService } from 'src/app/services/open-orders.service';
import { OpenOrdersModel } from './../../../models';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-open-orders',
  templateUrl: './open-orders.component.html',
  styleUrls: ['./open-orders.component.scss'],
  providers: [DatePipe]
})
export class OpenOrdersComponent implements OnInit {
  public loading = false;
  public openOrders : OpenOrdersModel[];
  public lastUpdated = moment().format();
  public settings = {
    actions: false,
    columns: {
      salesOrderNumber: {
        title: 'SON',
        width:'100px'
      },
      customerId: {
        title: 'Cust No',
        width:'100px'
      },
      customerName: {
        title: 'Cust Name',
        width:'500px'
      },
      requestedDeliveryDate: {
        title: 'Req Dt',
        width:'120px',
        valuePrepareFunction: (requestedDeliveryDate) => {
          return this.datePipe.transform(new Date(requestedDeliveryDate), 'yyyy-MM-dd');
        }
      },
      purchaseOrderNo: {
        title: 'PO No',
        width:'150px'
      },
      shipVia: {
        title: 'Ship Via',
        width:'100px'
      },
      priority: {
        title: 'Priority',
        width:'150px'
      },
      itemCategoryCode: {
        title: 'Prod Class',
        width:'150px'
      },
      productCategoryId: {
        title: 'Prod',
        width:'100px'
      },
      navSKU: {
        title: 'NAV Item',
        width:'150px'
      },
      navDescription: {
        title: 'Description',
        width:'500px'
      },
      location: {
        title: 'Location',
        width:'100px'
      },
      pon: {
        title: 'PON',
        width:'100px'
      },
      qtyOrdered: {
        title: 'Qty Ordered',
        width:'100px'
      },
      qtyProduced: {
        title: 'Qty Produced',
        width:'100px'
      },
      qtyToProduce: {
        title: 'Qty to Produce',
        width:'100px'
      },
      qtyDamaged: {
        title: 'Qty Damaged',
        width:'100px'
      },
      lastCheckpoint: {
        title: 'Last Chkpt',
        width:'500px'
      },
      currentCheckpoint: {
        title: 'Curr Chkpt',
        width:'500px'
      },
      checkpointStatus: {
        title: 'Chkpt Status',
        width:'300px'
      },
    },
    attr: {
      class: 'table table-bordered table-striped'
    },
  };
  
  constructor(private openOrdersService: OpenOrdersService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.loading = true;
    this.openOrdersService.getOpenOrders().subscribe(list => {
      this.openOrders = list;
      this.loading = false;

      var lastUpdatedUtc = moment.utc(this.openOrders[0].lastUpdated);
      this.lastUpdated = lastUpdatedUtc.local().format("YYYY-MM-DD HH:mm:ss");
      // this.lastUpdated = moment(this.openOrders[0].lastUpdated).format("YYYY-MM-DD HH:mm:ss");
      // this.lastUpdated = this.lastUpdated.
    });
  }

  Download(){
    var json = this.openOrders;
    var fields = Object.keys(json[0]);
    var replacer = function(key, value) { return value === null ? '' : value } 
    var csv = json.map(function(row){
    return fields.map(function(fieldName){
    return JSON.stringify(row[fieldName], replacer)
    }).join(',')
    })
    csv.unshift(fields.join(',')) // add header column
    console.log(csv.join('\r\n'))
    
    //Creates blob to be able to download CSV file
    var downloadLink = document.createElement("a");
    var csvFile = new Blob([csv.join('\r\n')], {type: 'text/csv'});
    var url = URL.createObjectURL(csvFile);
    downloadLink.href = url;
    downloadLink.download = "Open_Orders.csv";
    
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    }
}
