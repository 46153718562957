import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { prepareValue } from 'ng2-smart-table/lib/lib/data-set/cell';
import { LocationModel, UnexpectedShipment, UnexpectedShipmentDisplayModel } from 'src/app/models';
import { CsgRequestsService } from 'src/app/services/csg-requests.service';
import { LocationLookupService } from 'src/app/services/location-lookup.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { shortDate } from './../../displayHelpers';

@Component({
    selector: 'app-unexpected-shipments',
    templateUrl: './csg-unexpected-shipments.component.html',
})

export class CsgUnexpectedShipmentsComponent implements OnInit {
    timeout: number;
    loading: boolean;
    addMoreloading: boolean;
    shipments: UnexpectedShipmentDisplayModel[];
    companyNameAscending = true;
    dateAddedAscending = true;
    ageAscending = true;
    poAscending = true;
    locationAscending = true;
    shortDate = shortDate;
    filters = { location: '', status: '', filterString: '' };
    statuses: string[];
    locationModel = new Array<LocationModel>();
    noMore = false;
    sortHeaders = {
        receivingIdAsc: true,
        actionAsc: true,
        ageAsc: true,
        companyNameAsc: true,
        wearerAsc: true,
        createDateAsc: true,
        poNumberAsc: true,
        totalPiecesAsc: true,
        warehouseCodeAsc: true,
        internalNotesAsc: true
    };

    constructor(private csgService: CsgRequestsService, private notificationService: NotificationService, private router: Router,
        private locationService: LocationLookupService) { }

    ngOnInit() {
        this.loading = true;
        this.csgService.getAllUnexpectedShipments().subscribe(r => {
            this.mapListToDisplayModel(r);
            this.loading = false;
        });

        this.locationService.getLocations(true).subscribe(results => {
            this.locationModel = results;
        });

        this.csgService.getAvailableStatusList(true, false).subscribe(results => {
            this.statuses = results;
        });
    }

    sendEmail(item: UnexpectedShipment): void {
        this.router.navigate(['unexpectedshipments/' + item.id + '/email']);
    }

    cancelOrder(id: string): void {
        this.csgService.cancelUnexpectedShipment(id).subscribe(response => {
            if (!!response && response.success) {
                this.notificationService.showPopup('Unexpected Shipment cancelled!', 'success', 'Success!');
                this.shipments = this.shipments.filter(c => c.id !== id);
            } else {
                this.notificationService.showPopup(response.message, 'danger', 'Error!');
            }
        }, err => {
            this.notificationService.showPopup('Error cancelling Unexpected Shipment', 'danger', 'Error');
        });
    }

    disposeOrder(id: string): void {
        this.csgService.disposeUnexpectedShipment(id).subscribe(response => {
            if (!!response && response.success) {
                this.notificationService.showPopup('Unexpected Shipment disposed!', 'success', 'Success!');
                this.shipments = this.shipments.filter(c => c.id !== id);
            } else {
                this.notificationService.showPopup(response.message, 'danger', 'Error!');
            }
        }, err => {
            this.notificationService.showPopup('Error disposing Unexpected Shipment', 'danger', 'Error');
        });
    }

    hasActionsLeft(shipment: UnexpectedShipment) {
        return (shipment.status !== 'All Actions Completed');
    }

    getItemShopUrl(receivingId: string): string {
        // tslint:disable-next-line:radix
        return environment.shopUrl + 'shop/decorate-application?receivingId=' + parseInt(receivingId);
    }

    getTotalPieces(item: UnexpectedShipment): number {
        const totalPieces = item.unexpectedCsgShipmentItems
            .reduce((total, shipItem) => total + (shipItem.quantity && shipItem.quantity > 0 ? shipItem.quantity : 0), 0);

        if (!totalPieces || totalPieces < 0) {
            return 0;
        }

        return totalPieces;
    }

    filterStringChanged() {
        this.loading = true;
        this.noMore = false;
        if (this.timeout) {
            window.clearTimeout(this.timeout);
        }
        this.timeout = window.setTimeout(t => {
            this.performSearchWithFilter();
        }, 2000);
    }

    performSearchWithFilter() {
        this.csgService.getAllUnexpectedShipments(this.filters.filterString, this.filters.location, this.filters.status)
            .subscribe(
                (list) => {
                    if (!list || list.length === 0) {
                        this.noMore = true;
                    }
                    this.mapListToDisplayModel(list);
                    this.loading = false;
                });
    }

    mapListToDisplayModel(shipments: UnexpectedShipment[]) {
        this.shipments = shipments.map((s: UnexpectedShipment) => (this.mapItemToDisplayModel(s)));
    }

    mapItemToDisplayModel(shipment: UnexpectedShipment): UnexpectedShipmentDisplayModel {
        return {
            id: shipment.id,
            receivingId: shipment.receivingId,
            action: shipment.action,
            age: shipment.age,
            companyName: shipment.customer?.companyName ?? 'N/A',
            wearer: shipment.unexpectedCsgShipmentItems ? shipment.unexpectedCsgShipmentItems[0]?.wearer : 'N/A',
            createDate: shipment.createDate,
            createDateString: this.shortDate(shipment.createDate?.toString(), true),
            poNumber: shipment.poNumber,
            totalPieces: this.getTotalPieces(shipment),
            warehouseCode: shipment.warehouseCode,
            internalNotes: shipment.internalNotes,
            clientReferenceNumber: shipment.clientReferenceNumber,
            emailSentCount: shipment.emailSentCount,
            status: shipment.status
        } as UnexpectedShipmentDisplayModel;
    }

    loadMoreUnex() {
        this.addMoreloading = true;
        this.noMore = false;

        const unexpectedLength = this.shipments.length;
        this.csgService.getAllUnexpectedShipments(this.filters.filterString,
            this.filters.location,
            this.filters.status,
            100,
            unexpectedLength)
            .subscribe(results => {
                if (!results || results.length === 0) {
                    this.noMore = true;
                }
                results.forEach(ship => {
                    this.shipments.push(this.mapItemToDisplayModel(ship));
                });
                this.addMoreloading = false;
            });
    }

    sortByNumberColumn(column: string): void {
        if (this.sortHeaders[`${column}Asc`]) {
            this.shipments.sort((a, b) => a[column] - b[column]);
        } else {
            this.shipments.sort((a, b) => b[column] - a[column]);
        }
        this.sortHeaders[`${column}Asc`] = !this.sortHeaders[`${column}Asc`];
    }

    sortByStringColumn(column: string): void {
        if (this.sortHeaders[`${column}Asc`]) {
            this.shipments.sort((a, b) => (a[column] || '')
                .localeCompare(b[column] || ''));
        } else {
            this.shipments.sort((a, b) => (b[column] || '')
                .localeCompare(a[column] || ''));
        }
        this.sortHeaders[`${column}Asc`] = !this.sortHeaders[`${column}Asc`];
    }

    sortByDateColumn(column: string): void {
        if (this.sortHeaders[`${column}Asc`]) {
            this.shipments.sort((a, b) => (Date.parse(a[column]) || 0) - (Date.parse(b[column]) || 0));
        } else {
            this.shipments.sort((a, b) => (Date.parse(b[column]) || 0) - (Date.parse(a[column]) || 0));
        }
        this.sortHeaders[`${column}Asc`] = !this.sortHeaders[`${column}Asc`];
    }

    clearFilters() {
        this.loading = true;
        this.noMore = false;
        this.filters = { location: '', status: '', filterString: '' };
        this.csgService.getAllUnexpectedShipments().subscribe(r => {
            this.mapListToDisplayModel(r);
            this.loading = false;
        });
    }

    anyFiltersApplied() {
        return this.filters && (this.filters.location || this.filters.status || this.filters.filterString);
    }
}
