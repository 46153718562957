import { Component, OnInit } from '@angular/core';
import { PendingDesignListItem } from '../../models';
import { PendingDesignService } from '../../services/pending-design.service';
import { NotificationService } from 'src/app/services/notification.service';
import { EventService } from 'src/app/services/event.service';
import * as displayHelpers from '../../displayHelpers';
import * as uuid from 'uuid';
import * as moment from 'moment';

@Component({
  selector: 'app-pending-designs',
  templateUrl: './pending-design.component.html',
  styleUrls: ['./pending-design.component.scss', './../../app.component.scss']
})
export class PendingDesignComponent implements OnInit {
  public loading = false;
  noMore = false;
  middlewareEvent: { Source: string, Topic: string, Id: string, EventDate: string };
  eventToEmit: any;
  model = {
    filterString: '',
    pendingDesigns: new Array<PendingDesignListItem>()
  };

  constructor(private pendingDesignService: PendingDesignService
    , private eventService: EventService
    , private notificationService: NotificationService) { }

  ngOnInit() {
    this.pendingDesignService.getPendingDesigns().subscribe(list => this.model.pendingDesigns = list);
  }

  filterStringChanged() {
    this.loading = true;
    this.noMore = false;
    this.pendingDesignService.getPendingDesigns(0, 20, this.model.filterString).subscribe(list => this.model.pendingDesigns = list);
    this.loading = false;
  }

  completeDesign(item) {
    var getDesignInfo = this.model.pendingDesigns.filter(x => x.designId == item.designId);
    if (getDesignInfo != undefined && getDesignInfo.length >= 1) {
      this.pendingDesignService.completePendingDesign(getDesignInfo[0].eventId).subscribe(
        result => {

          //Fetch the Event data for the design from event logs - this is an already logged in event
          this.eventService.getEventItem(item.eventId).subscribe(detail => {
            this.middlewareEvent = JSON.parse(detail.content) as { Topic: string, Source: string; Id: string, EventDate: string };
            this.eventToEmit = { ...this.middlewareEvent };
            this.middlewareEvent.Id = '<generated>';
            this.middlewareEvent.EventDate = moment().format();
            this.middlewareEvent.Source = 'admin-interface';

            //Emit the event
            this.eventService.emitEvent(this.eventToEmit)
              .subscribe(m => this.notificationService.notifications.next({
                id: uuid.v4(), alertClass: 'success', message: 'Synced pricing back to EPI server!',
                title: 'Success', isRead: false, persist: false
              }));
          });

          item.isActive = false;
        });
    }
  }
}