import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventService } from 'src/app/services/event.service';
import { AuthService } from 'src/app/services/auth.service';
import { EventLogDetailItem } from 'src/app/models';
import { JsonEditorOptions } from 'ang-jsoneditor';
import { stringify } from 'querystring';
import * as moment from 'moment';
import { DeadLetterService } from 'src/app/services/dead-letter.service';

@Component({
  selector: 'app-admin-event',
  templateUrl: './admin-event.component.html',
  styleUrls: ['./admin-event.component.scss']
})
export class AdminEventComponent implements OnInit {
  model: EventLogDetailItem;
  editorOptions: JsonEditorOptions;
  emitting = false;
  emitted = false;
  emittedId = '';
  isDeadLetter = false;
  middlewareEvent: { Source: string, Topic: string, Id: string, EventDate: string };
  eventToEmit: any;
  constructor(private route: ActivatedRoute,
    private eventService: EventService,
    private authService: AuthService,
    private deadLetterService: DeadLetterService) { }

  ngOnInit() {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.modes = ['code', 'text', 'tree', 'view'];
    const { eventId, deadLetterEventId } = this.route.snapshot.params;
    
    if (deadLetterEventId) {
      this.isDeadLetter = true;
      this.deadLetterService.getDeadLetterEntry(deadLetterEventId).subscribe(detail => {
        this.middlewareEvent = JSON.parse(detail.content) as { Topic: string, Source: string; Id: string, EventDate: string };
        this.eventToEmit = { ...this.middlewareEvent };
        this.middlewareEvent.Id = '<generated>';
        this.middlewareEvent.EventDate = moment().format();
        this.middlewareEvent.Source = 'admin-interface';
        this.model = {
          id: detail.id,
          orderId: detail.orderId,
          eventName: detail.eventName,
          eventDate: detail.eventDate,
          version: detail.version,
          source: detail.source,
          content: detail.content,
          whenReceived: detail.whenReceived
        } as EventLogDetailItem;
      })
    } else {
      this.isDeadLetter = false;
      this.eventService.getEventItem(eventId).subscribe(detail => {
        this.middlewareEvent = JSON.parse(detail.content) as { Topic: string, Source: string; Id: string, EventDate: string };
        this.eventToEmit = { ...this.middlewareEvent };
        this.middlewareEvent.Id = '<generated>';
        this.middlewareEvent.EventDate = moment().format();
        this.middlewareEvent.Source = 'admin-interface';
        this.model = detail;
      });
    }
  }

  setEvent(evt: any) {
    this.eventToEmit = evt;
  }

  emit() {
    this.emitting = true;
    this.eventService.emitEvent(this.eventToEmit).subscribe(r => {
      this.emitted = true;
      this.emittedId = r;
      if (this.isDeadLetter) {
        this.deadLetterService.clearDeadLetterEntry(this.model.id, true).subscribe(res => {

        })
      }
    });
  }

}
