import { RedirectorModel, RedirectorLocation } from './../models/index';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RedirectorService {
  baseUrl = environment.baseUrl;

  constructor(private httpClient: HttpClient) { }

  getRedirectorRules(): Observable<RedirectorModel[]> {
    return this.httpClient.get<{ redirectorRules: RedirectorModel[] }>
      (`${this.baseUrl}/api/redirector`)
      .pipe(
        map(result => result.redirectorRules))
      .pipe(
        first()
      );
  }

  getRedirectorLocation(defaultLoc: string, productCode: string, customerNumber: string = "*", zipCode: string ="*"): Observable<RedirectorLocation> {
    return this.httpClient.get<RedirectorLocation>
      (`${this.baseUrl}/api/redirector/${defaultLoc}/${productCode}/${customerNumber}/${zipCode}`)
      .pipe(
        first()
      );

      //${customerNumber!==''?`/${customerNumber}/${take}`
  }

  getRedirectorRule(ruleId: string): Observable<RedirectorModel> {
    return this.httpClient.get<RedirectorModel>
      (`${this.baseUrl}/api/redirector/${ruleId}`)
      .pipe(
        first()
      );
  }

  
  addRedirectorRule(redirectorRule: RedirectorModel): Observable<any> {
    return this.httpClient.post<any>
      (`${this.baseUrl}/api/redirector/add`, redirectorRule)
      .pipe(
        first()
      );
  }

  updateRedirectorRule(redirectorRule: RedirectorModel): Observable<any> {
    return this.httpClient.put<any>
      (`${this.baseUrl}/api/redirector/update`, redirectorRule)
      .pipe(
        first()
      );
  }


  deleteRedirectorRule(redirectorRule: RedirectorModel): Observable<any> {
    return this.httpClient.post<any>
      (`${this.baseUrl}/api/redirector/del/${redirectorRule}`, null)
      .pipe(
        first()
      );
  }
  
}
