<div class="row">
  <div class="col-md">
    <h3>Update Design - {{ designId }}</h3>
  </div>
</div>

<div class="row mb-2">
  <div class="col-md">
    <form name="clientinfo" (ngSubmit)="updateClientInformation()">
      <div class="row mb-1">
        <div class="col-sm-4 data-label">
          Client Design Description
        </div>
        <div class="col">
          <input name="descr" type="text" class="form-control" [(ngModel)]="clientInformationAttr.description">
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-sm-4 data-label">
          Additional Text
        </div>
        <div class="col">
          <input name="descr2" type="text" class="form-control" [(ngModel)]="clientInformationAttr.description2">
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-sm-4 data-label">
          
        </div>
        <div class="col">
          <button class="btn btn-primary" type="submit">Update Client Information</button>
          <div *ngIf="updatedClientInfo" class="alert alert-success">
            Updated client information!
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="row mb-2">
  <div class="col">
    <a class="btn btn-sm btn-secondary" (click)="getDesign()" data-toggle="collapse" href="#details" role="button" aria-expanded="false" aria-controls="#details">More Details</a>
  </div>
</div>
<div id="details" class="row collapse">
  <div class="col-md">
    <app-design-view [model]="model"></app-design-view>
  </div>
</div>
