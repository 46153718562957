import { Observable } from 'rxjs';
import { ModalService } from 'src/app/services/modal-service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { map, scan, filter } from 'rxjs/operators';
import { eventNames } from 'process';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'wei-intranet-web';

  model = {
    user: undefined
  };
  popouts: Observable<Array<{
    message: string;
    messageSeverity: 'info' | 'warning' | 'danger' | 'success' | 'primary';
    id: string;
    isDismissed: boolean;
    expiresInSeconds: number;
  }>>;

  constructor(private authService: AuthService, private router: Router, private modalService: ModalService) { }

  ngOnInit() {
    this.authService.user.subscribe(user => this.model.user = user);
    this.authService.getUser().subscribe(result => this.model.user = result, err => this.logout());
    this.popouts = this.modalService.popouts.pipe(filter(p => !p.isDismissed)).pipe(scan((acc, cur) => [ ...acc, cur], []));
  }

  dismissEvent(event) {
    event.isDismissed = true;
  }

  logout(event?: Event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.authService.logout();
    this.router.navigateByUrl('/login');
  }

  getEnvDescription() {
    return environment.envDescription ? `${environment.envDescription}` : '';
  }

  
}
