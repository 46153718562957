import { ColorwayModel, DesignAttribute } from './../models/index';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as uuid from 'uuid';

import { DesignRequestDetailItem, DesignRequestListItem, DesignViewModel, DesignRequestSummaryItem } from './../models';

@Injectable({
  providedIn: 'root'
})
export class DesignRequestsService {

  baseUrl = environment.baseUrl;

  constructor(private httpClient: HttpClient) { }

  getDesignRequests(skip: number = 0, take: number = 20, filter: string = ''): Observable<DesignRequestListItem[]> {
    return this.httpClient.get<{ designRequests: DesignRequestListItem[] }>
      (`${this.baseUrl}/api/designrequests/${skip}/${take}/${filter}`)
      .pipe(
        map(result => result.designRequests))
      .pipe(
        first()
      );
  }

  getDesignRequest(designRequestId: string): Observable<DesignRequestDetailItem> {
    return this.httpClient.get<DesignRequestDetailItem>
      (`${this.baseUrl}/api/designrequests/${designRequestId}?includeEpiserverModel=true`)
      .pipe(
        first()
      );
  }

  getDesignRequestSummary(reqDate: string): Observable<DesignRequestSummaryItem> {
    return this.httpClient.get<DesignRequestSummaryItem>
      (`${this.baseUrl}/api/summary/drsum/${reqDate}`)
      .pipe(
        first()
      );
  }

  changeDesignRequestStatus(designRequestId: string, selectedStatus: string): Observable<any> {
    return this.httpClient.put<any>
      (`${this.baseUrl}/api/designrequests/${designRequestId}/${selectedStatus}`, null)
      .pipe(
        first()
      );
  }

  releaseDesignRequest(designRequestId: string): Observable<any> {
    return this.httpClient.put<any>
      (`${this.baseUrl}/api/designrequests/${designRequestId}`, null)
      .pipe(
        first()
      );
  }

  upsertAttributeById(attribute: DesignAttribute): Observable<any> {
    return this.httpClient.put<any>
      (`${this.baseUrl}/api/items/design/${attribute.designId}`, attribute)
      .pipe(
        first()
      );
  }

  updateDesignRequest(designRequestId: string, updateModel: { Status: string }): Observable<any> {
    return this.httpClient.put<any>
      (`${this.baseUrl}/api/designrequests/${designRequestId}/update`, updateModel)
      .pipe(
        first()
      );
  }

  getDesign(designId: string, isEpi: boolean = false): Observable<DesignViewModel> {
    if (isEpi) {
      return this.httpClient.get<DesignViewModel>
      (`${this.baseUrl}/api/items/design/episerver/${designId}`)
      .pipe(
        first()
      );
    } else {
      return this.httpClient.get<DesignViewModel>
      (`${this.baseUrl}/api/items/design/${designId}`)
      .pipe(
        first()
      );
    }
  }

  getColorways(designId: string): Observable<Array<ColorwayModel>> {
    return this.httpClient.get<Array<ColorwayModel>>
      (`${this.baseUrl}/api/items/${designId}/colorways`)
      .pipe(
        first()
      );
  }

  generateNavSku(attributes: DesignAttribute[]): Observable<string> {
    return this.httpClient.post
      (`${this.baseUrl}/api/sku/generate`, attributes, {
        responseType: 'text'
      })
      .pipe(
        first()
      );
  }

  getPricing(designId: string, navSku: string, navCustomerId: string): Observable<{
    payload: {
      data: {
        imageUrl: string,
        prices: {
          minQuantity?: number,
          amount?: number,
          currency: string,
        }[]
      }
    }
  }> {
    let url = environment.servicesBaseUrl;
    if (location.href.includes('wpsdev')) {
      url = url.replace('-dev', '-wpsdev');
    }
    return this.httpClient.get<any>
      (`${url}/api/nav/admin/sku/${navSku}/pricing/${navCustomerId}/${designId}`)
      .pipe(
        first()
      );
  }
}
