import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EpiAddressModel, LocationModel, ReturnsAddressModel, UnexpectedShipment } from 'src/app/models';
import { CsgRequestsService } from 'src/app/services/csg-requests.service';
import { LocationLookupService } from 'src/app/services/location-lookup.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-unexpected-shipments-returns',
    templateUrl: './csg-unexpected-shipments-returns.component.html'
})

export class CsgUnexpectedShipmentsReturnsComponent implements OnInit {
    loading: boolean;
    shipment: UnexpectedShipment;
    formIsValid = false;
    validationErrs = [];
    selectedShippingAgent: string;
    selectedShippingCode: string;
    shippingCodeOptions: Array<any>;
    shippingAgents = {
        'DELIVERY': ['10'],
        'EMAIL': ['EMAIL'],
        'FEDEX': ['F1', 'F2', 'F8', 'F9', 'FG'],
        'PICK UP': ['11'],
        'UPS': ['01', '02', '03', '04', '07']
    };

    shippingAgentDescriptions = [
        { id: 'EMAIL', agent: 'EMAIL', name: 'Email' },
        { id: 'F1', agent: 'FEDEX', name: 'FedEx Next Day Air' },
        { id: 'F2', agent: 'FEDEX', name: 'FedEx 2nd Day' },
        { id: 'F8', agent: 'FEDEX', name: 'FedEx 8AM' },
        { id: 'F9', agent: 'FEDEX', name: 'Fedex Saturday Delivery' },
        { id: 'FG', agent: 'FEDEX', name: 'FedEx Ground' },
        { id: '11', agent: 'PICK UP', name: 'Local Pickup' },
        { id: '01', agent: 'UPS', name: 'UPS Ground' },
        { id: '02', agent: 'UPS', name: 'UPS 2nd Day' },
        { id: '03', agent: 'UPS', name: 'UPS Next Day Air' },
        { id: '04', agent: 'UPS', name: 'UPS Early 8AM' },
        { id: '07', agent: 'UPS', name: 'UPS Sat Delivery' },
        { id: '10', agent: 'DELIVERY', name: 'Local Delivery' }
    ];

    addressModel = {
        addressNickname: '',
        phone: '',
        extension: '',
        email: '',
        country: '',

        contactName: '',
        companyName: '',
        line1: '',
        line2: '',
        city: '',
        state: '',
        postalCode: '',
        countryCode: '',
        warehouseCode: '',
        shippingAgentCode: '',
        shippingAgentServiceCode: ''

    } as ReturnsAddressModel;

    locationModel = new Array<LocationModel>();
    saveInProgress = false;

    constructor(
        private csgService: CsgRequestsService,
        private notificationService: NotificationService,
        private router: Router,
        private route: ActivatedRoute,
        private locationService: LocationLookupService) { }

    ngOnInit() {
        this.loading = true;
        const { unexpectedId } = this.route.snapshot.params;
        this.locationService.getLocations().subscribe(results => {
            this.locationModel = results;
        });

        this.csgService.getUnexpectedShipmentById(unexpectedId).subscribe(r => {
            this.shipment = r;
            this.addressModel.warehouseCode = this.shipment.warehouseCode;
            this.addressModel.companyName = this.shipment.customer.companyName;
            this.loading = false;
        });
    }

    sendReturnRequest() {
        this.saveInProgress = true;
        this.validateForm();
        if (this.formIsValid) {
            this.csgService.sendReturnRequest(this.shipment.id, this.addressModel).subscribe(response => {
                if (response.success) {
                    this.notificationService.showPopup('Return Request Accepted!', 'success', 'Success!');
                    this.saveInProgress = false;
                    this.router.navigate(['/csgrequests/unexpected/' + this.shipment.id]);
                } else {
                    this.notificationService.showPopup(`Error Submitting Return! ${response.message}`, 'danger', 'Error!');
                    this.saveInProgress = false;
                }
            }, err => {
                this.notificationService.showPopup('Error Sending Return', 'danger', 'Error');
                this.saveInProgress = false;
            });
        } else {
            this.saveInProgress = false;
            const validations = this.validationErrs.join(', ');
            this.notificationService.showPopup(`Must Provide Values For: ${validations}`, 'danger', 'Unable To Process Return!');
        }
    }

    validateForm() {
        this.formIsValid = true;
        this.validationErrs = [];

        if (this.IsNullOrEmpty(this.addressModel.contactName)) {
            this.formIsValid = false;
            this.validationErrs.unshift('Contact Name');
        }
        if (this.IsNullOrEmpty(this.addressModel.line1)) {
            this.formIsValid = false;
            this.validationErrs.unshift('Address Line 1');
        }
        if (this.IsNullOrEmpty(this.addressModel.city)) {
            this.formIsValid = false;
            this.validationErrs.unshift('City');
        }
        if (this.IsNullOrEmpty(this.addressModel.state)) {
            this.formIsValid = false;
            this.validationErrs.unshift('State');
        }
        if (this.IsNullOrEmpty(this.addressModel.postalCode)) {
            this.formIsValid = false;
            this.validationErrs.unshift('Postal Code');
        }
        if (this.IsNullOrEmpty(this.addressModel.countryCode)) {
            this.formIsValid = false;
            this.validationErrs.unshift('Country Code');
        }
        if (this.IsNullOrEmpty(this.addressModel.shippingAgentCode)) {
            this.formIsValid = false;
            this.validationErrs.unshift('Shipping Agent');
        }
        if (this.IsNullOrEmpty(this.addressModel.warehouseCode)) {
            this.formIsValid = false;
            this.validationErrs.unshift('Warehouse Code');
        }
        if (this.IsNullOrEmpty(this.addressModel.shippingAgentServiceCode)) {
            this.formIsValid = false;
            this.validationErrs.unshift('Shipping Service Code');
        }
        if (this.IsNullOrEmpty(this.addressModel.companyName)) {
            this.formIsValid = false;
            this.validationErrs.unshift('Company Name');
        }
    }

    shippingAgentNames() {
        return Object.keys(this.shippingAgents);
    }

    setShippingCodes() {
        this.shippingCodeOptions = this.shippingAgentDescriptions.filter(s => s.agent == this.addressModel.shippingAgentCode);
        this.addressModel.shippingAgentServiceCode = this.shippingCodeOptions[0].id;
    }

    IsNullOrEmpty(item: string): boolean {
        return !item || item === '';
      }
}
