import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { ProductRevenueSummaryItem } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class ProductRevenueService {

  baseUrl = environment.baseUrl;

  constructor(private httpClient: HttpClient) { }

  getProductRevenueSummary(): Observable<ProductRevenueSummaryItem> {
    return this.httpClient.get<ProductRevenueSummaryItem>
    (`${this.baseUrl}/api/summary/prsum`)
    .pipe(map(result => result));
  }
}

