<div *ngIf="customers">
  <div class="card" style="min-width: 27rem;">
    <div class="card-body">
      <h5 class="card-title" i18n>All Customers</h5>
      <ul class="list-group">
        <li class="list-group-item" *ngFor="let customer of customers">
          <a class="btn btn-sm btn-primary" [routerLink]="['/customers', customer.id]">Details</a>
          {{ customer.sourceCustomerNumber }} - {{ customer.companyName }}
          <span class="badge badge-success" *ngIf="customer.isEnabled"> <i class="fa fa-check"></i> Enabled</span>
        </li>
      </ul>
    </div>
  </div>
</div>