import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventLogDetailItem } from 'src/app/models';
import { EventService } from 'src/app/services/event.service';

import { longDateWithSeconds } from './../../../displayHelpers';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-event-log-details',
  templateUrl: './event-log-details.component.html',
  styleUrls: ['./event-log-details.component.scss', './../../../app.component.scss']
})
export class EventLogDetailsComponent implements OnInit {
  model: EventLogDetailItem;
  longDateWithSeconds = longDateWithSeconds;
  isAdmin = false;

  constructor(private route: ActivatedRoute,
    private eventService: EventService,
    private authService: AuthService) { }

  ngOnInit() {
    const { eventId } = this.route.snapshot.params;
    this.eventService.getEventItem(eventId).subscribe(detail => {
      this.model = detail;
    });
  }

  getEventContent(): string {
    return JSON.stringify(JSON.parse(this.model.content), null, 1);
  }

  showAdminEvent() {
    return this.authService.isAdmin();
  }
}
