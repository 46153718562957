import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { LocationModel } from './../models';

@Injectable({
  providedIn: 'root'
})
export class LocationLookupService {

  baseUrl = environment.baseUrl;

  constructor(private httpClient: HttpClient) { }


  getLocations(isCsg: boolean = false): Observable<LocationModel[]> {
    return this.httpClient.get<LocationModel[]>
      (`${this.baseUrl}/api/location/locations?isCsg=${isCsg}`)
      .pipe(
        first()
      );
  }
  // getLocationById(skip: number = 0, take: number = 20): Observable<LocationModel> {
  //   return this.httpClient.get<{ aliasLookups:  }>
  //     (`${this.baseUrl}/api/aliaslookup/${skip}/${take}`)
  //     .pipe(
  //       map(result => result.aliasLookups))
  //     .pipe(
  //       first()
  //     );
  // }
}
