<div class="row">
  <div class="col">
    <a [routerLink]="['../../requests']">Back to list</a>
  </div>
</div>
<div *ngIf="model?.model && model?.model?.createDesign.createDesignOption !== 'EnterText'" id="previewComponent"
  [attr.data-baseurl]="environment.shopUrl" [attr.data-designrequest]="designRequestModel"></div>
<div *ngIf="model?.model?.createDesign.createDesignOption === 'EnterText'"> 
  <div *ngIf="model.model?.createDesign?.line1">
    Line 1:
    <div>Text: {{model.model?.createDesign?.line1}}, Font: {{model.model?.createDesign?.line1Font}}</div>
  </div>
  <div *ngIf="model.model?.createDesign?.line2">
    Line 2:
    <div>Text: {{model.model?.createDesign?.line2}}, Font: {{model.model?.createDesign?.line2Font}}</div>
  </div>
  <div *ngIf="model.model?.createDesign?.line3">
    Line 3:
    <div>Text: {{model.model?.createDesign?.line3}}, Font: {{model.model?.createDesign?.line3Font}}</div>
  </div>
</div>
<div *ngIf="model && !loading" class="row">
  <div class="col-sm">
    <div class="card" style="min-width: 27rem;">
      <img *ngIf="model.artwork == 'Upload'; else displayArtworkText" src="{{ model.textbox1Art }}"
        style="max-height: 600px;" class="card-img-top" alt="Art">
      <ng-template #displayArtworkText>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title" i18n>Requested Text</h5>
            <div class="row">
              <div class="col-sm-4 data-label">
                Text
              </div>
              <div class="col-auto data-label">
                Font
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4" [innerHTML]="model.textbox1Art">
              </div>
              <div class="col-auto">
                {{ model.textbox1Font }}
              </div>
            </div>
            <div class="row" *ngIf="model.textbox2Art && model.textbox2Art !== ''">
              <div class="col-sm-4" [innerHTML]="model.textbox2Art">
              </div>
              <div class="col-auto">
                {{ model.textbox2Font }}
              </div>
            </div>
            <div class="row" *ngIf="model.textbox3Art && model.textbox3Art !== ''">
              <div class="col-sm-4" [innerHTML]="model.textbox3Art">
              </div>
              <div class="col-auto">
                {{ model.textbox3Font }}
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <div class="card-body">
        <h5 class="card-title">{{ model.description }}</h5>
        <div class="row">
          <div class="col-sm-4 data-label">
            Status
          </div>
          <div class="col-auto">
            {{ model.status }} <a href="#" (click)="toggleSetStatus($event)">Change</a>
          </div>
        </div>
        <div class="row" *ngIf="showStatusEdit">
          <div class="col-auto">
            <input class="form-control" [(ngModel)]="model.status">
          </div>
          <div class="col-auto">
            <button (click)="updateDr($event)" class="btn btn-success mr-1">Set Status</button>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 data-label">
            Requestor
          </div>
          <div class="col-auto">
            {{ model.requestor }}
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 data-label">
            Design ID
          </div>
          <div class="col-auto">
            {{ model.designId }}
          </div>
        </div>
        <div class="row"
          *ngIf="model.copiedFrom != '' && model.copiedFrom != null && model.copiedFrom != model.designId">
          <div class="col-sm-4 data-label">
            Copied From
          </div>
          <div class="col-auto">
            {{ model.copiedFrom }}
          </div>
        </div>
        <div *ngIf="model.poNumber" class="row">
          <div class="col-sm-4 data-label">
            Purchase Order
          </div>
          <div class="col-auto">
            {{ model.poNumber }}
          </div>
        </div>
        <div class="row" *ngIf="model.searchField1 && model.searchField1 !== ''">
          <div class="col-sm-4 data-label">
            Search Keywords
          </div>
          <div class="col-auto">
            {{ model.searchField1 }}
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 data-label">
            Received/DateTime
          </div>
          <div class="col-auto">
            {{ longDateWithSeconds(model.dateTime, true) }}
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 data-label">
            Customer No
          </div>
          <div class="col-auto">
            <a i18n routerLink="/customers/{{ model.navCusNo }}">{{ model.cusNo }}</a>
            {{ model.companyName }}
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 data-label">
            <a [attr.href]="model.originalDesignArtwork" target="_blank" [download]="getOriginalArtworkName(model.originalDesignArtwork)"
              *ngIf="model.originalDesignArtwork" id="downloadImage">Download Original Artwork</a>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 data-label">
            <a [attr.href]="model.vectorizedImage" target="_blank" [download]="getOriginalArtworkName(model.vectorizedImage)"
            *ngIf="model.vectorizedImage" id="downloadVectorizedImage">Download Vectorized Image</a>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 data-label">
            <a [attr.href]="model.vectorizedColorReduction" target="_blank" [download]="getOriginalArtworkName(model.vectorizedColorReduction)"
            *ngIf="model.vectorizedColorReduction" id="downloadColorImage">Download Vectorized Color Reduction Image</a>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 data-label">
            <a [attr.href]="model.vectorizedDstFile" target="_blank" [download]="getOriginalArtworkName(model.vectorizedDstFile)"
            *ngIf="model.vectorizedDstFile" id="downloadDst">Download Vectorized DST File</a>
          </div>
        </div>        
        <div class="row" *ngIf="model.designValidatedBy">
          <div class="col-sm-4 data-label">
            Last Validated By
          </div>
          <div class="col-auto">
            {{ model.designValidatedBy }}
          </div>
        </div>
        <div class="row" *ngIf="model.designLastValidatedDate">
          <div class="col-sm-4 data-label">
            Last Validated Date
          </div>
          <div class="col-auto">
            {{ calendarDate(model.designLastValidatedDate) }}
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-sm-4">
            <button [attr.disabled]="(isReleasing || isReleased) ? 'disabled' : undefined" (click)="edit($event)"
              class="btn btn-secondary">Edit</button>
          </div>
          <div class="col-auto align-top">
            <button style="display: inline-block; vertical-align: top"
              [attr.disabled]="(isReleasing || isReleased) ? 'disabled' : undefined" (click)="release($event)"
              class="btn btn-success mr-1">Release</button>
            <div *ngIf="isReleased" style="display: inline-block; vertical-align: bottom"
              class="ml-2 mb-0 alert alert-success">Design request released!</div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="col-sm">
    <div class="card" style="min-width: 27rem;">
      <div class="card-body">
        <h5 class="card-title" i18n>Product</h5>
        <div class="row">
          <div class="col-sm-4 data-label">
            Product Requested
          </div>
          <div class="col-auto">
            {{ model.drProductClassesNm }}
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 data-label">
            Product Type
          </div>
          <div class="col-auto">
            {{ model.productTypeName }}
          </div>
        </div>
        <div class="row" *ngIf="model.garmentTypeName && model.garmentTypeName !== ''">
          <div class="col-sm-4 data-label">
            Garment Type
          </div>
          <div class="col-auto">
            {{ model.garmentTypeName }}
          </div>
        </div>
        <div class="row" *ngIf="model.safetyStripeName && model.safetyStripeName !== ''">
          <div class="col-sm-4 data-label">
            Stripe Name
          </div>
          <div class="col-auto">
            {{ model.safetyStripeName }}
          </div>
        </div>
        <div class="row" *ngIf="model.stripingPlacements && model.stripingPlacements.length > 0">
          <div class="col-sm-4 data-label">
            Placements
          </div>
          <div class="col-auto">
            <ul class="list-group">
              <li class="list-group-item" *ngFor="let placement of model.stripingPlacements">
                {{ placement.name }}
              </li>
            </ul>
          </div>
        </div>
        <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
      </div>
    </div>
    <div *ngIf="!model.safetyStripeName || model.safetyStripeName === ''" class="card" style="min-width: 27rem;"
      [ngClass]="{'alert-danger': incompleteSizeInfo}">
      <div class="card-body">
        <h5 class="card-title" i18n>Style and Location</h5>
        <div class="row" *ngIf="model.drDropperLayoutsNm && model.drDropperLayoutsNm !== ''">
          <div class="col-sm-4 data-label">
            Layout
          </div>
          <div class="col-auto">
            {{ model.drDropperLayoutsNm }}
          </div>
        </div>
        <div class="row" *ngIf="model.shapeSizeInfo?.id !== ''">
          <div class="col-sm-4 data-label">
            Standard Size / Shape
          </div>
          <div class="col-sm-8">
            {{ model.shapeSizeInfo?.nm }} - {{ model.shapeSizeInfo?.name }}
          </div>
        </div>
        <div class="row" *ngIf="weiSizeImage(model.shapeSizeInfo?.id, model.height, model.width)">
          <div class="col-sm-4 data-label">
            Sizing
          </div>
          <div class="col-auto">
            WEI will size your design based on your selected location.
          </div>
        </div>
        <div class="row" *ngIf="numberGreaterThanZero(model.width)">
          <div class="col-sm-4 data-label">
            Width
          </div>
          <div class="col-auto">
            {{ model.width }}
          </div>
        </div>
        <div class="row" *ngIf="numberGreaterThanZero(model.height)">
          <div class="col-sm-4 data-label">
            Height
          </div>
          <div class="col-auto">
            {{ model.height }}
          </div>
        </div>
        <div *ngIf="(model.maintainProportions != '') && (model.maintainProportions != null)">
          The proportions will be maintained
        </div>
        <div class="row" *ngIf="model.embellishmentGroupsDs && model.embellishmentGroupsDs !== ''">
          <div class="col-sm-4 data-label">
            Group
          </div>
          <div class="col-auto">
            {{ model.embellishmentGroupsDs }}
          </div>
        </div>
        <div class="row" *ngIf="model.impacCodesNm && model.impacCodesNm !== ''">
          <div class="col-sm-4 data-label">
            Impac Codes
          </div>
          <div class="col-sm-8">
            {{ model.impacCodesNm }}
          </div>
        </div>
        <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
      </div>
    </div>
    <div class="card" style="min-width: 27rem;">
      <div class="card-body">
        <h5 class="card-title" i18n>Emblem Information</h5>
        <div class="row" *ngIf="model.fabricTypeId && model.fabricTypeId !== ''">
          <div class="col-sm-4 data-label">
            Fabric type
          </div>
          <div class="col-auto">
            {{ model.fabricTypeId }}
          </div>
        </div>
        <div class="row" *ngIf="model.fabricNm && model.fabricNm !== ''">
          <div class="col-sm-4 data-label">
            Fabric Color
          </div>
          <div class="col-auto">
            {{ model.fabricNm }}
          </div>
        </div>
        <div class="row" *ngIf="model.designLocId && model.designLocId !== ''">
          <div class="col-sm-4 data-label">
            Location
          </div>
          <div class="col-auto">
            {{ model.designLocId }}
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 data-label" *ngIf="model.borderNm && model.borderNm !== ''">
            Border
          </div>
          <div class="col-auto">
            {{ model.borderNm }}
          </div>
        </div>
        <div class="row" *ngIf="model.backingNm && model.backingNm !== ''">
          <div class="col-sm-4 data-label" >
            Backing
          </div>
          <div class="col-auto">
            {{ model.backingNm }}
          </div>
        </div>
        <div class="row"  *ngIf="model.sewingChannel && model.sewingChannel !== ''">
          <div class="col-sm-4 data-label">
            Sewing Channel
          </div>
          <div class="col-auto">
            {{ model.sewingChannel }}
          </div>
        </div>
        <div class="row" *ngIf="model.sewingGuideColor && model.sewingGuideColor !== ''">
          <div class="col-sm-4 data-label">
            Guide Color
          </div>
          <div class="col-auto">
            {{ model.sewingGuideColor }}
          </div>
        </div>
        <div class="row" *ngIf="model.expectedDate && model.expectedDate !== ''">
          <div class="col-sm-4 data-label">
            Expected Send Date
          </div>
          <div class="col-auto">
            {{ model.expectedDate }}
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!model.safetyStripeName || model.safetyStripeName == ''" class="card" style="min-width: 27rem;"
      [ngClass]="{'alert-danger': incompleteColorInfo}">
      <div class="card-body">
        <h5 class="card-title" i18n>Color</h5>
        <div *ngIf="model.threadInkColors.length === 0; else displayColors">
          A designer at WEI will select the colors for your design.
        </div>
        <ng-template #displayColors>
          <div *ngFor="let color of model.threadInkColors.split('|')">
            <div [innerHTML]="color" style="white-space: pre-line;">
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="col-sm">
    <div class="card" style="min-width: 27rem;">
      <div class="card-body">
        <h5 class="card-title" i18n>Delivery Options</h5>
        <div class="row">
          <div class="col-sm-5 data-label">
            Ship Via
          </div>
          <div class="col-sm-2 data-label">
            Qty
          </div>
          <div class="col-sm-5 data-label">
            Address
          </div>
        </div>
        <hr />
        <div class="row" *ngFor="let emails of emailAddresses">
          <div class="col-sm-5 data-label">
            {{ 'Email Digital Passport' }}
          </div>
          <div class="col-sm-2">
            1
          </div>
          <div class="col-sm-5">
            {{ emails.email}}
          </div>
        </div>

        <div class="row" *ngFor="let address of physicalSamples">
          <div class="col-sm-5 data-label">
            {{ address.shipVia }}
          </div>
          <div class="col-sm-2">
            {{address.quantity}}
          </div>
          <div class="col-sm-5">
            {{ this.getAddressForRow(address) }}
          </div>
        </div>
        <!-- <ng-template #physicalSample>

          <hr />
          <div class="row">
            <div class="col-sm-5 data-label">
              {{ model.shipVia1Nm || 'Email Digital Passport' }}
            </div>
            <div class="col-sm-2">
              {{ model.qty1 }}
            </div>
            <div class="col-sm-5">
              {{ model.address1 || model.emailAddr1 }}
            </div>
          </div>
          <div *ngIf="numberGreaterThanZero(model.qty2)" class="row">
            <div class="col-sm-5 data-label">
              {{ model.shipVia2Nm || 'Email Digital Passport' }}
            </div>
            <div class="col-sm-2">
              {{ model.qty2 }}
            </div>
            <div class="col-sm-5">
              {{ model.address2 || model.emailAddr2 }}
            </div>
          </div>
          <div *ngIf="numberGreaterThanZero(model.qty3)" class="row">
            <div class="col-sm-5 data-label">
              {{ model.shipVia3Nm || 'Email Digital Passport' }}
            </div>
            <div class="col-sm-2">
              {{ model.qty3 }}
            </div>
            <div class="col-sm-5">
              {{ model.address3 || model.emailAddr3 }}
            </div>
          </div>
        </ng-template> -->
      </div>
    </div>
    <div class="card" style="min-width: 27rem;" *ngIf="model.contacts">
      <div class="card-body">
        <h5 class="card-title" i18n>Orders Contacts</h5>
        <div class="row">
          <div class="col-sm-4 data-label">
            Contact
          </div>
          <div class="col-auto">
            {{ model.contacts }}
          </div>
        </div>
        <div class="row" *ngIf="model.salesRepName && model.salesRepName !== ''">
          <div class="col-sm-4 data-label">
            Sales Rep
          </div>
          <div class="col-auto">
            {{ model.salesRepName }}
          </div>
        </div>
      </div>
    </div>

    <div class="card" style="min-width: 27rem; max-height: 30em; overflow-y: auto"
      *ngIf="model && model.specInstr && model.specInstr !== ''">
      <div class="card-body">
        <h5 class="card-title" i18n>Special Instructions</h5>
        <div class="card-text">
          <div class="card-text" [innerHTML]="model.specInstr" style="white-space: pre-line;">
          </div>
        </div>
      </div>
    </div>

    <div class="card" style="min-width: 27rem; max-height: 30em; overflow-y: auto"
      *ngIf="model && model.specInstr3D && model.specInstr3D !== ''">
      <div class="card-body">
        <h5 class="card-title" i18n>3D Special Instructions</h5>
        <div class="card-text" *ngFor="let itemSpecialInstr3D of specInstr3D">
          <div class="card-text" [innerHTML]="itemSpecialInstr3D" style="white-space: pre-line;">
          </div>
        </div>
      </div>
    </div>

    <div class="card" style="min-width: 27rem; max-height: 30em; overflow-y: auto"
      *ngIf="model && model.specInstrPrintStitch && model.specInstrPrintStitch !== ''">
      <div class="card-body">
        <h5 class="card-title" i18n>Print Stitch Special Instructions</h5>
        <div class="card-text" *ngFor="let itemSpecialInstrPS of specInstrPrintStitch">
          <div class="card-text" [innerHTML]="itemSpecialInstrPS" style="white-space: pre-line;">
          </div>
        </div>
      </div>
    </div>

    <div class="card" style="min-width: 27rem; max-height: 30em; overflow-y: auto"
      *ngIf="model && model.specInstrLeather && model.specInstrLeather !== ''">
      <div class="card-body">
        <h5 class="card-title" i18n>Leather Special Instructions</h5>
        <div class="card-text" *ngFor="let itemSpecialInstrLthr of specInstrLeather">
          <div class="card-text" [innerHTML]="itemSpecialInstrLthr" style="white-space: pre-line;">
          </div>
        </div>
      </div>
    </div>



    <div class="card" style="min-width: 27rem; max-height: 30em; overflow-y: auto"
      *ngIf="model.events && model.events.length">
      <div class="card-body">
        <h5 class="card-title" i18n>Event Log</h5>
        <div class="table-responsive">
          <table class="table wei-table-striped">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col" i18n>Event Name</th>
                <th scope="col" i18n>Source</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of model.events"
                [ngClass]="{'exception-row': (item.eventName.includes('exception'))}">
                <td>
                  {{ calendarDate(item.eventDate) }}
                </td>
                <td>
                  {{ item.eventName || 'No Event Name' }}
                </td>
                <td>
                  {{ item.source }}
                </td>
                <td>
                  <a i18n routerLink="/events/{{ item.id }}">Details</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="model" class="row">
  <div class="col" *ngIf="model.editReasonCmt && model.editReasonCmt !== ''">
    <div class="card" style="min-width: 27rem;">
      <div class="card-body">
        <h5 class="card-title" i18n>Edit Reason Comments</h5>
        <div class="card-text">
          {{ model.editReasonCmt }}
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="model" class="row">
  <div class="col" *ngIf="model.changes && model.changes && model.changes.length">
    <div class="card" style="min-width: 27rem;">
      <div class="card-body">
        <h5 class="card-title" i18n>Edit / Revision Tracking</h5>
        <div class="row" *ngFor="let item of model.changes">
          <div class="col-sm-4 data-label">
            {{ item.fieldDisplay }}
          </div>
          <div class="col-auto alert-success">
            {{ item.newValue }}
          </div>
          <div class="col-auto alert-danger">
            <del>{{ item.oldValue }}</del>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>