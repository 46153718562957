import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { SalesOrderListItem, SalesOrderDetailItem, SalesOrderSummary, ProductionOrderSummaryItem,CsgPdfForm, SalesOrderUpdate, DeleteModel, OrderFile, NavSalesOrderStatusModel } from './../models';

@Injectable({
  providedIn: 'root'
})
export class SalesOrderService {

  baseUrl = environment.baseUrl;

  constructor(private httpClient: HttpClient) { }

  getSalesOrders(skip: number = 0, take: number = 20, searchTerm: string = ''): Observable<SalesOrderListItem[]> {
    return this.httpClient.get<{ salesOrders: SalesOrderListItem[] }>
      (`${this.baseUrl}/api/orders/${skip}/${take}/${searchTerm}`)
      .pipe(
        map(result => result.salesOrders))
      .pipe(
        first()
      );
  }
  
  getCsgPDF(csgRequestId:string): Observable<CsgPdfForm[]> {
    return this.httpClient.get<CsgPdfForm[]>
      (`${this.baseUrl}/api/orders/csg/form/${csgRequestId}`)
      //ask jon about using first() vs. map(result=>result.etc)
      .pipe(
        first()
      );
  }

  getBarcodeForOrderId(id: string): Observable<Blob> {
    return this.httpClient.get(`${this.baseUrl}/api/orders/barcode/${id}`, { responseType: 'blob' });
  }

  getBarcodeForOrderIdString(id: string): string {
    return `${this.baseUrl}/api/orders/barcode/${id}`;
  }

  getSalesOrdersFromCsgId(csgRequestId:string): Observable<SalesOrderListItem[]> {
    return this.httpClient.get<{ salesOrders: SalesOrderListItem[] }>
      (`${this.baseUrl}/api/orders/list/csg/${csgRequestId}`)
      .pipe(
        map(result => result.salesOrders))
      .pipe(
        first()
      );
  }
  getSalesOrder(orderId: string, includeDeleted = false): Observable<SalesOrderDetailItem> {
    return this.httpClient.get<SalesOrderDetailItem>
      (`${this.baseUrl}/api/orders/${orderId}?includeDeleted=${includeDeleted}`)
      .pipe(
        first()
      );
  }
  getSalesOrderFromCsgId(csgRequestId:string):Observable<SalesOrderDetailItem>{
    return this.httpClient.get<SalesOrderDetailItem>
      (`${this.baseUrl}/api/orders/csg/${csgRequestId}`)
      .pipe(
        first()
      );
  }

  getOrderFiles(orderId: string): Observable<OrderFile[]> {
    return this.httpClient.get<OrderFile[]>
      (`${this.baseUrl}/api/orders/${orderId}/files`)
      .pipe(
        first()
      );
  }

  getStatusFromNAV(orderId: string): Observable<NavSalesOrderStatusModel> {
    return this.httpClient.get<NavSalesOrderStatusModel>
      (`${this.baseUrl}/api/orders/nav/status/${orderId}`)
      .pipe(map(result => result));
  }

  getSalesOrderSummary(): Observable<SalesOrderSummary[]> {
    return this.httpClient.get<SalesOrderSummary[]>
    (`${this.baseUrl}/api/orders/summary`)
    .pipe(map(result => result));
  }

  getProductionOrderSummary(reqDate: string): Observable<ProductionOrderSummaryItem> {
    return this.httpClient.get<ProductionOrderSummaryItem>
    (`${this.baseUrl}/api/summary/posum/${reqDate}`)
    .pipe(map(result => result));
  }

  trackOrder(orderId: string): Observable<{ status: string, message: string, salesOrderId: string}> {
    return this.httpClient.post<{ status: string, message: string, salesOrderId: string}>
      (`${this.baseUrl}/api/orders/RetryRelease`, { salesOrderId: orderId })
      .pipe(
        first()
      );
  }

  redirectOrder(salesOrderModel: SalesOrderDetailItem, sendToWps: boolean = false): Observable<{ status: string, message: string, salesOrderId: string}> {
    const updates = { lineUpdates: salesOrderModel.lines.map(l => ({ lineNumber: l.lineNumber, locationCode: l.locationCode })) };
    return this.httpClient.put<{ status: string, message: string, salesOrderId: string}>
      (`${this.baseUrl}/api/orders/Redirect?sendToWps=${sendToWps}`, { salesOrderId: salesOrderModel.id, orderUpdates: updates })
      .pipe(
        first()
      );
  }

  deleteOrder(deleteModel: DeleteModel): Observable<{ status: string, message: string, salesOrderId: string}> {
    return this.httpClient.post<{ status: string, message: string, salesOrderId: string}>
      (`${this.baseUrl}/api/orders/delete/${deleteModel.SalesOrderNumber}`, {
        DeleteSalesOrderModel: deleteModel
      })
      .pipe(
        first()
      );
  }

  sendUpdatePayload(updateModel: SalesOrderUpdate): Observable<{ status: string, message: string, salesOrderId: string}> {
    console.log(`${this.baseUrl}/api/orders/update/${updateModel.OrderId}`);
    return this.httpClient.post<{ status: string, message: string, salesOrderId: string}>
      (`${this.baseUrl}/api/orders/update/${updateModel.OrderId}`, updateModel)
      .pipe(
        first()
      );
  }

  setEdiFlag(salesOrderId: string, ediFlag: boolean): Observable<{status: string, message: string, salesOrderId: string}> {
    return this.httpClient.post<{status: string, message: string, salesOrderId: string}>
    (`${this.baseUrl}/api/orders/edi/${salesOrderId}`, {EDIFlag: ediFlag})
    .pipe(
      first()
    );
  }

  requestRetryCDRFileInitialization(designId: string, overwrite: boolean = false, orderSource: string = 'episerver-order'): Observable<any> {
    return this.httpClient.put(`${this.baseUrl}/api/orders/retrydesigninitialization/${designId}?overwrite=${overwrite}&orderSource=${orderSource}`, {})
    .pipe(
      first()
    );
  }
}
