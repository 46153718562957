import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import {
  CsgReceiveRequest, CsgReceiveResponse, CsgRequestDetailItem,
  CsgRequestListItem, DesignDetail, EpiAddressModel, OrderFile, PendingCsgOrdersWithSameDesignIds, ReleasedCsgRequestItemModel,
  UnexpectedShipment,
  UnexpectedShipmentEmailModel
} from './../models';

export const unexpected = 'Unexpected Shipment';

@Injectable({
  providedIn: 'root'
})
export class CsgRequestsService {

  baseUrl = environment.baseUrl;
  lastModel: CsgRequestDetailItem;
  lastUnexpectedModel: UnexpectedShipment;

  constructor(private httpClient: HttpClient) { }

  getCsgRequests(filter?: string, csgStatus?: string, loadMore?: boolean, take?: Number, skip?: Number): Observable<CsgRequestListItem[]> {
    return this.httpClient.get<CsgRequestListItem[]>
      // tslint:disable-next-line:max-line-length
      (`${this.baseUrl}/api/csgrequests?${filter ? `filter=${filter}` : ''}&${csgStatus ? `status=${csgStatus}` : ''}&${loadMore ? `loadMore=${loadMore}` : ''}&${take ? `take=${take}` : ''}&${skip ? `skip=${skip}` : ''}`)
      .pipe(
        first()
      );
  }

  getCsgRequest(csgRequestId: string, includeRawOrder = false): Observable<CsgRequestDetailItem> {
    return this.httpClient.get<CsgRequestDetailItem>
      (`${this.baseUrl}/api/csgrequests/${csgRequestId}?includeRawOrderModel=${includeRawOrder}`)
      .pipe(
        first()
      );
  }

  getCsgRequestFiles(csgRequestId: string, isUnexpected: boolean = false): Observable<OrderFile[]> {
    return this.httpClient.get<OrderFile[]>
      (`${this.baseUrl}/api/csgrequests/${csgRequestId}/files?isUnexpected=${isUnexpected}`)
      .pipe(
        first()
      );
  }

  releaseCsgRequest(csgRequestId: string, csgRequestReleasedItems: ReleasedCsgRequestItemModel[], location: string): Observable<any> {
    const request = { csgRequestId, csgRequestReleasedItems, location };
    return this.httpClient.put<any>
      (`${this.baseUrl}/api/csgrequests/release`, request)
      .pipe(
        first()
      );
  }

  releaseCsgRequestWithUploads(csgRequestId: string, csgRequestReleasedItems: ReleasedCsgRequestItemModel[], location: string,uploadedURLs: string): Observable<any> {
    const request = { csgRequestId, csgRequestReleasedItems, location,uploadedURLs };
    return this.httpClient.put<any>
      (`${this.baseUrl}/api/csgrequests/release`, request)
      .pipe(
        first()
      );
  }

  addCsgRequest(model: CsgRequestDetailItem): Observable<any> {
    return this.httpClient.post<any>
      (`${this.baseUrl}/api/csgrequests/add`, model)
      .pipe(
        first()
      );
  }

  updateCsgRequestLocation(csgRequestId: string, updatedLocation: string): Observable<any> {
    return this.httpClient.put<any>
      (`${this.baseUrl}/api/csgrequests/${csgRequestId}/${updatedLocation}/updateLocation`, null)
      .pipe(
        first()
      );
  }

  cancelCsgRequest(csgRequestId: string): Observable<any> {
    return this.httpClient.put<any>
      (`${this.baseUrl}/api/csgrequests/${csgRequestId}/cancel`, null)
      .pipe(
        first()
      );
  }
  updateCsgRequestComments(csgRequestId: string, comments: string): Observable<any> {
    return this.httpClient.put<any>
      (`${this.baseUrl}/api/csgrequests/${csgRequestId}/${comments}/updateComments`, null)
      .pipe(
        first()
      );
  }
  updateCsgRequestBoxes(csgRequestId: string, numberOfBoxes: number, isLegacy: boolean): Observable<any> {
    return this.httpClient.put<any>
      (`${this.baseUrl}/api/csgrequests/${csgRequestId}/${numberOfBoxes}/updateBoxes?isLegacy=${isLegacy}`, null)
      .pipe(
        first()
      );
  }

  reenterCsgRequest(csgRequestId: string, isLegacyCsg: boolean): Observable<any> {
    return this.httpClient.put<any>
      (`${this.baseUrl}/api/csgrequests/${csgRequestId}/reenter?isLegacyCsg=${isLegacyCsg}`, null)
      .pipe(
        first()
      );
    return null;
  }

  reprocessUpdatedDesigns(csgRequestId: string, isLegacyCsg: boolean): Observable<any> {
    return this.httpClient.put<any>
      (`${this.baseUrl}/api/csgrequests/${csgRequestId}/reprocessupdateddesigns?isLegacyCsg=${isLegacyCsg}`, null)
      .pipe(
        first()
      );
  }

  receiveOnly(receiveRequest: CsgReceiveRequest, sendEmail: boolean): Observable<CsgReceiveResponse> {
    return this.httpClient.post<any>
      (`${this.baseUrl}/api/csgrequests/receive?sendEmail=${sendEmail}`, receiveRequest)
      .pipe(
        first()
      );
  }

  completeUnexpectedReturn(unexpectedId: string): Observable<UnexpectedShipment> {
    return this.httpClient.put<UnexpectedShipment>
      (`${this.baseUrl}/api/csgrequests/unexpectedshipments/${unexpectedId}/completereturn`, {})
      .pipe(
        first()
      );
  }

  getUnexpectedShipmentById(unexpcetedId: string): Observable<UnexpectedShipment> {
    return this.httpClient.get<UnexpectedShipment>
      (`${this.baseUrl}/api/csgrequests/unexpectedshipments/${unexpcetedId}`)
      .pipe(
        first()
      );
  }

  getAllUnexpectedShipments(
    filter?: string,
    location?: string,
    status?: string,
    take?: Number,
    skip?: Number
  ): Observable<UnexpectedShipment[]> {
    const baseUrl = `${this.baseUrl}/api/csgrequests/unexpectedshipments?${filter ? `filter=${filter.trim()}` : ''}`;
    return this.httpClient.get<UnexpectedShipment[]>
      (baseUrl
        + `${location ? `&location=${location.trim()}` : ''}`
        + `${status ? `&status=${status.trim()}` : ''}`
        + `${take ? `&take=${take}` : ''}`
        + `${skip ? `&skip=${skip}` : ''}`)
      .pipe(
        first()
      );
  }

  getUnexpectedShipmentsWithActionRemaining(
    filter?: string,
    csgStatus?: string,
    take?: Number,
    skip?: Number,
    location?: string,
    action?: string): Observable<UnexpectedShipment[]> {
    const baseUrl = `${this.baseUrl}/api/csgrequests/unexpectedshipments/actionrequired?${filter ? `filter=${filter}` : ''}`;
    return this.httpClient.get<UnexpectedShipment[]>
      (baseUrl
        + `${csgStatus ? `&status=${csgStatus.trim()}` : ''}`
        + `${take ? `&take=${take}` : ''}`
        + `${skip ? `&skip=${skip}` : ''}`
        + `${location ? `&location=${location.trim()}` : ''}`
        + `${action ? `&action=${action.trim()}` : ''}`)
      .pipe(
        first()
      );
  }

  cancelUnexpectedShipment(unexpectedShipmentId: string): Observable<any> {
    return this.httpClient.put<any>
      (`${this.baseUrl}/api/csgrequests/cancelunexpected/${unexpectedShipmentId}`, null)
      .pipe(
        first()
      );
  }

  disposeUnexpectedShipment(unexpectedShipmentId: string): Observable<any> {
    return this.httpClient.put<any>
      (`${this.baseUrl}/api/csgrequests/disposeunexpected/${unexpectedShipmentId}`, null)
      .pipe(
        first()
      );
  }

  disposeUnexpected(unexpectedShipmentId: string): Observable<any> {
    return this.httpClient.put<any>
      (`${this.baseUrl}/api/csgrequests/unexpected/${unexpectedShipmentId}/dispose`, null)
      .pipe(
        first()
      );
  }

  emailUnexpectedShipment(unexpectedId: string, model: UnexpectedShipmentEmailModel): Observable<any> {
    return this.httpClient.put<any>
      (`${this.baseUrl}/api/csgrequests/unexpected/${unexpectedId}/email`, model)
      .pipe(
        first()
      );
  }

  sendReturnRequest(unexpectedId: string, addressModel: EpiAddressModel): Observable<any> {
    return this.httpClient.put<any>
      (`${this.baseUrl}/api/csgrequests/unexpected/${unexpectedId}/return`, addressModel)
      .pipe(
        first()
      );
  }

  updateColorMatchedItems(csgRequestId: string, colorMatchPayload: DesignDetail[], isLegacy: boolean) {
    return this.httpClient.put<any>
      (`${this.baseUrl}/api/csgrequests/${csgRequestId}/colormatch?isLegacyCsg=${isLegacy}`, colorMatchPayload)
      .pipe(
        first()
      );
  }

  updateCancelByDate(csgRequestId: string, date: Date) {
    return this.httpClient.put<any>
      (`${this.baseUrl}/api/csgrequests/${csgRequestId}/canceldate`, { updatedCancelDate: date })
      .pipe(
        first()
      );
  }


  // add in to make sure it is not null
  getSearchCsgRequests(csgNumber?: string): Observable<CsgRequestListItem[]> {
    return this.httpClient.get<CsgRequestListItem[]>
      (`${this.baseUrl}/api/csgrequests/search?filter=${csgNumber}`)
      .pipe(
        first()
      );
  }

  toggleIsThreadColorMatchFee(id: string): Observable<any> {
    return this.httpClient.put<any>
      (`${this.baseUrl}/api/csgrequests/removecolormatchfee/${id}`, null)
      .pipe(
        first()
      );
  }

  getAvailableStatusList(isUnexpected: boolean = false, actionRequired: boolean = false): Observable<string[]> {
    return this.httpClient.get<string[]>
      (`${this.baseUrl}/api/csgrequests/statuses?unexpected=${isUnexpected}&actionRequired=${actionRequired}`)
      .pipe(
        first()
      );
  }

  getReceivingId(): Observable<string> {
    return this.httpClient.get<string>
      (`${this.baseUrl}/api/csgrequests/receivingid/new`)
      .pipe(
        first()
      );
  }

  getRequestsWithSameDesignIds(csgId: string): Observable<PendingCsgOrdersWithSameDesignIds[]> {
    return this.httpClient.get<PendingCsgOrdersWithSameDesignIds[]>
      (`${this.baseUrl}/api/csgrequests/${csgId}/other/open/requests`)
      .pipe(
        first()
      );
  }

  getCsgRequestsWithDesignId(designId: string): Observable<CsgRequestListItem[]> {
    return this.httpClient.get<CsgRequestListItem[]>
      (`${this.baseUrl}/api/csgrequests?${designId ? `designId=${designId}` : ''}`)
      .pipe(
        first()
      );
  }
}
