import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { OrderFile } from '../models';

@Injectable({
    providedIn: 'root'
})
export class FileService {

    baseUrl = environment.baseUrl;

    constructor(private httpClient: HttpClient) { }

    uploadFilesForUnexpectedShipment(files: Array<File>, episerverUnxCsgId: string): Observable<OrderFile[]> {
        const formData: FormData = new FormData();
        files.forEach((c: File, index: number) =>
            formData.append(`fileKey${index}`, c, c.name)
        );
        return this.httpClient.post<OrderFile[]>(
            `${this.baseUrl}/api/orderfiles/unexpectedshipment/${episerverUnxCsgId}`, formData)
            .pipe(
                first()
            );
    }

    uploadCSGFiles(files: Array<File>, episerverUnxCsgId: string): Observable<OrderFile[]> {
        const formData: FormData = new FormData();
        files.forEach((c: File, index: number) =>
            formData.append(`fileKey${index}`, c, c.name)
        );
        return this.httpClient.post<OrderFile[]>(
            `${this.baseUrl}/api/orderfiles/uploadCSGFiles/${episerverUnxCsgId}`, formData)
            .pipe(
                first()
            );
    }
}
