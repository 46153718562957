class ModelBase {
  [index: string]: any;

  constructor(data: any) {
    this.init(data);
  }
  init(data: any) {
    for (const key of Object.keys(this)) {
      // automap anything with the same property names
      this[key] = data[key];
    }
  }
}

export class AliasLookup extends ModelBase {
  lookup = '';
  alias = '';
}

export class DesignRequestListItem extends ModelBase {
  id = '';
  designId = '';
  companyName = '';
  enteredDate = '';
  description = '';
  customerNumber = '';
  status = '';
  source = '';
}

export class PendingDesignListItem extends ModelBase {
  designId='';
  eventId = '';
  eventSource = '';
  id = '';
  isActive=true;
  orderId = '';
}


export class DesignRequestDetailItem extends ModelBase {
  id = '';
  events: Array<EventLogListItem>;
  description: string;
  status: string;
  copiedFrom: string;
  artwork: string;
  textbox1Art: string;
  textbox2Art: string;
  textbox3Art: string;
  textbox1Font: string;
  textbox2Font: string;
  textbox3Font: string;
  salesRepName: string;
  searchField1: string;
  requestor: string;
  designId: string;
  poNumber: string;
  dateTime: string;
  cusNo: string;
  navCusNo: string;
  companyName: string;
  qty1: string;
  qty2: string;
  qty3: string;
  shipVia1: string;
  shipVia2: string;
  shipVia3: string;
  shipVia1Nm: string;
  shipVia2Nm: string;
  shipVia3Nm: string;
  address1: string;
  address2: string;
  address3: string;
  emailAddr1: string;
  emailAddr2: string;
  emailAddr3: string;
  prodRequest: string;
  productTypeDisplay: string;
  productCategory: string;
  contacts: string;
  shapeSizeInfo: {
    id: string;
    nm: string;
    name: string;
    standard: string;
  };
  expectedDate: string;
  garmentTypeName: string;
  garmentPlacementsWeiId: string;
  embellishmentGroupsDs: string;
  width: string;
  height: string;
  impacCodes: string;
  impacCodesNm: string;
  designLocId: string;
  fabricTypeId: string;
  fabric: string;
  fabricNm: string;
  drDropperLayoutsWeiId: string;
  drDropperLayoutsNm: string;
  drShapesSizesWeiId: string;
  backing: string;
  backingNm: string;
  border: string;
  borderNm: string;
  threadInkColors: string;
  specInstr: string;
  specInstr3D: string;
  specInstrColor: string;
  specInstrPrintStitch: string;
  specInstrLeather:string;
  drProductClassesNm: string;
  drWebProductType: string;
  productTypeName: string;
  editReasonCmt: string;
  dataDrKey: string;
  stripingPlacements: { name: string, placementSku: string, placementDesignId: string }[];
  safetyStripeName: string;
  safetyStripeWeiId: string;
  changes: { fieldDisplay: string, newValue: string, oldValue: string }[];
  DeliveryDetails: Array<DeliveryDetails>;
  editUrl: string;
  designValidatedBy: string;
  designLastValidatedDate: Date;
  originalDesignArtwork: string;
  vectorizedImage: string;
  vectorizedColorReduction: string;
  vectorizedDstFile: string;
}

export class DeliveryDetails extends ModelBase {
  DeliveryToName: string;
  Address: string;
  Address2: string;
  City: string;
  State: string;
  PostalCode: string;
  Country: string;
  IsElectronicSimulation: boolean;
  Email: string;
  Quantity: number;
  ShipVia: string;
}

export class DesignRequestSummaryItem extends ModelBase {
  productClass = '';
  status = '';
  rowCnt = 0;
}

export class ProductionOrderSummaryItem extends ModelBase {
  productClass = '';
  latestStatus = '';
  checkpointName = '';
  locationCode = '';
  rowCnt = 0;
}

export class EventLogListItem extends ModelBase {
  id = '';
  orderId = '';
  eventName = '';
  eventDate = '';
  version = '';
  source = '';
}

export class DeadLetterLogItem extends ModelBase {
  id = '';
  orderId = '';
  eventName = '';
  eventDate = '';
  version = '';
  source = '';
  reemitted = '';
  cleared = '';
}

export class EventLogDetailItem extends EventLogListItem {
  content = '';
  whenReceived = '';
}

// export class OrderMonitorListItem extends ModelBase {
//   id = '';
//   orderId = '';
//   eventName = '';
//   eventDate = '';
//   version = '';
//   source = '';
//   message = '';
//   orderType = '';
// }

// export class OrderMonitorDetailItem extends OrderMonitorListItem {
//   content = '';
//   whenReceived = '';
// }

export class ImportMonitorListItem extends ModelBase {
  id = '';
  orderId = '';
  eventName = '';
  eventDate = '';
  version = '';
  source = '';
  message = '';
  orderType = '';
  orderSource = '';
  content = '';
  claimedBy = '';
  clearedBy = '';
  cleared = '';
  clearedDate = '';
  eventModel = <any>{};
}

export class ImportMonitorDetailItem extends ImportMonitorListItem {
  whenReceived = '';
}

export class SalesOrderListItem extends ModelBase {
  id = '';
  orderSource = '';
  designReqestId = '';
  addDate = '';
  orderType = '';
  navCustomerNumber = '';
  referenceNumber = '';
  legacyCustomerNo = '';
  customerName = '';
  customerType = '';
  designId = '';
  priority = '';
  salesOrderNumber = '';
  dueDate = '';
  address = '';
  address2 = '';
  city = '';
  state = '';
  postalCode = '';
  country = '';
  shippingAgentCode = '';
  shippingAgentServiceCode = '';
  shippingAgentDescription = '';
  socationCode = '';
  isSplitOrder = '';
  requestedDeliveryDate = '';
  taxLiable = '';
  poNo = '';
  clientImageURL = '';
  comments = '';
  designRequestId = '';
  lineCount = '';
  navItemNos = '';
  designIds = '';
  posted = '';
  csgRequestId = '';
  shipToCompany = '';
  shipToName = '';
  isDeleted: boolean;
  deletedBy = '';
  deleteReason = '';
  deleteDate = '';
  impersonator = '';
  isConsolidated: boolean;
  onHold: boolean;
  isWpsOrderLine: boolean;
  alreadyBeenThroughQc: boolean;
  lpnNumber: boolean;
}

export class SalesOrderSummary extends ModelBase {
  products: Array<{
    productClass: string;
    productType: string;
    latestStatus: string;
  }>;
}

export class LineStatus {
  lineNumber: number;
  canBeModified: boolean;
}

export class SalesOrderDetailItem extends SalesOrderListItem {
  test: string;
  designRequestId: string;
  webOrderNumber: string;
  webUser: string;
  email: string;
  packingSlipUrl: string;
  externalDocumentNumber: string;
  textbox2Art: string;
  textbox3Art: string;
  trackedDate: string;
  locationCode: string;
  isTracked: string;
  isTracking: boolean;
  reTracked: boolean;
  latestStatus: string;
  trackingNumber: string;
  checkPoint: string;
  ediFlag: boolean;
  designFileInitializationErrors: string[];
  designFileInitialized: boolean;
  impersonator: string;

  csgRequest: {
    csgNumber: string,
    expectedPrice?: number,
    id: string,
    externalOrderNumber: string,
    clientReferenceNumber: string
  };
  lines: SalesOrderLineItem[];
  events: Array<any>;
  invoices: Array<InvoiceItem>;
  files: OrderFile[];
  specInstr: string;
  specInstr3D: string;
  specInstrColor: string;
  specInstrPrintStitch: string;
  specInstrLeather:string;
  onHold: boolean;
}

export class ShipmentItem {
  id: string;
  salesOrderLineItemId: string;
  trackingNumber: string;
  trackingNumberDate: string;
  carrier: string;
}

export class InvoiceItem {
  id: string;
  invoiceNumber: string;
  invoiceDate: string;
  terms: string;
  subtotal: number;
  shippingCharges: number;
  miscCharges: number;
  tax: number;
  amountPaid: number;
  amountDue: number;
  pdfDownloadUrl: string;
  postingDate: string;
  status: string;
  consolidatedInvoiceNumber: string;

  billingAddress1: string;
  billingAddress2: string;
  billingZip: string;
  billingCity: string;
  billingState: string;
  billingCountry: string;
  billingName: string;

  shippingAddress1: string;
  shippingAddress2: string;
  shippingZip: string;
  shippingCity: string;
  shippingState: string;
  shippingCountry: string;
  shippingName: string;
}

export interface CsgPdfForm {
  salesOrderNumber: string;
  groupingId: string;
  pon: string;
  brand: string;
  color: string;
  description: string;
  quantity: string;
  size: string;
  receivedDate: Date;
}


export interface SalesOrderLineItem {
  discountPercent: number;
  id: string;
  lineNumber: number;
  appliesToLineNumber?: number;
  pon: string;
  lineType: string;
  sku: string;
  catalogSku: string;
  customerGLNumber: string;
  remakePon: string;
  assemblyNumber: string;
  locationCode: string;
  description: string;
  description2: string;
  priceQuantity: string;
  quantity: string;
  quantityShipped: string;
  quantityToShip: string;
  quantityBackOrdered: string;
  price: number;
  clientImageURL: string;
  discountCode: string;
  unitPrice: number;
  discountedUnitPrice: number;
  discountPercentage: number;
  designId: string;
  weiImageURL: string;
  salesOrderId: string;
  designAttributes: {
    attributeList: Array<DesignAttribute>;
  };
  dueDate: string;
  nameDropperNames: string;
  names: Array<SalesOrderLineName>;
  isSelected: boolean;
  isComplete: boolean;
  pickNumber: string;
  shipments: ShipmentItem[];
  finishedGarmentImages: string[];
  designFileInitialized: boolean;
  designFileInitializationErrors: string;
  designValidatedBy: string;
  designLastValidatedDate: Date;
  digitalPassportUrl: string;
  isDropShip: boolean;
  isWpsOrderLine: boolean;
}

export class SalesOrderLineName {
  line1: string;
  line2: string;
  line3: string;
  quantity: number;
  isActive: boolean;
  nameNumber: number;
  recipeId: string;
}

export class NameDropperName {
  constructor(
    dropperName: {
      LineNameNo: string;
      QtyToProduce: number;
      Line1Text: string;
      Line2Text: string;
      Line3Text: string;
      RecipeId: string;
    }
  ) {
    if (dropperName) { Object.assign(this, dropperName); }
  }

  LineNameNo: string;
  QtyToProduce: number;
  Line1Text: string;
  Line2Text: string;
  Line3Text: string;
  RecipeId: string;
}

export class DropperNameEdit {
  constructor(
    dropperName: {
      salesOrderLineId: string;
      lineNameNo: string;
      line1Text: string;
      line2Text: string;
      line3Text: string;
      qtyToProduce: number;
      recipeId: string;
      isSelected: boolean;
    }
  ) {
    if (dropperName) { Object.assign(this, dropperName); }
  }

  salesOrderLineId: string;
  lineNameNo: string;
  line1Text: string;
  line2Text: string;
  line3Text: string;
  qtyToProduce: number;
  recipeId: string;
  isSelected: boolean;
}

export interface DesignAttribute {
  id: string;
  designId: string;
  attributeType: string;
  code: string;
  description: string;
  description2: string;
  description3: string;
  value: string;
  dataSource: string;
  lastUpdated: string;
  showOnWorkOrder: boolean;
}

export interface ColorwayModel {
  id: string;
  designId: string;
  colorwayName: string;
  isDefault: boolean;
  addDate: string;
  updateDate: string;
  colors: Array<ColorwayColorModel>;
}

export interface ColorwayColorModel {
  id: string;
  weColorCode: string;
  threadCode: string;
  description: string;
  order: number;
  addDate: string;
  updateDate: string;
}

export interface CustomerModel {
  id: string;
  sourceCustomerNumber: string;
  companyName: string;
  customerTypeCode: string;
  phone: string;

  shippingAddr1: string;
  shippingAddr2: string;
  shippingAddr3: string;
  shippingCity: string;
  shippingState: string;
  shippingCounty: string;
  shippingCountry: string;
  shippingZip: string;

  billingAddr1: string;
  billingAddr2: string;
  billingAddr3: string;
  billingCity: string;
  billingState: string;
  billingCounty: string;
  billingCountry: string;
  billingZip: string;

  externalAccountNumber: string;

  isEnabled: boolean;
  enabledProductsTypeIds: string[];
}

export interface DesignModel {
  designId: string;
  attributes: Array<DesignAttribute>;
  colorways: Array<ColorwayModel>;
}

export interface DesignViewModel {
  colorways: ColorwayModel[];
  isEditing?: boolean;
  regenerating?: boolean;
  designId: string;
  designImageUrl: string;
  clientImageUrl: string;
  attributeModels: Array<DesignAttributeModel>;
  pricingTool: {
    designId: string,
    navSku: string,
    customer: string,
    result?: {
      payload: {
        data: {
          imageUrl: string,
          prices: {
            minQuantity?: number,
            amount?: number,
            currency: string,
          }[]
        }
      },
      Topic?: string
    }
  };
  navSku?: string;
}

export interface DesignAttributeModel {
  type: string;
  displayName: string;
  value: string;
  sort: number;
  rawAttr: DesignAttribute;
  hexColor: string;
}

export interface CsgRequestListItem {
  id: string;
  customerName: string;
  customerNumber: string;
  customerId: string;
  poNumber: string;
  wspOrderNo: string;
  shipTrackingNumbers: string;
  vendorPO: string;
  vendorName: string;
  outsideVendorName: string;
  csgNumber: string;
  orderKey: string;

  createDate: string;
  stage: string;

  addEdit: boolean;
  status: string;
  createdInCart: boolean;
  totalQuantity: number;
  warehouseCode: string;
  shipVia: string;
  shipCompanyName: string;
  shipAddress1: string;
  shipAddress2: string;
  shipAddress3: string;
  shipCity: string;
  shipState: string;
  shipCountry: string;
  shipZip: string;
  shipFirstName: string;
  phoneNumber: string;
  emailAddress: string;
  externalOrderNumber: string;
  customerNotes: string;
  internalNotes: string;
  shipFinishedGoodsType: string;
  clientReferenceNumber: string;
  receivedDate: string;
  receivedConfirmationEmail: string;
  releaseDate?: boolean;
  comments: string;
  numberOfBoxes: number;
  receivingId: string;
  orderReference1: string;
  orderReference2: string;
  orderReference3: string;
  orderReference4: string;
  orderReference5: string;
  impersonator: string;
  autoReceiveTagsAndBags: boolean;
  cancelByDate: Date;
}

export interface CsgRequestDetailItem extends CsgRequestListItem {
  receivingLocation: string;
  customerSuppliedItems: CustomerSuppliedModelItem[];
  csgRequestItems: CsgRequestItemModel[];
  csgRequestItemsModel: CsgRequestItemViewModel[];
  releasedItems: ReleasedCsgRequestItemModel[];
  receivedItems: ReceivedCsgRequestItemModel[];
  salesOrders: { id: string, addDate: string, dueDate: string }[];
  files: OrderFile[];
  rawOrderModel: CsgOrderModel;
  hasManualColorMatchFee: boolean;
  uploadedURLs: string;
}

export interface CustomerSuppliedModelItem {
  placementId: string;
  operationType: string;
  savedStatus?: string;
  saveSuccess?: boolean;
  saveError?: boolean;
  item: string;
  description: string;
  garmentGroup: string;
  qtyExpected: number;
  quantityReceived: number;
  receivingLocation: string;
}

export interface UnexpectedShipment {
  id: string;
  customer: CustomerModel;
  receivingId: string;
  poNumber: string;
  clientReferenceNumber: string;
  clientRef2: string;
  clientRef3: string;
  vendorPO: string;
  vendorName: string;
  internalNotes: string;
  outsideVendorName: string;
  warehouseCode: string;
  totalQuantity: number;
  numberOfBoxes: number;
  phoneNumber: string;
  emailAddress: string;
  emailText: string;
  emailSentCount: number;
  receivedDate: Date;
  createDate: Date;
  inboundTracking: string;
  status: string;
  isCancelled: boolean;
  csgRequestId: string;
  emailLog: string;
  unexpectedCsgShipmentItems: UnexpectedCsgShipmentItems[];
  salesOrderId: string;
  returnOrderId: string;
  unexpectedShipmentFeeOrderId: string;
  unexpectedCsgOrderId: string;
  action: string;
  age: number;
}

export interface UnexpectedShipmentDisplayModel {
  id: string;
  receivingId: string;
  action: string;
  status: string;
  age: number;
  companyName: string;
  wearer: string;
  createDate: Date;
  createDateString: string;
  poNumber: string;
  clientReferenceNumber: string;
  totalPieces: number;
  warehouseCode: string;
  internalNotes: string;
  emailSentCount;
  csgRequestId: string;
}

export interface UnexpectedShipmentEmailLogModel {
  Subject: string;
  DateSent: string;
  SentToAddress: string;
}

export interface UnexpectedCsgShipmentItems {
  quantity: number;
  wearer: string;
  color: string;
  brand: string;
  size: string;
  itemDescription: string;
  noDE: boolean;
  lineNumber: number;
  orderUrl: string;
  IdTapeNum: string;
}

export interface UnexpectedShipmentEmailModel {
  emailText: string;
  emailNotes: string;
  emailAddress: string;
}

export interface GarmentFee {
  description: string;
  code: string;
  price: number;
}

export interface ProductOption {
  name: string;
  code: string;
  hTMLColor: string;
  class: string;
  description: string;
}

export interface GarmentOperationColor {
  /// <summary>
  /// Selected color
  /// </summary>
  color: ProductOption;

  /// <summary>
  /// User entered instructions of how to apply color
  /// </summary>
  instructions: string;
}

export interface GarmentOperation {
  operationType: string;

  design: any;

  placement: any;

  embellishmentMethod: string;

  /// <summary>
  /// Category for Tag or Packaging operation. Some example values are:
  /// Customer Supplied Tag, Upload Tag (PDF File), Bag Garment or Box Garment
  /// </summary>
  category: string;

  /// <summary>
  /// Customer entered description for Customer Supplied Decoration
  /// </summary>
  itemDescription: string;

  /// <summary>
  /// Packaging option for bagged garments
  /// </summary>
  bag: string;

  /// <summary>
  /// Packaging option for labels
  /// </summary>
  label: string;

  /// <summary>
  /// User entered comments
  /// </summary>
  comments: string;

  /// <summary>
  /// Labor or application fees (optional)
  /// </summary>
  fees: Array<GarmentFee>;

  /// <summary>
  /// List if reference Ids of GarmentGroup file Ids that this operations references.
  /// The related file could be a DST, Thumbnail, PDF, etc.
  /// </summary>
  fileIds: Array<number>;

  /// <summary>
  /// Colors and Instructions - for DST File Decoration
  /// </summary>
  colors: Array<GarmentOperationColor>;

  orderLineReference1: string;
  orderLineReference2: string;
  orderLineReference3: string;
  orderLineReference4: string;
  orderLineReference5: string;
  isSupplementalFileExtracted: boolean;

  quantityReceived: number;
  receivingLocation: string;
}

export interface CsgOrderModel {
  garmentShipFrom: { address: EpiAddressModel };
  garmentShipTo: { address: EpiAddressModel };
  shipToAddresses: { address: EpiAddressModel, groupingIds: number[] }[];
  garmentGroups: {
    requestedShipmentDate: string,
    groupingId: number,
    garmentFiles: GarmentFile[],
    quantity: number,
    receivingLocation: string,
    quantityReceived: number,
    hasInvalidThreadData: boolean,
    garmentDesigns: {
      placement: {},
      product: {
        designId: string,
        sku: string,
        designFile: {
          renderedDesignImageUrl: string
        },
        designDetail: DesignDetail[]
      }
    }[],
    operations: GarmentOperation[],
    color: string,
    styleCode: string
  }[];
}

export interface EpiAddressModel {
  addressNickname: string;
  contactName: string;
  phone: string;
  extension: string;
  email: string;
  companyName: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  postalCode: string;
  countryCode: string;
  country: string;
}

export interface ReturnsAddressModel extends EpiAddressModel {
  warehouseCode: string;
  shippingAgentCode: string;
  shippingAgentServiceCode: string;
}


export interface OrderFile {
  url: string;
  fileType: string;
  extension: string;
  description: string;
  originUrl: string;
  uploadedUrl: string;
  fileName: string;
  stitchCount: number;
  widthMM: number;
  heightMM: number;
  numColors: number;
  designId: string;
}

export interface ReleasedCsgRequestItemModel extends CsgRequestItemModel {
  parentItemId: string;
  dateReleased: string;
  releaseSequence: number;
}

export interface ReceivedCsgRequestItemModel extends CsgRequestItemModel {
  quantity: number;
  quantityReceived: number;
  quantityDamaged: number;
  size: string;
  color: string;
  brand: string;
  description: string;
  lineNumber: string;
  catalogSku: string;
  received: string;
  isInsulatedGarment: boolean;
  receivingLocation: string;
  dateReceived: string;
  isReleased: boolean;
  doRelease: boolean;
  IdTapeNum: string;
  placements: Placement[];

}

export interface CsgRequestItemModel {
  isInvalid?: boolean;
  id: string;
  received: string;
  createDate?: string;

  addEdit?: boolean;
  status?: string;
  quantity: number;
  quantityReceived: number;
  quantityDamaged: number;
  size: string;
  color: string;
  brand: string;
  description: string;
  lineNumber: string;
  garmentPlacementName: string;
  garmentPlacementId: string;
  catalogSku: string;
  isInsulatedGarment: boolean;
  stripeDesignName?: string;
  stripeDesignCode?: string;
  numberBoxes?: number;
  isNameDropper: boolean;
  nameLine1: string;
  nameLine2: string;
  nameLine3: string;
  nameComment: string;
  noDecoration: boolean;
  wearer: string;
  receivingLocation: string;
  groupingId: number;
  IdTapeNum: string;
  isSorting: boolean;
}

export interface CsgRequestItemViewModel {
  isInvalid?: boolean;
  id: string;
  received: string;
  createDate?: string;

  addEdit?: boolean;
  status?: string;
  quantity: number;
  quantityReceived: number;
  quantityDamaged: number;
  size: string;
  color: string;
  brand: string;
  description: string;
  lineNumber: string;
  placements: Placement[];
  isInsulatedGarment: boolean;
  stripeDesignName?: string;
  stripeDesignCode?: string;
  numberBoxes?: number;
  isReceived?: boolean;
  isAddedItem?: boolean;
  groupingId: number;
  IdTapeNum: string;
  isSorting: boolean;
}

export interface Placement {
  garmentPlacementName: string;
  garmentPlacementId: string;
  id: string;
  isNameDropper: boolean;
  nameLine1: string;
  nameLine2: string;
  nameLine3: string;
  nameComment: string;
  catalogSku: string;
}

export interface RedirectorModel {
  id: number;
  productCategoryGroup: string;
  productCode: string;
  customerNumber: string;
  zipCode: string;
  defaultLocation: string;
  manufacturingLocation: string;
  activeFlag: string;
}

export interface OpenOrdersModel {
  salesOrderNumber: string;
  customerId: number;
  customerName: string;
  requestedDeliveryDate: Date;
  PON: string;
  navSKU: string;
  navDescription: string;
  productClass: string;
  product: string;
  poNo: string;
  shippingAgentServiceCode: string;
  priority: string;
  location: string;
  qtyOrdered: number;
  qtyToProduce: number;
  qtyProduced: number;
  qtyDamanged: number;
  lastCheckpoint: string;
  currentCheckpoint: string;
  status: string;
  lastUpdated: Date;
}

export interface LocationModel {
  id: number;
  locationCode: string;
  locationCode2Dig: string;
  state: string;
  activeFlag: string;
}

export class NavSalesOrderStatusModel {
  orderStatus: string;
  lines: Array<LineStatus>;
}

export class ProductRevenueSummaryItem extends ModelBase {
  dueDate: string;
  productClass: string;
  revenueAmt: number;
}

export interface RedirectorLocation {
  ruleId: number;
  location: string;
}

export class DesignRequestStatus {
  Id: string;
  Name: string;
}

export class SalesOrderUpdate {
  OrderId: string;
  ShipToAddress1: string;
  ShipToAddress2: string;
  ShipToCity: string;
  ShipToState: string;
  ShipToZip: string;
  ShipToCountry: string;
  ShippingAgent: string;
  ShippingAgentServiceCode: string;
  ShippingAgentDescription: string;
}

export class DeleteModel {
  SalesOrderNumber: string;
  DeleteFromAllSystems: boolean;
  PonsToDelete: Array<string>;
  DeleteWholeSalesOrder: boolean;
  DeleteFromSystems: Array<string>;
}

export class CsgReceiveRequest {
  csgRequestId: string;
  items: CsgReceivedItem[];
}

export class CsgReceiveResponse {
  csgRequestId: string;
  success: boolean;
  messages: string[];
}

export class CsgReceivedItem {
  garmentGroupId: string;
  operationType: string;
  itemDescription: string;
  placementId: string;
  quantity: number;
  location: string;
}

export interface DesignDetail {
  type: string;
  subType: string;
  order: number;
  sourceCode: string;
  originalSourceCode: string;
  description: string;
  destinationCode: string;
  isValid: boolean;
  errorMessage: string;
  notes: string;
  color98SequenceValue: string;
  designId: string;
  doNotTranslate: boolean;
  garmentStyle: string;
  garmentColor: string;
}

export interface GarmentFile {
  id: number;
  url: string;
  fileType: string;
}

export interface ColorMatchResult {
  success: boolean;
  message: string;
  orderStatus: string;
  csgModel: CsgRequestDetailItem;
  newTranslations: DesignDetail[];
}

export interface NonTranslatedThread {
  id: string;
  threadColorCode: string;
  threadBrand: string;
  csgRequestId: string;
  csgNumber: string;
  salesOrderId: string;
  addDate: Date;
  isThreadOrdered: boolean;
}

export interface CustomerNotificationLog {
  orderId: string;
  url: string;
  sent: Date;
  response: string;
  success: boolean;
  bodyJson: any;
  customerId: number;
  showBodyJson: boolean;
  loadingResend: boolean;
  id: string;
}

export interface PendingCsgOrdersWithSameDesignIds {
  id: string;
  CompanyName: string;
  shipTrackingNumbers: string;
  csgNumber: string;
  designId: string;
  clientReferenceNumber: string;
  poNumber: string;
}


export interface Countries {
  name: string;
  abbreviation: string;
}