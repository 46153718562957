import { Component, ElementRef, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ModalService } from '../services/modal-service';
import { DesignRequestsService } from '../services/design-requests.service';
import { DesignRequestStatus } from '../models/index';
import { DesignRequestDetailItem } from 'src/app/models';

@Component({
    selector: 'change-status-modal',
    templateUrl: './change-status-modal.component.html'
})
export class ChangeStatusModalComponent {
    @Input() model: DesignRequestDetailItem;
    @Input() isReleased: boolean;

    private element: any;
    @Output() errorEvent = new EventEmitter();
    @Output() statusChangedEvent = new EventEmitter();
    statuses: DesignRequestStatus[];
    selectedStatusId: string;

    constructor(private modalService: ModalService, private designRequestService: DesignRequestsService, private el: ElementRef) {
        this.element = el.nativeElement;
    }

    ngOnChanges()
    {
        this.statuses = [
            { Id: "", Name: "" },
            { Id: "canceled", Name: "Canceled" }
        ];
        if (this.isReleased) {
            this.statuses.push({ Id: "ordercreated", Name: "Order Created" });
        }
        this.selectedStatusId = "";
    }

    saveNewStatus(event: Event) {
        this.designRequestService.changeDesignRequestStatus(this.model.id, this.selectedStatusId).subscribe(
            result => {
                // refresh status in parent
                this.model.status = this.statuses.find(s => s.Id == this.selectedStatusId).Name;
                // no longer allowed to Release for the selectable Status options
                this.statusChangedEvent.emit(this.selectedStatusId);
            }, err => {
                console.log(err);
                this.errorEvent.emit("An error occurred server-side while processing the submit.");
            }, () => {
                this.modalService.close("change-status-modal");
        });
    }  
    
    abortChange(event: Event) {
        this.modalService.close("change-status-modal");
    }  

}