import * as moment from 'moment-timezone';


const estTimezone = 'America/New_York'
export const calendarDate = (dateString: string, utc: boolean = true) => {
    return moment(`${dateString}${utc ? 'Z' : ''}`).tz(estTimezone).calendar();
}
export const shortDate = (dateString: string, utc: boolean = true) => {
    return moment(`${dateString}${utc ? 'Z' : ''}`).tz(estTimezone).format('MM/DD/YYYY');
}
export const longDateWithSeconds = (dateString: string, utc: boolean = true) => {
    return moment(`${dateString}${utc ? 'Z' : ''}`).tz(estTimezone).format('LL LTS');
}
export const monthDayDate = (dateString: string, utc: boolean = true) => {
    return moment(`${dateString}${utc ? 'Z' : ''}`).tz(estTimezone).format('MMM DD');
}


