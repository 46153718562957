import { ImportMonitorDetailItem } from './../models/index';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { ImportMonitorListItem } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ImportMonitorService {

  baseUrl = environment.baseUrl;

  constructor(private httpClient: HttpClient) { }

  getImportMonitorItem(eventId: string): Observable<ImportMonitorDetailItem> {
    return this.httpClient.get<ImportMonitorDetailItem>
      (`${this.baseUrl}/api/importmonitor/${eventId}`)
      .pipe(
        first()
      );
  }

  getErrorImportMonitorItems(startAt: string = '', take: number = 20): Observable<ImportMonitorListItem[]> {
    return this.httpClient.get<{ importMonitorEntries: ImportMonitorListItem[] }>
    (`${this.baseUrl}/api/importmonitor${startAt !== '' ? `/${startAt}/${take}` : ''}`)
    .pipe(
      map(result => result.importMonitorEntries))
    .pipe(
      first()
    );
  }

  getClearedImportMonitorItems(numOfDays: number): Observable<ImportMonitorListItem[]> {
    return this.httpClient.get<{ importMonitorEntries: ImportMonitorListItem[] }>
    (`${this.baseUrl}/api/importmonitor/history/${numOfDays}`)
    .pipe(
      map(result => result.importMonitorEntries))
    .pipe(
      first()
    );
  }

  clearImportMonitorItem(event: ImportMonitorDetailItem): Observable<ImportMonitorDetailItem> {
    return this.httpClient.put<ImportMonitorDetailItem>
    (`${this.baseUrl}/api/importmonitor/${event.id}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    })
    .pipe(
      first()
    );
  }

  claimImportMonitorItem(event: ImportMonitorListItem): Observable<any> {
    return this.httpClient.put<ImportMonitorListItem>
    (`${this.baseUrl}/api/importmonitor/${event.id}/claim`, { eventId: event.id, claimedBy: event.claimedBy }, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    })
    .pipe(
      first()
    );
  }
}
