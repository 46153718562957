<h3>Emit new event</h3>

<div class="row" *ngIf="model">
  <div class="col">
    <json-editor (change)="setEvent($event)" [options]="editorOptions" [data]="middlewareEvent"></json-editor>
  </div>
</div>
<div class="row mt-2" *ngIf="model">
  <div class="col">
    <button type="button" attr.disabled="emitting ? 'disabled' : undefined" class="btn btn-danger" (click)="emit()">Emit new event</button>
  </div>
</div>
<div class="row" *ngIf="model && emitted">
  <div class="col">
    <div class="alert alert-success">Successfully emitted event id {{ emittedId }}</div>
  </div>
</div>
