<div class="row">
  <div class="col">
    <a [routerLink]="['..']">Back to list</a>
  </div>
</div>
<form name="addForm" (ngSubmit)="addRequest()" *ngIf="!loading">
  <div class="row">
    <div class="col-sm-5">
      <div class="card" style="min-width: 25rem;">
        <div class="card-body">
          <h5 class="card-title">{{'Unexpected Shipment - ' + model.receivingId}}</h5>
          <div class="row mb-2">
            <div class="col-sm-4 data-label">
              Stage
            </div>
            <div class="col-auto">
              Received Unexpected
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 data-label">
              Internal Notes
            </div>
            <div class="col-auto">
              <input [(ngModel)]="model.internalNotes" type="text" class="form-control" name="internalNotes"
                id="internalNotes" maxlength="100">
            </div>
          </div>
          <div class="row mt-2">
            <div style="text-align:left;" class="col-sm-6">
              <label class="btn btn-sm btn-warning selectFilesControl">
                <input type="file" class="selectFilesControl" id="selectUploadFile" multiple
                  (change)="handleFileInput($event.target.files)" [disabled]="uploadPending">
                Select Files To Upload
              </label>
            </div>
            <div class="col-sm-6"></div>
            <div *ngIf="loading" class="progress" style="width:75px">
              <div i18n class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100"
                aria-valuemin="0" aria-valuemax="100" style="width: 75px">Loading</div>
            </div>
          </div>
          <div class="col-sm mt-2">
            <div class="row">
              <strong *ngIf="filesToUpload && filesToUpload.length > 0">Selected Files:</strong>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="row">
              <div class="col-sm-1"></div>
              <div class="col-sm">
                <div *ngFor="let file of filesToUpload">
                  <div class="row">
                    <div>{{file.name}}&nbsp;&nbsp;<span (click)="removeFile(file.name)" class="fa fa-trash"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
          <div class="row mt-2 mb-2">
            <div class="col-sm">
                <div *ngIf="filesToUpload && filesToUpload.length > 0 && !uploadPending">
                    <button class="btn btn-sm btn-warning" (click)="uploadFileList()">Upload</button>
                </div>
                <div *ngIf="uploadPending" class="progress" style="width:75px">
                    <div i18n class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                        aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 75px">Loading</div>
                </div>
            </div>
        </div>
        </div>
      </div>
    </div>

   

    <div class="col-sm-7">
      <div class="card" style="min-width: 25rem;">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">

              <div class="row mb-1">
                <div class="col-sm-4 data-label">
                  PO Number
                </div>
                <div class="col-auto">
                  <input [(ngModel)]="model.poNumber" type="text" class="form-control" name="poNumber" id="poNumber"
                    maxlength="100">
                </div>
              </div>

              <div class="row mb-1">
                <div class="col-sm-4 data-label">
                  Vendor PO
                </div>
                <div class="col-auto">
                  <input [(ngModel)]="model.vendorPO" type="text" class="form-control" name="vendorPO" id="vendorPO"
                    maxlength="100">
                </div>
              </div>

              <div class="row mb-1">
                <div class="col-sm-4 data-label">
                  Vendor Name
                </div>
                <div class="col-auto">
                  <input [(ngModel)]="model.vendorName" type="text" class="form-control" name="vendorName" id="vendorName"
                    maxlength="100">
                </div>
              </div>

              <div class="row mb-1">
                <div class="col-sm-4 wrapped-data-label">
                  Outside Vendor Name
                </div>
                <div class="col-auto">
                  <input [(ngModel)]="model.outsideVendorName" type="text" class="form-control" name="outsideVendorName" id="outsideVendorName"
                    maxlength="100">
                </div>
              </div>

              <div class="row mb-1">
                <div class="col-sm-4 data-label">
                  Client Reference #
                </div>
                <div class="col-auto">
                  <input [(ngModel)]="model.clientReferenceNumber" type="text" class="form-control"
                    name="clientReferenceNumber" id="clientReferenceNumber" maxlength="100">
                </div>
              </div>

              <div class="row mb-1">
                <div class="col-sm-4 data-label">
                  Warehouse Code
                </div>
                <div class="col-auto">
                  <select class="form-control" (change)="setLocationToModel()" [(ngModel)]="locationSelect"
                    [ngModelOptions]="{standalone: true}">
                    <option *ngFor="let location of locationModel">{{location.locationCode}}</option>
                  </select>
                </div>
              </div>

            </div>
            <div class="col-sm-6">


              <div class="row mb-1">
                <div class="col-sm-4 data-label">
                  Client Ref # 2
                </div>
                <div class="col-auto">
                  <input [(ngModel)]="model.orderReference1" type="text" class="form-control"
                    name="clientReferenceNumber2" id="clientReferenceNumber2" maxlength="50">
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-sm-4 data-label">
                  Client Ref # 3
                </div>
                <div class="col-auto">
                  <input [(ngModel)]="model.orderReference2" type="text" class="form-control"
                    name="clientReferenceNumber3" id="clientReferenceNumber3" maxlength="50">
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-sm-4 data-label">
                  Inbound Tracking
                </div>
                <div class="col-auto">
                  <input [(ngModel)]="model.orderReference3" type="text" class="form-control" name="inboundTracking"
                    id="inboundTracking" maxlength="50">
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-sm-4 data-label">
                  Number Of Boxes
                </div>
                <div class="col-auto">
                  <input [(ngModel)]="model.numberOfBoxes" type="number" class="form-control" name="numberOfBoxes"
                    id="numberOfBoxes" max="10" (change)="ensureBoxesFullNum(model.numberOfBoxes)">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row mb-1">
            <div class="col-sm-2 data-label">
              Customer
            </div>
            <div class="col-sm-3">
              <input class="form-control" (change)="getCustomers()" [(ngModel)]="customerInput" type="search"
                name="customerInput" id="customerInput" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="col-auto">
              <div *ngIf="customersLoading" class="fa fa-spinner fa-spin"></div>
              <select class="form-control" *ngIf="!customersLoading && customerInput.length != 0"
                (change)="setCustomerToModel()" [(ngModel)]="customerSelect" [ngModelOptions]="{standalone: true}">
                <option *ngFor="let customer of customersModel" [ngValue]="customer">{{customer.companyName}}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-sm-4 data-label">
              Selected Customer Details:
            </div>
            <div class="col-auto">
              <p *ngIf="!customerSelect">No Customer Selected</p>
              <p *ngIf="customersModel && customersModel.length > 0 && customerSelect">
                {{customerSelect.sourceCustomerNumber + ' - ' + customerSelect.companyName}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="unexpectedShipmentFiles && unexpectedShipmentFiles.length > 0" class="row">
    <div class="col-lg">
        <div class="card" style="min-width: 27rem;">
            <div class="card-body">
                <h5 class="card-title" i18n>Uploaded Shipment Files</h5>
                <div class="card-text">

                    <div class="list-group order-files">
                        <div class="list-group-item">
                            <div class="row data-label">
                                <div class="link col-2">File Name</div>
                                <div class="filetype col-2">File Type</div>
                            </div>
                        </div>
                        <div class="list-group-item" *ngFor="let file of unexpectedShipmentFiles">
                            <div class="row">
                                <div class="link col-2">
                                    <a [attr.href]="file.uploadedUrl">{{ file.fileName || file.description ||
                                        "Download" }}</a>
                                </div>
                                <div class="filetype col-2">
                                    {{file.extension}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  <div class="row mt-1 mb-1 ml-1">
    <!-- <div *ngIf="customerNumber.invalid && (customerNumber.dirty || customerNumber.touched)"
      class="alert alert-danger">

      <div *ngIf="customerNumber.errors.required">
        Customer Number is required.
      </div>
    </div> -->
  </div>

  <div class="row">
    <div class="col-sm">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Items</h5>
          <table class="table table-striped">
            <thead>
              <tr>
                <th style="width: 10%">
                  <span>Quantity</span>
                </th>
                <th style="width: 20%">
                  <span>Wearer</span>
                </th>
                <th style="width: 20%">
                  <span>ID Tape Number</span>
                </th>
                <th style="width: 10%">
                  <span>Color</span>
                </th>
                <th style="width: 10%">
                  <span>Brand</span>
                </th>
                <th style="width: 10%">
                  <span>Size</span>
                </th>
                <th style="width: 20%">
                  <span>Item/Description (Style #)</span>
                </th>
                <th style="width: 5%">
                  <span>No DE</span>
                <th style="width: 5%"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of model.csgRequestItems; let i = index">
                <td>
                  <div>
                    <input class="form-control" type="number" placeholder="Quantity" [(ngModel)]="item.quantity"
                      name="itemQuantiy{{i}}" max="500" (change)="ensureQtyFullNum(item, item.quantity)">
                  </div>
                </td>
                <td>
                  <div>
                    <input class="form-control" type="text" placeholder="wearer" [(ngModel)]="item.wearer"
                      name="wearer{{i}}" maxlength="100">
                  </div>
                </td>
                <td>
                  <div>
                    <input class="form-control" type="text" placeholder="ID Tape Number" [(ngModel)]="item.IdTapeNum"
                      name="IdTapeNum{{i}}" maxlength="100">
                  </div>
                </td>
                <td>
                  <div>
                    <input class="form-control" type="text" placeholder="Color" [(ngModel)]="item.color"
                      name="itemColor{{i}}" maxlength="100">
                  </div>
                </td>
                <td>
                  <div>
                    <input class="form-control" type="text" placeholder="Brand" [(ngModel)]="item.brand"
                      name="itemBrand{{i}}" maxlength="100">
                  </div>
                </td>
                <td>
                  <div>
                    <input class="form-control" type="text" placeholder="Size" [(ngModel)]="item.size"
                      name="itemSize{{i}}" maxlength="100">
                  </div>
                </td>
                <td>
                  <div>
                    <input class="form-control" type="text" placeholder="Description" [(ngModel)]="item.description"
                      name="itemDescription{{i}}" maxlength="100">
                  </div>
                </td>
                <td>
                  <div>
                    <input class="form-control" type="checkbox" [(ngModel)]="item.noDecoration"
                      name="noDecoration{{i}}">
                  </div>
                </td>
                <td>
                  <div>
                    <div class="form-control trashCan" type="button" class="fa fa-2x fa-trash" name="removeRow{{i}}"
                      (click)="removeRow(item)"></div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="mt-1">
            <button type="button" class="btn btn-secondary" (click)="addGarment()"><span class="fa fa-plus"></span> Add
              Garment</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <button *ngIf="!saveLoading" type="submit" class="btn btn-primary ml-2">Save Unexpected Shipment</button>
    <div *ngIf="saveLoading" class="ml-5">
      <span class="fa fa-spinner fa-3x fa-spin"></span>
    </div>
  </div>
</form>