import { DesignAttribute } from './../../../models/index';
import { Component, OnInit } from '@angular/core';
import { DesignViewModel, DesignAttributeModel } from 'src/app/models';
import * as moment from 'moment';
import { DesignRequestsService } from 'src/app/services/design-requests.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-design-update',
  templateUrl: './design-update.component.html',
  styleUrls: ['./design-update.component.scss', './../../../app.component.scss']
})
export class DesignUpdateComponent implements OnInit {

  model: DesignViewModel;
  showSearchBox = false;
  designId = '';
  designNotFound = false;
  isSearching = false;
  moment = moment;
  updatedClientInfo = false;

  clientInformationAttr = <DesignAttribute> {
    id: undefined,
    designId: '',
    attributeType: 'clientinformation',
    code: '',
    description: '',
    description2: '',
    description3: '',
    value: '',
    dataSource: 'manual-entry',
    lastUpdated: moment().format(),
    showOnWorkOrder: false
  };

  constructor(private designRequestService: DesignRequestsService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    const designId = this.route.snapshot.params['designId'];
    if (!designId || designId === '') {
      return;
    }
    this.designId = designId;
    this.getDesign();
  }

  getLastUpdatedString(item: DesignAttributeModel) {
    if (!item || !item.rawAttr.lastUpdated || !item.rawAttr.dataSource) {
      return '';
    }
    return `${moment(item.rawAttr.lastUpdated).calendar()} from ${item.rawAttr.dataSource}`;
  }

  loadDesign() {
    if (this.designId.length <= 5 || this.isSearching) {
      return;
    }
    this.router.navigate([`designs/${this.designId}`]);
    this.getDesign();
  }

  updateClientInformation() {
    console.log(this.clientInformationAttr);
    this.clientInformationAttr.designId = this.designId;
    this.clientInformationAttr.showOnWorkOrder = false;
    this.designRequestService.upsertAttributeById(this.clientInformationAttr).subscribe(a => this.updatedClientInfo = true);
  }

  getDesign() {
    this.updatedClientInfo = false;
    this.designNotFound = false;
    this.isSearching = true;
    this.designRequestService.getDesign(this.designId)
      .subscribe(model => {
        this.model = model;
        this.clientInformationAttr = this.model.attributeModels
          .map(m => m.rawAttr).find(m => m.attributeType === 'clientinformation') ||
          this.clientInformationAttr; // upsert
      },
        err => {
          this.model = null;
          this.designNotFound = true;
          this.isSearching = false;
        }, () => this.isSearching = false);
  }
}
