<div>
    <h2 *ngIf="!loadingCustomer" i18n>Customer Notifications - <span>{{customer.companyName}}</span></h2>
    <!-- <div class="row"> -->
        <div class="col-md-12">
            <div>
                <div style="text-align: center" *ngIf="!loadingNotifications && notifications.length === 0">
                    <div i18n class="alert alert-success">No Notifications Found</div>
                </div>
                <div *ngIf="!loadingNotifications && notifications && notifications.length > 0">
                    <table class="table table-striped" style="table-layout:fixed">
                        <thead>
                            <th style="width: 5%; text-align:center" scope="col" i19n>
                                Order Number
                            </th>
                            <th style="width: 10%; text-align:center" scope="col" i19n>
                                Sent Date
                            </th>
                            <th style="width: 25%; text-align:center" scope="col" i19n>
                                Url
                            </th>
                            <th style="width: 5%; text-align:center" scope="col" i19n>
                                Success
                            </th>
                            <th style="width: 15%; text-align:center" scope="col" i19n>
                                Response
                            </th>
                            <th style="width: 23%; text-align:center" scope="col" i19n>
                                Status
                            </th>
                            <th style="width: 10%; text-align:center" scope="col" i19n>
                            </th>
                            <th style="width: 7%; text-align:center" scope="col" i19n>
                            </th>
                        </thead>
                        <tbody *ngFor="let notification of notifications; let i = index">
                            <tr>
                                <td *ngIf="notification.orderId != null && notification.orderId.startsWith('SO')"
                                    style="text-align:center"><a [routerLink]="['/orders', notification.orderId]">{{
                                        notification.orderId }}</a></td>
                                <td *ngIf="notification.orderId == null || !notification.orderId.startsWith('SO')"
                                    style="text-align:center"><span>{{ notification.orderId }}</span></td>

                                <td style="text-align:center">{{ formatDate(notification.sent) || 'N/A'}}</td>
                                <td style="word-break:break-all;">{{ notification.url || 'N/A'}}</td>
                                <td style="text-align:center">{{ notification.success}}</td>
                                <td style="text-align:center">{{ formatResponse(notification.response) || 'N/A'}}</td>
                                <td style="text-align:center">{{ notification.bodyJson["Status"] || 'N/A'}}</td>
                                <td style="text-align:center" *ngIf="!notification.showBodyJson"><button
                                        class="btn btn-sm btn-primary" (click)="showPayload(notification)">View
                                        Payload</button>
                                </td>
                                <td style="text-align:center" *ngIf="notification.showBodyJson"><button
                                        class="btn btn-sm btn-primary" (click)="hidePayload(notification)">Hide
                                        Payload</button>
                                </td>
                                <td style="text-align:center">
                                    <span *ngIf="notification.loadingResend" class="fa fa-spinner fa-2x fa-spin"></span>
                                    <button *ngIf="!notification.loadingResend" class="btn btn-sm btn-primary"
                                        (click)="retryNotification(notification)">Resend</button>
                                </td>

                            </tr>
                            <tr *ngIf="notification.showBodyJson">
                                <td colspan="2"></td>
                                <td colspan="4">
                                    <pre><p>{{ notification.bodyJson | json}}</p></pre>
                                </td>
                                <td colspan="1"></td>

                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-2"></div>
            </div>
            <div *ngIf="loadingMore" class="progress">
                <div i18n class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100"
                    aria-valuemin="0" aria-valuemax="100" style="width: 100%">Loading</div>
            </div>
            <div *ngIf="noMore" class="alert alert-warning" i18n>
                There are no older orders
            </div>
            <div *ngIf="!isLoading() && !isOrderNotifications && (!loadingMore && !noMore)" class="mt-2 mb-2">
                <button i18n (click)="loadOlder()" type="button" class="btn btn-primary btn-sm">Load older orders</button>
            </div>
            <!-- </div> -->
        </div>