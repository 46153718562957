import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { Router } from '@angular/router';

import { AliasLookup } from './../../models';
import { AliasLookupService } from './../../services/alias-lookup.service';

@Component({
  selector: 'app-alias-lookups',
  templateUrl: './alias-lookups.component.html',
  styleUrls: ['./alias-lookups.component.scss']
})
export class AliasLookupsComponent implements OnInit {
  model = {
    aliasLookups: new Array<AliasLookup>()
  };
  loading = false;
  noMore = false;

  constructor(private aliasLookupService: AliasLookupService, private router: Router) { }


  ngOnInit() {
    this.aliasLookupService.getAliasLookups().subscribe(list => this.model.aliasLookups = list);
  }

}
