import { Component, OnInit } from '@angular/core';
import { NonTranslatedThread } from 'src/app/models';
import { ReportService } from 'src/app/services/report.service';

@Component({
    selector: 'app-non-translated-threads',
    templateUrl: './non-translated-threads.component.html',
      styleUrls: ['./non-translated-threads.component.scss']
})
export class NonTranslatedThreadsComponent implements OnInit {

    threads: NonTranslatedThread[];
    loading: boolean;
    csgNumberAscending = true;
    salesOrderAscending = true;
    threadBrandAscending = true;

    constructor(private reportService: ReportService) { }

    ngOnInit() {
        this.loading = true;
        this.reportService.getNonTranslatedThreadReport().subscribe(
            (result) => {
                this.threads = result;
                this.loading = false;
            }
        );
    }

    sortByCsgNumber() {
        if (this.csgNumberAscending) {
            this.threads.sort((a, b) => (a.csgNumber || '').localeCompare(b.csgNumber || ''));
        } else {
            this.threads.sort((a, b) => (b.csgNumber || '').localeCompare(a.csgNumber || ''));
        }
        this.csgNumberAscending = !this.csgNumberAscending;
    }

    sortBySalesOrder() {
        if (this.salesOrderAscending) {
            this.threads.sort((a, b) => (a.salesOrderId || '').localeCompare(b.salesOrderId || ''));
        } else {
            this.threads.sort((a, b) => (b.salesOrderId || '').localeCompare(a.salesOrderId || ''));
        }
        this.salesOrderAscending = !this.salesOrderAscending;
    }

    sortByThreadBrand() {
        if (this.threadBrandAscending) {
            this.threads.sort((a, b) => (a.threadBrand || '').localeCompare(b.threadBrand || ''));
        } else {
            this.threads.sort((a, b) => (b.threadBrand || '').localeCompare(a.threadBrand || ''));
        }
        this.threadBrandAscending = !this.threadBrandAscending;
    }

    toggleIsOrdered(id: string) {
        this.reportService.toggleIsThreadOrdered(id).subscribe(result => {

        });
    }

    deleteThreadFromReport(id: string) {
        this.reportService.deleteThreadFromReport(id).subscribe(result => {
            this.threads = this.threads.filter(c => c.id !== id);
        });
    }
}


