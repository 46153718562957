<div>
  <h1 i18n>Aliases</h1>

  <div class="table-responsive">
    <table class="table wei-table-striped">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col" i18n>Lookup</th>
          <th scope="col" i18n>Alias</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of model.aliasLookups">
          <td></td>
          <td>{{ item.lookup }}</td>
          <td>{{ item.alias }}</td>
        </tr>
      </tbody>
    </table>
  </div>


  <div *ngIf="loading" class="progress">
    <div i18n class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100"
      aria-valuemin="0" aria-valuemax="100" style="width: 100%">Loading</div>
  </div>
  <div *ngIf="noMore" class="alert alert-warning" i18n>
    There are no more Aliases
  </div>
  <div *ngIf="!loading && !noMore" class="mt-2 mb-2">
    <button i18n type="button" class="btn btn-primary btn-sm">Load more</button>
  </div>
</div>
