import { Component, ElementRef, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ModalService } from '../services/modal-service';
import { SalesOrderService } from '../services/sales-order.service';
import { SalesOrderDetailItem } from 'src/app/models';

@Component({
    selector: 'update-edi-flag-modal',
    templateUrl: './update-edi-flag-modal.component.html'
})
export class UpdateEDIFlagModalComponent {
    @Input() model: SalesOrderDetailItem;
    @Input() ediFlagMessage: string;

    private element: any;
    errorMessage: string;
    hasError: boolean = false;


    constructor(private modalService: ModalService, private salesOrderService: SalesOrderService, private el: ElementRef) {
        this.element = el.nativeElement;
    }

    saveEdiFlag(event: Event) {
        this.salesOrderService.setEdiFlag(this.model.id, !this.model.ediFlag).subscribe(res => {
            
            if (res.status != "OK") {
                this.hasError = true;
                this.errorMessage = res.message;
            }
            else 
            {
                this.model.ediFlag = !this.model.ediFlag;
                this.ediFlagMessage = this.model.ediFlag ? 'Unset EDI Flag' : 'Set EDI Flag';
            }
            }, err => {
                console.log(err);
            }, () => {
                if (!this.hasError)
                {
                    this.modalService.close("update-edi-flag-modal");
                }
                
        });
    }  
    
    abortChange(event: Event) {
        this.hasError = false;
        this.errorMessage = "";
        this.modalService.close("update-edi-flag-modal");
    }  

}