import { Component, OnInit } from '@angular/core';

import { RedirectorModel } from './../../models';
import { RedirectorService } from './../../services/redirector.service';
import { Ng2SmartTableModule } from 'ng2-smart-table';

@Component({
  selector: 'app-redirector',
  templateUrl: './redirector.component.html',
  styleUrls: ['./redirector.component.scss']
})
export class RedirectorComponent implements OnInit {  
  public defaultLoc = "";
  public productCode = "";
  public zipCode = "*";
  public customerNumber = "*";
  public redirectorRows: RedirectorModel[];
  public loading = false;
  public settings = {
    columns: {
      id: {
        title: 'Rule Id',
      },
      productCategoryGroup: {
        title: 'Product Group',
      },
      productCode: {
        title: 'Product Code',
      },
      customerNumber: {
        title: 'Customer No',
      },
      zipCode: {
        title: 'Zip Code',
      },
      defaultLocation: {
        title: 'Default Loc',
      },
      manufacturingLocation: {
        title: 'Mfg Loc',
      },
      activeFlag: {
        title: 'Active Flag',
      },
    },
    attr: {
      class: 'table table-bordered table-striped'
    },
    add: {
      addButtonContent: '<i class="fa fa-plus"></i>',
      createButtonContent : '<i class="fas fa-check"></i>',
      cancelButtonContent : '<i class="fas fa-times"></i>',
      confirmCreate: true,
    },
    edit: {
      editButtonContent: '<i class="far fa-edit"></i>',
      saveButtonContent: '<i class="fas fa-check"></i>',
      cancelButtonContent: '<i class="fas fa-times"></i>',
      confirmSave: true,
    },
    delete: {
      deleteButtonContent: '<i class="far fa-trash-alt"></i>',
      confirmDelete: true,
    },
  };

  public productCodes = [];
  public defaultLocs = [];
  public location = "";
  public ruleid;

  constructor(private redirectorService: RedirectorService) { }

  ngOnInit() {
    this.loading = true;
    this.loadRedirectorRules();    
  }

  getProductCodeList() {
    this.productCodes = this.redirectorRows.map(item => item.productCode)
        .filter((value, index, self) => self.indexOf(value) === index && value != null);
  }

  getDefaultLocList() {
    this.defaultLocs = this.redirectorRows.map(item => item.manufacturingLocation)
        .filter((value, index, self) => self.indexOf(value) === index);
  }

  // filterActiveRows() {
  //   this.redirectorRows = this.redirectorRows.filter(a=>a.activeFlag=="*")
  // }

  loadRedirectorRules(){
    this.redirectorService.getRedirectorRules().subscribe(list => {
      this.redirectorRows = list;
      //this.filterActiveRows();
      this.getProductCodeList();  
      this.getDefaultLocList();
      this.loading = false;
    });
  }

  findLoc(){
    this.redirectorService.getRedirectorLocation(this.defaultLoc, this.productCode, this.customerNumber, this.zipCode)
    .subscribe(res => {
      this.ruleid = res.ruleId;
      this.location = res.location;
      console.log (this.defaultLoc + "," + this.productCode + "," + this.customerNumber + "," + this.zipCode);
      console.log("location = " + JSON.stringify(res) );
      
    });
  }

  onSaveConfirm(event) {
    if (window.confirm('Are you sure you want to save?')) {
      event.confirm.resolve(event.newData);
    } else {
      event.confirm.reject();
    }
  }

  onCreateConfirm(event) {
    if (window.confirm('Are you sure you want to create?')) {
      //event.newData
      event.confirm.resolve(event.newData);
      this.redirectorService.addRedirectorRule(event.newData)
      .subscribe(res => {
        console.log (res);
      });
    } else {
      event.confirm.reject();
    }
  }

  onDeleteConfirm(event) {
    if (window.confirm('Are you sure you want to delete?')) {
      //event.newData.activeFlag = "N";
      event.confirm.resolve(event.newData);
      this.redirectorService.updateRedirectorRule(event.newData)
      .subscribe(res => {
        console.log (res);
      });
    } else {
      event.confirm.reject();
    }
  }

}
