<div class="popup" *ngIf="notifications.length">
  <div class="overlay"></div>
  <div class="modal_content">
    <div *ngFor="let notification of notifications">
      <!-- Dynamic Section -->
      <h2>{{ notification.title }}</h2>
      <p class="alert alert-{{ notification.alertClass }}">
        {{notification.message}}
      </p>
      <!-- End of Dynamic Section -->
      <button class="close_modal" title="Close" (click)="closeNotification(notification.id)">
        <i class="fas fa-times"></i>
      </button>
    </div>
   
  </div>
</div>