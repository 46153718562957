import { PendingDesignListItem } from '../models/index';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PendingDesignService {

  baseUrl = environment.baseUrl;

  constructor(private httpClient: HttpClient) { }

  getPendingDesigns(skip: number = 0, take: number = 20, filter: string = ''): Observable<PendingDesignListItem[]> {
    return this.httpClient.get<{ pendingDesigns: PendingDesignListItem[] }>
      (`${this.baseUrl}/api/pendingdesign/${skip}/${take}/${filter}`)
      .pipe(
        map(result => result.pendingDesigns))
      .pipe(
        first()
      );
  }

  getPendingDesignsBySearchTerm(skip: number = 0, take: number = 20, filter: string = '', value = ''): Observable<PendingDesignListItem[]> {
    return this.httpClient.get<{ pendingDesigns: PendingDesignListItem[] }>
      (`${this.baseUrl}/api/pendingdesign/ListBySearchTerm/${skip}/${take}/${filter}/${value}`)
      .pipe(
        map(result => result.pendingDesigns))
      .pipe(
        first()
      );
  }


  completePendingDesign(EventId = ''): Observable<PendingDesignListItem[]> {
    return this.httpClient.put<any>
      (`${this.baseUrl}/api/pendingdesign/CompletePendingDesign/${EventId}`, null)
      .pipe(
        first()
      );
  }
}