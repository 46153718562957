import { OrderFile, Placement, UnexpectedShipment, UnexpectedShipmentEmailLogModel } from './../../models/index';
import { shortDate } from './../../displayHelpers';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import * as uuid from 'uuid';
import * as _ from 'lodash';

import { longDateWithSeconds } from '../../displayHelpers';
import {
  CsgPdfForm,
  CsgRequestDetailItem,
  CsgRequestItemModel,
  LocationModel,
  ReleasedCsgRequestItemModel,
  SalesOrderDetailItem,
  SalesOrderListItem,
} from '../../models';
import { AuthService } from '../../services/auth.service';
import { CsgRequestsService } from '../../services/csg-requests.service';
import { LocationLookupService } from '../../services/location-lookup.service';
import { NotificationService } from '../../services/notification.service';
import { SalesOrderService } from '../../services/sales-order.service';
import { type } from 'os';
import { formatDate } from '@angular/common';
import { createDate } from 'ngx-bootstrap/chronos/create/date-from-array';
import { stringify } from '@angular/compiler/src/util';
import { slice } from 'lodash';
import { FileService } from 'src/app/services/file.service';

const jsPDF = require('jspdf');
require('jspdf-autotable');
@Component({
  selector: 'app-csgrequest-detail',
  templateUrl: './csg-unexpected-detail.component.html',
  styleUrls: ['../csgrequest-detail/csgrequest-detail.component.scss', './../../app.component.scss']
})
export class CsgUnexpectedDetailComponent implements OnInit {
  model: UnexpectedShipment;
  orderSkus = new Array<string>();
  placements = new Array<{
    id: string,
    name: string
  }>();
  longDateWithSeconds = longDateWithSeconds;
  shortDate = shortDate;
  isReceiving: boolean;
  locationModel = new Array<LocationModel>();
  selectedLocation = '';
  comments = '';
  salesOrderDetailModel: SalesOrderDetailItem;
  loading = false;
  commentsSaved = false;
  csgPdfFormInfo = new Array<CsgPdfForm>();
  changeWarehouseVisible = false;
  emailLogs: UnexpectedShipmentEmailLogModel[];
  status: string;
  filesToUpload = new Array<File>();
  unexpectedShipmentFiles = new Array<OrderFile>();
  uploadPending = false;

  constructor(private csgService: CsgRequestsService,
    private salesOrderService: SalesOrderService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private locationService: LocationLookupService,
    private fileService: FileService) { }

  ngOnInit() {
    this.loading = true;
    const { csgRequestId } = this.route.snapshot.params;
    this.locationService.getLocations().subscribe(results => {
      this.locationModel = results;
    });
    this.csgService.getUnexpectedShipmentById(csgRequestId).subscribe(r => {
      this.model = r;
      this.selectedLocation = r.warehouseCode;
      if (!this.IsNullOrEmpty(this.model.emailLog)) {
        this.emailLogs = JSON.parse(this.model.emailLog);
      }

      if (this.model.status === 'Action Required') {
        this.status = this.model.action;
      } else if (this.model.action === 'Dispose Complete') {
        this.status = this.model.action;
      } else {
        this.status = this.model.isCancelled ? 'Cancelled' : this.model.status;
      }

      this.csgService.getCsgRequestFiles(r.receivingId, true).subscribe(files => {
        this.unexpectedShipmentFiles = files;
      });

      this.loading = false;
    });


  }
  saveBoxesAdd() {
    this.model.numberOfBoxes += 1;
  }
  saveBoxesMinus() {
    if (this.model.numberOfBoxes > 0) {
      this.model.numberOfBoxes = this.model.numberOfBoxes - 1;
    } else {
      return;
    }
  }
  saveBoxes() {
    this.csgService.updateCsgRequestBoxes(this.model.id, this.model.numberOfBoxes, false).subscribe(response => {
      if (response.success) {
        this.notificationService.showPopup('Number of boxes updated!', 'success', 'Updated');
      } else {
        this.notificationService.showPopup(response.message, 'danger', 'Error');
      }
    }, err => {
      this.notificationService.showPopup('Error updating Csg request boxes', 'danger', 'Error');
    });
  }
  saveComments() {
    this.csgService.updateCsgRequestComments(this.model.id, this.comments).subscribe(response => {
      this.commentsSaved = true;
      this.notificationService.showPopup('CSG request comments updated!', 'warning', 'Updated');
    }, err => {
      this.notificationService.showPopup('Error updating Csg request comments', 'danger', 'Error');
    });
  }

  updateLocation() {
    this.csgService.updateCsgRequestLocation(this.model.id, this.selectedLocation).subscribe(response => {
      this.notificationService.showPopup('CSG request location updated!', 'warning', 'Updated');
      this.changeWarehouseVisible = false;
    }, err => {
      this.notificationService.showPopup('Error updating Csg request location', 'danger', 'Error');
    });
  }

  viewCsgLabel(): void {
    this.csgService.lastUnexpectedModel = this.model;
    this.router.navigate(['csgrequests', this.model.id, 'label'], {
      queryParams: { 'unexpected': true }
    });
  }

  getDateReleased(item: ReleasedCsgRequestItemModel) {
    return moment(item.dateReleased).fromNow();
  }

  downloadDetailedLabel() {
    const detailedLabelButton = document.getElementById('detailedLabelButton');
    if (detailedLabelButton.classList.contains('disabled')) {
      return;
    } else {
      const doc = new jsPDF();
      const yPosition = 10;
      doc.setFont('arial', 'bold');
      doc.text(20, 20, 'CSG ORDER FORM');
      doc.line(20, 22, 72, 22, 'fill');
      doc.setFont('arial', 'normal');
      if (this.model.receivingId) {
        doc.text(20, 30, 'Receiving ID : ' + this.model.receivingId);
      } else {
        doc.text(20, 30, 'Receiving ID : N/A');
      }
      doc.text(20, 40, 'CSG # : N/A');
      if (this.model.customer.companyName) {
        doc.text(20, 50, 'Customer Name : ' + this.model.customer.companyName);
      } else {
        doc.text(20, 50, 'Customer Name : N/A');
      }
      if (this.model.customer.id) {
        doc.text(20, 60, 'Customer # : ' + this.model.customer.id);
      } else {
        doc.text(20, 60, 'Customer # : N/A');
      }
      if (this.model.clientReferenceNumber) {
        doc.text(20, 70, 'Client Reference # : ' + this.model.clientReferenceNumber);
      } else {
        doc.text(20, 70, 'Client Reference # : N/A');
      }
      if (this.model.numberOfBoxes) {
        doc.text(20, 80, 'Total Number of Boxes : ' + this.model.numberOfBoxes);
      } else {
        doc.text(20, 80, 'Total Number of Boxes : 0');
      }
      doc.text(20, 90, 'Sales Order # : N/A');
      // table
      const columns = ['Grouping Id', 'PON', 'Brand', 'Color', 'Description', 'Size', 'Quantity', 'Received Date'];
      const rows = [];
      doc.text(20, 100, 'Total Qty Received : 0');
      doc.autoTable(columns, rows, { startY: 110 });
      doc.save('CSG Order - ' + this.model.receivingId + '.pdf');
    }
  }

  canCancelSalesOrder(): boolean {
    if (this.model.isCancelled) {
      return false;
    }

    return (this.authService.canEditSalesOrder() && this.model.status.toLowerCase() !== 'cancelled');
  }

  IsNullOrEmpty(item: string): boolean {
    return !item || item === '';
  }

  cancelRequest(): void {
    this.csgService.cancelUnexpectedShipment(this.model.id).subscribe(response => {
      if (!!response && response.success) {
        this.notificationService.showPopup('Unexpected Shipment cancelled!', 'success', 'Success!');
      } else {
        this.notificationService.showPopup(response.message, 'danger', 'Error!');
      }
    }, err => {
      this.notificationService.showPopup('Error cancelling Unexpected Shipment', 'danger', 'Error');
    });
  }

  getFormattedDateSent(date: string): string {
    return this.longDateWithSeconds(date.replace('Z', ''));
  }

  ensureBoxesFullNum(numberOfBoxes: number): void {
    this.model.numberOfBoxes = Math.floor(numberOfBoxes);
    if (this.model.numberOfBoxes < 1) {
      this.model.numberOfBoxes = 1;
    }
  }

  handleFileInput(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      if (this.filesToUpload.findIndex((c: File) => c.name === files.item(i).name) === -1) {
        this.filesToUpload.push(files.item(i));
      }
    }
  }

  removeFile(fileName: string) {
    this.filesToUpload = this.filesToUpload.filter(c => c.name !== fileName);
  }

  uploadFileList() {
    this.uploadPending = true;
    this.fileService.uploadFilesForUnexpectedShipment(this.filesToUpload, this.model.receivingId).subscribe(
      (response) => {
        this.unexpectedShipmentFiles = response;
        this.filesToUpload = new Array<File>();
        this.uploadPending = false;
        this.notificationService.showPopup('Successfully Uploaded Documents!', 'success', 'Success!');
      }, err => {
        this.notificationService.showPopup('Error Uploading Documents', 'danger', 'Error');
      }
    );
  }
}
