import { Component, OnInit } from '@angular/core';
import { SalesOrderDetailItem, EventLogDetailItem, SalesOrderLineItem, NameDropperName, DropperNameEdit } from 'src/app/models';
import { calendarDate } from 'src/app/displayHelpers';
import { longDateWithSeconds } from 'src/app/displayHelpers';
import { ActivatedRoute } from '@angular/router';
import { SalesOrderService } from './../../../services/sales-order.service';
import { EventService } from './../../../services/event.service';
import { UtilitiesService } from './../../../services/utilities.service';
import * as moment from 'moment';
import { htmlAstToRender3Ast } from '@angular/compiler/src/render3/r3_template_transform';
import { count } from 'console';

@Component({
	selector: 'app-sales-order-remake',
	templateUrl: './sales-order-remake.component.html',
	styleUrls: ['./sales-order-remake.component.scss', './../../../app.component.scss']
})

export class SalesOrderRemakeComponent implements OnInit {
	countriesData: any;
	defaultCountry: string;
	newShippingCode: string;
	model: SalesOrderDetailItem;
	currentOrderLineId: string;
	selectedLines: Array<number>;
	event: EventLogDetailItem;
	remakeSent: boolean;
	calendarDate = calendarDate;
	longDateWithSeconds = longDateWithSeconds;
	selectedShippingAgent: string;
	selectedShippingCode: string;
	shippingCodeOptions: Array<any>;
	shippingAgents = {
		'DELIVERY': ['10'],
		'EMAIL': ['EMAIL'],
		'FEDEX': ['F1', 'F2', 'F8', 'F9', 'FG'],
		'PICK UP': ['11'],
		'UPS': ['01', '02', '03', '04', '07']
	};
	sortedLines: Array<SalesOrderLineItem>;
	viewingLine: SalesOrderLineItem;
	dropperNames = new Array<DropperNameEdit>();
	dropperOrderLineIds = new Array<string>();
	submitDropperNames: Array<NameDropperName>;
	shippingAgentDescriptions = [
		{ id: 'EMAIL', agent: 'EMAIL', name: 'Email' },
		{ id: 'F1', agent: 'FEDEX', name: 'FedEx Next Day Air' },
		{ id: 'F2', agent: 'FEDEX', name: 'FedEx 2nd Day' },
		{ id: 'F8', agent: 'FEDEX', name: 'FedEx 8AM' },
		{ id: 'F9', agent: 'FEDEX', name: 'Fedex Saturday Delivery' },
		{ id: 'FG', agent: 'FEDEX', name: 'FedEx Ground' },
		{ id: '11', agent: 'PICK UP', name: 'Local Pickup' },
		{ id: '01', agent: 'UPS', name: 'UPS Ground' },
		{ id: '02', agent: 'UPS', name: 'UPS 2nd Day' },
		{ id: '03', agent: 'UPS', name: 'UPS Next Day Air' },
		{ id: '04', agent: 'UPS', name: 'UPS Early 8AM' },
		{ id: '07', agent: 'UPS', name: 'UPS Sat Delivery' },
		{ id: '10', agent: 'DELIVERY', name: 'Local Delivery' }
	];
	errorMessage: string;

	constructor(private route: ActivatedRoute,
		private salesOrderService: SalesOrderService,
		private eventService: EventService,
		private utilitiesService: UtilitiesService
	) { }

	ngOnInit() {
		this.errorMessage = null;
		this.viewingLine = null;
		this.currentOrderLineId = null;
		this.selectedLines = [];
		this.remakeSent = false;
		this.sortedLines = [];

		const { orderId } = this.route.snapshot.params;

		this.salesOrderService.getSalesOrder(orderId).subscribe(detail => {
			this.model = detail;
			this.sortedLines = this.sortLineByLineNumber(this.model.lines)
			this.model.priority = 'REMAKE';
			this.eventService.getEventItemByOrderId(this.model.id).subscribe(event => {
				this.event = event;
			});
			this.selectedShippingAgent = this.model.shippingAgentCode;
			this.selectedShippingCode = this.model.shippingAgentServiceCode;
			this.setShippingCodes();
			this.model.dueDate = null;
			//jvv - loading countries and setting the default one
			this.getCountries();
			this.setDefaultCountry(this.model.country);
		});
	}

	getCustomerGlNum(lines) {
		if (lines.length > 0) {
			return lines[0].customerGLNumber || '';
		}
		return '';
	}

	getAssemblyNumbers() {
		return this.model.lines.map(l => l.assemblyNumber).join(',');
	}

	ponFromSON(son: String, lineNum: String) {
		let output = son.replace('S', '');
		if (this.model.lines.length > 1) { output = output + '-' + lineNum; }
		return output;
	}

	sortLineByLineNumber(lines: Array<SalesOrderLineItem>) {
		return lines.sort(function (a, b) {
			return a.lineNumber < b.lineNumber ? -1 : 1;
		});
	}

	isRemake() {
		return this.model.isRemake;
	}

	selectAllOrderLines(e) {
		const checked = e.target.checked;
		this.model.lines.forEach(l => {
			this.toggleOrderLine(l.lineNumber, checked, false);
			if (checked) {
				this.addDropperNames(l);
				this.currentOrderLineId = l.id;
				this.toggleAllDropperNames(checked);
			}
		});
		this.currentOrderLineId = null;
		this.viewingLine = null;
	}

	selectAllDropperNames(e) {
		const checked = e.target.checked;
		this.toggleAllDropperNames(checked);
	}

	toggleAllDropperNames(checked: boolean) {
		if (this.currentOrderLineId != null) {
			this.dropperNames.forEach(dn => {
				if (dn.salesOrderLineId == this.currentOrderLineId) {
					dn.isSelected = checked;
				}
			});
			if (checked && this.hasSelectedDropperNames(this.currentOrderLineId)) {
				this.refreshOrderLineQuantity(this.currentOrderLineId);
			}
		}
	}

	selectOrderLine(lineNumber: number, evt) {
		const checked = evt.target.checked;
		this.toggleOrderLine(lineNumber, checked, true);
	}

	toggleOrderLine(lineNumber: number, checked: boolean, includeFocus: boolean) {
		const indexOfLineNum = this.selectedLines.indexOf(lineNumber);
		if (checked) {
			if (indexOfLineNum < 0) {
				this.selectedLines.push(lineNumber);
			}
			if (includeFocus) {
				this.setFocusOrderLine(lineNumber);
			}
		} else {
			if (indexOfLineNum >= 0) {
				this.selectedLines.splice(indexOfLineNum, 1);
			}
			if (includeFocus) {
				this.setFocusOrderLine(0);
			}
		}
	}

	setFocusOrderLine(lineNumber: number) {
		if (lineNumber <= 0) {
			this.currentOrderLineId = null;
			this.viewingLine = null;
		}
		else {
			const salesOrderLine = this.model.lines.find(l => l.lineNumber == lineNumber);
			if (salesOrderLine && salesOrderLine != null) {
				this.addDropperNames(salesOrderLine);
				this.currentOrderLineId = salesOrderLine.id;
				this.viewingLine = salesOrderLine;
			}
			else {
				this.currentOrderLineId = null;
				this.viewingLine = null;
			}
		}
	}

	getDropperNamesForSubmit(orderLineId: string) {
		var submitDropperNames = new Array<NameDropperName>();
		dropperName: NameDropperName;
		this.dropperNames.forEach(dn => {
			if (dn.isSelected && dn.salesOrderLineId == orderLineId) {
				let dropperName = new NameDropperName({
					LineNameNo: dn.lineNameNo,
					QtyToProduce: dn.qtyToProduce,
					Line1Text: dn.line1Text,
					Line2Text: dn.line2Text,
					Line3Text: dn.line3Text,
					RecipeId: dn.recipeId,
				});
				submitDropperNames.push(dropperName);
			}
		});
		return submitDropperNames;
	}

	remakeOrder() {
		if (this.selectedLines.length === 0) {
			alert('At least one order line must be selected.');
			return;
		}
		if (!this.validDueDate()) {
			alert('Due date must be in the future.');
			return;
		}
		if (this.selectedShippingCode === '') {
			alert('Shipping Agent Code must be selected.');
			return;
		}
		var invalidQtyMessage = this.validateOrderLineQuantities();
		if (invalidQtyMessage != null && invalidQtyMessage.length > 0) {
			alert(invalidQtyMessage);
			return;
		}
		// Note: this should never happen in production, but has in dev/test due to bad test data.
		if (this.event == null || this.event.content == null) {
			this.errorMessage = "ERROR: A remake order cannot be submitted for order '" + this.model.id + "' because it is missing from the event log. Please contact IT support.";
			alert(this.errorMessage);
		}

		// alter values in this.event to match UI.
		const formattedDueDate = this.formatDate(this.model.dueDate);
		let orderDate = new Date();
		const event = JSON.parse(this.event.content).Payload;
		event.Data.IsRemake = true;
		event.Data.PoNumber = this.model.externalDocumentNumber;
		event.Data.ExternalDocumentNumber = this.model.externalDocumentNumber;
		event.Data.DueDate = formattedDueDate;
		event.Data.OrderedDateTime = (orderDate.getMonth() + 1) + "/" + orderDate.getDate() + "/" + orderDate.getFullYear();
		event.Data.RequestedDeliveryDate = formattedDueDate;
		event.Data.Comments = this.model.comments;
		event.Data.Address = this.model.address;
		event.Data.Address2 = this.model.address2;
		event.Data.City = this.model.city;
		event.Data.State = this.model.state;
		event.Data.PostalCode = this.model.postalCode;
		event.Data.ShippingAgentCode = this.selectedShippingAgent;
		event.Data.ShippingAgentServiceCode = this.selectedShippingCode;
		event.Data.ShippingAgentDescription = this.getShippingAgentDescription(this.selectedShippingCode);
		event.Data.Email = this.model.email;
		event.Data.referenceNumber = 'remake-' + this.model.referenceNumber;
		event.Data.shipToCompany = this.model.shipToCompany;
		event.Data.shipToName = this.model.shipToName;
		event.Data.StatusCallbackUri = '';

		//jvv - adding field for country code
		event.Data.Country = this.defaultCountry;
		event.Data.country = this.defaultCountry;

		// filter lines from this.event.payload.
		event.Data.Lines = event.Data.Lines.filter(line => {
			return this.selectedLines.includes(line.LineNumber);
		});

		event.Data.Lines = event.Data.Lines.map(line => {
			const modelLine = this.model.lines.find(l => l.lineNumber === line.LineNumber);
			line.RemakePon = modelLine.assemblyNumber ?? modelLine.pon;
			if (modelLine.assemblyNumber == null)
				line.pon = null;
			line.DesignId = modelLine.designId
			line.Quantity = modelLine.quantity;
			line.NameDropperNames = this.getDropperNamesForSubmit(modelLine.id);
			return line;
		});

		var counter = 1;
		event.Data.Lines.forEach(line => {
			line.lineNumber = counter;
			counter++;
		});

		const e = JSON.stringify(event);
		//this.submitEvent = e;

		const newEvent = {
			OrderId: event.Data.referenceNumber,
			Topic: 'new-sales-order',
			Payload: event
		};
		this.eventService.emitEvent(newEvent).subscribe(id => {
			this.event.id = id;
		});
		this.remakeSent = true;
	}

	validDueDate() {
		const todayString = moment().format('YYYY-MM-DD');
		return this.model.dueDate >= todayString;
	}

	formatDate(sortableDateString: string) {
		return moment(sortableDateString).format('MM/DD/YYYY [00:00:00]');
	}

	shippingAgentNames() {
		return Object.keys(this.shippingAgents);
	}

	setShippingCodes() {
		this.shippingCodeOptions = this.shippingAgentDescriptions.filter(s => s.agent == this.selectedShippingAgent);
		if (this.shippingCodeOptions.length == 1) {
			this.selectedShippingCode = this.shippingCodeOptions[0].id;
		}
		else {
			this.selectedShippingCode = '';
		}
	}

	getShippingAgentDescription(selectedShippingCode: string): string {
		return this.shippingAgentDescriptions.find(code => code.id == selectedShippingCode).name;
	}

	// toDo: Do we need to filter on isNameDropper == "1" ?
	addDropperNames(line: SalesOrderLineItem) {
		if (line.nameDropperNames != null && line.nameDropperNames > "" && line.nameDropperNames != "[]" && line.nameDropperNames != "null"
			&& !this.dropperOrderLineIds.includes(line.id)) {
			rows: Array<any>();
			dropperName: DropperNameEdit;
			let rows = JSON.parse(line.nameDropperNames);
			rows.forEach(row => {
				let dropperName = new DropperNameEdit({
					lineNameNo: row.LineNameNo,
					qtyToProduce: row.QtyToProduce,
					line1Text: row.Line1Text,
					line2Text: row.Line2Text,
					line3Text: row.Line3Text,
					recipeId: row.RecipeId,
					salesOrderLineId: line.id,
					isSelected: false
				});
				this.dropperNames.push(dropperName);
			});
			this.dropperOrderLineIds.push(line.id);
		}
	}

	getDropperNames(): Array<DropperNameEdit> {
		if (this.viewingLine && this.viewingLine != null) {
			return this.dropperNames.filter(dn => dn.salesOrderLineId == this.viewingLine.id);
		}
		else {
			return null;
		}
	}

	getSubmittedDropperNames(): Array<DropperNameEdit> {
		if (this.viewingLine && this.viewingLine != null) {
			return this.dropperNames.filter(dn => dn.salesOrderLineId == this.viewingLine.id && dn.isSelected);
		}
		else {
			return null;
		}
	}

	getCurrentOrderLineVisual() {
		if (this.viewingLine && this.viewingLine != null) {
			return ("  (PON: " + this.viewingLine.pon + ")");
		}
		else {
			return null;
		}
	}

	hasDropperNames(): boolean {
		if (this.viewingLine && this.viewingLine != null) {
			return (this.dropperOrderLineIds.includes(this.viewingLine.id));
		}
		return false;
	}

	hasSelectedDropperNames(orderLineId: string): boolean {
		if (this.dropperNames && this.dropperNames != null) {
			if (this.dropperNames.filter(dn => dn.salesOrderLineId == orderLineId && dn.isSelected).length > 0) {
				return true;
			}
		}
		return false;
	}

	hasDropperNamesAndOrderLineSelected(orderLineId: string, lineNumber: number): boolean {
		return (this.selectedLines.includes(lineNumber) && this.dropperOrderLineIds.includes(orderLineId));
	}

	isOrderLineSelected(lineNumber: number): boolean {
		return (this.selectedLines.includes(lineNumber));
	}

	isDropperNameSelected(orderLineId: string, lineNo: string): boolean {
		if (this.dropperNames && this.dropperNames != null) {
			if (this.dropperNames.filter(dn => dn.salesOrderLineId == orderLineId && dn.lineNameNo == lineNo && dn.isSelected).length > 0) {
				return true;
			}
		}
		return false;
	}

	refreshOrderLineQuantity(orderLineId: string) {
		if (this.dropperNames && this.dropperNames != null) {
			const total = this.sumOfDropperNameQuantities(orderLineId);
			var line = this.sortedLines.find(l => l.id == orderLineId);
			if (line != null) {
				line.quantity = total.toString();
			}
		}
	}

	sumOfDropperNameQuantities(orderLineId: string): number {
		return this.dropperNames.filter(dn => dn.isSelected && dn.salesOrderLineId == orderLineId).reduce((sum, current) => Number(sum) + Number(current.qtyToProduce), 0);
	}

	validateOrderLineQuantities(): string {
		var invalidLineNumbers = this.sortedLines.filter(l => this.hasDropperNamesAndOrderLineSelected(l.id, l.lineNumber) && 
		(Number(l.quantity) != this.sumOfDropperNameQuantities(l.id)))
			.reduce((last, current) => last + ", " + current.lineNumber, "");
		if (invalidLineNumbers != null && invalidLineNumbers.length > 0) {
			invalidLineNumbers = invalidLineNumbers.substring(2);
			return "Quantity is invalid for the following sales order NAV Line Number: " + invalidLineNumbers + ". Please make corrections before submitting the remke order."
		}
		return null;
	}

	validateForSubmit() {
		var isValid = true;
		if (this.selectedLines.length > 0) {
			this.sortedLines.forEach(l => {
				if (this.hasDropperNamesAndOrderLineSelected(l.id, l.lineNumber)) {
					if (!this.hasSelectedDropperNames(l.id)) {
						isValid = false;
					}
				}
			});
		}
		else {
			isValid = false;
		}

		if (this.errorMessage != null) {
			isValid = false;
		}

		return isValid;
	}

	getCountries() {
		this.utilitiesService.getCountriesCatalog().subscribe({
			next: (response) => {
				this.countriesData = response;
			},
			error: (error) => {
				//console.error('Error fetching countries:', error);
			},
			complete: () => {
				//console.log('Country fetch completed');
			}
		});
	}

	setDefaultCountry(countryCode:string) {

		//if countryCode hasn't value then no default country is applied
		if(countryCode !="" || !countryCode == undefined){	
			this.defaultCountry = countryCode;
		}	
	}

}
