<div>
    <h2 i18n>Non Translated Threads</h2>
    <div class="row">
        <div class="col-md-10">
            <div class="table-responsive" *ngIf="!loading">
                <table class="table table-striped table-centered">
                    <thead>
                        <th style="text-align:center" scope="col" i19n>
                            <div (click)="sortByCsgNumber()">Csg Number <span class="fa fa-sort"></span></div>
                        </th>
                        <th style="text-align:center" scope="col" i19n>
                            <div (click)="sortBySalesOrder()">Sales Order Number <span class="fa fa-sort"></span></div>
                        </th>
                        <th style="text-align:center" scope="col" i19n>
                            <div (click)="sortByThreadBrand()">Thread Brand <span class="fa fa-sort"></span></div>
                        </th>
                        <th style="text-align:center" scope="col" i19n>
                            Thread Color
                        </th>
                        <th style="text-align:center" scope="col" i19n>
                            Ordered?
                        </th>
                        <th style="text-align:center" scope="col" i19n>
                        </th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let thread of threads; let i = index">
                            <td style="text-align:center">{{ thread.csgNumber|| 'N/A'}}</td>
                            <td style="text-align:center">{{ thread.salesOrderId || 'N/A'}}</td>
                            <td style="text-align:center">{{ thread.threadBrand|| 'N/A'}}</td>
                            <td style="text-align:center">{{ thread.threadColorCode || 'N/A'}}</td>
                            <td style="text-align:center"><input class="checkbox checkbox-centered" id="threadOnHand{{i}}" type="checkbox"
                                [(ngModel)]="thread.isThreadOrdered" (ngModelChange)="toggleIsOrdered(thread.id)" /></td>
                            <td style="text-align:center"><button class="btn btn-sm btn-primary" (click)="deleteThreadFromReport(thread.id)">Clear</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-2"></div>
        </div>
    </div>
</div>