import { DesignAttribute, ColorwayModel, ColorwayColorModel } from './../../../models/index';
import { NotificationService } from 'src/app/services/notification.service';
import { EventService } from 'src/app/services/event.service';
import { first } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DesignRequestsService } from './../../../services/design-requests.service';
import { Component, OnInit } from '@angular/core';
import { DesignViewModel } from 'src/app/models';
import * as moment from 'moment';
import * as uuid from 'uuid';
import * as _ from 'lodash';

@Component({
  selector: 'app-design-viewer',
  templateUrl: './design-viewer.component.html',
  styleUrls: ['./design-viewer.component.scss', './../../../app.component.scss']
})
export class DesignViewerComponent implements OnInit {
  model = <DesignViewModel> {
    pricingTool: {
      designId: '',
      navSku: '',
      customer: '',
      result: undefined
    }
  };
  showSearchBox = false;
  designId = '';
  designNotFound = false;
  isSearching = false;
  moment = moment;
  isEpiserver: boolean = false;
  navSku: string;
  navCustomer: string;

  constructor(private designRequestService: DesignRequestsService,
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    private eventService: EventService,
    private notificationService: NotificationService) { }

  ngOnInit() {
    const designId = this.route.snapshot.params['designId'];
    if (!designId || designId === '') {
      return;
    }

    this.route.paramMap.subscribe((params) => {
      this.isEpiserver = params.get('isEpiserver') == 'true';
      this.navSku = params.get('navSku');
      this.navCustomer = params.get('navCustomer');

      this.model.pricingTool.designId = designId;
      this.model.pricingTool.navSku = this.navSku;
      this.model.pricingTool.customer = this.navCustomer;
    });
    this.designId = designId;
    this.getDesign();
  }

  getPricing() {
    const { designId, customer, navSku } = this.model.pricingTool;
    this.designRequestService.getPricing(designId, navSku, customer)
      .subscribe(m => this.model.pricingTool.result = m);
  }

  syncPricing() {
    debugger;
    const customerArt = this.model.attributeModels.filter(c => c.type === 'customerart');
    if (customerArt && customerArt.length > 0 ) {
      const imageAttr = customerArt[0];
      if (imageAttr) {
          this.model.pricingTool.result.payload.data.imageUrl = (imageAttr.rawAttr) ? imageAttr.rawAttr.value : null;
      }
    }

    this.eventService.emitEvent({ ...this.model.pricingTool.result, Topic: this.model.pricingTool.result.Topic })
      .subscribe(m => this.notificationService.notifications.next({
        id: uuid.v4(), alertClass: 'success', message: 'Synced pricing back to EPI server!',
        title: 'Success', isRead: false, persist: false } ));
  }

  loadDesign() {
    if (this.designId.length <= 5 || this.isSearching) {
      return;
    }
    this.router.navigate([`designs/${this.designId}`]);
    this.getDesign();
  }

  save(attr: DesignAttribute) {
    this.model.regenerating = true;
    this.designRequestService
      .upsertAttributeById(attr)
      .subscribe(() => {
        this.notificationService.showPopup('Updated design attribute OK.');
        this.model.regenerating = false;
      });
  }

  generateNavSku(callback: (sku: string) => void) {
    this.model.regenerating = true;
    this.designRequestService
      .generateNavSku(this.model.attributeModels.map(m => m.rawAttr))
      .subscribe(sku => {
        this.model.navSku = JSON.parse(sku);
        callback(this.model.navSku);
        this.model.pricingTool.navSku = this.model.navSku;
        this.model.regenerating = false;
      });
  }

  getDesign() {
    this.designNotFound = false;
    this.isSearching = true;
    this.designRequestService.getDesign(this.designId, this.isEpiserver)
      .subscribe(model => {
        this.model = {
          ...model,
          pricingTool: this.model.pricingTool
        };
        if (this.model?.attributeModels?.length <= 0) {
          return;
        }
        this.designRequestService
          .generateNavSku(this.model.attributeModels.map(m => m.rawAttr))
          .subscribe(sku => {
            this.model.navSku = JSON.parse(sku);
            this.model.pricingTool.navSku = this.model.navSku;
          });

          this.designRequestService.getColorways(this.designId)
          .subscribe(colorways => {
            this.model.colorways = colorways;
          },
            err => {
              this.isSearching = false;
            }, () => this.isSearching = false);
      },
        err => {
          this.model = null;
          this.designNotFound = true;
          this.isSearching = false;
        }, () => this.isSearching = false);
  }

  getcolors(colors: ColorwayColorModel[]) {
    return _.orderBy(colors, c => c.order);
  }
}


