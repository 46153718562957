import { Component, TemplateRef, OnInit, OnDestroy } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { DesignRequestSummaryItem } from 'src/app/models';
import { DesignRequestsService } from 'src/app/services/design-requests.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-design-request',
  templateUrl: './design-request.component.html',
  styleUrls: ['./design-request.component.scss']
})
export class DesignRequestComponent implements OnInit {

  private designRequests: DesignRequestSummaryItem;
  private designRequestsAll: DesignRequestSummaryItem;
  drLoaded: Promise<boolean>;
  private bsConfig: Partial<BsDatepickerConfig>;
  private modalRef: BsModalRef;
  private productList = [];
  private toggleBarVal: boolean = false;
  private optionSelected: any;
  private dateValue:Date;
  public today:Date ;
  private curDateStr:string;
  private noData: boolean;

  //chart 
  private barChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
      labels: {
        boxWidth: 10,
      }
    },
    scales: {
      xAxes: [{}], yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };

  private barChartColors: Array<any> = [
    {backgroundColor: 'rgba(0,128,128)'},
    {backgroundColor: 'rgba(0,0,128)'},
    {backgroundColor: 'rgba(77,83,96,0.2)'},
    {backgroundColor: 'rgba(128,128,128)'},
  ];
  private barChartLabels = ['Status'];
  private barChartType: ChartType = 'bar';
  private barChartLegend = true;
  private barChartData: ChartDataSets[] = [];
  
  constructor(private designRequestService: DesignRequestsService,
              private modalService: BsModalService) { }

  ngOnInit() {
    this.optionSelected = 'All';
    this.bsConfig = Object.assign({}, { containerClass: "theme-default" });
    this.today = new Date();
    this.dateValue = this.today;
    this.loadDesignRequests(this.today);
  }

  onOptionsSelected(event) {
    console.log(event); //option value will be sent as event
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  toggleBar() {
    if (this.toggleBarVal == true) this.toggleBarVal = false
    else this.toggleBarVal = true;
  }

  loadDesignRequests(curDate:Date) {
    this.curDateStr = String(curDate.getFullYear()) + '-' 
                    + String(curDate.getMonth()+1).padStart(2, '0') + '-' 
                    + String(curDate.getDate()).padStart(2, '0');
    this.dateValue = curDate;
    this.designRequestService.getDesignRequestSummary(this.curDateStr).subscribe(list => {
      this.designRequestsAll = list;
      this.designRequests = this.designRequestsAll.designRequestSummaryModel;
      this.getProductList();
      this.filterDesignRequest('All');
      if (Object.keys(this.designRequests).length == 0)
      this.noData = true;
      else this.noData = false;
      this.drLoaded = Promise.resolve(true);      
    });
  }

  filterbyDate() {
     this.loadDesignRequests(this.dateValue);
  }

  getProductList() {
    this.productList = this.designRequestsAll.designRequestSummaryModel.map(item => item.productClass)
        .filter((value, index, self) => self.indexOf(value) === index);
  }

  filterDesignRequest(productClass) {
    this.designRequests = this.designRequestsAll.designRequestSummaryModel.filter(a=>a.productClass==productClass)
    this.buildBarData();
  }

  buildBarData() {
    this.barChartData = [];
    for (var i = 0; i < this.designRequests.length; i++) {
      this.barChartData.push({
        data: [this.designRequests[i].rowCnt],
        label: this.designRequests[i].status
      });
    }  
  }
}
