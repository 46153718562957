import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { AliasLookup } from './../models';

@Injectable({
  providedIn: 'root'
})
export class AliasLookupService {

  baseUrl = environment.baseUrl;

  constructor(private httpClient: HttpClient) { }

  getAliasLookups(skip: number = 0, take: number = 20): Observable<AliasLookup[]> {
    return this.httpClient.get<{ aliasLookups: AliasLookup[] }>
      (`${this.baseUrl}/api/aliaslookup/${skip}/${take}`)
      .pipe(
        map(result => result.aliasLookups))
      .pipe(
        first()
      );
  }
}
