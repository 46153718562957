import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { CsgRequestDetailItem, CsgRequestItemModel, ReleasedCsgRequestItemModel, LocationModel, CustomerModel, OrderFile } from 'src/app/models';
import { CsgRequestsService, unexpected } from 'src/app/services/csg-requests.service';
import { ActivatedRoute, Router, Event, NavigationEnd, RoutesRecognized } from '@angular/router';
import * as moment from 'moment';
import { NotificationService } from 'src/app/services/notification.service';
import { LocationLookupService } from '../../../services/location-lookup.service';

import { CustomerService } from '../../../services/customer.service';
import { FileService } from 'src/app/services/file.service';

@Component({
  selector: 'app-csgrequest-add',
  templateUrl: './csgrequest-add.component.html',
  styleUrls: ['./csgrequest-add.component.scss', './../../../app.component.scss']
})
export class CsgrequestAddComponent implements OnInit {
  
  capturedFilesToUpload = new Array<File>();
  
  model = <CsgRequestDetailItem><unknown>{
    id: '',
    customerName: '',
    customerNumber: '',
    poNumber: '',
    vendorName: '',
    vendorPO: '',
    outsideVendorName: '',
    csgNumber: '',
    orderKey: '',

    createDate: null,
    stage: '',
    numberOfBoxes: 1,
    addEdit: true,
    status: unexpected,
    createdInCart: true,
    totalQuantity: 0,
    warehouseCode: '',
    shipVia: '',
    shipCompanyName: '',
    shipAddress1: '',
    shipAddress2: '',
    shipAddress3: '',
    shipCity: '',
    shipState: '',
    shipCountry: '',
    shipZip: '',
    shipFirstName: '',
    phoneNumber: '',
    emailAddress: '',
    externalOrderNumber: '',
    customerNotes: '',
    internalNotes: '',
    shipFinishedGoodsType: '',
    clientReferenceNumber: '',
    receivedDate: '',
    receivedConfirmationEmail: '',
    receivingId: '',
    releaseDate: null,
    csgRequestItems: [],
    releasedItems: [],
    orderReference1: '',
    orderReference2: '',
    orderReference3: '',
    orderReference4: '',
    orderReference5: ''
  };
  locationModel = new Array<LocationModel>();
  locationSelect = '';
  customersLoading = false;
  customersModel = new Array<CustomerModel>();
  customerInput = '';
  customerSelect;
  formIsValid = false;
  validationErrs = [];
  saveLoading = false;
  loading = false;
  filesToUpload = new Array<File>();
  unexpectedShipmentFiles = new Array<OrderFile>();
  uploadPending = false;

  constructor(private csgService: CsgRequestsService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private locationService: LocationLookupService,
    private customerService: CustomerService,
    private fileService: FileService) {
  }

  async ngOnInit() {
    this.loading = true;
    this.csgService.getReceivingId().subscribe((recId: string) => {
      this.model.receivingId = recId;
      this.loading = false;
    });

    this.locationService.getLocations(true).subscribe(results => {
      this.locationModel = results;
      const firstLoc = this.locationModel[0];
      if (firstLoc != null && firstLoc !== undefined) {
        this.model.warehouseCode = firstLoc.locationCode;
      }
    });
    this.addGarment();
  }

  getCustomers() {

    if ((this.customerInput == null || this.customerInput === '') || this.customerInput.length < 3) { // dont try to load every customer.
      return;
    }
    this.customersLoading = true;
    this.customerService.getCustomers(this.customerInput).subscribe(c => {
      this.customersModel = c;
      this.customerSelect = c[0];
      this.customersLoading = false;
    });
  }

  setCustomerToModel() {
    if (this.customerSelect) {
      this.model.customerName = this.customerSelect.companyName;
      this.model.customerNumber = this.customerSelect.sourceCustomerNumber;
      this.model.customerId = this.customerSelect.id;
    }
  }

  validateForm() {
    this.formIsValid = true;
    this.validationErrs = [];

    if (this.filesToUpload && this.filesToUpload.length > 0) {
      this.formIsValid = false;
      this.validationErrs
        .unshift('Files have been selected but not yet uploaded. Please click \'Upload\' to upload the selected files or remove them');
      return;
    }

    if (this.IsNullOrEmpty(this.model.customerName)) {
      this.formIsValid = false;
      this.validationErrs.unshift('Customer Selection is required');
    }

    if (this.IsNullOrEmpty(this.model.warehouseCode)) {
      this.formIsValid = false;
      this.validationErrs.unshift('Warehouse Code is required');
    }

    if (this.model.numberOfBoxes > 10) {
      this.formIsValid = false;
      this.validationErrs.unshift('Number of Boxes cannot exceed 10');
    }

    if (this.model.csgRequestItems.filter(c => c.quantity > 500).length > 0) {
      this.formIsValid = false;
      this.validationErrs.unshift('Garment Quantity cannot exceed 500');
    }
  }

  addRequest() {
    this.setCustomerToModel();
    this.validateForm();

    if (this.formIsValid) {
      this.saveLoading = true;
      //Call this method once we are done with all Uploads and want to save
      this.fileService.uploadFilesForUnexpectedShipment(this.capturedFilesToUpload, this.model.receivingId).subscribe(
        (response) => {

          this.model.uploadedURLs = '';
          for (var i = 0; i < response.length; i++)
            this.model.uploadedURLs += response[i].uploadedUrl + ';';

          this.capturedFilesToUpload = new Array<File>();

          this.csgService.addCsgRequest(this.model).subscribe(res => {
            if (!!res && res.success) {
              this.notificationService.showPopup('Created CSG request!', 'success', 'Success');
              this.router.navigate(['csgrequests/unexpected/' + res.csgId]);
              this.saveLoading = false;
            } else {
              this.notificationService.showPopup(res.message, 'danger', 'Error');
              this.saveLoading = false;
            }
          }, err => {
            this.notificationService.showPopup('CSG request not created!', 'danger', 'Error');
          });
        });
    } else {
      const validations = this.validationErrs.join(', ');
      this.notificationService.showPopup(`Form Validation Errors: ${validations}`, 'danger', 'Unable To Save Shipment!');
    }
  }

  removeRow(model: CsgRequestItemModel) {
    this.model.csgRequestItems = this.model.csgRequestItems.filter(obj => obj !== model);
  }

  setLocationToModel() {
    this.model.warehouseCode = this.locationSelect;
  }

  addGarment() {
    this.model.csgRequestItems.push(<CsgRequestItemModel>{
      id: '',
      quantity: 1,
      received: moment().format(),
      createDate: moment().format(),
      addEdit: true,
      status: '',
      quantityReceived: 0,
      size: '',
      color: '',
      brand: '',
      description: '',
      lineNumber: '',
      catalogSku: '',
      garmentPlacementName: '',
      wearer: '',
      noDecoration: false
    });
  }

  ensureBoxesFullNum(numberOfBoxes: number): void {
    this.model.numberOfBoxes = Math.floor(numberOfBoxes);
    if (this.model.numberOfBoxes < 1) {
      this.model.numberOfBoxes = 1;
    }
  }

  ensureQtyFullNum(item: CsgRequestItemModel, qty: number): void {
    item.quantity = Math.floor(qty);
    if (item.quantity < 0) {
      item.quantity = 0;
    }
  }

  IsNullOrEmpty(item: string): boolean {
    return !item || item === '';
  }

  handleFileInput(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      if (this.filesToUpload.findIndex((c: File) => c.name === files.item(i).name) === -1) {
        this.filesToUpload.push(files.item(i));
      }
    }
  }

  removeFile(fileName: string) {
    this.filesToUpload = this.filesToUpload.filter(c => c.name !== fileName);
  }

  uploadFileList() {
    this.uploadPending = true;
    this.fileService.uploadFilesForUnexpectedShipment(this.filesToUpload, this.model.receivingId).subscribe(
      (response) => {
        this.unexpectedShipmentFiles = response;
        this.filesToUpload = new Array<File>();
        this.uploadPending = false;
        this.notificationService.showPopup('Successfully Uploaded Documents!', 'success', 'Success!');
      }, err => {
        this.notificationService.showPopup('Error Uploading Documents', 'danger', 'Error');
      }
    );
  }
}
