<nav class="navbar navbar-expand-md navbar-light bg-light mb-4 fixed-top" role="navigation">
  <a class="navbar-brand" href="#"><img src="header_logo.png" alt="WEI Intranet" ><small>{{ getEnvDescription() }}</small></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
    aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarCollapse">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item" routerLinkActive="active">
        <a i18n routerLink="/landing" class="nav-link" href="#">Summary</a>
      </li>
      <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            Designs
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" i18n routerLink="/requests" href="#">Design Request</a>
            <a class="dropdown-item" i18n routerLink="/designs" href="#">Design Lookup</a>
            <a class="dropdown-item" i18n routerLink="/pendingrequests" href="#">Pending Designs</a>
          </div>
      </li>
      <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            Orders
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" i18n routerLink="/orders" href="#">Sales Orders</a>
            <a class="dropdown-item" i18n routerLink="/csgrequests" href="#">CSG Orders</a>
            <a class="dropdown-item" i18n routerLink="/unexpectedshipments" href="#">Unexpected Shipments</a>
            <a class="dropdown-item" i18n routerLink="/unexpected-csg-action-required" href="#">Unexpected Shipments - Action Required</a>
          </div>
      </li>
      <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            Monitor
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" i18n routerLink="/events" href="#">Event Log</a>
            <a class="dropdown-item" i18n routerLink="/monitor" href="#">Import Monitor</a>
            <!-- <a class="dropdown-item" i18n routerLink="/deadletterlogs" href="#">Dead Letter</a>  commenting out for now until we turn on the dead letter polling-->
          </div>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          Reports
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" i18n routerLink="/open-orders" href="#">Tracking System Status</a>
          <a class="dropdown-item" i18n routerLink="/report/nontranslatedthreads" href="#">Non Translated Threads</a>
        </div>
    </li>

    </ul>
    <ul class="navbar-nav pull-right">
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          Settings
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" i18n routerLink="/settings" href="#">Customers</a>
          <a class="dropdown-item" i18n routerLink="/redirector" href="#">Redirector</a>
        </div>
      </li>
      <li class="nav-item">
        <a i18n class="nav-link" (click)="logout($event)" href="#">Logout</a>
      </li>
    </ul>
    <div *ngIf="model.user" class="nav-text mr-1"><a [routerLink]="['/me']" routerLinkActive="active">Hi, {{ model.user.username }}</a></div>
  </div>
</nav>
<div class="fixed-top" style="margin-top: 50px">
  <div *ngFor="let item of popouts | async">
    <div *ngIf="!item.isDismissed" class="alert" [ngClass]="'alert-' + item.messageSeverity">
      {{ item.message }}
      &nbsp;<button class="btn btn-primary" (click)="dismissEvent(item)">Dismiss</button>
    </div>
  </div>
</div>
<main role="main" class="container-fluid">
  <app-notification></app-notification>
  <router-outlet></router-outlet>
</main>