import { Component, OnInit } from '@angular/core';
import { DeadLetterLogItem } from 'src/app/models';
import { EventService } from 'src/app/services/event.service';

import * as displayHelpers from '../../displayHelpers';
import { ActivatedRoute } from '@angular/router';
import { DeadLetterService } from 'src/app/services/dead-letter.service';

@Component({
  selector: 'app-dead-letter-log',
  templateUrl: './dead-letter-log.component.html',
  styleUrls: ['./../../app.component.scss']
})
export class DeadLetterLogComponent implements OnInit {
  deadLetterEventItems: DeadLetterLogItem[] = [];
  model = {
    orderId: '',
    searchTerm: ''
  };
  loading = false;
  noMore = false;
  calendarDate = displayHelpers.calendarDate;
  timeout: number;

  constructor(private deadLetterService: DeadLetterService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParamMap.subscribe((pMap) => {
      this.model.orderId = pMap.get('orderId');
    });
    if (this.model.orderId != '') {
      this.fetchLatestdeadLetterEventItems(this.model.searchTerm);
    } else {
      this.fetchLatestdeadLetterEventItems();
    }
  }

  fetchLatestdeadLetterEventItems(search: string = '') {
    this.loading = true;
    this.noMore = false;
    this.deadLetterService.getDeadLetterLogEntries('', 20, search).subscribe(items => {
      this.deadLetterEventItems = items;
      this.loading = false;
    });
  }

  searchTermChanged() {
    if (!this.model.searchTerm || this.model.searchTerm.length < 3) return;

    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
    this.timeout = window.setTimeout(t =>  {
      this.fetchLatestdeadLetterEventItems(this.model.searchTerm);
    }, 2000);
  }

  loadOlder() {
    this.loading = true;
    this.noMore = false;
    this.deadLetterService.getDeadLetterLogEntries(this.deadLetterEventItems[this.deadLetterEventItems.length - 1].id,
      20,
      this.model.orderId).subscribe(items => {
      if (!items || items.length === 0) {
        this.noMore = true;
      }
      items.forEach(i => this.deadLetterEventItems.push(i));
      this.loading = false;
    });
  }

  clearDeadLetterItem(item: DeadLetterLogItem) {
    this.deadLetterService.clearDeadLetterEntry(item.id, false).subscribe(res => {
      this.deadLetterEventItems.splice(this.deadLetterEventItems.findIndex(e => e.id === item.id), 1);
    });
  }
}
