import { EventLogDetailItem } from './../models/index';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { EventLogListItem } from '../models';
import * as signalr from '@microsoft/signalr';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  baseUrl = environment.baseUrl;
  eventHub: signalr.HubConnection;

  constructor(private httpClient: HttpClient) { }

  getEventItems(startAt: string = '', take: number = 20, orderId: string = ''): Observable<EventLogListItem[]> {
    let url = `${this.baseUrl}/api/eventlog${startAt !== '' ? `/${startAt}/${take}` : ''}`;
    if (orderId && orderId !== '') {
      url = `${url}?orderId=${orderId}`;
    }
    return this.httpClient.get<{ eventLogEntries: EventLogListItem[] }>
      (url)
      .pipe(
        map(result => result.eventLogEntries))
      .pipe(
        first()
      );
  }

  //jvv-function for get eventlogs by eventName
  getEventItemsByEventName(last: string = '', skip: number = 0, take: number = 20, orderId: string = ''): Observable<EventLogListItem[]> {
    //let url = `${this.baseUrl}/api/eventlog/listbyeventname/${take}:''`; 

    let url = `${this.baseUrl}/api/eventlog/listbyeventname/${skip !== 0 ? ` ${skip} / ${take}` : ''}`;

    if (last && last !== '') {
      url = `${url}/${last}`;
    }

    if (orderId && orderId !== '') {
      url = `${url}?eventName=${orderId}`;
    }

    return this.httpClient.get<{ eventLogEntries: EventLogListItem[] }>
    (url)
    .pipe(map
      (result => result.eventLogEntries)).pipe(
        first()
      );
  }


  getEventItem(eventId: string): Observable<EventLogDetailItem> {
    return this.httpClient.get<EventLogDetailItem>
      (`${this.baseUrl}/api/eventlog/${eventId}`)
      .pipe(
        first()
      );
  }

  getEventItemByOrderId(orderId: string): Observable<EventLogDetailItem> {
    return this.httpClient.get<EventLogDetailItem>
      (`${this.baseUrl}/api/eventbyorderid/${orderId}`)
      .pipe(
        first()
      );
  }

  // getErrorEventItems(startAt: string = '', take: number = 20): Observable<OrderMonitorListItem[]> {
  //   return this.httpClient.get<{ orderMonitorEntries: OrderMonitorListItem[] }>
  //   (`${this.baseUrl}/api/ordermonitor${startAt !== '' ? `/${startAt}/${take}` : ''}`)
  //   .pipe(
  //     map(result => result.orderMonitorEntries))
  //   .pipe(
  //     first()
  //   );
  // }

  emitEvent(event: { Topic: string }): Observable<string> {
    return this.httpClient.put<any>
      (`${this.baseUrl}/api/admin/emitEvent`, JSON.stringify(JSON.stringify(event)), { headers: { 'content-type': 'application/json' }})
      .pipe(
        first()
      );
  }

  subscribeToOrder(orderId: string): Subject<EventLogDetailItem> {
    if (!this.eventHub) {
      this.connectToEventHub().then(() => this.eventHub.send('SubscribeToOrderEvents', orderId));
    } else {
      this.eventHub.send('SubscribeToOrderEvents', orderId);
    }
    const subject = new Subject<EventLogDetailItem>();
    this.eventHub.on('NewEvent', evt => subject.next(evt));
    return subject;
  }

  async unsubscribeFromOrder(orderId: string): Promise<any> {
    if (!this.eventHub) {
      await this.connectToEventHub();
      return this.eventHub.send('UnsubscribeFromOrderEvents', orderId);
    } else {
      return this.eventHub.send('UnsubscribeFromOrderEvents', orderId);
    }
  }

  connectToEventHub(): Promise<any> {
    this.eventHub = new signalr.HubConnectionBuilder()
      .withUrl(`${this.baseUrl}/hubs/events`)
      .build();
    return this.eventHub
      .start()
      .then(() => {
        console.log('Connection started');
      })
      .catch(err => {
        console.log('Error while starting connection: ' + err);
      });
  }
}
