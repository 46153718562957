<div>
  <h2 i18n>{{pageTitle}}</h2>
  <form class="form-inline mt-2 mb-1 mt-md-0">
    <input name="filterString" [(ngModel)]="model.filterString" (ngModelChange)="filterStringChanged()"
      class="form-control mr-sm-2" type="text" placeholder="Type to filter" aria-label="Filter">
  </form>
  <div class="table-responsive">
    <table class="table wei-table-striped">
      <thead>
        <th scope="col"></th>
        <th scope="col" i18n>CSG No.</th>
        <th scope="col" i18n>Company Name</th>
        <th scope="col" i18n>Date Added</th>
        <th scope="col" i18n>Description</th>
        <th scope="col" i18n>Customer No.</th>
        <th scope="col" i18n>PO</th>
        <th scope="col" i18n>Client Ref. #</th>
        <th scope="col" i18n>Inbound Tracking No.</th>
        <th scope="col" i18n>Qty</th>
        <th scope="col" i18n>Status</th>
      </thead>
      <tbody>
        <tr *ngFor="let item of model.csgRequests" [ngClass]="{ 'exception-row': hasIssues(item) }">
          <td><a class="btn btn-sm btn-primary" [routerLink]="['/csgrequests', item.id]">View</a></td>
          <td>{{ item.csgNumber }}</td>
          <td>{{ item.customerName }}</td>
          <td>{{ longDateWithSeconds(item.createDate, true) }}</td>
          <td>{{ item.customerNotes }}</td>
          <td>{{ item.customerId || item.customerNumber }}</td>
          <td>{{ item.poNumber }}</td>
          <td>{{ item.clientReferenceNumber }}</td>
          <td>{{ formatTrackingNumbers(item.shipTrackingNumbers) }}</td>
          <td>{{ item.totalQuantity }}</td>
          <td>{{ item.status }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="loading" class="progress">
    <div i18n class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100"
      aria-valuemin="0" aria-valuemax="100" style="width: 100%">Loading</div>
  </div>
  <div *ngIf="noMore" class="alert alert-warning" i18n>
    There are no more requests
  </div>
  <div *ngIf="!loading && !noMore" class="mt-2 mb-2">
    <button i18n (click)="loadMore()" type="button" class="btn btn-primary btn-sm">Load More Requests</button>
  </div>
</div>