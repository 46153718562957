import { Component, OnInit } from '@angular/core';
import { SalesOrderDetailItem, EventLogDetailItem, SalesOrderUpdate } from 'src/app/models';
import { ActivatedRoute, Router } from '@angular/router';
import { SalesOrderService } from './../../../services/sales-order.service';
import * as _ from 'lodash';
import { NotificationService } from 'src/app/services/notification.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-sales-order-change',
  templateUrl: './sales-order-change.component.html',
  styleUrls: ['./sales-order-change.component.scss', './../../../app.component.scss']
})
export class SalesOrderChangeComponent implements OnInit {

  model: SalesOrderDetailItem;
  event: EventLogDetailItem;
  selectedShippingAgent: string = '';
  selectedShippingCode: string = '';
  shippingCodeOptions: Array<string>;
  shippingAgentDescription: string;
  updateModel: SalesOrderUpdate = new SalesOrderUpdate(); 
  loading: boolean = true;

  updateSent: boolean;
  shippingAgents = {
    'DELIVERY': ['10'],
    'EMAIL': ['EMAIL'],
    'FEDEX': ['F1', 'F2', 'F8', 'F9', 'FG'],
    'PICK UP': ['11'],
    'UPS': ['01', '02', '03', '04', '07']
  };

  shippingAgentDescriptions = {
    'EMAIL': 'Email',
    'F1': 'FedEx NDA',
    'F2': 'FedEx 2nd Day',
    'F8': 'FedEx 8AM',
    'F9': 'Fedex Saturday Delivery',
    'FG': 'FedEx Ground',
    '11': 'Local Pickup',
    '01': 'UPS GROUND',
    '02': 'UPS 2ND DAY',
    '03': 'UPS NDA',
    '04': 'UPS Early 8AM',
    '07': 'UPS Sat Delivery',
    '10': 'Local Delivery'
  };
  sortedLines: Array<any>;

  constructor(private route: ActivatedRoute,
               private salesOrderService: SalesOrderService,
               private authService: AuthService,
               private notificationService: NotificationService,
               private router: Router) { }

  ngOnInit() {
    const { orderId } = this.route.snapshot.params;
    this.getOrderModel(orderId);
    this.loading = false
    this.updateSent = false;
  }

  getOrderModel(orderId: string) {
    this.salesOrderService.getSalesOrder(orderId).subscribe(detail => {
      this.model = detail;
      // this.viewingLine = _.orderBy(detail.lines, 'lineNumber')[0];
      this.model.isTracking = false;
      this.model.reTracked = false;
      this.selectedShippingAgent = this.model.shippingAgentCode;
      this.setShippingCodes()
      this.shippingAgentDescription = this.getAgentDescription(this.model.shippingAgentServiceCode)
      this.selectedShippingCode = this.model.shippingAgentServiceCode;
    });
  }

  shippingAgentNames() {
    return Object.keys(this.shippingAgents);
  }

  setShippingCodes() {
    this.shippingCodeOptions = this.shippingAgents[this.selectedShippingAgent];
    this.selectedShippingCode = '';
  }

  getShippingAgentDescription(selectedShippingCode: string) {
    this.shippingAgentDescription = this.shippingAgentDescriptions[selectedShippingCode];
    //return this.shippingAgentDescriptions[selectedShippingCode];
  }

  getAgentDescription(selectedShippingCode: string): string {
    return this.shippingAgentDescriptions[selectedShippingCode];
  }
  canEditSalesOrder() {
    return this.authService.canEditSalesOrder();
  }

  UpdateOrder() {
    if (this.model.address == '' || this.model.address == null ) {
      alert('Ship-to-Address must have a value.');
      return;
    }

    if (this.model.city == '' || this.model.city == null ) {
      alert('Ship-to-City must have a value.');
      return;
    }

    if (this.model.state == '' || this.model.state == null ) {
      alert('Ship-to-State must have a value.');
      return;
    }

    if (this.model.postalCode == '' || this.model.postalCode == null ) {
      alert('Ship-to-ZIP Code must have a value.');
      return;
    }

    if (this.selectedShippingAgent == '' || this.selectedShippingAgent == null) {
      alert('Shipping Agent must be selected.')
      return;
    }

    if (this.selectedShippingCode === '' || this.selectedShippingCode == null ) {
      alert('Shipping Service Code must be selected.');
      return;
    }
    
    this.updateModel.OrderId = this.model.id;
    this.updateModel.ShipToAddress1 = this.model.address;
    this.updateModel.ShipToAddress2 = this.model.address2;
    this.updateModel.ShipToCity = this.model.city;
    this.updateModel.ShipToState = this.model.state;
    this.updateModel.ShipToZip = this.model.postalCode;
    this.updateModel.ShippingAgent = this.selectedShippingAgent;
    this.updateModel.ShippingAgentServiceCode = this.selectedShippingCode;
    this.updateModel.ShippingAgentDescription = this.shippingAgentDescriptions[this.selectedShippingCode];
    this.salesOrderService.sendUpdatePayload(this.updateModel).subscribe(response => 
      {
        if (response.status != "OK") {
          this.notificationService.showPopup(response.message, 'danger', 'Error Submitting Changes');
        }
        else 
        {
          this.notificationService.showPopup('Submitted Order Updates', 'success', 'Success');
        }
      }, err => {
        this.notificationService.showPopup(err.message, 'danger', 'Error');
      });

      // this.router.navigateByUrl('/orders/' + this.model.id); 
  }
}