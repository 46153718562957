<div *ngIf="user">
  <div class="row">
    <div class="col-sm">
      <div class="card" style="max-width: 30rem;">
    <div class="card-body">
      <h5 class="card-title">{{ user.userName }}</h5>
      <div class="row">
        <div class="col-sm-4 data-label">
          Email
        </div>
        <div class="col-auto">
          {{ user.email }}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 data-label">
          Roles
        </div>
        <div class="col-auto">
          <ul class="list-group">
            <li class="list-group-item" *ngFor="let role of user.roles">
              {{ role }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>