import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ReleasedCsgRequestItemModel } from 'src/app/models';
import { CsgRequestsService } from 'src/app/services/csg-requests.service';
import { SalesOrderService } from 'src/app/services/sales-order.service';

@Component({
  selector: 'app-csg-label',
  templateUrl: './csg-label.component.html',
  styleUrls: ['./csg-label.component.scss']
})
export class CsgLabelComponent implements OnInit {
  csgRequestId: string;
  selectedDisplayModel: {
    customerName: string;
    salesOrders: string;
    totalReceived: number;
    boxes: number;
    receivedDate: string;
    dueDate: string;
  };

  displayModel: {
    customerName: string;
    salesOrders: string;
    totalReceived: number;
    boxes: number;
    receivedDate: string;
    dueDate: string;
  }[] = [];
  salesOrders: any;
  loading: boolean;
  unexpected: boolean;
  barcodeImage: Blob;
  barcodeImageUrl: SafeUrl;

  constructor(public csgService: CsgRequestsService,
    private salesOrderService: SalesOrderService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer) { }

  async ngOnInit(): Promise<void> {
    const { csgRequestId } = this.route.snapshot.params;
    this.csgRequestId = csgRequestId;
    this.loading = true;
    if (!this.csgService.lastModel || this.csgService.lastModel.id !== this.csgRequestId) {
      this.csgService.lastModel = await this.csgService.getCsgRequest(this.csgRequestId, true).toPromise();
    }
    if (!this.csgService.lastUnexpectedModel || this.csgService.lastUnexpectedModel.id !== this.csgRequestId) {
      this.csgService.lastUnexpectedModel = await this.csgService.getUnexpectedShipmentById(this.csgRequestId).toPromise();
    }
    if (this.route.snapshot.queryParams.unexpected) {
      this.unexpected = true;
      await this.loadModelFromUnexpectedShipment();
    } else {
      this.unexpected = false;
      await this.loadModelFromCsgRequest();
    }
    this.loading = false;
    if (this.salesOrders && this.salesOrders.length === 1) {
      setTimeout(() => window.print(), 1000);
    }
  }

  async loadModelFromUnexpectedShipment(): Promise<void> {
    const salesOrderNum = this.csgService.lastUnexpectedModel.returnOrderId || this.csgService.lastUnexpectedModel.unexpectedShipmentFeeOrderId;
    const orderNum = salesOrderNum.replace('SO', '');
    const receivingId = this.csgService.lastUnexpectedModel.receivingId; // needs a unique int for packing slip for eshipglobal

    // this.barcodeImage = await this.salesOrderService.getBarcodeForOrderId(`${receivingId}${orderNum}`).toPromise();
    // const unsafeImg = URL.createObjectURL(this.barcodeImage);
    // this.barcodeImageUrl = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
    this.barcodeImageUrl = this.salesOrderService.getBarcodeForOrderIdString(`${receivingId}${orderNum}`);
    const totalGarments = this.csgService.lastUnexpectedModel.unexpectedCsgShipmentItems.map(i => i.quantity).reduce((a, b) => a += b);

    this.selectedDisplayModel = {
      customerName: this.csgService.lastUnexpectedModel.customer?.companyName,
      salesOrders: this.csgService.lastUnexpectedModel.receivingId,
      totalReceived: totalGarments,
      boxes: this.csgService.lastUnexpectedModel.numberOfBoxes,
      receivedDate: moment(this.csgService.lastUnexpectedModel.createDate).format('DD-MMMM') + ', unexpected',
      dueDate: ''
    };
    this.printThisLabel(this.selectedDisplayModel);
  }

  printThisLabel(model) {
    if (model) {
      this.selectedDisplayModel = model;
      setTimeout(() => window.print(), 1000);
    }
  }

  deselectLabel(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.selectedDisplayModel = null;
  }

  async loadModelFromCsgRequest(): Promise<void> {
    this.salesOrders = await this.salesOrderService.getSalesOrdersFromCsgId(this.csgRequestId).toPromise();
    this.salesOrders.forEach((order) => {
      const relatedCsgRows = this.csgService.lastModel.releasedItems.filter(c => c.releaseSequence === order.csgReleaseSequence);
      const gatheredCsgRows = new Array<ReleasedCsgRequestItemModel>();
      relatedCsgRows.forEach((row) => {
        if (gatheredCsgRows.findIndex(c => c.groupingId === row.groupingId) === -1) {
          gatheredCsgRows.push(row);
        }
      });

      if (order.isSplitOrder) {
        const addresses = this.csgService.lastModel.rawOrderModel ? this.csgService.lastModel.rawOrderModel.shipToAddresses : null;
        const addressIndex = addresses ?
          addresses.findIndex(c => c.address.contactName === order.shipToName && c.address.line1 === order.address)
          : -1;

        if (addressIndex > -1) {
          const address = addresses[addressIndex];
          const applicableCsgRows = gatheredCsgRows
            .filter(c => address.groupingIds.findIndex(d => d.toString() === c.groupingId.toString()) > -1);

          if (applicableCsgRows.length > 0) {
            this.displayModel.push({
              customerName: this.csgService.lastModel.customerName,
              salesOrders: order.id,
              totalReceived: applicableCsgRows ? applicableCsgRows.map(i => i.quantityReceived).reduce((a, b) => a += b) : 0,
              boxes: this.csgService.lastModel.numberOfBoxes,
              receivedDate: moment(order.addDate).format('DD-MMM'),
              dueDate: moment(order.dueDate).format('DD-MMM')
            });
            return;
          }
        }
        this.displayModel.push({
          customerName: this.csgService.lastModel.customerName,
          salesOrders: order.id,
          totalReceived: gatheredCsgRows ? gatheredCsgRows.map(i => i.quantityReceived).reduce((a, b) => a += b) : 0,
          boxes: this.csgService.lastModel.numberOfBoxes,
          receivedDate: moment(order.addDate).format('DD-MMM'),
          dueDate: moment(order.dueDate).format('DD-MMM')
        });
      } else {
        this.displayModel.push({
          customerName: this.csgService.lastModel.customerName,
          salesOrders: order.id,
          totalReceived: gatheredCsgRows ? gatheredCsgRows.map(i => i.quantityReceived).reduce((a, b) => a += b) : 0,
          boxes: this.csgService.lastModel.numberOfBoxes,
          receivedDate: moment(order.addDate).format('DD-MMM'),
          dueDate: moment(order.dueDate).format('DD-MMM')
        });
      }
    });
  }

  print(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    window.print();
  }
}
