import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ImportMonitorListItem } from 'src/app/models';
import { ImportMonitorDetailItem } from 'src/app/models/index';
import { ImportMonitorService } from 'src/app/services/import_monitor.service';

import * as displayHelpers from '../../displayHelpers';

@Component({
  selector: 'app-order-monitor',
  templateUrl: './order-monitor.component.html',
  styleUrls: ['./order-monitor.component.scss', './../../app.component.scss']
})
export class ImportMonitorComponent implements OnInit {
  eventItems: ImportMonitorListItem[] = [];
  model = {
    orderId: ''
  };
  claimedItemCount = 0;
  loading = false;
  noMore = false;
  calendarDate = displayHelpers.calendarDate;
  timeout: number;
  tableColumns:  string[] =
    ['claimedBy', 'content', 'eventDate', 'eventModel', 'eventName', 'id', 'message',
      'orderId', 'orderSource', 'source', 'status', 'version'];

  constructor(private importMonitorService: ImportMonitorService,
    private authService: AuthService) { }

  ngOnInit() {
    this.getErrorEventItems();
  }

  getErrorEventItems() {
    this.loading = true;
    this.noMore = false;
    this.importMonitorService.getErrorImportMonitorItems().subscribe(items => {
      this.eventItems = items.filter(i => this.model.orderId === '' || i.orderId === this.model.orderId);
      this.loading = false;
      this.claimedItemCount = this.eventItems.filter(i => i.claimedBy && i.claimedBy !== '').length;
    });
  }

  orderIdChanged() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
    this.timeout = window.setTimeout(t =>  {
      this.getErrorEventItems();
    }, 2000);
  }

  clearItem(event: ImportMonitorDetailItem) {
    if(event.claimedBy!=null){
    this.importMonitorService.clearImportMonitorItem(event).subscribe(item => {
      this.eventItems.splice(this.eventItems.findIndex(e => e.id === event.id), 1);
    });
  }
  else{
    alert('Please add comment in claimed by, before clearing the event.');
    return;
  }
  }

  getItemReferenceString(item: ImportMonitorListItem) {
    if (!item.eventModel) {
      return '';
    }
    return item.eventModel.wspOrderNo || item.eventModel.designId || '';
  }

  eventMessage(item: ImportMonitorListItem) {
    return item.message || 'Click to view message';
  }

  async claimItem(item: ImportMonitorListItem) {
    item.claimedBy = item.claimedByEntry || (await this.authService.getUser().toPromise()).username;
    this.importMonitorService.claimImportMonitorItem(item).subscribe(it => {
      item.isClaiming = false;
    });
  }

  startClaim(item: ImportMonitorListItem) {
    if (item.claimedBy && item.claimedBy !== '') {
      // unclaim
      item.claimedBy = '';
      this.importMonitorService.claimImportMonitorItem(item).subscribe(it => {
        item.isClaiming = false;
        item.claimedBy = '';
      });
      return;
    }
    item.isClaiming = true;
  }
}
