import { DesignAttribute } from './../../../models/index';
import { Observable, Subject } from 'rxjs';
import { AuthService } from './../../../services/auth.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DesignViewModel, DesignAttributeModel } from 'src/app/models';
import * as moment from 'moment';
import { longDateWithSeconds } from 'src/app/displayHelpers';

@Component({
  selector: 'app-design-view',
  templateUrl: './design-view.component.html',
  styleUrls: ['./design-view.component.scss', './../../../app.component.scss']
})
export class DesignViewComponent implements OnInit {
  @Input()
  model: DesignViewModel;
  @Output()
  generate =  new EventEmitter<(sku: string) => void>();
  @Output()
  onsave =  new EventEmitter<DesignAttribute>();
  moment = moment;
  origModel: DesignViewModel;
  constructor(public authService: AuthService) { }

  ngOnInit() {
  }

  edit() {
    this.origModel = { ...this.model };
    this.model.isEditing = !this.model.isEditing;
  }

  cancel() {
    this.model = this.origModel;
    this.model.isEditing = false;
  }

  save(attr: DesignAttribute) {
    this.model.regenerating = true;
    this.onsave?.emit(attr);
    this.model.regenerating = false;
  }

  regenerateNavSku() {
    this.model.regenerating = true;
    this.generate?.emit((s: string) => {
      this.model.regenerating = false;
      this.model.navSku = s;
    });
  }

  getLastUpdatedString(item: DesignAttributeModel) {
    if (!item || !item.rawAttr.lastUpdated || !item.rawAttr.dataSource) {
      return '';
    }
    return `${moment(longDateWithSeconds(item.rawAttr.lastUpdated, true)).calendar()} from ${item.rawAttr.dataSource}`;
  }

}
