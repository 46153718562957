import { ActivatedRoute } from '@angular/router';
import { CustomerService } from './../../../services/customer.service';
import { Component, OnInit } from '@angular/core';
import { CustomerModel, CustomerNotificationLog } from 'src/app/models';
import * as moment from 'moment';
import { calendarDate, longDateWithSeconds, shortDate } from 'src/app/displayHelpers';
import { NotificationService } from 'src/app/services/notification.service';
import { ModalService } from 'src/app/services/modal-service';

@Component({
    selector: 'app-customer-notifications',
    templateUrl: './customer-notifications.component.html',
    styleUrls: []
})
export class CustomerNotificationsComponent implements OnInit {
    notifications: CustomerNotificationLog[];
    loadingCustomer: boolean;
    loadingNotifications: boolean;
    customer: CustomerModel;
    noMore = false;
    loadingMore = false;
    isOrderNotifications = false;

    constructor(private customerService: CustomerService,
        private route: ActivatedRoute,
        private notificationService: NotificationService) { }

    ngOnInit() {
        const { customerId, orderId } = this.route.snapshot.params;
        this.loadingCustomer = true;
        this.loadingNotifications = true;
        this.customerService.getCustomer(customerId).subscribe(c => {
            this.customer = c;
            this.loadingCustomer = false;
        });

        if (!orderId) {
            this.customerService.getCustomerNotifications(customerId, 0, 20).subscribe(c => {
                this.setNotificationsModel(c);
                this.loadingNotifications = false;
            });
        } else {
            this.customerService.getCustomerNotificationsForOrder(customerId, orderId).subscribe(c => {
                this.setNotificationsModel(c);
                this.loadingNotifications = false;
                this.isOrderNotifications = true;
            });
        }
    }

    retryNotification(log: CustomerNotificationLog) {
        log.loadingResend = true;
        this.customerService.retryNotification(log).subscribe(c => {
            if (c.success) {
                this.notificationService.showPopup('Notification Sucessfully Resent', 'success', 'Notification Sent');
            } else {
                this.notificationService.showPopup('Notification Error', 'danger', 'Error');
            }
            log.loadingResend = false;
        });
    }

    loadOlder() {
        this.loadingMore = true;
        this.noMore = false;

        this.customerService.getCustomerNotifications(this.customer.id, this.notifications.length, 20).subscribe(
            items => {
                if (!items || items.length === 0) {
                    this.noMore = true;
                }

                items.forEach(i => {
                    this.notifications.push(i);
                });
                this.loadingMore = false;
            });
    }

    formatDate(date: Date) {
        return longDateWithSeconds(date.toString());
    }

    getJson(notification: CustomerNotificationLog) {
        return JSON.parse(notification.bodyJson);
    }

    showPayload(log: CustomerNotificationLog) {
        log.showBodyJson = true;
    }

    hidePayload(log: CustomerNotificationLog) {
        log.showBodyJson = false;
    }

    setNotificationsModel(notifications: CustomerNotificationLog[]) {
        this.notifications = notifications.map(c => {
            return {
                orderId: c.orderId,
                url: c.url,
                sent: c.sent,
                response: c.response,
                success: c.success,
                bodyJson: JSON.parse(c.bodyJson),
                customerId: c.customerId,
                showBodyJson: c.showBodyJson,
                loadingResend: c.loadingResend,
                id: c.id
            } as CustomerNotificationLog;
        });
    }

    isLoading() {
        return this.loadingCustomer || this.loadingNotifications;
    }

    formatResponse(response: string): string {
        if (!response) {
            return null;
        }

        if (response && response.length > 250) {
            return response.substring(0, 250) + ' . . . ';
        }

        return response;
    }
}
