<div>
  <a [routerLink]="['..']">Back to list</a>
  <h1 i18n>Dead Letter Log Entry</h1>
  <div class="m-2" *ngIf="model && showAdminEvent()">
    <a class="btn btn-warning" [routerLink]="['/admin/deadletter', model.id]">Emit new event based on this event</a>
  </div>
  <div *ngIf="model">
    
    <div class="row">
      <div class="col-sm-2 event-detail-label">
        Event ID
      </div>
      <div class="col">
        {{ model.id }}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-2 event-detail-label">
        Order ID
      </div>
      <div class="col">
        {{ model.orderId }}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-2 event-detail-label">
        Event Date
      </div>
      <div class="col">
        {{ longDateWithSeconds(model.eventDate, true) }}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-2 event-detail-label">
        Received
      </div>
      <div class="col">
        {{ longDateWithSeconds(model.whenReceived, true) }}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-2 event-detail-label">
        Name
      </div>
      <div class="col">
        {{ model.eventName }}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-2 event-detail-label">
        Source
      </div>
      <div class="col">
        {{ model.source }}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-2 event-detail-label">
        Version
      </div>
      <div class="col">
        {{ model.version }}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-2 event-detail-label">
        Content
      </div>
      <div class="col">
        <code><pre>{{ getEventContent() }}</pre></code>
      </div>
    </div>
  </div>
</div>
