import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {Countries} from './../models';

@Injectable({
    providedIn: 'root'
  })
  export class UtilitiesService {

    baseUrl = environment.baseUrl;

    constructor(private httpClient: HttpClient) { }

    getCountriesCatalog(): Observable<any> {
      return this.httpClient.get<any>(`${this.baseUrl}/api/utilities/countries`).pipe(
        map(data => {
          //console.log(data);
          return data;
        })
      );
    }

  }