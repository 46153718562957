import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { calendarDate, longDateWithSeconds, monthDayDate } from 'src/app/displayHelpers';
import { EventLogDetailItem, InvoiceItem, LocationModel, OrderFile, SalesOrderDetailItem, SalesOrderLineItem, ShipmentItem, NavSalesOrderStatusModel, LineStatus } from 'src/app/models';
import { AuthService } from 'src/app/services/auth.service';
import { CsgRequestsService } from 'src/app/services/csg-requests.service';
import { EventService } from 'src/app/services/event.service';
import { LocationLookupService } from 'src/app/services/location-lookup.service';
import { ModalService } from 'src/app/services/modal-service';
import { NotificationService } from 'src/app/services/notification.service';

import { environment } from 'src/environments/environment';
import { SalesOrderService } from './../../../services/sales-order.service';


@Component({
  selector: 'app-sales-order-detail',
  templateUrl: './sales-order-detail.component.html',
  styleUrls: ['./sales-order-detail.component.scss', './../../../app.component.scss']
})

export class SalesOrderDetailComponent implements OnInit, OnDestroy {
  specialInstrColor: string[];
  specInstr3D: string[];
  specInstrColor: string[];
  specInstrPrintStitch: string[];
  specInstrLeather: string[];
  model: SalesOrderDetailItem;
  viewingLine: SalesOrderLineItem;
  calendarDate = calendarDate;
  longDateWithSeconds = longDateWithSeconds;
  monthDayDate = monthDayDate;
  isPickingRedirectLocation: boolean;
  confirmDeleteOrderLines: boolean;
  selectedRedirectorLocation: string;
  redirectInProgress: boolean;
  hasImage = true;
  ediFlagButtonText = 'Update EDI Flag';
  deleting: boolean;
  deleteReason = '';
  noShipments: boolean;
  eventSubscription: Subject<EventLogDetailItem>;
  designEventSubscription: Subject<EventLogDetailItem>;
  justRedirected: boolean;
  allGarmentImages: OrderFile[];
  currentGarmentImages: OrderFile[];
  currentGarmentIndex = 0;
  overwriteCdr = false;
  locationModel = new Array<LocationModel>();
  storageLocation = environment.storageLocation;
  username: string;
  viewingLineDesignFileErrors: string[];
  sendToWps = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private salesOrderService: SalesOrderService,
    private notificationService: NotificationService,
    public authService: AuthService,
    private modalService: ModalService,
    private eventService: EventService,
    private csgService: CsgRequestsService,
    private locationService: LocationLookupService) { }

  async ngOnDestroy() {
    await this.eventService.unsubscribeFromOrder(this.model.id);
    await this.eventService.unsubscribeFromOrder(this.model.lines[0].designId);
  }

  ngOnInit() {
    const { orderId } = this.route.snapshot.params;

    this.getOrderModel(orderId);
    this.locationService.getLocations().subscribe(results => {
      this.locationModel = results;
    });

    this.authService.getUser().subscribe(c => {
      this.username = c.username.replace('WEI\\', '');
    });

    this.eventSubscription = this.eventService.subscribeToOrder(orderId);
    this.eventSubscription.subscribe(this.onOrderEvent.bind(this));
  }

  onDesignCreatedEvent(evt: EventLogDetailItem) {
    if (evt.eventName === 'create-design-file-result') {
      if ((this.model.lines.findIndex(c => c.designId === evt.orderId) >= 0)) {
        if (evt.content) {
          const parsedEvent = JSON.parse(evt.content);
          console.log(parsedEvent);
          console.log(parsedEvent['Payload']);
          console.log(parsedEvent['Payload']['Data']);
          if (parsedEvent && parsedEvent['Payload'] && parsedEvent['Payload']['Data'] && parsedEvent['Payload']['Data']['IsResult']) {
            this.getOrderModel(this.model.id); // refresh the data now that the processing has been completed
          }
        }
        this.modalService.popout(`New ${evt.eventName} event for order ${this.model.id}.`, 'info');
      }
      return;
    }
  }

  onOrderEvent(evt: EventLogDetailItem) {
    if (evt.eventName === 'create-design-file') {
      if (evt.orderId === this.model.id || (this.model.lines.findIndex(c => c.designId === evt.orderId) < 0)) {
        if (evt.content && evt.content['payload'] && evt.content['payload']['data'] && evt.content['payload']['data']['isResult']) {
          this.getOrderModel(this.model.id); // refresh the data now that the processing has been completed
        }
        this.modalService.popout(`New ${evt.eventName} event for order ${this.model.id}.`, 'info');
      }
      return;
    }

    if (evt.orderId !== this.model.id) {
      return;
    }

    if (this.justRedirected) {
      if (evt.eventName === 'order-tracked') {
        this.justRedirected = false;
        this.modalService.popout(`Redirect successful!`, 'success');
        setTimeout(() => this.getOrderModel(this.model.id), 1000);
        return;
      }
      if (evt.eventName === 'new-sales-order-exception') {
        this.justRedirected = false;
        const errorItem = JSON.parse(evt.content).Exceptions[0];
        this.modalService.popout(`Error redirecting order: ${errorItem.Message}`, 'danger');
        return;
      }
      // don't notify for the other events in this case, just provide the clear message for the redirect
      return;
    }
    this.modalService.popout(`New ${evt.eventName} event for order ${this.model.id}.`,
      evt.eventName === 'new-sales-order-exception' ? 'danger' : 'info');
  }

  getOrderModel(orderId: string) {
    this.salesOrderService.getSalesOrder(orderId, true).subscribe(detail => {
      this.model = detail;
      if (this.model != null) {
        if (this.model.specInstr3D != null && this.model.specInstr3D != undefined)
          this.specInstr3D = this.model.specInstr3D.split("~");

        if (this.model.specInstrColor != null && this.model.specInstrColor != undefined)
          this.specialInstrColor = this.model.specInstrColor.split("~");

        if (this.model.specInstrPrintStitch != null && this.model.specInstrPrintStitch != undefined)
          this.specInstrPrintStitch = this.model.specInstrPrintStitch.split("~");

        if (this.model.specInstrLeather != null && this.model.specInstrLeather != undefined)
          this.specInstrLeather = this.model.specInstrLeather.split("~");
      }
     
      if (this.model.orderSource === 'episerver-design-request') {
        console.log('listening for design created');
        this.listenForDesignCreatedUpdates();
      }

      this.viewingLine = _.orderBy(detail.lines, 'lineNumber')[0];

      if (this.viewingLine.designFileInitializationErrors) {
        this.viewingLineDesignFileErrors = JSON.parse(this.viewingLine.designFileInitializationErrors);
      }
      this.model.isTracking = false;
      this.model.reTracked = false;
      if (this.model.ediFlag) {
        this.ediFlagButtonText = 'Unset EDI Flag';
      } else {
        this.ediFlagButtonText = 'Set EDI Flag';
      }
      this.salesOrderService.getOrderFiles(orderId).subscribe(files => {
        this.model.files = files;
      });
      if (!!this.model.csgRequestId) {
        this.csgService.getCsgRequestFiles(this.model.csgRequestId).subscribe(f => {
          this.allGarmentImages = f.filter(c => c.fileType.includes('FinishedGarmentImage'));
          this.setViewingLine(this.viewingLine);
        });
      }
    });
  }

  listenForDesignCreatedUpdates() {
    console.log('attempting to listen to events for ' + this.model.lines[0].designId);
    this.designEventSubscription = this.eventService.subscribeToOrder(this.model.lines[0].designId);
    this.designEventSubscription.subscribe(this.onDesignCreatedEvent.bind(this));
  }

  getAssemblyNumbers() {
    return this.model.lines.map(l => l.assemblyNumber).join(', ');
  }

  getDueDates() {
    const salesOrderLinesDueDates = this.model.lines.map(l => monthDayDate(l.dueDate, true));

    // Get distinct due dates.
    const dueDates: string[] = [];
    for (const dueDate of salesOrderLinesDueDates) {
      if (!dueDate || dueDate === '' || dueDates.includes(dueDate)) {
        continue;
      }

      dueDates.push(dueDate);
    }

    if (dueDates.length === 0) {
      return longDateWithSeconds(this.model.dueDate, false);
    }

    return dueDates.sort().join(', ');
  }

  digitalPassportUrl(line: SalesOrderLineItem) {
    if (line.catalogSku === '' || line.catalogSku.includes('-')) {
      return `${environment.digitalPassportUrl}d${line.designId}`;
    } else {
      return `${environment.digitalPassportUrl}d${line.catalogSku}`;
    }
  }

  getProductAttribute(line: SalesOrderLineItem) {
    if (!line.designAttributes) {
      return { code: '', description: '' };
    }
    const prodAttr = line.designAttributes.attributeList.find(a => a.attributeType === 'product');
    if (!prodAttr) {
      return { code: '', description: '' };
    }
    return prodAttr;
  }

  getCustomerGlNum(lines) {
    if (lines.length > 0) {
      return lines[0].customerGLNumber || '';
    }
    return '';
  }

  ponFromSON(son: String, lineNum: String) {
    let output = son.replace('S', '');
    if (this.model.lines.length > 1) { output = output + '-' + lineNum; }
    return output;
  }

  isUrl(artwork: string) {

    return artwork && artwork.startsWith('http');
  }

  HasFinishedGarmentImage(): boolean {
    return (!!this.currentGarmentImages && this.currentGarmentImages.length > 0);
  }

  setGarmentImageIndex(indexInterval: number): void {
    if (indexInterval > 0) {
      if (this.currentGarmentIndex + 1 >= (this.currentGarmentImages.length)) {
        this.currentGarmentIndex = 0;
      } else {
        this.currentGarmentIndex++;
      }
    } else {
      if (this.currentGarmentIndex - 1 < 0) {
        this.currentGarmentIndex = this.currentGarmentImages.length - 1;
      } else {
        this.currentGarmentIndex--;
      }
    }
  }

  trackedEvents(events) {
    return events.filter(function (e) { return e.eventName === 'order-tracked'; });
  }

  trackingNumber() {
    if (this.model.trackingNumber && this.model.trackingNumber !== '') {
      return this.model.trackingNumber;
    }
    return '';
  }

  trackOrder() {
    this.model.isTracking = true;
    this.salesOrderService.trackOrder(this.model.id).subscribe(result => {
      this.model.isTracking = false;
      this.model.reTracked = true;
    });
  }

  trackingCheckpoint() {
    return this.model.checkPoint;
  }

  printCheckpoint(events) {
    // return JSON.parse(this.trackedEvents(events)[0].content).Payload.Data.CheckPointDescription;
    return '';
  }

  checkpointNames(events) {
    return JSON.parse(this.trackedEvents(events)[0].content).Payload.Data.Checkpoints;
  }

  setViewingLine(line: any) {
    this.currentGarmentImages = [];

    this.currentGarmentIndex = 0;
    if (line && !!line.finishedGarmentImages) {
      line.finishedGarmentImages.forEach(image => {
        this.currentGarmentImages.push(...this.allGarmentImages.filter(agi => agi.uploadedUrl.includes(image)));
      });
    }

    if (line.designFileInitializationErrors) {
      this.viewingLineDesignFileErrors = JSON.parse(line.designFileInitializationErrors);
    }

    this.viewingLine = line;
    this.hasImage = true;
  }


  setDefaultImage(event: Event) {
    this.hasImage = false;
  }

  sortLineByLineNumber(lines: SalesOrderLineItem[]) {
    return _.orderBy(lines, l => l.lineNumber);
  }

  discountTotal(line: SalesOrderLineItem) {
    const unitPrice = line.unitPrice;
    const quantity = line.quantity;
    const subtotal = unitPrice * Number(quantity);
    const discountPercent = line.discountPercent || 0;
    const discountRate = discountPercent / 100;
    const discountTotal = subtotal * discountRate;
    return discountTotal;
  }

  orderTotal(line: SalesOrderLineItem) {
    const unitPrice = line.unitPrice;
    const quantity = line.quantity;
    // tslint:disable-next-line:radix
    const subtotal = unitPrice * parseInt(quantity);
    return subtotal - this.discountTotal(line);
  }

  getPriority() {
    if (this.model.priority === 'RUSH') { return 'RUSH'; }
    if (this.model.priority === 'EXPEDITED') { return 'EXPEDITED'; }
    if (this.model.isRemake) { return 'REMAKE'; }
    if (this.model.orderSource === 'wsp-sample-convert') { return 'CONVERT'; }
    if (this.model.orderSource === 'episerver-sample-convert') { return 'CONVERT'; }
    switch (this.model.orderType) {
      case 'WSP - Sample Edit':
        return 'EDIT';
      case 'Intranet NAV Order':
        if (this.isRemake()) {
          return 'REMAKE';
        } else {
          return 'STANDARD';
        }
      case 'WSP - Sample Convert':
      case 'episerver-sample-convert':
        return 'CONVERT';
      default:
        return 'STANDARD';
    }
  }

  getRemakeComments() {
    if (this.model.isRemake) {
      return this.model.comments;
    }
  }

  updateEdiFlagForSalesOrder() {
    this.modalService.open('update-edi-flag-modal');
  }

  selectAll() {
    this.model.lines.filter(l => this.canSelectLine(l)).forEach(l => l.isSelected = true);
  }

  selectNone() {
    this.model.lines.forEach(l => l.isSelected = false);
  }

  anySelected() {
    return this.model.lines.some(l => l.isSelected);
  }

  pickRedirectLocation() {
    this.isPickingRedirectLocation = true;
    //this.selectedRedirectorLocation = this.model.locationCode;
  }

  toggleDeleteOrderLines() {
    this.confirmDeleteOrderLines = true;
  }

  redirect() {
    this.redirectInProgress = true;
    this.salesOrderService.getStatusFromNAV(this.model.id).subscribe(result => {
      
      //validating a location is or not selected
      if(this.selectedRedirectorLocation === undefined || this.selectedRedirectorLocation === ""){
        this.notificationService.showPopup('Please choose a Location', 'danger', 'Warning');
        this.redirectInProgress = false;
      }
      else
      {

        if (result.orderStatus === 'Closed') {
          const cannotModifyOrderMessage = 'This order cannot be modified due to its status in Business Central.';
          this.notificationService.showPopup(cannotModifyOrderMessage, 'danger', 'Error Redirecting');
          this.redirectInProgress = false;
          return;
        }
        const cantRedirectLines = this.model.lines.filter(c => c.isSelected &&
          result.lines.some(d => d.lineNumber === c.lineNumber && d.canBeModified === false));
  
        if (cantRedirectLines !== null && cantRedirectLines.length > 0) {
          const lines = cantRedirectLines.map(m => m.lineNumber);
          const unableToModifyMessage = `Line(s) ${lines.join(',')} cannot be modified due to its status in Business Central.`;
          this.notificationService.showPopup(unableToModifyMessage, 'danger', 'Error Redirecting');
          this.redirectInProgress = false;
          return;
        }

        this.model.lines.filter(l => l.isSelected).forEach(l => l.locationCode = this.selectedRedirectorLocation);
        const updateModel = { ... this.model } as SalesOrderDetailItem;
        updateModel.lines = updateModel.lines.filter(l => l.isSelected);
        const tagAlong = this.model.lines.filter(l => l.appliesToLineNumber > 0 &&
        updateModel.lines.findIndex(u => u.lineNumber === l.appliesToLineNumber));
        if (tagAlong.length) {
          tagAlong.forEach(l => l.locationCode = this.selectedRedirectorLocation);
          updateModel.lines = updateModel.lines.concat(tagAlong);
        }
        this.salesOrderService.redirectOrder(updateModel, this.sendToWps).subscribe(result => {
          if (result.status !== 'OK') {
            this.notificationService.showPopup(result.message, 'danger', 'Error Redirecting');
          } else {
            this.notificationService.showPopup('Redirect Was Successful', 'success', 'Success!');
          }
          this.isPickingRedirectLocation = false;
          this.redirectInProgress = false;
          this.justRedirected = true;
        });

      }

    },
      err => {
        this.notificationService.showPopup((err && err.message) || err, 'danger', 'Error Redirecting');
        this.redirectInProgress = false;
        return;
      });
  }

  cancelRedirect() {
    // do something
    this.isPickingRedirectLocation = false;
  }

  cancelDelete() {
    this.confirmDeleteOrderLines = false;
    this.deleteReason = '';
    this.selectNone();
  }

  deleteOrderLines() {
    const deleteModel = {
      SalesOrderNumber: this.model.id,
      DeleteFromAllSystems: true,
      PonsToDelete: [],
      DeleteWholeSalesOrder: false,
      DeleteFromSystems: [],
      DeleteReason: this.deleteReason
    };
    const selectedLines = this.model.lines.filter(l => l.isSelected === true);
    if (selectedLines.length === this.model.lines.length) {
      deleteModel.DeleteWholeSalesOrder = true;
    } else {
      deleteModel.PonsToDelete = selectedLines.map(l => l.pon);
    }
    this.deleting = true;
    this.salesOrderService.getStatusFromNAV(this.model.id).subscribe(result => {
      {
        if (result.orderStatus === 'Closed') {
          this.notificationService.showPopup('This order cannot be modified due to its status in Business Central.', 'danger', 'Error Deleting');
          this.deleting = false;
          return;
        }
        const cantDeleteLines = this.model.lines.filter(c => c.isSelected && result.lines.some(d => d.lineNumber === c.lineNumber && d.canBeModified === false));
        if (cantDeleteLines !== null && cantDeleteLines.length > 0) {
          //need to get LineNum to show in popup
          const lines = cantDeleteLines.map(m => m.lineNumber);
          this.notificationService.showPopup(`Line(s) ${lines.join(',')} cannot be modified due to its status in Business Central.`, 'danger', 'Error Deleting');
          this.deleting = false;
          return;
        }
        this.salesOrderService.deleteOrder(deleteModel).subscribe(r => {
          this.cancelDelete();
          this.deleting = false;
          if (deleteModel.DeleteWholeSalesOrder) {
            this.router.navigate(['/orders']);
          } else {
            _.remove(this.model.lines, l => selectedLines.indexOf(l) > -1);
          }
        })
      } err => {
        this.notificationService.showPopup((err && err.message) || err, 'danger', 'Error Deleting');
        this.redirectInProgress = false;
        return;
      }
    });
  }

  selectResourceLines(line) {
    const relatedLines = this.model.lines.filter(c => c.appliesToLineNumber > 0 && (c.appliesToLineNumber * 10000) === line.lineNumber);
    if (relatedLines && relatedLines.length > 0) {
      relatedLines.forEach(c => c.isSelected = line.isSelected);
    }
  }

  canEditSalesOrder() {
    if (this.model.latestStatus === 'Order Tracked' || this.model.latestStatus === 'Order Created') {
      return this.authService.canEditSalesOrder() || this.authService.isAdmin();
    }
    return false;
  }

  isAdmin() {
    return this.authService.isAdmin();
  }

  canDeleteSalesOrder() {
    return this.authService.canEditSalesOrder() || this.authService.isAdmin();
  }

  canSelectLine(line: SalesOrderLineItem) {
    if (this.model.latestStatus === 'Redirected') {
      return false;
    }
    return !line.isComplete &&
      !this.isBeingPicked(line) &&
      !(line.appliesToLineNumber > 0);
  }

  getLineSelectTooltip(line: SalesOrderLineItem) {
    if (this.model.latestStatus === 'Redirected') {
      return 'Redirect in progress.';
    }

    if (this.isBeingPicked(line)) {
      return 'This line is being picked.  To redirect, please delete the pick from NAV.';
    }
    return !this.canSelectLine(line) ? 'This production order is complete so it cannot be redirected or deleted' : 'Select this line';
  }

  isBeingPicked(line: SalesOrderLineItem) {
    return line.pickNumber && line.pickNumber !== '';
  }

  canEditEdiFlag() {
    return this.authService.canToggleEdiFlagOnSalesOrder() || this.authService.isAdmin();
  }

  isRemake() {
    return this.model.lines.some(l => l.remakePon && l.remakePon !== '');
  }

  getComments() {
    if (this.model.nbiComments === null) { return []; }
    return JSON.parse(this.model.nbiComments);
  }

  commentsForLine(lineNo: number) {
    // tslint:disable-next-line:radix
    return this.getComments().filter(comment => (parseInt(comment['LineNum']) * 10000) === lineNo);
  }

  nameDropperNames(line: SalesOrderLineItem) {
    return JSON.parse(line.nameDropperNames);
  }

  getShipments(): Array<ShipmentItem & { pon: string, url: string }> {
    const modelTrackingNumber = this.model.trackingNumber || '';
    const ret: Array<ShipmentItem & { pon: string, url: string }> = [];
    this.model.lines.forEach(l => {
      const shipments = l.shipments || [];
      shipments.forEach(s => ret.push({ ...s, pon: l.pon, url: this.getTrackingUrl(s.trackingNumber, s.carrier) }));
    });
    const shipmentWithModelTracking = ret.find(s => s.trackingNumber === modelTrackingNumber);
    if (modelTrackingNumber !== '' && !shipmentWithModelTracking) {
      // then add the legacy shipment on here
      const carrier = this.getCarrierFromTrackingNumber(modelTrackingNumber);
      ret.push({
        trackingNumber: modelTrackingNumber,
        carrier: carrier,
        pon: 'N/A',
        id: '',
        url: this.getTrackingUrl(modelTrackingNumber, carrier),
        salesOrderLineItemId: '',
        trackingNumberDate: ''
      });
    }
    this.noShipments = ret.length === 0;
    return ret;
  }

  getDisplayDate(dateString: string) {
    if (!dateString || dateString === '') {
      return '';
    }
    return moment(dateString).format('L');
  }

  getCarrierFromTrackingNumber(trackingNumber: string) {
    if (trackingNumber.toLowerCase().startsWith('1z')) {
      return 'UPS';
    }
    if (trackingNumber.toLowerCase().startsWith('ld')) {
      return 'LOCAL';
    }
    return 'FedEx';
  }

  isFedexOrUps() {
    // they only use these 2
    return this.model.trackingNumber && this.model.trackingNumber !== '';
  }

  getTrackingUrl(trackingNumber: string, carrier: string) {
    if (!trackingNumber || trackingNumber === '') {
      return '';
    }
    if (carrier &&
      (carrier.includes('LOCAL') || carrier.includes('DELIVERY') || carrier.includes('EMAIL'))) {
      return '';
    }
    if (trackingNumber.toLowerCase().startsWith('1z')) {
      return `${environment.upsTrackingPrefix}${trackingNumber}`;
    }
    return `${environment.fedexTrackingPrefix}${trackingNumber}`;
  }

  routeToCSGPage() {
    this.router.navigate([`/csgrequests/${this.model.csgRequest.id}`]);
  }

  routeToDesignsPage(line: SalesOrderLineItem) {
    // We're storing the attributes now since they need to be updatable from TS
    this.router.navigate(['/designs/' + line.designId,
    { isEpiserver: false, navSku: line.sku, navCustomer: this.model.navCustomerNumber }]);
  }

  getInvoiceTotal(invoice: InvoiceItem) {
    return (invoice.subtotal || 0) +
      (invoice.tax || 0) +
      (invoice.shippingCharges || 0) +
      (invoice.miscCharges || 0);
  }

  launchCorelClickOnce() {
    console.log('launching corel...');
  }

  retryCDRFileInitialization() {
    this.salesOrderService.requestRetryCDRFileInitialization(this.viewingLine.designId, this.overwriteCdr, this.model.orderSource).subscribe((result) => {
      this.notificationService.showPopup(result.message, 'success', 'Success!');
    });
  }

  // Below function converts 26-07-2022 11:41:25 AM to 2022-07-26T11:41:25
  formatDateString(stringdate: string) {
    if (!stringdate) {
      return null;
    }

    return moment(stringdate).format('YYYY-MM-DDTHH:mm:ss');
  }

  // Below function converts 2022-07-26T07:55:00.92 to 2022-07-26T07:55:00.000
  formatDateString2(stringdate: string) {
    if (!stringdate) {
      return null;
    }

    const dateParts = stringdate.split('-');
    const timeParts = dateParts[2].split('T')[1].split(':');
    dateParts[2] = dateParts[2].split(' ')[0];

    return dateParts[0] + '-' + dateParts[1] + '-' + dateParts[2].split('T')[0] + 'T'
      + timeParts[0] + ':' + timeParts[1] + ':' + timeParts[2].split('.')[0] + '.000';
  }

  getCorelClickOnceUrl() {
    let url = `${this.storageLocation}clickonce/publish/wei.coreldraw.clickonce.application?designId=${this.viewingLine.designId}`;
    if (this.username) {
      url = url + `&username=${this.username}`;
    }
    if (this.model.orderSource === 'nbi-order' ||
      this.model.orderSource === 'fastpens-order' ||
      this.model.orderSource === 'sticker-order') {
      return url + `&pon=${this.viewingLine.pon}`;
    }
    return url;
  }

  editSalesOrder() {
    this.salesOrderService.getStatusFromNAV(this.model.id).subscribe(result => {
      {
        const cantEditOrder = this.model.lines.filter(c => c.isSelected && result.lines.some(d => d.lineNumber === c.lineNumber && d.canBeModified === false));
        if (result.orderStatus === 'Closed') {
          this.notificationService.showPopup('This order cannot be modified due to its status in Business Central.', 'danger', 'Error Editing');
          return;
        }
        if (cantEditOrder !== null && cantEditOrder.length > 0) {
          const lines = cantEditOrder.map(m => m.lineNumber);
          this.notificationService.showPopup(`Line(s) ${lines.join(',')} cannot be modified due to its status in Business Central.`, 'danger', 'Error Editing');
          return;
        }
        this.router.navigate([`/orders/edit/${this.model.id}`]);
      } err => {
        this.notificationService.showPopup((err && err.message) || err, 'danger', 'Error Editing');
        return;
      }
    });
  }

  wpsUrl(){
    return `${environment.wpsUrlPrefix}/search?searchTerm=${this.model.id}`;
  }

  anyWps(): boolean {
    return this.model.lines.some(l => l.isWpsOrderLine);
  }

  anyTs(): boolean {
    return this.model.lines.some(l => !l.isWpsOrderLine);
  }
}
