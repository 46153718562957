<h1 i18n>Tracking System Status</h1>
<div align="right">Last Updated {{this.lastUpdated}}</div>
<br/><br/>
<div class="smart-table-container">

  <ng2-smart-table [settings]="settings" [source]="this.openOrders" ></ng2-smart-table>
</div>
<div class="btn-group btn-group-sm" role="group" aria-label="Actions">
    <a class="btn btn-sm btn-outline-info" (click)="Download()">Download</a>
</div>
<div *ngIf="loading" class="progress">
  <div i18n class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100"
    aria-valuemin="0" aria-valuemax="100" style="width: 100%">Loading</div>
</div>