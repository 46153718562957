export const defaults = {
  production: true,
  adminUsers: [ 'wei\\joel.vazquez', 'wei\\mcard', 'wei\\mmiller', 'wei\\jflatt', 'wei\\lphillips', 'wei\\nchapman', 'wei\\dswingle', 'wei\\echai', 'wei\\praman', 'wei\\kscurlock', 'wei\\cbrown', 'wei\\jecheverria', 'wei\\odelacruz', 'wei\\grodriguez' ],
  editSalesOrderUsers: [ 'wei\\joel.vazquez', 'wei\\mmiller', 'wei\\mcard', 'wei\\jflatt', 'wei\\lphillips', 'wei\\nchapman', 'wei\\dswingle', 'wei\\kscurlock', 'wei\\cbrown', 'wei\\grodriguez', 'wei\\tpatterson', 'wei\\acobb','wei\\gramirez', 'wei\\jecheverria', 'wei\\ecooper', 'wei\\lviger', 'wei\\odelacruz', 'wei\\brosa' ],
  ediFlagUsers: ['wei\\joel.vazquez', 'wei\\mmiller', 'wei\\mcard', 'wei\\jflatt', 'wei\\lphillips', 'wei\\nchapman', 'wei\\dswingle', 'wei\\kscurlock', 'wei\\cbrown', 'wei\\grodriguez', 'wei\\orderentrymx03', 'wei\\aromo','wei\\gramirez', 'wei\\jecheverria', 'wei\\odelacruz' ],
  adminGroup: 'MiddlewareAdmins',
  editSalesOrderGroup: 'MiddlewareEditUsers',
  ediFlagGroup: 'MiddlewareOrderEntry',
  envDescription: 'Local',
  baseUrl: 'https://localhost:5001',
  digitalPassportUrl: 'http://testservices.worldemblem.com/weidocs/weidocs.aspx?i=',
  fedexTrackingPrefix: 'https://www.fedex.com/apps/fedextrack/index.html?tracknumbers=',
  upsTrackingPrefix: 'https://www.ups.com/track?loc=en_US&tracknum=',
  oAuthLoginUrl: 'https://login.worldemblem.com/adfs/oauth2/authorize?client_id=d82f2d29-d5f6-4d85-8e73-cce0dff770ee&resource=https://localhost:4200/login&response_type=code&redirect_uri=http://localhost:4200/login&response_mode=query',
  navApiBaseUrl: 'http://localhost:9100/api',
  shopUrl: 'https://shop-qa.worldemblem.com/',
  storageLocation: 'https://f947d465acaef4c3stgdev.blob.core.windows.net/',
  servicesBaseUrl: 'https://middlewareservices-dev.worldemblem.com',
  wpsUrlPrefix: 'https://ops.test.worldemblem.com'
  // tslint:disable-next-line:max-line-length
  //oAuthLoginUrl: 'https://login.worldemblem.com/adfs/oauth2/authorize?client_id=d82f2d29-d5f6-4d85-8e73-cce0dff770ee&resource=https://localhost:4200/login&response_type=code&redirect_uri=http://localhost:4200/login&response_mode=query',
  //oAuthLoginUrl: 'https://login.worldemblem.com/adfs/oauth2/authorize?client_id=cdd694cd-0690-4bbf-870c-d33408ef664e&resource=https://localhost:4200/login&response_type=code&redirect_uri=http://localhost:4200/login&response_mode=query'
  //oAuthLoginUrl: 'https://login.worldemblem.com/adfs/oauth2/authorize?client_id=cdd694cd-0690-4bbf-870c-d33408ef664e&resource=https://7f8636c612f63250stgprod.z20.web.core.windows.net/login&response_type=code&redirect_uri=https://7f8636c612f63250stgprod.z20.web.core.windows.net/login&response_mode=query
};
