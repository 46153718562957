
<div class="card w-100" *ngIf="model">
    <div class="card-body">
      <h5 class="card-title">{{ model.designId }}</h5>
      <div class="alert alert-info">
        NAV SKU: {{model.navSku}} 
        <button *ngIf="authService.isAdmin()" class="btn btn-sm btn-secondary ml-2" (click)="regenerateNavSku()">Re-generate</button>
        <div class="progress" *ngIf="model.regenerating">
          <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100px"></div>
        </div>
      </div>
      <div *ngIf="authService.isAdmin()">
        <button class="btn btn-sm btn-primary" data-toggle="collapse" data-target=".multi-collapse" (click)="edit()">Edit</button>&nbsp;
        <button class="btn btn-sm btn-secondary" *ngIf="model.isEditing" data-toggle="collapse" data-target=".multi-collapse" (click)="cancel()">Cancel</button><br />
      </div>
      <ul class="list-group list-group-flush" *ngFor="let a of model.attributeModels">
        <li class="list-group-item">
          <div class="d-flex flex-wrap justify-content-between">
            <span>
              {{ a.displayName }} - {{ a.value }} 
                <span *ngIf="a.hexColor" class="badge inline-block" [ngStyle]="{ 'background-color': '#' + a.hexColor }"><span style="mix-blend-mode: difference;">{{ a.hexColor }}</span></span>
            </span>
            <span>
              Last updated: {{ getLastUpdatedString(a) }}
              <a data-toggle="collapse" [attr.href]="'#details' + a.rawAttr.id" role="button" aria-expanded="false" [attr.aria-controls]="'#details' + a.rawAttr.id">more</a>
            </span>
            
            <div class="collapse multi-collapse w-100" [attr.id]="'details' + a.rawAttr.id">
              <div *ngIf="model.isEditing">
                <button class="btn btn-sm btn-success" *ngIf="model.isEditing" data-toggle="collapse" data-target=".multi-collapse" (click)="save(a.rawAttr)">Save Changes</button>
              </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">
                    <div class="d-flex flex-wrap justify-content-between">
                      <label>Id:</label><span>{{ a.rawAttr.id }}</span>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div *ngIf="!model.isEditing" class="d-flex flex-wrap justify-content-between">
                      <label>Code:</label><span>{{ a.rawAttr.code }}</span>
                    </div>
                    <div *ngIf="model.isEditing" class="d-flex flex-wrap justify-content-between">
                      <label>Code:</label><input type="text" [(ngModel)]="a.rawAttr.code">
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div *ngIf="!model.isEditing" class="d-flex flex-wrap justify-content-between">
                      <label>Description:</label><span>{{ a.rawAttr.description }}</span>
                    </div>
                    <div *ngIf="model.isEditing" class="d-flex flex-wrap justify-content-between">
                      <label>Description:</label><input type="text" [(ngModel)]="a.rawAttr.description">
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div *ngIf="!model.isEditing" class="d-flex flex-wrap justify-content-between">
                      <label>Description 2:</label><span>{{ a.rawAttr.description2 }}</span>
                    </div>
                    <div *ngIf="model.isEditing" class="d-flex flex-wrap justify-content-between">
                      <label>Description 2:</label><input type="text" [(ngModel)]="a.rawAttr.description2">
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div  *ngIf="!model.isEditing"class="d-flex flex-wrap justify-content-between">
                      <label>Description 3:</label><span>{{ a.rawAttr.description3 }}</span>
                    </div>
                    <div *ngIf="model.isEditing" class="d-flex flex-wrap justify-content-between">
                      <label>Description 3:</label><input type="text" [(ngModel)]="a.rawAttr.description3">
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div *ngIf="!model.isEditing" class="d-flex flex-wrap justify-content-between">
                      <label>Value:</label><span>{{ a.rawAttr.value }}</span>
                    </div>
                    <div *ngIf="model.isEditing" class="d-flex flex-wrap justify-content-between">
                      <label>Value:</label><input type="text" [(ngModel)]="a.rawAttr.value">
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div class="d-flex flex-wrap justify-content-between">
                      <label>Show in TWO Product Structure:</label><span>{{ a.rawAttr.showOnWorkOrder }}</span>
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div *ngIf="!model.isEditing" class="d-flex flex-wrap justify-content-between">
                      <label>Source:</label><span>{{ a.rawAttr.dataSource }}</span>
                    </div>
                    <div *ngIf="model.isEditing" class="d-flex flex-wrap justify-content-between">
                      <label>Source:</label><input type="text" [(ngModel)]="a.rawAttr.dataSource">
                    </div>
                  </li>
                  <li class="list-group-item">
                      <div class="d-flex flex-wrap justify-content-between">
                      <label>Last Updated:</label><span>{{ moment(a.rawAttr.lastUpdated).calendar() }}</span>
                    </div>
                  </li>
                </ul>
            </div>
          </div>

        </li>
      </ul>
    </div>
  </div>