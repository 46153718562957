<div>
  <h1 i18n>Design Requests</h1>
  <form class="form-inline mt-2 mb-1 mt-md-0">
    <input name="filterString"
      [(ngModel)]="model.filterString" (ngModelChange)="filterStringChanged()"
      class="form-control mr-sm-2" type="text" placeholder="Type to filter" aria-label="Filter">
      <div *ngIf="loading" class="progress">
        <div i18n class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 75px">Loading</div>
      </div>
  </form>
  <div class="table-responsive">
    <table class="table table-striped">
      <thead>
            <th scope="col"></th>
            <th scope="col" i18n>Design ID</th>
            <th scope="col" i18n >Company Name</th>
            <th scope="col" i18n>Date Added</th>
            <th scope="col" i18n>Description</th>
            <th scope="col" i18n>Customer Number</th>
            <th scope="col" i18n>Status</th>
        </thead>
        <tbody>
        <tr *ngFor="let item of model.designRequests" >
          <td *ngIf="item.source == 'episerver'"><a class="btn btn-sm btn-primary" [routerLink]="['/eprequests', item.id]">View</a></td>
          <td *ngIf="item.source != 'episerver'"><a class="btn btn-sm btn-primary" [routerLink]="['/requests', item.id]">View</a></td>
          <td>{{ item.designId }}</td>
          <td>{{ item.companyName }}</td>
          <td>{{ longDateWithSeconds(item.enteredDate, true) }}</td>
          <td>{{ item.description }}</td>
          <td>{{ item.customerNumber }}</td>
          <td>{{ item.status }}</td>
          <td></td>
        </tr>
    </tbody>
  </table>
</div>
  <div *ngIf="loading" class="progress">
    <div i18n class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">Loading</div>
  </div>
  <div *ngIf="noMore" class="alert alert-warning" i18n>
    There are no older design requests
  </div>
  <div *ngIf="!loading && !noMore" class="mt-2 mb-2">
    <button i18n (click)="loadOlder()" type="button" class="btn btn-primary btn-sm">Load older designs</button>
  </div>
</div>
