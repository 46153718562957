import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/services/customer.service';
import { CustomerModel } from 'src/app/models';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  enabledCustomers: CustomerModel[];

  constructor(private customerService: CustomerService) { }

  ngOnInit() {
    this.customerService.getEnabledCustomers().subscribe(lst => this.enabledCustomers = lst);
  }

  getEnabledProductCount(customer: CustomerModel) {
    if (!customer.enabledProductsTypeIds) {
      return 'NO products enabled';
    }
    if (customer.enabledProductsTypeIds.some(i => i === 'ALL')) {
      return 'ALL products enabled';
    }
    return `${(customer.enabledProductsTypeIds || []).length} products enabled`;
  }
}
