<div>
  <h1 i18n>Event Log</h1>
  <form class="form-inline mt-2 mb-1 mt-md-0">
    <input name="filterString" [(ngModel)]="model.orderId" (ngModelChange)="orderIdChanged()" class="form-control mr-sm-2"
      type="text" placeholder="Filter by Order ID" aria-label="Filter">
      <input type="checkbox" id="searchByEventName" class="form-control mr-sm-2">
      <label for="searchByEventName" style="margin-right: 10px;">search by event name?</label>
    <div *ngIf="loading" class="progress">
      <div i18n class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 75px">Loading</div>
    </div>
  </form>
  <div class="table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Timestamp</th>
          <th scope="col" i18n>Order ID</th>
          <th scope="col" i18n>Event Name</th>
          <th scope="col" i18n>Source</th>
          <th scope="col" i18n></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of eventItems">
          <td>
            {{ calendarDate(item.eventDate) }}
          </td>
          <td>
            {{ item.orderId || 'No Order ID' }}
          </td>
          <td>
            {{ item.eventName || 'No Event Name' }}
          </td>
          <td>
            {{ item.source }}
          </td>
          <td>
            <a i18n routerLink="{{ item.id }}">Details</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="loading" class="progress">
    <div i18n class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">Loading</div>
  </div>
  <div *ngIf="noMore" class="alert alert-warning" i18n>
    There are no more older events
  </div>
  <div *ngIf="!loading && !noMore" class="mt-2 mb-2">
    <button i18n (click)="loadOlder()" type="button" class="btn btn-primary btn-sm">Load older events</button>
  </div>
</div>