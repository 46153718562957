<h2>Settings</h2>
<div class="card" style="min-width: 27rem;">
  <div class="card-body">
    <h5 class="card-title" i18n>Enabled Customers [<a [routerLink]="['/customers']">All</a>] </h5>
    <ul class="list-group">
      <li class="list-group-item" *ngFor="let customer of enabledCustomers">
        <a [routerLink]="['/customers', customer.id]" routerLinkActive="router-link-active">{{ customer.sourceCustomerNumber }} - {{ customer.companyName }} ({{ getEnabledProductCount(customer) }})</a>
      </li>
    </ul>
  </div>
</div>
