<div class="navlink">
  <a *ngIf="!unexpected" [routerLink]="['..']">Back to details</a>
  <a *ngIf="unexpected" [routerLink]="['/csgrequests', 'unexpected', this.csgRequestId]">Back to details</a>
  <a href="#" (click)="print($event)">Print</a>
  <a *ngIf="selectedDisplayModel" href="#" (click)="deselectLabel($event)">View All Labels</a>
</div>
<div class="loading" *ngIf="loading">
  <div class="text-centered">
    <span class="fa fa-spinner fa-3x fa-spin"></span>
  </div>
</div>

<div *ngIf="!loading && selectedDisplayModel">
  <div class="label-content" *ngIf="!loading">
    <div class="customer">
      <label>Customer:</label>
      <span>{{ selectedDisplayModel?.customerName }}</span>
    </div>
    <div class="son">
      <label>{{ unexpected ? 'Receiving ID' : 'SON' }}:</label>
      <span>{{ selectedDisplayModel?.salesOrders }}</span>
    </div>
    <div class="totalUnits">
      <label>Total Units:</label>
      <span>{{ selectedDisplayModel?.totalReceived }}</span>
    </div>
    <div class="totalBoxes">
      <label>Total Boxes:</label>
      <span>{{ selectedDisplayModel?.boxes }}</span>
    </div>

    <div class="receivedDate">
      <label>Received:</label>
      <span>{{ selectedDisplayModel?.receivedDate }}</span>
    </div>
    
    <div *ngIf="!unexpected" class="dueDate">
      <label>Due:</label>
      <span>{{ selectedDisplayModel?.dueDate }}</span>
    </div>
    <div class="barcodeImage">
      <img [src]="barcodeImageUrl"  alt="Barcode Image">
    </div>
  </div>
</div>

<div *ngIf="!loading && !selectedDisplayModel">
  <div *ngFor="let model of displayModel">
    <div class="label-content" id="labelFor{{model.salesOrders}}" *ngIf="!loading" (click)="printThisLabel(model)">

      <div class="customer">
        <label>Customer:</label>
        <span>{{ model?.customerName }}</span>
      </div>
      <div class="son">
        <label>{{ unexpected ? 'Receiving ID' : 'SON' }}:</label>
        <span>{{ model?.salesOrders }}</span>
      </div>
      <div class="totalUnits">
        <label>Total Units:</label>
        <span>{{ model?.totalReceived }}</span>
      </div>
      <div class="totalBoxes">
        <label>Total Boxes:</label>
        <span>{{ model?.boxes }}</span>
      </div>

      <div class="receivedDate">
        <label>Received:</label>
        <span>{{ model?.receivedDate }}</span>
      </div>
      <div class="dueDate">
        <label>Due:</label>
        <span>{{ model?.dueDate }}</span>
      </div>
    </div>
  </div>
</div>