import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import {
    NonTranslatedThread
} from './../models';

export const unexpected = 'Unexpected Shipment';

@Injectable({
    providedIn: 'root'
})
export class ReportService {

    baseUrl = environment.baseUrl;

    constructor(private httpClient: HttpClient) { }


    getNonTranslatedThreadReport(): Observable<NonTranslatedThread[]> {
        return this.httpClient.get<NonTranslatedThread[]>
            (`${this.baseUrl}/api/reports/nontranslatedthreads`)
            .pipe(
                first()
            );
    }

    toggleIsThreadOrdered(id: string): Observable<any> {
        return this.httpClient.put<any>
            (`${this.baseUrl}/api/reports/nontranslatedthreads/toggle/${id}`, null)
            .pipe(
                first()
            );
    }

    deleteThreadFromReport(id: string): Observable<any> {
        return this.httpClient.put<any>
            (`${this.baseUrl}/api/reports/nontranslatedthreads/${id}/delete`, null)
            .pipe(
                first()
            );
    }
}
