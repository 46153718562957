<div class="col-sm-4">
  <div class="card" style="width: 28rem;">
    <div class="card-header">
      Production Order Status
      <i class="fas fa-question" (click)="openModal(template)"></i>
      <i class="fas fa-sync" style="float:right;" (click)="loadProductionOrders(this.today)"></i>
    </div>

    <div class="container">
      <ng-template #template>
        <div class="modal-header">
          <h4 class="modal-title pull-left">Production Order Summary</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          This view shows the product's On Time Performance. Production orders that are due today with their status. 
          Drill down available by product, checkpoint and date.
        </div>
      </ng-template>

      <div class="card-body">
        <div *ngIf="dataLoaded | async">

          <div class="row">
            <div class="col-sm-3"> Product</div>
            <select class="col-sm-3 select-option  btn btn-outline-info btn-sm" required [(ngModel)]='productSelected'
              (ngModelChange)="getCheckpointList(productSelected); filterByProduct(productSelected)">
              <option class='option' *ngFor='let product of this.productList' [value]="product">{{product}}
              </option>
            </select>
          
            <div class="col-sm-3">Checkpoint</div>
            <select class='col-sm-3 select-option btn btn-outline-info btn-sm' required [(ngModel)]='checkpointSelected'
            (ngModelChange)="filterbyProductCheck(productSelected, checkpointSelected)">
              <option class='option' *ngFor='let checkpoint of this.checkpointList' [value]="checkpoint">{{checkpoint}}
              </option>
            </select>
          </div>
          <br/>
          <div class="row">
              <div class="col-sm-4"> Date</div>
              
              <input type="text" placeholder="" class="col-sm-4 form-group datebtn btn-outline-info btn-sm" 
                  [bsConfig]="bsConfig" [(ngModel)]="dateValue" value="{{ this.todaydate | date: 'MM/dd/yyyy' }}" (ngModelChange)="filterbyDate()" bsDatepicker>
           
            </div>

          <div *ngIf="togglePieVal">
            <br /> <br /> <br />
            <table class=" table table-striped">
              <thead>
                <th scope="col">Product</th>
                <th scope="col">Status</th>
                <th scope="col">Checkpoint</th>
                <th scope="col">Location</th>
                <th scope="col">Count</th>

              </thead>
              <tbody>
                <tr *ngFor="let row of this.productionOrders">
                  <td scope="col">{{ row.productClass}}</td>
                  <td scope="col">{{ row.latestStatus }}</td>
                  <td scope="col">{{ row.checkpointName}}</td>
                  <td scope="col">{{ row.locationCode}}</td>
                  <td scope="col">{{ row.rowCnt}}</td>
                </tr>
              </tbody>
            </table>

          </div>
          <div *ngIf="!(togglePieVal)">
            <div *ngIf="!(noData)">
              <br /> <br /> <br /><br />
              <div>            
                <div class="chart">
                  <canvas baseChart style="height:60vh; width:40vw" [data]="pieChartData" [labels]="pieChartLabels"
                    [chartType]="pieChartType" [options]="pieChartOptions" [colors]="pieChartColors" [legend]="pieChartLegend">
                  </canvas>
                </div>  
              </div>
            </div>
          </div>
        </div>

        <br />
        <div align="right"><button class="btn btn-outline-info btn-sm" (click)="togglePie()">Toggle</button></div>
      </div>
    </div>
  </div>
</div>
