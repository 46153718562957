import { OpenOrdersModel } from './../models/index';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OpenOrdersService {
  baseUrl = environment.baseUrl;

  constructor(private httpClient: HttpClient) { }

  getOpenOrders(): Observable<OpenOrdersModel[]> {
    return this.httpClient.get<{ openOrders: OpenOrdersModel[] }>
      (`${this.baseUrl}/api/openorders`)
      .pipe(
        map(result => result.openOrders))
      .pipe(
        first()
      );
  }
}

