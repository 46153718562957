import { Component, TemplateRef, OnInit, OnDestroy } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { ProductionOrderSummaryItem } from 'src/app/models';
import { SalesOrderService } from 'src/app/services/sales-order.service';

import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-production-order',
  templateUrl: './production-order.component.html',
  styleUrls: ['./production-order.component.scss']
})

export class ProductionOrderComponent implements OnInit {

  private productionOrders: ProductionOrderSummaryItem;
  private productionOrdersAll: ProductionOrderSummaryItem;
  private productionOrdersChk: ProductionOrderSummaryItem;

  private productionOrder: any = [];
  dataLoaded: Promise<boolean>;
  private bsConfig: Partial<BsDatepickerConfig>;
  private modalRef: BsModalRef;
  private productList = [];
  private checkpointList = [];
  private togglePieVal: boolean = false;
  private productSelected: any = "";
  private checkpointSelected: any = "";
  private dateValue:Date;
  public today:Date ;
  private curDateStr:string;
  private noData:boolean;

  private pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
      labels: {
        boxWidth: 10
      }
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };

  private pieChartLabels = [];
  private pieChartData: number[] = [];
  private pieChartType: ChartType = 'pie';
  private pieChartLegend = true;
  private pieChartPlugins = [pluginDataLabels];
  private pieChartColors = [
    {
      backgroundColor: ['rgba(77,83,96,0.2)', 'rgba(0,128,128)', 'rgba(0,0,128)', 'rgba(128,128,128)'],
    },
  ];

  constructor(private salesOrderService: SalesOrderService,
    private modalService: BsModalService) { }

  ngOnInit() {
    this.productSelected = 'All';    
    this.bsConfig = Object.assign({}, { containerClass: "theme-default" });
    this.today = new Date();
    this.dateValue = this.today;
    this.loadProductionOrders(this.today);
  }

  onOptionsSelected(event) {
    console.log(event);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  togglePie() {
    if (this.togglePieVal == true) this.togglePieVal = false
    else this.togglePieVal = true;
    return this.togglePieVal;

  }

  loadProductionOrders(curDate:Date) {
    this.checkpointSelected = "";
    this.productSelected = "";
    this.curDateStr = String(curDate.getFullYear()) + '-' 
                    + String(curDate.getMonth()+1).padStart(2, '0') + '-' 
                    + String(curDate.getDate()).padStart(2, '0');
    this.dateValue = curDate;
    this.salesOrderService.getProductionOrderSummary(this.curDateStr).subscribe(list => {
      this.productionOrdersAll = list;
      this.productionOrders = this.productionOrdersAll.productionOrderSummaryModel;
      this.getProductList();
      this.getTotals();
      this.buildPieData();
      this.dataLoaded = Promise.resolve(true);
      if (Object.keys(this.productionOrders).length == 0)
      this.noData = true;
      else this.noData = false;
    });
  }

  getProductList() {
    this.productList = this.productionOrdersAll.productionOrderSummaryModel.map(item => item.productClass)
      .filter((value, index, self) => self.indexOf(value) === index);
  }

  getCheckpointList(productClass) {
    //for the given product find applicable checkpoints.
    this.productionOrdersChk = this.productionOrdersAll.productionOrderSummaryModel.filter(a => a.productClass == productClass);
    this.checkpointList = this.productionOrdersChk.map(item => item.checkpointName)
      .filter((value, index, self) => self.indexOf(value) === index);
    this.checkpointList = this.checkpointList.filter(a => a != null);    
  }

  filterByProduct(productClass) {
    this.productionOrders = this.productionOrdersAll.productionOrderSummaryModel.filter(a => a.productClass == productClass);
    this.getTotals();
    this.buildPieData();
  }

  filterbyProductCheck(productClass, checkpointName) {
    this.productionOrders = this.productionOrdersAll.productionOrderSummaryModel.filter(a => a.productClass == productClass);
    this.productionOrders = this.productionOrders.filter(a => a.checkpointName == checkpointName)
    this.getTotals();
    this.buildPieData();
  }

  filterbyDate() {
    this.loadProductionOrders(this.dateValue);
 }

  getTotals(){
    var result = [];
    this.productionOrders.reduce(function (res, value) {
      if (!res[value.latestStatus]) {
        res[value.latestStatus] = { latestStatus: value.latestStatus, rowCnt: 0 };
        result.push(res[value.latestStatus])
      }
      res[value.latestStatus].rowCnt += value.rowCnt;
      return res;
    }, {});

    this.productionOrder = result; 
  }

  buildPieData() {
    this.pieChartLabels = [];
    this.pieChartLabels = this.productionOrder.map(a => a.latestStatus);
    this.pieChartData = this.productionOrder.map(a => a.rowCnt);
  }

}
