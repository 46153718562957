import { CsgRequestListItem } from './../../models';
import { longDateWithSeconds, shortDate } from './../../displayHelpers';
import { Component } from '@angular/core';
import { CsgRequestsService } from '../../services/csg-requests.service';

const MissingThreadTranslation = 'Thread Translation Missing';
const DesignImportFailed = 'Design Import Failed';
const PriceException = 'Price Tolerance Exception';
const BadThreadData = 'Unable To Process Order. Invalid Thread Data';
const DesigRequestPending = 'Design Request Pending';
const DesigRequestPendingPartial = 'Partially Received; Design Request Pending';
const DesigRequestPendingReceived = 'Received; Design Request Pending';
const ThreadUpdatedPleaseReimport = 'Thread Data Updated; Please Reimport';

@Component({
  selector: 'app-csgrequest-search',
  templateUrl: './csgrequest-search.component.html',
  styleUrls: ['./csgrequest-search.component.scss', './../../app.component.scss']
})
export class CsgrequestSearchComponent {

  model = {
    filterString: '',
    csgRequests: new Array<CsgRequestListItem>(),
    unexpectedCsgRequests: new Array<CsgRequestListItem>()
  };

  loading = false;

  longDateWithSeconds = longDateWithSeconds;
  shortDate = shortDate;
  searchHandle: number;

  constructor(
    private csgService: CsgRequestsService,
  ) { }

  filterStringChanged() {
    if (this.searchHandle) {
      window.clearTimeout(this.searchHandle);
    }
    this.searchHandle = window.setTimeout(() => {
      this.loading = true;
      this.csgService.getSearchCsgRequests(this.model.filterString).subscribe(results => {
        this.model.csgRequests = results;
        this.loading = false;
      });
    }, 2000);
  }

  hasIssues(item: CsgRequestListItem) {
    return item.status === MissingThreadTranslation ||
      item.status === DesignImportFailed ||
      item.status === PriceException ||
      item.status === BadThreadData ||
      item.status === DesigRequestPending ||
      item.status === DesigRequestPendingPartial ||
      item.status === DesigRequestPendingReceived ||
      item.status === ThreadUpdatedPleaseReimport;
  }

  formatTrackingNumbers(trackNumber: string) {
    if (!!trackNumber && trackNumber !== '') {
      return trackNumber.split(',').join(', ');
    } else {
      return '';
    }
  }

}
