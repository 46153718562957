import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-me',
  templateUrl: './me.component.html',
  styleUrls: ['./me.component.scss', './../../app.component.scss']
})
export class MeComponent implements OnInit {

  user: { userName: string, email: string, roles: string[] };
  constructor(private authService: AuthService) { }

  ngOnInit() {
    const token = this.authService.getTokenPayload();
    if (!token) {
      return;
    }
    this.user = { userName: token.unique_name, email: token.upn, roles: token.group };
  }

}
