<div>
    <h1 i18n>Import Monitor History</h1>
    <div class="row">
        <div class="col-auto mb-3"><a class="float-left" href="" [routerLink]="['..']"><span class="fa fa-chevron-left"></span>&nbsp;Back</a></div>
    </div>

    <div class="row mb-2">
        <div class="col-md-6 mb-1 form-group" style="display: inline-block">
            <div class="row last-number-of-days">
                <label class="num-days-label" for="numberOfDays"> Show Cleared Records For Last&nbsp;</label>
                <input name="numberOfDays" class="form-control short-input" type="number"
                    [(ngModel)]="numberOfDays" (change)="refreshImportMonitorForDays()" />
                <label class="num-days-label" >&nbsp;Days</label>
            </div>
        </div>
    </div>
<div class="table-responsive">
    <table class="table table-striped">
        <thead>
            <tr>
                <th scope="col" style="width:15%">Error Date</th>
                <th scope="col" style="width:10%"  i18n>Order ID</th>
                <th scope="col" style="width:10%"  i18n>Source</th>
                <th scope="col" style="width:30%"  i18n>Error Message</th>
                <th scope="col" style="width:10%"  i18n>Cleared By</th>
                <th scope="col" style="width:15%"  i18n>Cleared Date</th>
                <th scope="col" style="width:10%"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of eventItems">
                <td>
                    {{ calendarDate(item.eventDate) }}
                </td>
                <td *ngIf="item.orderId != null && item.orderId.substring(0,2) == 'SO'">
                    <a [routerLink]="['/orders',item.orderId]">{{item.orderId}}</a>
                </td>
                <td *ngIf="item.orderId == null || item.orderId.substring(0,2) != 'SO'">
                    <a [routerLink]="['/events']" [queryParams]="{orderId: item.orderId}">{{item.orderId}}</a>
                </td>
                <td>
                    {{ item.source }}
                </td>
                <td>
                    {{ eventMessage(item) }}
                </td>
                <td>
                    {{ item.clearedBy }}
                </td>
                <td>
                    {{ calendarDate(item.clearedDate) }}
                </td>
                <td>
                    <a class="btn btn-primary" i18n routerLink="../{{ item.id }}">Details</a>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div *ngIf="loading" class="progress">
    <div i18n class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100"
        aria-valuemin="0" aria-valuemax="100" style="width: 100%">Loading</div>
</div>
<div style="text-align: center" *ngIf="!loading && eventItems.length === 0">
    <div i18n class="alert alert-success">Whew! Nothing here!</div>
</div>
</div>