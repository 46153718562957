<div class="row">
    <div class="col">
      <a [routerLink]="['../../orders']">Back to list</a>
    </div>
  </div>
  
  <div *ngIf="model" class="row">
    <div class="col-sm">
      <div class="card" style="min-width: 27rem;">
        <div class="card-body">
          <h5 class="card-title" i18n>General</h5>
          <div class="row">
            <div class="col-sm-4 data-label">
              Sales Order Id
            </div>
            <div class="col-auto">
              {{ model.id }}
            </div>
          </div>
  
          <div class="row">
            <div class="col-sm-4 data-label">
              Web Order Number
            </div>
            <div class="col-auto">
              {{ model.referenceNumber }}
            </div>
          </div>
  
          <div class="row">
            <div class="col-sm-4 data-label">
              Order Source
            </div>
            <div class="col-auto">
              {{ model.orderSource }}
            </div>
          </div>
  
          <div class="row">
            <div class="col-sm-4 data-label">
              PO No.
            </div>
            <div class="col-auto">
              <input type="text" [(ngModel)]="model.externalDocumentNumber" [disabled]="remakeSent" maxlength="20" /><span class="reqiured">*</span>
            </div>
          </div>
  
          <div class="row">
            <div class="col-sm-4 data-label">
              Order Type
            </div>
            <div class="col-auto">
              {{ model.orderType }}
            </div>
          </div>
  
          <div class="row">
            <div class="col-sm-4 data-label">
              NAV Customer Number
            </div>
            <div class="col-auto">
              {{ model.navCustomerNumber }}
            </div>
          </div>
  
          <div class="row">
            <div class="col-sm-4 data-label">
              Legacy Customer Number
            </div>
            <div class="col-auto">
              {{ model.legacyCustomerNo }}
            </div>
          </div>
  
          <div class="row">
            <div class="col-sm-4 data-label">
              Customer
            </div>
            <div class="col-auto">
              {{ model.customerName }}
            </div>
          </div>
  
          <div class="row">
            <div class="col-sm-4 data-label">
              Customer Type
            </div>
            <div class="col-auto">
              {{ model.customerType }}
            </div>
          </div>
  
          <div class="row">
            <div class="col-sm-4 data-label">
              Web User
            </div>
            <div class="col-auto">
              {{ model.webUser }}
            </div>
          </div>
  
          <div class="row">
            <div class="col-sm-4 data-label">
              Customer GL Number
            </div>
            <div class="col-auto">
              {{ getCustomerGlNum(model.lines) }}
            </div>
          </div>
  
          <div class="row">
            <div class="col-sm-4 data-label">
              NAV Assembly Number
            </div>
            <div class="col-auto">
              {{ getAssemblyNumbers() }}
            </div>
          </div>
  
          <div class="row">
            <div class="col-sm-4 data-label">
              Requested Delivery Date
            </div>
            <div class="col-auto">
              <input type='date' [(ngModel)]="model.dueDate" [disabled]="remakeSent" /><span class="reqiured">*</span>
            </div>
          </div>
  
          <div class="row">
            <div class="col-sm-4 data-label">
              Priority
            </div>
            <div class="col-auto">
              {{ model.priority }}
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 data-label">
              Comments
            </div>
            <div class="col-sm-8">
              <textarea class="textarea" [(ngModel)]="model.comments" [disabled]="remakeSent" ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm">
      <div class="card" style="min-width: 27rem;">
        <div class="card-body">
          <h5 class="card-title" i18n>Shipping</h5>
          <div class="card-text">
  
            <div class="row">
              <div class="col-sm-4 data-label">
                Ship-to Company
              </div>
              <div class="col-auto">
                <input type="text" width="260" [(ngModel)]="model.shipToCompany" [disabled]="remakeSent" />
              </div>
            </div>

            <div class="row">
              <div class="col-sm-4 data-label">
                Ship-to Contact
              </div>
              <div class="col-auto">
                <input type="text" width="260" [(ngModel)]="model.shipToName" [disabled]="remakeSent" />
              </div>
            </div>
            
            <div class="row">
              <div class="col-sm-4 data-label">
                Ship-to Address
              </div>
              <div class="col-auto">
                <input type="text" [(ngModel)]="model.address" [disabled]="remakeSent" />
              </div>
            </div>
  
            <div class="row">
              <div class="col-sm-4 data-label">
                Ship-to Address 2
              </div>
              <div class="col-auto">
                <input type="text" [(ngModel)]="model.address2" [disabled]="remakeSent" />
              </div>
            </div>
  
            <div class="row">
              <div class="col-sm-4 data-label">
                Ship-to City
              </div>
              <div class="col-auto">
                <input type="text" [(ngModel)]="model.city" [disabled]="remakeSent" />
              </div>
            </div>
  
            <div class="row">
              <div class="col-sm-4 data-label">
                Ship-to State
              </div>
              <div class="col-auto">
                <input type="text" [(ngModel)]="model.state" [disabled]="remakeSent" />
              </div>
            </div>

            <div class="row">
              <div class="col-sm-4 data-label">
                Ship-to ZIP Code
              </div>
              <div class="col-auto">
                <input type="text" [(ngModel)]="model.postalCode" [disabled]="remakeSent" />
              </div>
            </div>

            <div class="row">
              <div class="col-sm-4 data-label">
                Ship-to Country
              </div>
              <div class="col-auto">
                <select id="countries" style="width:146px;" [(ngModel)]="defaultCountry" [disabled]="remakeSent" class="ng-pristine ng-valid ng-touched">
                    <option *ngFor="let i of countriesData" [value]="i.abbreviation">{{i.name}}</option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-4 data-label">
                Shipping Agent
              </div>
              <div class="col-auto">
                <select [(ngModel)]="selectedShippingAgent" (change)="setShippingCodes()" [disabled]="remakeSent" >
                    <option *ngFor="let name of shippingAgentNames()">{{ name }}</option>
                </select>
              </div>
            </div>
  
            <div class="row">
              <div class="col-sm-4 data-label">
                Shipping Service Code
              </div>
              <div class="col-auto">
                <select id="selectShippingCodeDropdown" [(ngModel)]="selectedShippingCode" [disabled]="remakeSent" (change)="updateShippingCode()">
                    <option *ngFor="let i of shippingCodeOptions" [value]="i.id">{{i.name}}
                    </option>
                </select><span class="required">*</span>
              </div>
            </div>
  
            <div class="row">
              <div class="col-sm-4 data-label">
                Email Address
              </div>
              <div class="col-auto">
                <input type="text" [(ngModel)]="model.email" [disabled]="remakeSent" />
              </div>
            </div>
  
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="model" class="row">
    <div class="col-lg">
      <div class="card" style="min-width: 27rem;">
        <div class="card-body">
          <h5 class="card-title" i18n>Order Lines</h5>
          <div class="card-text">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>
                      <input id="checkAllOrderLines" type="checkbox" *ngIf="!remakeSent"
                        (change)="selectAllOrderLines($event)" title="Select or deselect all lines." 
                        onclick="$('[data-selector]').each(function() {$(this).prop('checked', $('#checkAllOrderLines').prop('checked'))});" />
                    </th>
                    <th scope="col">NAV Line No.</th>
                    <th scope="col">PON</th>
                    <th scope="col">Remake PON</th>
                    <th scope="col">NAV Item No.</th>
                    <th scope="col">Design ID</th>
                    <th scope="col">Quantity</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let line of sortedLines">
                    <td><input type="checkbox" data-selector [disabled]="remakeSent" (change)="selectOrderLine(line.lineNumber, $event)"></td>
                    <td>{{ line.lineNumber }}</td>
                    <td>{{ line.pon }}</td>
                    <td></td>
                    <td>{{ line.sku }}</td>
                    <td><input type="text" [(ngModel)]="line.designId" [disabled]="!isOrderLineSelected(line.lineNumber) || remakeSent" /></td>
                    <td><input type="text" [(ngModel)]="line.quantity" [disabled]="!isOrderLineSelected(line.lineNumber) || remakeSent" /></td>
                    <td>
                      <button class="btn-primary" onclick="window.scrollTo(0, document.body.scrollHeight);" (click)="setFocusOrderLine(line.lineNumber)" 
                        *ngIf="hasDropperNamesAndOrderLineSelected(line.id, line.lineNumber)">Names</button>
                    </td>
                    <td>
                      <div class="errorMessage" *ngIf="hasDropperNamesAndOrderLineSelected(line.id, line.lineNumber) && !hasSelectedDropperNames(line.id)">
                        Must select names below!
                      </div>
                    </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="hasDropperNames() || currentOrderLineId == null" class="row">
    <div class="col">
      <div class="row">
          <div class="col-lg">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Name Dropper Names {{getCurrentOrderLineVisual()}}</h5>
                <div class="card-text">
                  <table *ngIf="currentOrderLineId != null && !remakeSent" class="table table-striped">
                    <thead>
                      <tr>
                        <th>
                          <input id="checkAllDropperNames" type="checkbox" data-selector 
                            (change)="selectAllDropperNames($event)" title="Select or deselect all lines." />
                        </th>
                        <th>Line Name No</th>
                        <th>Line 1 Text</th>
                        <th>Line 2 Text</th>
                        <th>Line 3 Text</th>
                        <th>Qty</th>
                      </tr>
                    </thead>
                    <tr class="tr" *ngFor="let name of getDropperNames()">
                      <td><input type="checkbox" [(ngModel)]="name.isSelected" (change)="refreshOrderLineQuantity(name.salesOrderLineId)" ></td>
                      <td class="td">{{ name.lineNameNo }}</td>
                      <td class="td"><input type="text" [(ngModel)]="name.line1Text" [disabled]="!isDropperNameSelected(name.salesOrderLineId, name.lineNameNo) || remakeSent" /></td>
                      <td class="td"><input type="text" [(ngModel)]="name.line2Text" [disabled]="!isDropperNameSelected(name.salesOrderLineId, name.lineNameNo) || remakeSent" /></td>
                      <td class="td"><input type="text" [(ngModel)]="name.line3Text" [disabled]="!isDropperNameSelected(name.salesOrderLineId, name.lineNameNo) || remakeSent" /></td>
                      <td class="td"><input type="text" [(ngModel)]="name.qtyToProduce" (change)="refreshOrderLineQuantity(name.salesOrderLineId)" [disabled]="!isDropperNameSelected(name.salesOrderLineId, name.lineNameNo) || remakeSent" /></td>
                    </tr>
                  </table>
                  <table *ngIf="currentOrderLineId != null && remakeSent" class="table table-striped">
                    <thead>
                      <tr>
                        <th>
                        </th>
                        <th>Line Name No</th>
                        <th>Line 1 Text</th>
                        <th>Line 2 Text</th>
                        <th>Line 3 Text</th>
                        <th>Qty</th>
                      </tr>
                    </thead>
                    <tr class="tr" *ngFor="let name of getSubmittedDropperNames()">
                      <td></td>
                      <td class="td">{{ name.lineNameNo }}</td>
                      <td class="td">{{ name.line1Text }}</td>
                      <td class="td">{{ name.line2Text }}</td>
                      <td class="td">{{ name.line3Text }}</td>
                      <td class="td">{{ name.qtyToProduce }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm">
      <button class="btn btn-primary" [disabled]="remakeSent || !validateForSubmit()" (click)="remakeOrder()">Submit Remake</button>
      <div *ngIf="remakeSent" style="display: inline-block; vertical-align: bottom" class="ml-2 mb-0 alert alert-success">Remake Order is submitted.</div>
      <div *ngIf="!remakeSent && !validateForSubmit() && errorMessage == null" style="display: inline-block; vertical-align: bottom" class="ml-2 mb-0 alert alert-danger">Please select one or more lines above and resolve any invalid messages before submitting this Remake Order.</div>
      <div *ngIf="!remakeSent && errorMessage != null" style="display: inline-block; vertical-align: bottom" class="ml-2 mb-0 alert alert-danger">{{errorMessage}}</div>
    </div>
  </div>
  <br />
  <br />
  