<div class="row">
  <div class="col">
    <a [routerLink]="['/orders/', model.id]">Back to Sales Order</a>
  </div>
</div>

<div *ngIf="model" class="row">
  <div class="col-sm">
    <div class="card" style="min-width: 27rem;">
      <div class="card-body">
        <h5 class="card-title" i18n>Shipping</h5>
        <div class="card-text">

          <div class="row">
            <div class="col-sm-4 data-label">
              Ship-to Company
            </div>
            <div class="col-auto">
              {{ model.customerName }}
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 data-label">
              Ship-to Address
            </div>
            <div class="col-auto">
              <input type="text" [(ngModel)]="model.address" />
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 data-label">
              Ship-to Address 2
            </div>
            <div class="col-auto">
              <input type="text" [(ngModel)]="model.address2" />
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 data-label">
              Ship-to City
            </div>
            <div class="col-auto">
              <input type="text" [(ngModel)]="model.city" />
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 data-label">
              Ship-to State
            </div>
            <div class="col-auto">
              <input type="text" [(ngModel)]="model.state" />
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 data-label">
              Ship-to ZIP Code
            </div>
            <div class="col-auto">
              <input type="text" [(ngModel)]="model.postalCode" />
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 data-label">
              Shipping Agent
            </div>
            <div class="col-auto">
              <select [(ngModel)]="selectedShippingAgent" (change)="setShippingCodes()">
                <option *ngFor="let name of shippingAgentNames()">{{ name }}</option>
              </select>
              <!-- {{ model.shippingAgentCode }} -->
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 data-label">
              Shipping Service Code
            </div>
            <div class="col-auto">
              <select id="selectShippingCodeDropdown" (change)="getShippingAgentDescription($event.target.value)" [(ngModel)]="selectedShippingCode">
                  <option *ngFor="let i of shippingCodeOptions">{{i}}</option>
              </select>
              <!-- {{ model.shippingAgentServiceCode }} -->
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4 data-label">
              Shipping Service
            </div>
            <div class="col-auto">
              {{ shippingAgentDescription }}
            </div>
          </div>

          <div class="row">
            <div class="col-sm-4">
              <button class="btn btn-primary" [disabled]="updateSent" (click)="UpdateOrder()">Update</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
