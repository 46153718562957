import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { DesignRequestListItem } from './../../models';
import { DesignRequestsService } from './../../services/design-requests.service';

import * as displayHelpers from '../../displayHelpers';


@Component({
  selector: 'app-design-requests',
  templateUrl: './design-requests.component.html',
  styleUrls: ['./design-requests.component.scss', './../../app.component.scss']
})
export class DesignRequestsComponent implements OnInit {

  model = {
    filterString: '',
    designRequests: new Array<DesignRequestListItem>()
  };
  public loading = false;
  noMore = false;
  longDateWithSeconds = displayHelpers.longDateWithSeconds;


  constructor(private designRequestService: DesignRequestsService, private router: Router) { }

  ngOnInit() {
    this.designRequestService.getDesignRequests().subscribe(list => this.model.designRequests = list);
  }

  filterStringChanged() {
    this.loading = true;
    this.noMore = false;
    this.designRequestService.getDesignRequests(0, 20, this.model.filterString).subscribe(list => this.model.designRequests = list);
    this.loading = false;

  }

  loadOlder() {
    this.loading = true;
    this.noMore = false;
    this.designRequestService.getDesignRequests(this.model.designRequests.length, 20, this.model.filterString).subscribe(items => {
      if (!items || items.length === 0) {
        this.noMore = true;
      }
      items.forEach(i => {
        this.model.designRequests.push(i);
      });
      this.loading = false;
    });
  }

}
