import { Component, OnInit } from '@angular/core';
import { EventService } from 'src/app/services/event.service';

@Component({
  selector: 'app-admin-create-design-request',
  templateUrl: './admin-create-design-request.component.html',
  styleUrls: ['./admin-create-design-request.component.scss']
})
export class AdminCreateDesignRequestComponent implements OnInit {

  designRequestIds = '';

  constructor(private eventService: EventService) { }

  ngOnInit() {
  }

  createDesignRequests() {
    const SpecificDesignIds =
      this.designRequestIds.split('\n').map(s => s.trim()).filter(id => id !== '');
    const event = {
      Topic: 'check-for-new-design-request',
      SpecificDesignIds
    };
    this.eventService.emitEvent(event).subscribe(response => {
      this.designRequestIds = '';
    });
  }
}
