import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  TOKEN_STORAGE_NAME = 'wei-middleware-auth-token';
  user = new BehaviorSubject<{ username: string }>(null);

  baseUrl = environment.baseUrl;

  constructor(private httpClient: HttpClient) { }

  getToken() {
    return localStorage.getItem(this.TOKEN_STORAGE_NAME);
  }

  getTokenPayload(): { unique_name: string, exp: number, group: string[], upn: string } {
    const token = this.getToken();
    if (!token || token === '') {
      return null;
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const tokenPayload = JSON.parse(window.atob(base64)) as { unique_name: string, exp: number, upn: string, group: string[] };
    return tokenPayload;
  }

  loginStatus(): LoginStatus {
    const tokenPayload = this.getTokenPayload();
    if (!tokenPayload) {
      return 'not-logged-in';
    }
    const currentTime = new Date().getTime() / 1000;
    if (!tokenPayload.exp || currentTime > tokenPayload.exp) {
      return 'expired';
    }
    return 'logged-in';
  }

  isAdmin() {
    const tokenPayload = this.getTokenPayload();
    if (tokenPayload.group && tokenPayload.group.length > 0) {
       return tokenPayload && tokenPayload.unique_name && (
         (tokenPayload.group && tokenPayload.group.includes(environment.adminGroup)) ||
         environment.adminUsers.includes(tokenPayload.unique_name.toLowerCase())
       );
    } else {
      return tokenPayload && tokenPayload.unique_name && environment.adminUsers.includes(tokenPayload.unique_name.toLowerCase());
    }
    //return tokenPayload && tokenPayload.unique_name && tokenPayload.unique_name.toLowerCase() === 'admin';
    // return tokenPayload && tokenPayload.unique_name && (
    //   (tokenPayload.group || tokenPayload.group.includes(environment.adminGroup)) ||
    //   environment.adminUsers.includes(tokenPayload.unique_name.toLowerCase())
    // );
  }

  canEditSalesOrder() {
    if (this.isAdmin()) {
      return true;
    }
    const tokenPayload = this.getTokenPayload();
    if (tokenPayload.group && tokenPayload.group.length > 0) {
        return tokenPayload && tokenPayload.unique_name && (
          (tokenPayload.group && tokenPayload.group.includes(environment.editSalesOrderGroup)) ||
          environment.editSalesOrderUsers.includes(tokenPayload.unique_name.toLowerCase())
        );
    } else {
      return tokenPayload && tokenPayload.unique_name && environment.editSalesOrderUsers.includes(tokenPayload.unique_name.toLowerCase());
    }
    //return tokenPayload && tokenPayload.unique_name && environment.editSalesOrderUsers.includes(tokenPayload.unique_name.toLowerCase());
    // return tokenPayload && tokenPayload.unique_name && (
    //   (tokenPayload.group || tokenPayload.group.includes(environment.editSalesOrderGroup)) ||
    //   environment.editSalesOrderUsers.includes(tokenPayload.unique_name.toLowerCase())
    // );
  }

  canToggleEdiFlagOnSalesOrder() {
    if (this.isAdmin()) {
      return true;
    }
    const tokenPayload = this.getTokenPayload();
    if (tokenPayload.group && tokenPayload.group.length > 0) {
        return tokenPayload && tokenPayload.unique_name && (
          (tokenPayload.group && tokenPayload.group.includes(environment.editSalesOrderGroup)) ||
          environment.ediFlagUsers.includes(tokenPayload.unique_name.toLowerCase())
        );
    }
    else{
      return tokenPayload && tokenPayload.unique_name && environment.ediFlagUsers.includes(tokenPayload.unique_name.toLowerCase());
    }
    //return tokenPayload && tokenPayload.unique_name && environment.ediFlagUsers.includes(tokenPayload.unique_name.toLowerCase());
    // return tokenPayload && tokenPayload.unique_name && (
    //   (tokenPayload.group || tokenPayload.group.includes(environment.ediFlagGroup)) ||
    //   environment.ediFlagUsers.includes(tokenPayload.unique_name.toLowerCase())
    // );
  }

  logout() {
    localStorage.removeItem(this.TOKEN_STORAGE_NAME);
    this.user.next(undefined);
  }

  login(username: string, password: string): Observable<{ success: boolean }> {
    return this.httpClient.post<{ token: string, success: boolean }>(this.baseUrl + '/api/auth/login', {
      Username: username, Password: password
    }).pipe(first())
    .pipe(
      map(result => {
        if (result.success) {
          const tokenPayload = this.getTokenPayload();
          this.user.next({ username });
          localStorage.setItem(this.TOKEN_STORAGE_NAME, result.token);
        }
        return result;
      })
    );
  }

  loginUserFromAdfs(code: string): Observable<{ success: boolean }> {

    return this.httpClient.get<string>(`${this.baseUrl}/api/auth/oauthtoken/${code}`).pipe(first())
    .pipe(
      map(result => {
        if (result && result !== '') {
          localStorage.setItem(this.TOKEN_STORAGE_NAME, result);
          const payload = this.getTokenPayload();
          this.user.next({ username: payload.unique_name });
          return { success: true };
        }
        return { success: false };
      })
    );
  }

  getUser(): Observable<{ username: string}> {
    return this.httpClient.get<{ username: string}>(this.baseUrl + '/api/auth/me')
      .pipe(first())
      .pipe(
        map(result => {
          this.user.next(result);
          return result;
        })
      );
  }
}

export type LoginStatus = '' | 'not-logged-in' | 'expired' | 'logged-in' | 'error';
