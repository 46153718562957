import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { SalesOrderListItem } from './../../models';
import { SalesOrderService } from './../../services/sales-order.service';

import * as displayHelpers from '../../displayHelpers';


@Component({
  selector: 'app-sales-orders',
  templateUrl: './sales-orders.component.html',
  styleUrls: ['./sales-orders.component.scss', './../../app.component.scss']
})
export class SalesOrdersComponent implements OnInit {

  model = {
    filterString: '',
    salesOrders: new Array<SalesOrderListItem>()
  };
  loading = false;
  noMore = false;
  calendarDate = displayHelpers.calendarDate;
  onlyOpen: boolean;
  searchHandle: number;
  onlyClosed: boolean;

  constructor(private salesOrderService: SalesOrderService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.onlyOpen = false;
    this.onlyClosed = false;
    const { search } = this.route.snapshot.queryParams;
    if (search && search !== '') {
      this.salesOrderService.getSalesOrders(0, 20, search).subscribe(list => this.model.salesOrders = list);
      return;
    }
    if (sessionStorage.getItem('search')){

      this.model.filterString = sessionStorage.getItem('search');
      this.loading = true;
      this.salesOrderService.getSalesOrders(0, 20, sessionStorage.getItem('search')).subscribe(list => {this.model.salesOrders = list;
        this.loading = false;
      console.log(this.model.salesOrders)});
      return;
    }
    this.salesOrderService.getSalesOrders().subscribe(list => this.model.salesOrders = list);
  }

  filterStringChanged() {
    // delay searching to allow the user to finish their search
    
    if (this.searchHandle) {
      window.clearTimeout(this.searchHandle);
    }
  
    this.searchHandle = window.setTimeout(() => {
      sessionStorage.setItem('search',this.model.filterString);
      this.loading = true;
      this.noMore = false;
      this.salesOrderService.getSalesOrders(0, 20, this.model.filterString).subscribe(list => {
        this.model.salesOrders = list;
        this.loading = false;
      });
    }, 2000);
  }

  toggleClosed() {
    const closedOrders = document.getElementsByClassName('closedOrder');
    for (let i = 0; i < closedOrders.length; i++) {
      const order = closedOrders[i] as HTMLElement;
      order.style.display = this.onlyOpen ? '' : 'none';
    }
  }
  toggleOpen(){
    const openOrders = document.getElementsByClassName('openOrder');
    for (let i = 0; i< openOrders.length;i++){
    const order = openOrders[i] as HTMLElement;
    order.style.display = this.onlyClosed ? '' : 'none';
    }
  }
  hasCsg(item: SalesOrderListItem) {
    return item.csgRequestId && item.csgRequestId !== '';
  }

  loadOlder() {
    this.loading = true;
    this.noMore = false;

    this.salesOrderService.getSalesOrders(this.model.salesOrders.length, 20, this.model.filterString).subscribe(items => {
      if (!items || items.length === 0) {
        this.noMore = true;
      }

      items.forEach(i => {
        this.model.salesOrders.push(i);
      });
      this.loading = false;
    });
  }

}
