<div>
    <h1 i18n>Import Monitor</h1>
    <div class="row">
      <div class="col-md-6">
        <form class="form-inline mt-2 mb-1 mt-md-0">
          <input name="filterString" [(ngModel)]="model.orderId" (ngModelChange)="orderIdChanged()"
            class="form-control mr-sm-2" type="text" placeholder="Filter by Order ID" aria-label="Filter">
          <div *ngIf="loading" class="progress">
            <div i18n class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100"
              aria-valuemin="0" aria-valuemax="100" style="width: 75px">Loading</div>
          </div>
        </form>
      </div>
      <div class="col-md-4"></div>
      <div class="col-md-2"><a class="float-right" href="" [routerLink]="['./history']">View Cleared Entries &nbsp;<span class="fa fa-chevron-right"></span>&nbsp;</a></div>
    </div>

    <div>
      <h3>Stats</h3>
      <div>Total Items: {{eventItems.length}}</div>
      <div>Claimed: {{claimedItemCount}}</div>
    </div>
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Timestamp</th>
            <th scope="col" i18n>Order ID</th>
            <th scope="col" i18n>Source</th>
            <th scope="col" i18n>Error Message</th>
            <th scope="col" i18n>Claimed By</th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of eventItems">
            <td>
              {{ calendarDate(item.eventDate) }}
            </td>
            <td *ngIf="item.orderId != null && item.orderId.substring(0,2) == 'SO'">
                <a [routerLink] = "['/orders',item.orderId]">{{item.orderId}}</a>
            </td>
            <td *ngIf="item.orderId == null || item.orderId.substring(0,2) != 'SO'">
                <a [routerLink] = "['/events']" [queryParams]="{orderId: item.orderId}">{{item.orderId}}</a>
            </td>
            <td>
              {{ item.source }}
            </td>
            <td style="width:800px;">
              {{ eventMessage(item) }}
            </td>
            <td>
              <button [attr.disabled]="item.isClaiming ? 'disabled' : undefined" class="btn btn-secondary" (click)="startClaim(item)">{{ (item.claimedBy && item.claimedBy !== '') ? 'Unclaim' : 'Claim' }}</button>
              {{ item.claimedBy }}
              <div class="row mt-1 mb-1" *ngIf="item.isClaiming">
                <form name="claim" id="claim" (ngSubmit)="claimItem(item)">
                  <div class="col-auto">
                    <input name="claimer" id="claimer" class="form-control" placeholder="Enter your name here" [(ngModel)]="item.claimedByEntry">
                  </div>
                  <div class="col-auto">
                    <button class="btn btn-success" type="submit">Claim!</button>
                  </div>
                </form>
              </div>
            </td>
            <td>
              <a class="btn btn-primary" i18n routerLink="{{ item.id }}">Details</a>
            </td>
            <td>
              <button class="btn btn-warning" i18n (click)="clearItem(item)">Clear</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--
      <div>
      <mat-table [dataSource]="datasource">
        <ng-container matColumnDef="content">
          <mat-header-call [style.display]="'none'" *matHeaderCellDef>Content</mat-header-call>
          <mat-cell [style.display]="'none'" *mataCellDef="let content"></mat-cell>
        </ng-container>
        <ng-container matColumnDef="eventModel">
          <mat-header-call [style.display]="'none'" *matHeaderCellDef>Event Model</mat-header-call>
          <mat-cell [style.display]="'none'" *mataCellDef="let eventModel"></mat-cell>
        </ng-container>
        <ng-container matColumnDef="id">
          <mat-header-call [style.display]="'none'" *matHeaderCellDef>Id</mat-header-call>
          <mat-cell [style.display]="'none'" *mataCellDef="let id"></mat-cell>
        </ng-container>
        <ng-container matColumnDef="source">
          <mat-header-call [style.display]="'none'" *matHeaderCellDef>Source</mat-header-call>
          <mat-cell [style.display]="'none'" *mataCellDef="let source"></mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-call [style.display]="'none'" *matHeaderCellDef>Status</mat-header-call>
          <mat-cell [style.display]="'none'" *mataCellDef="let status"></mat-cell>
        </ng-container>
        <ng-container matColumnDef="version">
          <mat-header-call [style.display]="'none'" *matHeaderCellDef>Version</mat-header-call>
          <mat-cell [style.display]="'none'" *mataCellDef="let version"></mat-cell>
        </ng-container>
        <ng-container matColumnDef="eventName">
          <mat-header-call [style.display]="'none'" *matHeaderCellDef>Event Name</mat-header-call>
          <mat-cell [style.display]="'none'" *mataCellDef="let eventName"></mat-cell>
        </ng-container>
        <ng-container  matColumnDef="eventDate">
          <mat-header-cell *matHeaderCellDef>Timestamp</mat-header-cell>
          <mat-cell *matCellDef="let eventDate"></mat-cell>
        </ng-container>
        <ng-container  matColumnDef="orderId">
          <mat-header-cell *matHeaderCellDef>Order Id</mat-header-cell>
          <mat-cell *matCellDef="let orderId"></mat-cell>
        </ng-container>
        <ng-container  matColumnDef="orderSource">
          <mat-header-cell *matHeaderCellDef>Source</mat-header-cell>
          <mat-cell *matCellDef="let orderSource"></mat-cell>
        </ng-container>
        <ng-container  matColumnDef="message">
          <mat-header-cell *matHeaderCellDef>Error Message</mat-header-cell>
          <mat-cell *matCellDef="let message"></mat-cell>
        </ng-container>
        <ng-container  matColumnDef="claimedBy">
          <mat-header-cell *matHeaderCellDef>Claimed By</mat-header-cell>
          <mat-cell *matCellDef="let claimedBy"></mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>
      </mat-table>
    </div>
  -->
    <div *ngIf="loading" class="progress">
      <div i18n class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%">Loading</div>
    </div>
    <div style="text-align: center" *ngIf="!loading && eventItems.length === 0">
      <div i18n class="alert alert-success">Whew! Nothing here!</div>
    </div>
  </div>
