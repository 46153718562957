<div class="card" *ngIf="model && model.id">
    <div class="card-header">
        Change Status for Design:  {{model?.designId}} - {{model?.description}}
    </div>
    <div class="card-body">
        <p>Select the new status for this design request.
        </p>
        <select [(ngModel)]="selectedStatusId" >
            <option *ngFor="let status of statuses" [value]="status.Id">
                {{status.Name}}
            </option>
        </select>
        <br/><br/>
        <p>Note: This change will also be applied to the WSP eCommerce system.
        </p>
        <div class="row">
            <div class="col-auto">
                <button [attr.disabled]="(selectedStatusId =='') ? 'disabled' : undefined"
                        (click)="saveNewStatus($event)" class="btn btn-success mr-1">OK</button>
            </div>
            <div class="col-auto">
              <button (click)="abortChange($event)" class="btn btn-success mr-1">Cancel</button>
            </div>
        </div>
        <br/>
    </div>
</div>