import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UnexpectedShipment, UnexpectedShipmentEmailModel } from 'src/app/models';
import { CsgRequestsService } from 'src/app/services/csg-requests.service';
import { NotificationService } from 'src/app/services/notification.service';
import { shortDate } from './../../../displayHelpers';
@Component({
    selector: 'app-unexpected-shipment-email',
    templateUrl: './csg-unexpected-shipment-email.component.html',
    styleUrls: ['./csg-unexpected-shipment-email.component.scss', './../../../app.component.scss']
})

export class CsgUnexpectedShipmentEmailComponent implements OnInit {
    loading: boolean;
    shipment: UnexpectedShipment;
    emailModel: UnexpectedShipmentEmailModel = { emailAddress: '', emailText: '', emailNotes: '' };
    shortDate = shortDate;

    constructor(
        private csgService: CsgRequestsService,
        private notificationService: NotificationService,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.loading = true;
        this.loading = true;
        const { unexpectedId } = this.route.snapshot.params;
        this.csgService.getUnexpectedShipmentById(unexpectedId).subscribe(r => {
            this.shipment = r;
            this.emailModel.emailAddress = r.emailAddress;
            this.emailModel.emailText = r.emailText;
            this.loading = false;
        });
    }

    sendEmail(): void {
        if ((this.IsNullOrEmpty(this.emailModel.emailAddress)) || (!this.validEmailAddress(this.emailModel.emailAddress))) {
            this.notificationService.showPopup('Please Enter A Valid Email Address', 'danger', 'Error');
            return;
        }

        this.csgService.emailUnexpectedShipment(this.shipment.id, this.emailModel).subscribe(
            response => {
                if (response.success) {
                    this.notificationService.showPopup('Email Sent!', 'success', 'Success!');
                    this.router.navigate(['unexpectedshipments']);
                } else {
                    this.notificationService.showPopup(response.message, 'danger', 'Error');
                }
            }, err => {
                this.notificationService.showPopup('Error Sending Email', 'danger', 'Error');
            });
    }

    validEmailAddress(email: string): boolean {
        // tslint:disable-next-line: max-line-length
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email.toLowerCase());
    }

    IsNullOrEmpty(item: string): boolean {
        return !item || item === '';
      }
}
