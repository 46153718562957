<div *ngIf="model">
    <div class="row">
        <div class="col">
            <a [routerLink]="['/unexpectedshipments']">Back to list</a>
        </div>
    </div>
    <div class="row">
        <div class="col-sm">
            <div class="card" style="min-width: 25rem;">
                <div class="card-body">
                    <h5 class="card-title">Receiving Id {{ model.receivingId }}</h5>
                    <div class="row">
                        <div class="col-sm-4 data-label">
                            Stage
                        </div>
                        <div class="col-auto">
                            {{ status }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4 data-label">
                            Created
                        </div>
                        <div class="col-auto">
                            {{ longDateWithSeconds(model.createDate, true) }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4 data-label">
                            Customer
                        </div>
                        <div class="col-auto">
                            <a [routerLink]="['/customers', model.customer.id]"> {{ model.customer.companyName }} - {{
                                model.customer.id }}</a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4 data-label">
                            Customer Comments
                        </div>
                        <div class="col-auto">
                            {{ model.customerNotes }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4 data-label">
                            Number Of Boxes
                        </div>
                        <div class="col-sm-2">
                            <input class="form-control" type="number" [(ngModel)]="model.numberOfBoxes"
                                (change)="ensureBoxesFullNum(model.numberOfBoxes)">
                        </div>
                        <span (click)="saveBoxesAdd()"><i class="fa fa-2x fa-plus-circle text-success"></i></span>
                        <span (click)="saveBoxesMinus()"><i class="fa fa-2x fa-minus-circle text-danger"></i></span>
                        <span><button class="btn btn-sm btn-warning ml-1" (click)="saveBoxes()">Save
                                Boxes</button></span>
                    </div>
                    <div *ngIf="model.returnOrderId" class="row">
                        <div class="col-sm-4 data-label">
                            Return Order
                        </div>
                        <div class="col-auto">
                            <a [routerLink]="['/orders', model.returnOrderId]">{{model.returnOrderId}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm">
            <div class="card" style="min-width: 25rem;">
                <div class="card-body">
                    <h5 class="card-title">Details</h5>
                    <div class="row">
                        <div class="col-sm-4 data-label">
                            PO Number
                        </div>
                        <div class="col-auto">
                            {{ model.poNumber }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4 data-label">
                            Vendor PO
                        </div>
                        <div class="col-auto">
                            {{ model.vendorPo }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4 data-label">
                            Vendor Name
                        </div>
                        <div class="col-auto">
                            {{ model.vendorName }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4 data-label">
                            Outside Vendor Name
                        </div>
                        <div class="col-auto">
                            {{ model.outsideVendorName }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4 data-label">
                            Client Ref. #
                        </div>
                        <div class="col-auto">
                            {{ model.clientReferenceNumber }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4 data-label">
                            Client Ref. # 2
                        </div>
                        <div class="col-auto">
                            {{ model.clientRef2 }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4 data-label">
                            Client Ref. # 3
                        </div>
                        <div class="col-auto">
                            {{ model.clientRef3 }}
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-4 data-label">
                            Inbound Tracking
                        </div>
                        <div class="col-auto">
                            {{ model.inboundTracking }}
                        </div>
                    </div>
                    <div class="row" *ngIf="!changeWarehouseVisible">
                        <div class="col-sm-4 data-label">
                            Warehouse Code
                        </div>
                        <div class="col-4">
                            <div class="row">
                                <div class="col-6">
                                    {{ model.warehouseCode }}
                                </div>
                                <!-- <div class="col-6">
                                    <button class="btn btn-sm btn-warning mr-1 ml-1"
                                        (click)="changeWarehouseVisible = true">Change</button>
                                </div> -->
                            </div>
                        </div>
                        <div class="col-auto">
                        </div>
                    </div>
                    <div class="row" *ngIf="changeWarehouseVisible">
                        <div class="col-sm-4 data-label">
                            Select Warehouse Code
                        </div>
                        <div class="col-auto">
                            <select (change)="checkForSelected()" [(ngModel)]="selectedLocation"
                                [ngModelOptions]="{standalone: true}">
                                <option *ngFor="let location of locationModel"
                                    [value]='location.locationCode2Dig + " " + location.locationCode'>
                                    {{location.locationCode}}</option>
                            </select>
                            <button class="btn btn-sm btn-warning mr-1 ml-1" (click)="updateLocation()">Update
                                Location</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div style="text-align:left;" class="col-sm-5">
            <button class="btn btn-sm btn-warning mr-1" id="shortLabelButton" [ngClass]="{'disabled': loading == true}"
                (click)="viewCsgLabel()">Print CSG Label</button>
            <button class="btn btn-sm btn-warning mr-1" id="detailedLabelButton"
                [ngClass]="{'disabled': loading == true }" (click)="downloadDetailedLabel()">Download CSG Form</button>
            <label class="btn btn-sm btn-warning selectFilesControl">
                <input type="file" class="selectFilesControl" id="selectUploadFile" multiple
                    (change)="handleFileInput($event.target.files)" [disabled]="uploadPending">
                    Select Files To Upload
            </label>
        </div>
        <div class="col-sm-4"></div>
        <div class="col-sm-3">
            <button *ngIf="canCancelSalesOrder()" class="btn btn-danger mr-1" style="float:right"
                (click)="cancelRequest()">Cancel CSG Request</button>
        </div>
        <div *ngIf="loading" class="progress" style="width:75px">
            <div i18n class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 75px">Loading</div>
        </div>
    </div>
    <div class="col-sm">
        <div class="row">
            <strong *ngIf="filesToUpload && filesToUpload.length > 0">Selected Files:</strong>
        </div>
    </div>
    <div class="col-sm-4">
        <div class="row">
            <div class="col-sm-1"></div>
            <div class="col-sm">
                <div *ngFor="let file of filesToUpload">
                    <div class="row">
                        <div>{{file.name}}&nbsp;&nbsp;<span (click)="removeFile(file.name)" class="fa fa-trash"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-2 mb-2">
        <div class="col-sm">
            <div *ngIf="filesToUpload && filesToUpload.length > 0 && !uploadPending">
                <button class="btn btn-sm btn-warning" (click)="uploadFileList()">Upload</button>
            </div>
            <div *ngIf="uploadPending" class="progress" style="width:75px">
                <div i18n class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                    aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 75px">Loading</div>
            </div>
        </div>
    </div>

    <div *ngIf="unexpectedShipmentFiles && unexpectedShipmentFiles.length > 0" class="row">
        <div class="col-lg">
            <div class="card" style="min-width: 27rem;">
                <div class="card-body">
                    <h5 class="card-title" i18n>Uploaded Shipment Files</h5>
                    <div class="card-text">

                        <div class="list-group order-files">
                            <div class="list-group-item">
                                <div class="row data-label">
                                    <div class="link col-2">File Name</div>
                                    <div class="filetype col-2">File Type</div>
                                </div>
                            </div>
                            <div class="list-group-item" *ngFor="let file of unexpectedShipmentFiles">
                                <div class="row">
                                    <div class="link col-2">
                                        <a [attr.href]="file.uploadedUrl">{{ file.fileName || file.description ||
                                            "Download" }}</a>
                                    </div>
                                    <div class="filetype col-2">
                                        {{file.extension}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-sm">
            <div class="card">
                <div class="card-body">
                    <!-- <form name="csgForm" id="csgForm"> -->
                    <h5 class="card-title">Items</h5>
                    <table class="table table-striped"
                        *ngIf="model.unexpectedCsgShipmentItems && model.unexpectedCsgShipmentItems.length > 0">
                        <thead>
                            <tr>
                                <th style="width: 10%">
                                    <span>Quantity</span>
                                </th>
                                <th style="width: 20%">
                                    <span>Wearer</span>
                                </th>
                                <th style="width: 20%">
                                    <span>ID Tape Number</span>
                                </th>
                                <th style="width: 10%">
                                    <span>Color</span>
                                </th>
                                <th style="width: 10%">
                                    <span>Brand</span>
                                </th>
                                <th style="width: 10%">
                                    <span>Size</span>
                                </th>
                                <th style="width: 30%">
                                    <span>Item/Description (Style #)</span>
                                </th>
                                <th style="width: 5%">
                                    <span>No DE</span>
                                <th style="width: 5%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of model.unexpectedCsgShipmentItems">
                                <td>
                                    <span>{{item.quantity}}</span>
                                </td>
                                <td>
                                    <span>{{item.wearer}}</span>
                                </td>
                                <td>
                                    <span>{{item.IdTapeNum}}</span>
                                </td>
                                <td>
                                    <span>{{item.color}}</span>
                                </td>
                                <td>
                                    <span>{{item.brand}}</span>
                                </td>
                                <td>
                                    <span>{{item.size}}</span>
                                </td>
                                <td>
                                    <span>{{item.itemDescription}}</span>
                                </td>
                                <td>
                                    <div>
                                        <input class="form-control" type="checkbox" [(ngModel)]="item.noDE" disabled>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div *ngIf="isReceiving" class="mt-1">
                        <button class="btn btn-secondary" (click)="addGarment()"><span class="fa fa-plus"></span> Add
                            Garment</button>
                    </div>
                    <!-- </form> -->
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-sm">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Email History</h5>
                    <div class="alert alert-info" *ngIf="!emailLogs || emailLogs.length === 0">
                        No Emails have been sent for this shipment
                    </div>
                    <div *ngIf="emailLogs && emailLogs.length > 0">
                        <div class="row mb-1" *ngFor="let log of emailLogs">
                            <div class="col">{{getFormattedDateSent(log.DateSent) + ' - Subject: "' + log.Subject +'"'}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>