import { Component, ElementRef, Input, Output, EventEmitter, OnChanges, OnDestroy, OnInit, ViewChildren, QueryList } from '@angular/core';
import { ModalService } from '../services/modal-service';
import { CsgRequestsService } from '../services/csg-requests.service';
import { ColorMatchResult, DesignDetail, OrderFile } from '../models';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'color-match-modal',
    templateUrl: './color-match-modal.component.html',
    styleUrls: ['./color-match-modal.component.scss']

})
export class ColorMatchModalComponent implements OnChanges, OnInit, OnDestroy {
    @Input() designDetails: DesignDetail[];
    @Input() file: OrderFile;
    @Input() csgId: string;
    @Input() isLegacy: boolean;
    @Input() isReadOnly: boolean;
    @ViewChildren('tableRows', {read: ElementRef}) detailTableRows: QueryList<any>;

    private element: any;
    @Output() errorEvent = new EventEmitter();
    @Output() designDetailsChangedEvent = new EventEmitter();
    selectedStatusId: string;
    newDesignDetails: DesignDetail[];
    validationErrors: string[];
    loading = false;
    successfullySaved = false;
    id = 'color-match-modal';
    sortAsc = true;
    isInitialLoad = true;
    isTakingTooLong = false;

    constructor(private modalService: ModalService, private csgService: CsgRequestsService, private el: ElementRef) {
        this.element = el.nativeElement;
    }

    ngOnInit(): void {
        const modal = this;

        // default to closed
        this.element.style.display = 'none';

        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        document.body.appendChild(this.element);

        // add self (this modal instance) to the modal service so it's accessible from controllers
        this.modalService.add(this);
    }

    ngOnDestroy(): void {
        this.modalService.remove(this.id);
        this.element.remove();
    }

    // open modal
    open(): void {
        this.isInitialLoad = true;
        this.element.style.display = 'block';
        document.body.classList.add('color-match-modal-open');
        // managing focus is unnecessarily complex in angular apparently
        this.detailTableRows.changes.subscribe(c => {
            if (this.isInitialLoad) {
                if (c.first) {
                    c.first.nativeElement.cells[1].children[0].focus();
                }
            } else  {
                if (c.last) {
                    c.last.nativeElement.cells[1].children[0].focus();
                }
            }
        });
    }

    // close modal
    close(): void {
        this.isInitialLoad = true;
        this.element.style.display = 'none';
        this.isTakingTooLong = false;
        document.body.classList.remove('color-match-modal-open');
    }

    ngOnChanges(): void {
        this.newDesignDetails = cloneDeep(this.designDetails);
        this.validationErrors = [];
    }


    saveNewColors() {
        this.validationErrors = [];
        this.validateForm();
        if (this.validationErrors.length === 0) {
            this.loading = true;
            this.csgService.updateColorMatchedItems(this.csgId, this.newDesignDetails, this.isLegacy).subscribe((res: ColorMatchResult) => {
                if (res.success) {
                    this.designDetails = res.newTranslations;
                    this.newDesignDetails = res.newTranslations;
                    if (this.newDesignDetails) {
                        this.newDesignDetails.sort((a, b) => a.order - b.order);
                    }
                    this.designDetailsChangedEvent.emit(res);
                    this.successfullySaved = true;
                } else {
                    this.validationErrors.push(res.message);
                }
                this.loading = false;
            }, err => {
                this.isTakingTooLong = true;
                // console.log(err);
            });
        }
    }

    validateForm() {
        if (this.newDesignDetails == null || this.newDesignDetails.length < 1) {
            this.validationErrors.push('Cannot Remove All Colors From Design');
        }
        this.newDesignDetails.forEach(c => {
            if (c.order < 1) {
                this.validationErrors.push('Order is a required field');
            }
            if (this.IsNullOrEmpty(c.sourceCode)) {
                this.validationErrors.push('Color code is a required field');
            }
            if (this.IsNullOrEmpty(c.subType)) {
                this.validationErrors.push('Brand is a required field');
            }
        });
    }

    sortByOrder(): void {
        if (this.newDesignDetails == null || this.newDesignDetails.length < 1) {
            return;
        }
        this.sortAsc = !this.sortAsc;
        if (this.sortAsc) {
            this.newDesignDetails.sort((a, b) => a.order - b.order);
        } else {
            this.newDesignDetails.sort((a, b) => b.order - a.order);
        }
    }

    abortChange(event: Event) {
        this.validationErrors = [];
        this.successfullySaved = false;
        this.modalService.close('color-match-modal');
    }

    addRow() {
        this.isInitialLoad = false;
        this.newDesignDetails.sort((a, b) => a.order - b.order);
        this.newDesignDetails.push({
            type: 'ThreadColor',
            subType: '',
            order: this.newDesignDetails[this.newDesignDetails.length - 1].order + 1,
            sourceCode: '',
            description: '',
            destinationCode: '',
            errorMessage: '',
            notes: '',
            color98SequenceValue: '',
            designId: '',
            doNotTranslate: false
        } as DesignDetail);
    }

    removeRow(detail: DesignDetail) {
        if (this.isReadOnly) {
            return;
        }
        this.newDesignDetails = this.newDesignDetails.filter(obj => obj !== detail);
    }

    IsNullOrEmpty(item: string): boolean {
        return !item || item === '';
    }
}
