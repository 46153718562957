<div *ngIf="customer">
  <div class="row">
    <div class="col">
      <a [routerLink]="['..']">Back to list</a>
    </div>
  </div>
  <div class="row">
    <div class="col-sm">
      <div class="card" style="max-width: 30rem;">
    <div class="card-body">
      <h5 class="card-title">{{ customer.companyName }}</h5>
      <div class="row">
        <div class="col">
          <h6 class="badge" [ngClass]="{ 'badge-danger': !customer.isEnabled, 'badge-success': customer.isEnabled }">
            <i class="fa" [ngClass]="{ 'fa-x': !customer.isEnabled, 'fa-check': customer.isEnabled }"></i>
            <span>{{ customer.isEnabled ? 'Enabled' : 'Disabled' }}</span>
          </h6>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 data-label">
          NAV Id
        </div>
        <div class="col-auto">
          {{ customer.id }}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 data-label">
          Legacy Id
        </div>
        <div class="col-auto">
          {{ customer.sourceCustomerNumber }}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 data-label">
          Customer Type
        </div>
        <div class="col-auto">
          {{ customer.customerTypeCode }}
        </div>
      </div>
      <div class="row">
        <div class="col data-label">
          Enabled Products
        </div>
      </div>
      <div class="row">
        <div class="col-sm">
          <div class="alert alert-success w-25" *ngIf="customer.enabledProductsTypeIds.includes('ALL')">ALL</div>
          <ul class="list-group">
            <li class="list-group-item" *ngFor="let pt of customer.enabledProducts">
              {{ pt.categoryDescription }} > {{ pt.description }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
    </div>
    <div class="col-sm">
      <div class="card" style="max-width: 30rem;">
        <div class="card-body">
          <h5 class="card-title">Billing Address</h5>
          <div class="row">
            <div class="col-sm-4 data-label">
              Address 1
            </div>
            <div class="col-auto">
              {{ customer.billingAddr1 }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 data-label">
              Address 2
            </div>
            <div class="col-auto">
              {{ customer.billingAddr2 }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 data-label">
              Address 3
            </div>
            <div class="col-auto">
              {{ customer.billingAddr3 }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 data-label">
              City
            </div>
            <div class="col-auto">
              {{ customer.billingCity }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 data-label">
              State
            </div>
            <div class="col-auto">
              {{ customer.billingState }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 data-label">
              Zip
            </div>
            <div class="col-auto">
              {{ customer.billingZip }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 data-label">
              County
            </div>
            <div class="col-auto">
              {{ customer.billingCounty }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 data-label">
              Country
            </div>
            <div class="col-auto">
              {{ customer.billingCountry }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm">
      <div class="card" style="max-width: 30rem;">
        <div class="card-body">
          <h5 class="card-title">Shipping Address</h5>
          <div class="row">
            <div class="col-sm-4 data-label">
              Address 1
            </div>
            <div class="col-auto">
              {{ customer.shippingAddr1 }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 data-label">
              Address 2
            </div>
            <div class="col-auto">
              {{ customer.shippingAddr1 }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 data-label">
              Address 3
            </div>
            <div class="col-auto">
              {{ customer.shippingAddr1 }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 data-label">
              City
            </div>
            <div class="col-auto">
              {{ customer.shippingAddr1 }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 data-label">
              State
            </div>
            <div class="col-auto">
              {{ customer.shippingAddr1 }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 data-label">
              Zip
            </div>
            <div class="col-auto">
              {{ customer.shippingAddr1 }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 data-label">
              County
            </div>
            <div class="col-auto">
              {{ customer.shippingAddr1 }}
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 data-label">
              Country
            </div>
            <div class="col-auto">
              {{ customer.shippingAddr1 }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>