<div class="color-match-modal">
    <div class='color-match-modal-content'>
        <div class="card" *ngIf="designDetails && designDetails.length > 0">
            <div class="modal-header action-header">
                <h4 class="modal-title pull-left">{{'Color Match Editor - ' + designDetails[0].designId}}</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="abortChange($event)">
                    <span class="close-white" aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="card-body card-fixed-height padded" #scrollable>
                <div *ngIf="loading" class="card-body padded">
                    <div class="info-banner">
                        <strong *ngIf="!isTakingTooLong" class="alert-warn text-centered">Processing Color Changes</strong>
                        <strong *ngIf="isTakingTooLong" class="alert-warn text-centered">Still Processing Color Changes</strong>
                    </div>
                    <div>
                        <p *ngIf="!isTakingTooLong" class="text-centered">This may take a few minutes... </p>
                        <p *ngIf="isTakingTooLong" class="text-centered">Still Working... Please close and refresh page after a few minutes</p>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-sm-5"></div>
                        <div class="col-sm-2">
                            <div class="text-centered">
                                <span class="fa fa-spinner fa-3x fa-spin"></span>
                            </div>
                        </div>
                        <div class="col-sm-5"></div>
                    </div>
                </div>

                <div *ngIf="successfullySaved" class="card-body padded">
                    <div class="success-banner">
                        <strong class="alert-success">Successfully Saved Colors!</strong>
                    </div>
                </div>
                <div *ngIf="validationErrors && validationErrors.length > 0">
                    <p *ngFor="let error of validationErrors" class="text-danger">{{error}}</p>
                </div>

                <small *ngIf="file?.numColors && file.numColors > 0" class="alert alert-light" style="display: inline-block">Number of threads in DST: {{file.numColors}}</small>
            <div class="table-responsive">
                <table *ngIf="!loading" class="table" #detailsTable>
                    <thead>
                        <th style="width: 10%; text-align: center;" (click)="sortByOrder()">Order <span
                                class="fa fa-sort"></span></th>
                        <th style="width: 15%; text-align: center;">Color Code</th>
                        <th style="width: 19%; text-align: center;">Brand</th>
                        <th style="width: 15%; text-align: center;">Do Not Translate</th>
                        <th style="width: 3%; text-align: center;"></th>
                        <th style="width: 19%; text-align: center;">Translates To Madeira Polyneon</th>
                        <th style="width: 19%; text-align: center;">WE COL</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let detail of newDesignDetails; let i = index;" #tableRows>
                            <td><input class="form-control" id="order{{i}}" type="number" [(ngModel)]="detail.order" [disabled]="isReadOnly"/>
                            </td>
                            <td>
                                <input class="form-control" id="colorCode{{i}}" type="text" [disabled]="isReadOnly"
                                    [(ngModel)]="detail.sourceCode" />
                            </td>
                            <td>
                                <input class="form-control" id="colorBrand{{i}}" type="text" [disabled]="isReadOnly"
                                    [(ngModel)]="detail.subType" (focus)="i === newDesignDetails.length -1" />
                            </td>
                            <td>
                                <input class="form-control doNotTranslate" id="doNotTranslate{{i}}" type="checkbox" [disabled]="isReadOnly"
                                    [(ngModel)]="detail.doNotTranslate" />
                            </td>
                            <td>
                                <div class="form-control" type="button" class="fa fa-2x fa-trash" name="removeRow{{i}}" [disabled]="isReadOnly"
                                    (click)="removeRow(detail)"></div>
                            </td>
                            <td>
                                <p class="info-text">{{detail.color98SequenceValue}}</p>
                                <!-- <div class="form-control" type="button" class="fa fa-2x fa-trash" name="removeRow{{i}}"
                                    (click)="removeRow(detail)"></div> -->
                            </td>
                            <td>
                                <p class="info-text">{{detail.destinationCode}}</p>
                                <!-- <div class="form-control" type="button" class="fa fa-2x fa-trash" name="removeRow{{i}}"
                                    (click)="removeRow(detail)"></div> -->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </div>
            <div class="card-body padded">
                <div class="row" *ngIf="!loading && !isReadOnly">
                    <div class="col-auto">
                        <button (click)="saveNewColors($event)" class="btn btn-success mr-1">Save</button>
                    </div>
                    <div class="col-auto">
                        <button (click)="addRow()" class="btn btn-info mr-1">Add Row</button>
                    </div>
                    <div class="col-auto">
                        <button (click)="abortChange($event)" class="btn btn-secondary mr-1">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>