import { ActivatedRoute } from '@angular/router';
import { CustomerService } from './../../services/customer.service';
import { Component, OnInit } from '@angular/core';
import { CustomerModel } from 'src/app/models';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss', './../../app.component.scss' ]
})
export class CustomerComponent implements OnInit {
  customer: CustomerModel;

  constructor(private customerService: CustomerService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    const { customerId } = this.route.snapshot.params;
    this.customerService.getCustomer(customerId).subscribe(c => this.customer = c);
  }

}
