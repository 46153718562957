import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DeadLetterLogItem, EventLogDetailItem } from 'src/app/models';
import { EventService } from 'src/app/services/event.service';

import { longDateWithSeconds } from '../../../displayHelpers';
import { AuthService } from 'src/app/services/auth.service';
import { DeadLetterService } from 'src/app/services/dead-letter.service';

@Component({
  selector: 'app-dead-letter-log-details',
  templateUrl: './dead-letter-log-details.component.html',
  styleUrls: ['./dead-letter-log-details.component.scss', './../../../app.component.scss']
})
export class DeadLetterLogDetailsComponent implements OnInit {
  model: DeadLetterLogItem;
  longDateWithSeconds = longDateWithSeconds;
  isAdmin = false;

  constructor(private route: ActivatedRoute,
    private deadLetterService: DeadLetterService,
    private authService: AuthService) { }

  ngOnInit() {
    const { deadLetterLogId } = this.route.snapshot.params;
    this.deadLetterService.getDeadLetterEntry(deadLetterLogId).subscribe(detail => {
      this.model = detail;
    });
  }

  getEventContent(): string {
    return JSON.stringify(JSON.parse(this.model.content), null, 1);
  }

  showAdminEvent() {
    return this.authService.isAdmin();
  }
}
