import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {

  notifications = new Array<{id: string, alertClass: string, message: string, title: string, allowClose?: boolean}>();

  constructor(private notificationService: NotificationService) { }

  ngOnInit() {
    this.notificationService.notifications.subscribe(notification => {
      this.notifications.push(notification);
    });
    this.notificationService.closed.subscribe(id => {
      this.closeNotification(id);
    });
  }
  ngOnDestroy() {

  }

  closeNotification(id: string) {
    this.notifications = this.notifications.filter(n => n.id !== id);
  }
}
