
<div class="row mb-2">
  <!-- <div class="col">
    <a [routerLink]="['..']">Back to list</a>
  </div> -->
</div>
<div class="row mb-2">
  <div class="col-sm-4">
    <form name="searchForm">
        <input [(ngModel)]="designId" (ngModelChange)="loadDesign()" name="designId" type="text" class="form-control" placeholder="Design ID" aria-label="Design ID">
    </form>
  </div>
</div>

<div class="alert alert-warning" *ngIf="designNotFound">Design not found</div>
<div class="progress" *ngIf="isSearching">
    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
</div>

<div *ngIf="model?.designId">
  <div class="row mb-2">
    <div class="col">
      <a class="btn btn-primary" [routerLink]="['update']">Update Client Design Description</a> 
    </div>
  </div>
  <div class="row">
    <div class="col-md">
      <div>
        WEI Image: <br>
        <img [attr.src]="model.designImageUrl" class="card-img-top" alt="No WEI image yet">
      </div>
      <div>
        Client Art:
        <img [attr.src]="model.clientImageUrl" class="card-img-top" alt="No client art found">
      </div>
    </div>
    <div class="col-md">
      <app-design-view [model]="model" (onsave)="save($event)" (generate)="generateNavSku($event)"></app-design-view>
    </div>
  </div>
</div>
  <div class="row" *ngIf="authService.isAdmin() && model?.pricingTool">
    <div class="col">
      <form (ngSubmit)="getPricing()">
        <div class="row">
          <div class="col">
            <h5 class="card-title">Manage Pricing</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 data-label">
            Design
          </div>
          <div class="col-auto">
            <input name="pricing-design" id="pricing-design" class="form-control" type="text" [(ngModel)]="model.pricingTool.designId" />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 data-label">
            NAV Sku
          </div>
          <div class="col-auto">
            <input name="pricing-navSku" id="pricing-navSku" class="form-control" type="text" [(ngModel)]="model.pricingTool.navSku" />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 data-label">
            Customer
          </div>
          <div class="col-auto">
            <input name="pricing-customer" id="pricing-customer" class="form-control" type="text" [(ngModel)]="model.pricingTool.customer" />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button name="pricing-lkp" class="btn btn-primary" type="submit">Lookup Pricing</button>
          </div>
        </div>
        <div *ngIf="model.pricingTool.result">
          <div class="row">
            <div class="col-sm-4 data-label">
              Qty
            </div>
            <div class="col-sm-4 data-label">
              Amount
            </div>
            <div class="col-sm-4 data-label">
              Currency
            </div>
          </div>
          <div class="row" *ngFor="let price of model.pricingTool.result.payload.data.prices; index as i">
            <div class="col-sm-4 data-label">
              <input name="qty-{{i}}" class="form-control" type="number" [(ngModel)]="price.minQuantity" />
            </div>
            <div class="col-sm-4 data-label">
              <input name="amt-{{i}}" class="form-control" type="number" [(ngModel)]="price.amount" />
            </div>
            <div class="col-sm-4 data-label">
              <input name="currency-{{i}}" class="form-control" type="text" [(ngModel)]="price.currency" />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <button name="sync" class="btn btn-primary" type="button" (click)="syncPricing()">Sync w/ Episerver</button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="model.colorways && model.colorways.length" class="col">
      <div class="row">
        <div class="col">
          <h5 class="card-title">Colorways</h5>
        </div>
      </div>
      <div class="list-group">
        <div class="list-group-item" *ngFor="let item of model.colorways">
          Colorway name: {{ item.colorwayName }}
          <div class="list-group">
            <div class="list-group-item" *ngFor="let color of getcolors(item.designColorwayColors)">
              Sequence {{color.order}}: {{ color.weColorCode }}/{{ color.threadCode }}
              <span *ngIf="color.description">/{{color.description}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

