import { Subject, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import * as uuid from 'uuid';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class ModalService {
    private modals: any[] = [];

    public popouts = new BehaviorSubject<{
      message?: string;
      messageSeverity?: 'info' | 'warning' | 'danger' | 'success' | 'primary';
      id?: string;
      expiresInSeconds?: number;
      isDismissed: boolean;
    }>({ isDismissed: true });

    add(modal: any) {
        // add modal to array of active modals
        this.modals.push(modal);
    }

    remove(id: string) {
        // remove modal from array of active modals
        this.modals = this.modals.filter(x => x.id !== id);
    }

    open(id: string) {
        // open modal specified by id
        let modal: any = this.modals.filter(x => x.id === id)[0];
        modal.open();
    }

    close(id: string) {
        // close modal specified by id
        let modal: any = this.modals.filter(x => x.id === id)[0];
        modal.close();
    }

    popout(message: string,
      messageSeverity: 'info' | 'warning' | 'danger' | 'success' | 'primary',
      expiresInSeconds: number = 10) {

      const event = { message, messageSeverity, id: uuid.v4(), expiresInSeconds, isDismissed: false };
      this.popouts.next(event);
      setTimeout(() => event.isDismissed = true, expiresInSeconds * 1000);
    }
}
