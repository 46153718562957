<div>
  <h1 i18n>Orders Pending Designs</h1>
  <form class="form-inline mt-2 mb-1 mt-md-0">
    <input name="filterString" [(ngModel)]="model.filterString" (ngModelChange)="filterStringChanged()"
      class="form-control mr-sm-2" type="text" placeholder="Type to filter" aria-label="Filter">
    <div *ngIf="loading" class="progress">
      <div i18n class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100"
        aria-valuemin="0" aria-valuemax="100" style="width: 75px">Loading</div>
    </div>
  </form>
  <div class="table-responsive">
    <table class="table table-striped">
      <thead>
        <th scope="col" i18n></th>
        <th scope="col" i18n>Design ID</th>
        <th scope="col" i18n>Event ID</th>
        <th scope="col" i18n>Order ID</th>
        <th scope="col" i18n>Event Source</th>
      </thead>
      <tbody>
        <tr *ngFor="let item of model.pendingDesigns">
          <td *ngIf="item.isActive"><button class="btn btn-sm btn-primary" (click)="completeDesign(item)">
            Complete Design</button></td>
          <td *ngIf="item.isActive">{{ item.designId }}</td>
          <td *ngIf="item.isActive">{{ item.eventId }}</td>
          <td *ngIf="item.isActive">{{ item.orderId }}</td>
          <td *ngIf="item.isActive">{{ item.eventSource }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="loading" class="progress">
    <div i18n class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100"
      aria-valuemin="0" aria-valuemax="100" style="width: 100%">Loading</div>
  </div>
</div>