<div class="col-sm-4">
  <div class="card" style="width: 28rem;">
    <div class="card-header">
      <span>
        Design Request Summary
        <i class="fas fa-question" (click)="openModal(template)"></i>

        <i class="fas fa-sync" style="float:right;" (click)="loadDesignRequests(this.today)"></i>

      </span>
    </div>

    <div class="container">

      <ng-template #template>
        <div class="modal-header">
          <h4 class="modal-title pull-left">Design Request Summary</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          Status summary of design requests with a default view of all design requests due today. Drill down by product
          and date.
        </div>
      </ng-template>
    </div>
    <div class="card-body">
      <div *ngIf="drLoaded | async">
        <div class="row">
          <div class="col-sm-4"> Product</div>
          <select class="col-sm-4 select-option  btn btn-outline-info btn-sm" required [(ngModel)]='optionSelected'
            (ngModelChange)="filterDesignRequest(optionSelected)">
            <option class='option' *ngFor='let option of this.productList' [ngValue]="option">{{option}}
            </option>
          </select>
        </div>
        <br />
        <div class="row">
          <div class="col-sm-4"> Date</div>

          <input type="text" placeholder="" class="col-sm-4 form-group datebtn btn-outline-info btn-sm"
            [bsConfig]="bsConfig" [(ngModel)]="dateValue" value="{{ dateValue | date: 'MM/dd/yyyy' }}"
            (ngModelChange)="filterbyDate()" bsDatepicker>

        </div>
        <br /> <br /> <br />
        <div *ngIf="toggleBarVal">
          <table class=" table table-striped">
            <thead>
              <th scope="col">Product</th>
              <th scope="col">Status</th>
              <th scope="col">Total</th>

            </thead>
            <tbody>
              <tr *ngFor="let row of this.designRequests">
                <td scope="col">{{ row.productClass}}</td>
                <td scope="col">{{ row.status }}</td>
                <td scope="col">{{ row.rowCnt}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div *ngIf="!(toggleBarVal)">
          <div *ngIf="!(noData)">
            <div style="display: block">
              <canvas baseChart style="height:60vh; width:40vw" [datasets]="barChartData" [labels]="barChartLabels"
                [options]="barChartOptions" [colors]="barChartColors" [legend]="barChartLegend"
                [chartType]="barChartType">
              </canvas>
            </div>
          </div>
        </div>

      </div>
      <div align="right"><button class="btn btn-outline-info btn-sm" (click)="toggleBar()">Toggle</button></div>
    </div>
  </div>
</div>