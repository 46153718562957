import { defaults } from './defaults';

export const environment = {
  ...defaults,
  production: false,
  envDescription: 'Dev/Test',
  baseUrl: 'https://orders-api.dev.worldemblem.com',
  digitalPassportUrl: 'http://testservices.worldemblem.com/weidocs/weidocs.aspx?i=',
  // tslint:disable-next-line:max-line-length
  oAuthLoginUrl: 'https://login.worldemblem.com/adfs/oauth2/authorize?client_id=0fe39b08-5c13-4155-a127-61c1f1f2d8da&resource=https://orders.dev.worldemblem.com/login&response_type=code&redirect_uri=https://orders.dev.worldemblem.com/login&response_mode=query',
  navApiBaseUrl: 'http://apwt-devimport01.wei.local:9100/api',
  shopUrl: 'https://shop-qa.worldemblem.com/',
  storageLocation: 'https://f947d465acaef4c3stgdev.blob.core.windows.net/',
  servicesBaseUrl: 'https://middlewareservices-dev.worldemblem.com'
};
