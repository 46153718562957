import { Component, TemplateRef, OnInit, OnDestroy } from '@angular/core';
import { Chart } from 'chart.js';
import { ProductRevenueSummaryItem } from 'src/app/models';
import { ProductRevenueService } from 'src/app/services/product-revenue.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-product-revenue',
  templateUrl: './product-revenue.component.html',
  styleUrls: ['./product-revenue.component.scss']
})
export class ProductRevenueComponent implements OnInit , OnDestroy {

  private productRevenueAll : ProductRevenueSummaryItem;
  private productRevenue : ProductRevenueSummaryItem;
  dataLoaded: Promise<boolean>;
  public toggleLineVal = false;
  public noData:boolean;

  //create datasets
  private stickersNBIDataset:number[];
  private embroideryOtherDataset:number[];
  private flexDataset:number[];
  private imagePrintDataset:number[];
  private extraDataset:number[];

  constructor(private productRevenueService: ProductRevenueService,
              private modalService: BsModalService) { }

  ngOnInit() {
    this.loadProductRevenue();
  }

  async populateDatasets(){
    //5 Datasets total - to be replaced by finalized titles
    var data  = [];
    var data2 = [];
    var data3 = [];
    var data4 = [];
    var data5 = [];
    //Sort the data into its correct dataset
    for (var i = 0; i < this.productRevenue.length;i++){
    //
    if (this.productRevenue[i].productClass == 'NBI' || this.productRevenue[i].productClass == 'Stickers'){
      data.push(this.productRevenue[i]);
    }
    
    //Have to implement addition in order to get monthly totals, maybe do a seperate one 
    else if (this.productRevenue[i].productClass == 'Embroidery'){
      data2.push(this.productRevenue[i]);
    }
    else if (this.productRevenue[i].productClass == 'FlexStyle'){
      data3.push(this.productRevenue[i]);
    }
    else if (this.productRevenue[i].productClass == 'Sublimation/Perfect Print®' || this.productRevenue[i].productClass == 'ImagePrint' || this.productRevenue[i].productClass == 'Digital Print'){
      data4.push(this.productRevenue[i]);
    }
    //Leaving this blank for potential extra category
    else if (this.productRevenue[i].productClass == 'Extra 5th Category'){
      data5.push(this.productRevenue[i]);
    }
    else {
      //Otherwise push to embroidery class
      data2.push(this.productRevenue[i]);
    }
  }
  this.stickersNBIDataset = this.getMonthlyTotal(data);
  this.embroideryOtherDataset = this.getMonthlyTotal(data2);
  this.flexDataset = this.getMonthlyTotal(data3);
  this.imagePrintDataset = this.getMonthlyTotal(data4);
  this.extraDataset = this.getMonthlyTotal(data5);
  }

  getMonthlyTotal(data){
    var finalDataset = [];
    var uniqueMonths = [];
    //this will handle any cases where multiple items share the same months
    for (var i = 0; i<data.length;i++){
      for (var j = i+1; j<data.length; j++){
        if (data[i].dueDate == data[j].dueDate){
          finalDataset.push({revenue: data[i].revenueAmount + data[j].revenueAmount, month: data[i].dueDate});
          uniqueMonths.push(data[i].dueDate);
        }
        }
      }
    //handle it when month is unique to the current list of months
    for (var i = 0; i < data.length; i++){
      if (!(uniqueMonths.includes(data[i].dueDate))){
        finalDataset.push({revenue: data[i].revenueAmount, month: data[i].dueDate});
      }
    }
    //sort the data by year
    finalDataset.sort(function yearSort(a,b){
      if (a.month.slice(0,4)<b.month.slice(0,4)){
      return -1;
    }
    else if (a.month.slice(0,4) == b.month.slice(0,4)){
      return 0;
    }});     
    //sort the data by month
    finalDataset.sort(function monthSort(a,b){
      if (a.month.slice(-2)<b.month.slice(-2)){
      return -1;
    }
    else if (a.month.slice(-2) == b.month.slice(-2)){
      return 0;
    }});  
    
    //parse out the sorted data into a normal array for use by chart.js
    var res = [];
    finalDataset.forEach(element => {
      switch (element.month.slice(-2)){
        case '01':
          res.push({x:'January' ,y: element.revenue});
          break;
        case '02':
          res.push({x:'Febuary' ,y: element.revenue});
          break;
        case '03':
          res.push({x:'March' ,y: element.revenue});
          break;
        case '04':
          res.push({x:'April' ,y: element.revenue});
          break;
        case '05':
          res.push({x:'May' ,y: element.revenue});
          break;
        case '06':
          res.push({x:'June' ,y: element.revenue});
          break;
        case '07':
          res.push({x:'July' ,y: element.revenue});
          break;
        case '08':
          res.push({x:'August' ,y: element.revenue});
          break;
        case '09':
          res.push({x:'September' ,y: element.revenue});
          break;
        
        case '10':
          res.push({x:'October' ,y: element.revenue});
          break;
        case '11':
          res.push({x:'November' ,y: element.revenue});
          break;
        case '12':
          res.push({x:'December' ,y: element.revenue});
          break;
      }
    });
    return res;
  }
  createLabels(stickersNBI,embroideryOther,flexStyle,imagePrint,extra){
    var res = [];
    stickersNBI.forEach(element => {
      res.indexOf(element.x) === -1 ? res.push(element.x):console.log('');
    });
    embroideryOther.forEach(element => {
      res.indexOf(element.x) === -1 ? res.push(element.x):console.log('');
    });
    flexStyle.forEach(element => {
      res.indexOf(element.x) === -1 ? res.push(element.x):console.log('');
    });
    imagePrint.forEach(element => {
      res.indexOf(element.x) === -1 ? res.push(element.x):console.log('');
    });
    extra.forEach(element => {
      res.indexOf(element.x) === -1 ? res.push(element.x):console.log('');
    });
    return res
  }
  createGraph(){
    //create month labels for graph
    var labels: Array<string> = [];
    var months = ["January", "February", "March", "April", "May", "June", "July",
         "August", "September", "October", "November", "December"];
    var monthIndex = new Date().getMonth();

    //We are looking at past 4 months, we want to watch for when monthIndex is 3 or less
    for(var i = 4; i > 0;i--){
      if ((monthIndex-i)<0){
        //Go back to end of list and push the oldest month we need first
        var prevYear= 12 + (monthIndex - i);
        labels.push((months[prevYear]));
      }
      else{
      labels.push((months[monthIndex-i]));
      }
    }
   var labelsf = this.createLabels(this.stickersNBIDataset,this.embroideryOtherDataset,this.flexDataset,this.imagePrintDataset,this.extraDataset);
   var ctx = <HTMLCanvasElement>document.getElementById('lineGraph');
  }

  toggleLine() {
    this.loadProductRevenue();
    if (this.toggleLineVal == true) this.toggleLineVal = false
    else this.toggleLineVal = true;
    return this.toggleLineVal;
  }

  loadProductRevenue() {
    this.productRevenueService.getProductRevenueSummary().subscribe(list => {
      this.productRevenueAll = list;
      this.productRevenue = this.productRevenueAll.productRevenueSummaryModel;
      if (Object.keys(this.productRevenue).length == 0){
        this.noData = true;
      }
        else {
        this.noData = false;
        this.populateDatasets();
        this.createGraph();
      }
      this.dataLoaded = Promise.resolve(true);
    });
  }

  openModal(template: TemplateRef<any>) {
  }

  ngOnDestroy(){

  };

}
