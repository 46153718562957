<div>
  <h1 i18n>Manually Trigger Design Request</h1>
  <form (ngSubmit)="createDesignRequests()">
    <label for="designRequestIds">Enter 1 Design Id Per Line</label>
    <div>
      <textarea [(ngModel)]="designRequestIds" rows="10" cols="50" id="designRequestIds" name="designRequestIds"></textarea>
    </div>
    <div>
      <button class="btn btn-primary" type="submit">Create</button>
    </div>
  </form>
  
</div>  