<div class="col-sm-4">
  <div class="card" style="width: 28rem;">
    <div class="card-header">
      Revenue by Product
      <i class="fas fa-question" (click)="openModal(template)"></i>
    </div>
    <div class="container">
      <ng-template #template>
        <div class="modal-header">
          <h4 class="modal-title pull-left">Revenue by Product</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          Revenue by Product trend over last 4 month period.
        </div>
      </ng-template>
    </div>
    <div class="card-body">
      <div *ngIf="toggleLineVal">
        <br /> <br /> <br />
        <table class="table">
          <thead>
            <th scope="col">Product</th>
            <th scope="col">YYYY_MM</th>
            <th scope="col">Revenue</th>

          </thead>
          <tbody>
            <tr *ngFor="let row of this.productRevenue">
              <td scope="col">{{ row.productClass}}</td>
              <td scope="col">{{ row.dueDate }}</td>
              <td scope="col">{{ row.revenueAmount}}</td>
            </tr>
          </tbody>
        </table>

      </div>

      <div *ngIf = "!(noData)" [hidden]="(toggleLineVal)">
        <canvas id='lineGraph'></canvas>
        </div>
      <div align="right"><button class="btn btn-outline-info btn-sm" (click)="toggleLine()">Toggle</button></div>
    </div>
  </div>
</div>