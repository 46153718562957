import { DesignRequestsService } from './../../../services/design-requests.service';
import { Component, OnInit } from '@angular/core';
import { DesignRequestDetailItem } from 'src/app/models';
import { longDateWithSeconds, calendarDate } from 'src/app/displayHelpers';
import { ActivatedRoute, Router } from '@angular/router';
import { Base64 } from 'js-base64';
import { ModalService } from 'src/app/services/modal-service';

@Component({
  selector: 'app-design-request-detail',
  templateUrl: './design-request-detail.component.html',
  styleUrls: ['./design-request-detail.component.scss', './../../../app.component.scss']
})
export class DesignRequestDetailComponent implements OnInit {

  model: DesignRequestDetailItem;
  allowEditRelease = true;
  isReleasing = false;
  isReleased = false;
  longDateWithSeconds = longDateWithSeconds;
  calendarDate = calendarDate;
  incompleteDR = false;
  incompleteSizeInfo = false;
  incompleteColorInfo = false;
  stuff: any;
  showStatusEdit: boolean;
  eligibleChangeStatuses: string[] = [ "new" ];
  errorMessage: string = null;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private designRequestService: DesignRequestsService,
    private modalService: ModalService) { }

  ngOnInit() {
    const { requestId } = this.route.snapshot.params;
    this.designRequestService.getDesignRequest(requestId).subscribe(detail => {
      this.model = detail;
      this.isReleased = this.model.isReleased;
      this.incompleteDR = this.isIncompleteDR();
    });
  }

  openChangeStatusModal(event: Event) {
    this.clearMessages();
    this.modalService.open("change-status-modal");
  }

  closeChangeStatusModal(event: Event) {
    this.modalService.close("change-status-modal");
  }  

  numberGreaterThanZero(numString: string) {
    // tslint:disable-next-line:radix
    return numString && numString !== '' && parseInt(numString) > 0;
  }

  trimLeadingZeros(numString: string) {
    return Number(numString);
  }

  weiSizeImage(shapeSizeId: string, height: string, width: string) {
    let out = true;
    if (shapeSizeId !== '' && !this.IsNonStandardShape()) { out = false; }
    if (this.numberGreaterThanZero(height)) { out = false; }
    if (this.numberGreaterThanZero(width)) { out = false; }
    return out;
  }

  edit(event: Event) {
    this.clearMessages();
    const { encoded1, encoded2 } = this.stuff;
    const openThis =
      // tslint:disable-next-line:max-line-length
      `http://www.weicatalog.com/frame_redirect.asp?nosession=1&username=${Base64.decode(encoded1)}&password=${Base64.decode(encoded2)}&redirect=WEIForm.asp?formaction=edit&mode=admin&key=${this.model.dataDrKey}`;
    window.open(openThis);
  }

  release(event: Event) {
    this.clearMessages();
    this.isReleasing = true;
    this.designRequestService.releaseDesignRequest(this.model.id).subscribe(result => {
      this.isReleased = true;
      // this.router.navigate(['/requests']);
    }, err => {
      console.log(err);
    }, () => {
      this.isReleasing = false;
    });
  }

  updateDr(event: Event) {
    this.clearMessages();
    this.isReleasing = true;
    this.designRequestService.updateDesignRequest(this.model.id, { Status: this.model.status }).subscribe(result => {
      this.showStatusEdit = false;
    }, err => {
      console.log(err);
    }, () => {
      this.showStatusEdit = false;
    });
  }

  isIncompleteDR(): boolean {
    if (this.model.threadInkColors.length === 0) {
      this.incompleteColorInfo = true;
    }
    if (this.IsNonStandardShape() && (this.IsNullOrEmpty(this.model.height) && this.IsNullOrEmpty(this.model.width))) {
      this.incompleteSizeInfo = true;
    }
    if ((this.IsNullOrEmpty(this.model.height) &&
      this.IsNullOrEmpty(this.model.width)) &&
      this.IsNullOrEmpty(this.model.shapeSizeInfo.id)) {
      this.incompleteSizeInfo = true;
    }
    return this.incompleteColorInfo || this.incompleteSizeInfo;
  }

  IsNonStandardShape() {
    return this.NonStandardShapes.includes(this.model.drShapesName);
  }

  IsNullOrEmpty(item: string): boolean {
    return !item || item === '';
  }

  allowChangeStatus(): boolean {
    // note: this view not used for DRs having source EpiServer, so no need to include with logic here. See episerver-dr-detail component.
    return (this.eligibleChangeStatuses.includes(this.model.status.toLowerCase()));
  }

  reasonChangeStatusNotAllowed(): string {
    return this.allowChangeStatus() ? "" : "Change status is not allowed when status is currently '" + this.model.status + "'.";
  }

  toggleSetStatus(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.showStatusEdit = !this.showStatusEdit;
  }

  get NonStandardShapes(): string[] {
    return ['Circle', 'Square Shape / Round Corners', 'Triangle',
    'Rectangle Shape / Round Corners', 'Rectangle Shape / Square Corners', 'Oval',
    'Blunt Oval', 'Arc', 'Square Shape / Square Corners', 'Shield', 'Custom'];
  }

  private clearMessages() {
    this.errorMessage = null;
  }

  statusChangedEvent(newStatusId: string) {
    // currently all Status changes cause the DR to not be releasable
    this.allowEditRelease = false;
  }

  showError(message: string) {
    this.errorMessage = message;
  }

}
