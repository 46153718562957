<div class="row">
    <div class="col">
        <a [routerLink]="['/csgrequests']">Back to list</a>
    </div>
</div>

<div *ngIf="!loading">

    <form name="returnForm" (ngSubmit)="sendReturnRequest()">
        <div class="row mb-2">
            <div class="col">
                <div class="card" style="min-width: 25rem;">
                    <div class="card-body">
                        <h5 class="card-title">Return Unexpected Shipment</h5>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="row mb-1">
                                    <div class="col-sm-4 data-label">
                                        Contact Name
                                    </div>
                                    <div class="col-auto">
                                        <input [(ngModel)]="addressModel.contactName" type="text" class="form-control"
                                            name="contactName" id="contactName" maxlength="100">
                                    </div>
                                </div>

                                <div class="row mb-1">
                                    <div class="col-sm-4 data-label">
                                        Company Name
                                    </div>
                                    <div class="col-auto">
                                        <input [(ngModel)]="addressModel.companyName" type="text" class="form-control" name="companyName" id="companyName"
                                            maxlength="100">
                                    </div>
                                </div>

                                <div class="row mb-1">
                                    <div class="col-sm-4 data-label">
                                        Warehouse Code
                                    </div>
                                    <div class="col-auto">
                                        <select class="form-control"
                                            [(ngModel)]="addressModel.warehouseCode"
                                            [ngModelOptions]="{standalone: true}">
                                            <option *ngFor="let location of locationModel">{{location.locationCode}}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="row mb-1">
                                    <div class="col-sm-4 data-label">
                                        Shipping Agent
                                    </div>
                                    <div class="col-auto">
                                        <select class="form-control" id="selectShippingDropdown"
                                            name="selectShippingDropdown" [(ngModel)]="addressModel.shippingAgentCode"
                                            (change)="setShippingCodes()">
                                            <option *ngFor="let name of shippingAgentNames()">{{ name }}</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-4 data-label">
                                        Shipping Service Code
                                    </div>
                                    <div class="col-auto">
                                        <select class="form-control" id="selectShippingCodeDropdown"
                                            name="selectShippingCodeDropdown"
                                            [(ngModel)]="addressModel.shippingAgentServiceCode">
                                            <option *ngFor="let i of shippingCodeOptions" [value]="i.id">{{i.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <div class="row mb-1">
                                    <div class="col-sm-4 data-label">
                                        Address Line 1
                                    </div>
                                    <div class="col-auto">
                                        <input [(ngModel)]="addressModel.line1" type="text" class="form-control"
                                            name="line1" id="line1" maxlength="50">
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <div class="col-sm-4 data-label">
                                        Address Line 2
                                    </div>
                                    <div class="col-auto">
                                        <input [(ngModel)]="addressModel.line2" type="text" class="form-control"
                                            name="line2" id="line2" maxlength="50">
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <div class="col-sm-4 data-label">
                                        City
                                    </div>
                                    <div class="col-auto">
                                        <input [(ngModel)]="addressModel.city" type="text" class="form-control"
                                            name="city" id="city" maxlength="50">
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <div class="col-sm-4 data-label">
                                        State
                                    </div>
                                    <div class="col-auto">
                                        <input [(ngModel)]="addressModel.state" type="text" class="form-control"
                                            name="state" id="state">
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <div class="col-sm-4 data-label">
                                        Postal Code
                                    </div>
                                    <div class="col-auto">
                                        <input [(ngModel)]="addressModel.postalCode" type="text" class="form-control"
                                            name="postalCode" id="postalCode">
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <div class="col-sm-4 data-label">
                                        Country Code
                                    </div>
                                    <div class="col-auto">
                                        <input [(ngModel)]="addressModel.countryCode" type="text" class="form-control"
                                            name="countryCode" id="countryCode">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-1"></div>
        </div>
        <div class="row mt-2">
            <div class="col">
                <button *ngIf="!saveInProgress" type="submit" class="btn btn-primary">Return Unexpected Shipment</button>
                <div *ngIf="saveInProgress" class="ml-5">
                    <span class="fa fa-spinner fa-3x fa-spin"></span>
                </div>
            </div>
                
        </div>
    </form>
</div>