import { CsgRequestListItem } from '../../models';
import { longDateWithSeconds, shortDate } from '../../displayHelpers';
import { Component, OnInit } from '@angular/core';
import { LocationLookupService } from '../../services/location-lookup.service';
import { CsgRequestsService, unexpected } from '../../services/csg-requests.service';
import { UnexpectedShipment } from 'src/app/models';
import { NotificationService } from 'src/app/services/notification.service';
import { table } from 'console';
import { ActivatedRoute, Router } from '@angular/router';


const MissingThreadTranslation = 'Thread Translation Missing';
const DesignImportFailed = 'Design Import Failed';
const PriceException = 'Price Tolerance Exception';
const BadThreadData = 'Unable To Process Order. Invalid Thread Data';
const DesigRequestPending = 'Design Request Pending';
const PendingDesignCreated = 'Pending Design Completed';
const DesigRequestPendingPartial = 'Partially Received; Design Request Pending';
const DesigRequestPendingReceived = 'Received; Design Request Pending';
const ThreadUpdatedPleaseReimport = 'Thread Data Updated; Please Reimport';

@Component({
  selector: 'app-csgrequests',
  templateUrl: './csgrequests.component.html',
  styleUrls: ['./csgrequests.component.scss', './../../app.component.scss']
})
export class CsgRequestsComponent implements OnInit {

  model = {
    filterString: '',
    csgRequests: new Array<CsgRequestListItem>(),
    unexpectedCsgRequests: new Array<CsgRequestListItem>()
  };
  pageTitle = 'Pending CSG Orders';
  loading = false;
  noMore = false;
  locationSelect = '';
  filteredUnexpectedShipments = new Array<UnexpectedShipment>();
  unexpectedShipments = new Array<UnexpectedShipment>();
  filterReceivingNumber = [];
  receivingString = '';
  hideUnexpected = false;
  hidePendingDesign = false;
  ordersPendingDesign = new Array<CsgRequestListItem>();

  longDateWithSeconds = longDateWithSeconds;
  shortDate = shortDate;
  searchHandle: number;

  constructor(
    private csgService: CsgRequestsService,
    private locationService: LocationLookupService,
    private notificationService: NotificationService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.queryParamMap.subscribe((pMap) => {
      const designId = pMap.get('designId');
      if (designId) {
        this.model.filterString = designId;
        this.pageTitle = 'Pending CSG Orders With Design Id ' + designId;
        this.csgService.getCsgRequestsWithDesignId(designId).subscribe(results => {
          this.model.csgRequests = results;
        });
      } else {
        this.csgService.getCsgRequests().subscribe(results => {
          this.model.csgRequests = results;
        });
      }
    });

    this.csgService.getCsgRequests(null, PendingDesignCreated).subscribe(designPendingResults => {
      this.ordersPendingDesign = designPendingResults;
    });
  }

  filterReceiving() {
    // Return dataset back to original
    this.filteredUnexpectedShipments = this.unexpectedShipments;
    // apply receiving filter
    const filterString = this.receivingString.toLowerCase();
    this.filteredUnexpectedShipments = this.filteredUnexpectedShipments.filter(x =>
      (x.receivingId.includes(filterString)) ||
      (x.customer && x.customer.companyName && x.customer.companyName.toLowerCase().includes(filterString)) ||
      (x.poNumber && x.poNumber.toLowerCase().includes(filterString)) ||
      (x.unexpectedCsgShipmentItems &&
        x.unexpectedCsgShipmentItems.length > 0 &&
        x.unexpectedCsgShipmentItems.findIndex(c => c.wearer && c.wearer.toLowerCase().includes(filterString)) > -1)
    );
  }
  // sortLocation() {
  //   // Return dataset back to original
  //   this.filterUnexpectedCsgRequests = this.model.unexpectedCsgRequests;
  //   // if location is selected to be default then
  //   if (this.locationSelect === 'all') {
  //     return;
  //   } else {
  //     this.filterUnexpectedCsgRequests = this.filterUnexpectedCsgRequests.filter(x => x.warehouseCode.includes(this.locationSelect));
  //     // apply receiving filter
  //     this.filterUnexpectedCsgRequests = this.filterUnexpectedCsgRequests.filter(x => x.internalNotes.includes(this.receivingString));
  //   }
  // }
  filterStringChanged() {
    if (this.pageTitle !== 'Pending CSG Orders') {
      this.pageTitle = 'Pending CSG Orders';
    }

    if (this.searchHandle) {
      window.clearTimeout(this.searchHandle);
    }
    this.searchHandle = window.setTimeout(() => {
      this.loading = true;
      this.csgService.getCsgRequests(this.model.filterString).subscribe(results => {
        this.model.csgRequests = results;
        this.loading = false;
      });
    }, 2000);
  }

  hasIssues(item: CsgRequestListItem) {
    return item.status === MissingThreadTranslation ||
      item.status === DesignImportFailed ||
      item.status === PriceException ||
      item.status === BadThreadData ||
      item.status === DesigRequestPending ||
      item.status === DesigRequestPendingPartial ||
      item.status === DesigRequestPendingReceived ||
      item.status === ThreadUpdatedPleaseReimport;
  }

  getTotalPieces(item: UnexpectedShipment): number {
    const totalPieces = item.unexpectedCsgShipmentItems
      .reduce((total, shipItem) => total + (shipItem.quantity && shipItem.quantity > 0 ? shipItem.quantity : 0), 0);

    if (!totalPieces || totalPieces < 0) {
      return 0;
    }

    return totalPieces;
  }

  formatTrackingNumbers(trackNumber: string) {
    if (!!trackNumber && trackNumber !== '') {
      return trackNumber.split(',').join(', ');
    } else {
      return '';
    }
  }

  loadMore() {
    this.loading = true;
    this.noMore = false;

    const csgCount = this.model.csgRequests.length;
    this.csgService.getCsgRequests(this.model.filterString, '', true, 100, csgCount).subscribe(results => {
      if (!results || results.length === 0) {
        this.noMore = true;
      }
      results.forEach(result => {
        this.model.csgRequests.push(result);
      });
      this.loading = false;
    });
  }
}
