import { CsgRequestListItem, LocationModel, UnexpectedShipmentDisplayModel } from '../../models';
import { longDateWithSeconds, shortDate } from '../../displayHelpers';
import { Component, OnInit } from '@angular/core';
import { LocationLookupService } from '../../services/location-lookup.service';
import { CsgRequestsService, unexpected } from '../../services/csg-requests.service';
import { UnexpectedShipment } from 'src/app/models';
import { NotificationService } from 'src/app/services/notification.service';
import { Router } from '@angular/router';
import { result } from 'lodash';


const MissingThreadTranslation = 'Thread Translation Missing';
const DesignImportFailed = 'Design Import Failed';
const PriceException = 'Price Tolerance Exception';
const BadThreadData = 'Unable To Process Order. Invalid Thread Data';
const DesigRequestPending = 'Design Request Pending';
const PendingDesignCreated = 'Pending Design Completed';
const DesigRequestPendingPartial = 'Partially Received; Design Request Pending';
const DesigRequestPendingReceived = 'Received; Design Request Pending';
const ThreadUpdatedPleaseReimport = 'Thread Data Updated; Please Reimport';

@Component({
  selector: 'app-csgrequests',
  templateUrl: './unexpected-csg-action-required.component.html',
  styleUrls: ['./unexpected-csg-action-required.component.scss', './../../app.component.scss']
})
export class ActionRequiredComponent implements OnInit {
  locationModel = new Array<LocationModel>();
  locationSelect = '';
  unexpectedShipments = new Array<UnexpectedShipmentDisplayModel>();
  filterReceivingNumber = [];
  hideUnexpected = false;
  hidePendingDesign = false;
  filters = { location: '', status: '', filterString: '' };
  sortHeaders = {
    receivingIdAsc: true,
    actionAsc: true,
    ageAsc: true,
    companyNameAsc: true,
    wearerAsc: true,
    createDateAsc: true,
    poNumberAsc: true,
    totalPiecesAsc: true,
    warehouseCodeAsc: true,
    internalNotesAsc: true
  };
  statuses: string[];
  timeout: number;
  loading = false;
  noMore = false;
  addMoreloading = false;

  longDateWithSeconds = longDateWithSeconds;
  shortDate = shortDate;
  searchHandle: number;

  constructor(
    private csgService: CsgRequestsService,
    private locationService: LocationLookupService,
    private notificationService: NotificationService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this.csgService.getUnexpectedShipmentsWithActionRemaining().subscribe(results => {
      this.mapListToDisplayModel(results);
      this.loading = false;
    });

    this.locationService.getLocations(true).subscribe(results => {
      this.locationModel = results;
    });
    this.csgService.getAvailableStatusList(true, true).subscribe(results => {
      this.statuses = results;
    });
  }

  mapListToDisplayModel(shipments: UnexpectedShipment[]) {
    this.unexpectedShipments = shipments.map((s: UnexpectedShipment) => (this.mapItemToDisplayModel(s)));
  }

  mapItemToDisplayModel(shipment: UnexpectedShipment): UnexpectedShipmentDisplayModel {
    return {
      id: shipment.id,
      receivingId: shipment.receivingId,
      action: shipment.action,
      age: shipment.age,
      companyName: shipment.customer?.companyName ?? 'N/A',
      wearer: shipment.unexpectedCsgShipmentItems ? shipment.unexpectedCsgShipmentItems[0]?.wearer : 'N/A',
      poNumber: shipment.poNumber,
      totalPieces: this.getTotalPieces(shipment),
      warehouseCode: shipment.warehouseCode,
      internalNotes: shipment.internalNotes,
      clientReferenceNumber: shipment.clientReferenceNumber,
      emailSentCount: shipment.emailSentCount,
      createDate: shipment.createDate,
      createDateString: this.shortDate(shipment.createDate?.toString(), true),
      csgRequestId: shipment.csgRequestId
    } as UnexpectedShipmentDisplayModel;
  }

  loadMoreUnex() {
    this.addMoreloading = true;
    this.noMore = false;

    const unexpectedLength = this.unexpectedShipments.length;
    this.csgService.getUnexpectedShipmentsWithActionRemaining(this.filters.filterString,
      'Action Required',
      100,
      unexpectedLength,
      this.filters.location,
      this.filters.status)
      .subscribe(results => {
        if (!results || results.length === 0) {
          this.noMore = true;
        }
        results.forEach(ship => {
          this.unexpectedShipments.push(this.mapItemToDisplayModel(ship));
        });
        this.addMoreloading = false;
      });
  }

  hasIssues(item: CsgRequestListItem) {
    return item.status === MissingThreadTranslation ||
      item.status === DesignImportFailed ||
      item.status === PriceException ||
      item.status === BadThreadData ||
      item.status === DesigRequestPending ||
      item.status === DesigRequestPendingPartial ||
      item.status === DesigRequestPendingReceived ||
      item.status === ThreadUpdatedPleaseReimport;
  }

  getTotalPieces(item: UnexpectedShipment): number {
    const totalPieces = item.unexpectedCsgShipmentItems
      .reduce((total, shipItem) => total + (shipItem.quantity && shipItem.quantity > 0 ? shipItem.quantity : 0), 0);

    if (!totalPieces || totalPieces < 0) {
      return 0;
    }

    return totalPieces;
  }

  formatTrackingNumbers(trackNumber: string) {
    if (!!trackNumber && trackNumber !== '') {
      return trackNumber.split(',').join(', ');
    } else {
      return '';
    }
  }

  disposeUnex(id: string): void {
    this.csgService.disposeUnexpected(id).subscribe(response => {
      if (!!response && response.success) {
        this.notificationService.showPopup('Unexpected Shipment disposed!', 'success', 'Success!');
        this.unexpectedShipments = this.unexpectedShipments.filter(c => c.id !== id);
      } else {
        this.notificationService.showPopup(response.message, 'danger', 'Error!');
      }
    }, err => {
      this.notificationService.showPopup('Error disposing Unexpected Shipment', 'danger', 'Error');
    });
  }

  printReturnLabel(id: string) {
    this.csgService.completeUnexpectedReturn(id).subscribe(
      (shipment) => {
        this.csgService.lastUnexpectedModel = shipment;
        this.router.navigate([`csgrequests/${id}/label`], { queryParams: { unexpected: true } });
      });
  }

  getFilteredResults() {
    this.loading = true;
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
    this.timeout = window.setTimeout(t => {
      this.performSearchWithFilter();
    }, 2000);
  }

  performSearchWithFilter() {
    this.noMore = false;
    this.loading = true;
    this.csgService.getUnexpectedShipmentsWithActionRemaining(this.filters.filterString, 'Action Required', null, null,
      this.filters.location, this.filters.status)
      .subscribe(
        (list) => {
          if (!list || list.length === 0) {
            this.noMore = true;
          }
          this.mapListToDisplayModel(list);
          this.loading = false;
        });
  }

  sortByNumberColumn(column: string): void {
    if (this.sortHeaders[`${column}Asc`]) {
      this.unexpectedShipments.sort((a, b) => a[column] - b[column]);
    } else {
      this.unexpectedShipments.sort((a, b) => b[column] - a[column]);
    }
    this.sortHeaders[`${column}Asc`] = !this.sortHeaders[`${column}Asc`];
  }

  sortByStringColumn(column: string): void {
    if (this.sortHeaders[`${column}Asc`]) {
      this.unexpectedShipments.sort((a, b) => (a[column] || '')
        .localeCompare(b[column] || ''));
    } else {
      this.unexpectedShipments.sort((a, b) => (b[column] || '')
        .localeCompare(a[column] || ''));
    }
    this.sortHeaders[`${column}Asc`] = !this.sortHeaders[`${column}Asc`];
  }

  sortByDateColumn(column: string): void {
    if (this.sortHeaders[`${column}Asc`]) {
      this.unexpectedShipments.sort((a, b) => (Date.parse(a[column]) || 0) - (Date.parse(b[column]) || 0));
    } else {
      this.unexpectedShipments.sort((a, b) => (Date.parse(b[column]) || 0) - (Date.parse(a[column]) || 0));
    }
    this.sortHeaders[`${column}Asc`] = !this.sortHeaders[`${column}Asc`];
  }

  clearFilters() {
    this.loading = true;
    this.noMore = false;
    this.filters = { location: '', status: '', filterString: '' };
    this.csgService.getUnexpectedShipmentsWithActionRemaining().subscribe(results => {
      this.mapListToDisplayModel(results);
      this.loading = false;
    });
  }

  anyFiltersApplied() {
    return this.filters && (this.filters.location || this.filters.status || this.filters.filterString);
  }
}
