import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeMx from '@angular/common/locales/fr';
import { NgModule } from '@angular/core';
import {MatTableModule} from '@angular/material/table';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { Ng2SmartTableModule } from 'ng2-smart-table';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SetTokenInterceptor } from './interceptors/set-token.interceptor';
import {
  AdminCreateDesignRequestComponent,
} from './route_components/admin/admin-create-design-request/admin-create-design-request.component';
import {
  DesignRequestDetailComponent,
} from './route_components/design-requests/design-request-detail/design-request-detail.component';
import { EpiserverDrDetailComponent } from './route_components/design-requests/episerver-dr-detail/episerver-dr-detail.component';
import { PendingDesignComponent } from './route_components/pending-design/pending-design.component';
import { DesignRequestsComponent } from './route_components/design-requests/design-requests.component';
import { EventLogDetailsComponent } from './route_components/event-log/event-log-details/event-log-details.component';
import { EventLogComponent } from './route_components/event-log/event-log.component';
import { DashboardComponent } from './route_components/dashboard/dashboard.component';
import { LoginComponent } from './route_components/login/login.component';
import { SalesOrderDetailComponent } from './route_components/sales-orders/sales-order-detail/sales-order-detail.component';
import { SalesOrderRemakeComponent } from './route_components/sales-orders/sales-order-remake/sales-order-remake.component';
import { SalesOrdersComponent } from './route_components/sales-orders/sales-orders.component';
import { ImportMonitorComponent } from './route_components/order-monitor/order-monitor.component';
import { ImportMonitorHistoryComponent } from './route_components/order-monitor/order-monitor-history/order-monitor-history.component';
import { ImportMonitorDetailComponent } from './route_components/order-monitor/order-monitor-detail/order-monitor-detail.component';
import { IndexComponent } from './route_components/admin/index/index.component';
import { AdminEventComponent } from './route_components/admin/admin-event/admin-event.component';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { AliasLookupsComponent } from './route_components/alias-lookups/alias-lookups.component';
import { SettingsComponent } from './route_components/settings/settings.component';
import { CustomerComponent } from './route_components/customer/customer.component';
import { CustomerNotificationsComponent } from './route_components/customer/customer-notifications/customer-notifications.component';
import { DesignViewerComponent } from './route_components/designs/design-viewer/design-viewer.component';
import { CsgRequestsComponent } from './route_components/csgrequests/csgrequests.component';
import { ActionRequiredComponent } from './route_components/unexpected-csg-action-required/unexpected-csg-action-required.component';
import { CsgrequestDetailComponent } from './route_components/csgrequest-detail/csgrequest-detail.component';
import { CsgrequestSearchComponent } from './route_components/csgrequest-search/csgrequest-search.component';
import { CsgUnexpectedDetailComponent } from './route_components/csg-unexpected/csg-unexpected-detail.component';
import { CsgUnexpectedShipmentsComponent } from './route_components/csg-unexpected-shipments/csg-unexpected-shipments.component';
import { CsgUnexpectedShipmentEmailComponent } from './route_components/csg-unexpected-shipments/email/csg-unexpected-shipment-email.component';
import { CsgUnexpectedShipmentsReturnsComponent } from './route_components/csg-unexpected-shipments/return/csg-unexpected-shipments-returns.component';
import { CustomerListComponent } from './route_components/customer/customer-list/customer-list.component';
import { ProductionOrderComponent } from './route_components/dashboard/production-order/production-order.component';
import { DesignRequestComponent } from './route_components/dashboard/design-request/design-request.component';
import { ProductRevenueComponent } from './route_components/dashboard/product-revenue/product-revenue.component';

import { DesignUpdateComponent } from './route_components/designs/design-update/design-update.component';
import { DesignViewComponent } from './components/designs/design-view/design-view.component';
import { CsgrequestAddComponent } from './route_components/csg/csgrequest-add/csgrequest-add.component';
import { RedirectorComponent } from './route_components/redirector/redirector.component';
import { NotificationComponent } from './notification/notification.component';
import { ReportsComponent } from './route_components/reports/reports.component';
import { OpenOrdersComponent } from './route_components/reports/open-orders/open-orders.component';
import { MeComponent } from './route_components/me/me.component';
import { ModalComponent } from './modal_components/modal.component';
import { ConfirmOrCancelModalComponent } from './modal_components/confim-or-cancel-modal.component';
import { ChangeStatusModalComponent } from './modal_components/change-status-modal.component';
import { ColorMatchModalComponent } from './modal_components/color-match-modal.component';
import { UpdateEDIFlagModalComponent } from './modal_components/update-edi-flag-modal.component';
import { SalesOrderChangeComponent } from './route_components/sales-orders/sales-order-change/sales-order-change.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DisplayFieldComponent } from './components/display-field/display-field.component';
import { OrderFilesComponent } from './components/order-files/order-files.component';
import { NonTranslatedThreadsComponent } from './route_components/reports/non-translated-threads/non-translated-threads.component';
import { DeadLetterLogComponent } from './route_components/dead-letter-log/dead-letter-log.component';
import { DeadLetterLogDetailsComponent } from './route_components/dead-letter-log/dead-letter-log-details/dead-letter-log-details.component';
import { CsgLabelComponent } from './route_components/csg-label/csg-label.component';

registerLocaleData(localeMx, 'mx');

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    PendingDesignComponent,
    DesignRequestsComponent,
    EventLogComponent,
    EventLogDetailsComponent,
    DesignRequestDetailComponent,
    EpiserverDrDetailComponent,
    SalesOrdersComponent,
    SalesOrderDetailComponent,
    SalesOrderRemakeComponent,
    ImportMonitorComponent,
    ImportMonitorHistoryComponent,
    ImportMonitorDetailComponent,
    AdminCreateDesignRequestComponent,
    IndexComponent,
    AdminEventComponent,
    AliasLookupsComponent,
    SettingsComponent,
    CustomerComponent,
    CustomerNotificationsComponent,
    DesignViewerComponent,
    CsgRequestsComponent,
    ActionRequiredComponent,
    CsgrequestDetailComponent,
    CsgrequestSearchComponent,
    CsgUnexpectedDetailComponent,
    CsgUnexpectedShipmentsComponent,
    CsgUnexpectedShipmentEmailComponent,
    CsgUnexpectedShipmentsReturnsComponent,
    CustomerListComponent,
    ProductionOrderComponent,
    DesignRequestComponent,
    ProductRevenueComponent,
    DesignUpdateComponent,
    DesignViewComponent,
    CsgrequestAddComponent,
    RedirectorComponent,
    NotificationComponent,
    ReportsComponent,
    OpenOrdersComponent,
    MeComponent,
    ModalComponent,
    ConfirmOrCancelModalComponent,
    ChangeStatusModalComponent,
    ColorMatchModalComponent,
    UpdateEDIFlagModalComponent,
    SalesOrderChangeComponent,
    DisplayFieldComponent,
    OrderFilesComponent,
    NonTranslatedThreadsComponent,
    DeadLetterLogComponent,
    DeadLetterLogDetailsComponent,
    CsgLabelComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgJsonEditorModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    Ng2SmartTableModule,
    BrowserAnimationsModule,
    MatTableModule
  ],
  providers:  [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SetTokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
