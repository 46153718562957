<div class="card" *ngIf="model && model.id">
    <div class="card-header" style="text-align: center;">
         {{ediFlagMessage}} For {{model?.id}}?
    </div>
    <div class="card-body">
        <div class="row">
                <div class="col-sm-2"></div>
                <div *ngIf="hasError" class="alert alert-danger">{{errorMessage}}</div>
                <div *ngIf="!hasError" class="col-sm-2 text-center">
                    <button (click)="saveEdiFlag($event)" class="btn btn-success">OK</button>
                </div>
                <div class="col-sm-1">
                </div>
                <div class="col-sm-1 text-center">
                    <button (click)="abortChange($event)" class="btn btn-success">Cancel</button>
                </div>
        </div>
    </div>
</div>