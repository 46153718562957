<div>
  <div class="row title-row">
    <div class="col-sm-11 padding-0">
      <p class="action-header" i18n>Unexpected Shipment – Action Required</p>
    </div>
    <div *ngIf="!hideUnexpected" class="col-sm-1 expand-collapse">
      <div (click)="hideUnexpected = !hideUnexpected"
        class="fa fa-2x fa-minus floated-right expand-collapse vertical-center"></div>
    </div>
    <div *ngIf="hideUnexpected" class="col-sm-1 expand-collapse">
      <div (click)="hideUnexpected = !hideUnexpected"
        class="fa fa-2x fa-plus floated-right expand-collapse vertical-center"></div>
    </div>
  </div>
  <div class="mt-2 mb-2">
    <a [routerLink]="['add']" routerLinkActive="router-link-active" i18n class="btn btn-primary btn-sm">Add unexpected
      shipment <i class="fa fa-plus"></i></a>
  </div>
  <div class="col-sm-7">
    <div class="row">
      <div class="table-responsive mt-2 mb-2">
        <table>
          <thead>
            <th scope="col" style="width:20%">Keyword</th>
            <th scope="col" style="width:3%"></th>
            <th scope="col" style="width:20%">Location</th>
            <th scope="col" style="width:3%"></th>
            <th scope="col" style="width: 20%">Status</th>
            <th scope="col" style="width:3%"></th>
            <th scope="col" style="width:31%"></th>
          </thead>
          <tbody>
            <td><input name="filterString" [(ngModel)]="filters.filterString" (ngModelChange)="getFilteredResults()"
                class="form-control mr-sm-2" placeholder="Type to filter" type="text" aria-label="Filter"></td>
            <td></td>
            <td><select class="form-control" style="display: inline-block" [(ngModel)]="filters.location"
                [ngModelOptions]="{standalone: true}" (ngModelChange)="getFilteredResults()">
                <option></option>
                <option *ngFor="let location of locationModel" [value]="location.locationCode">
                  {{location.locationCode}}</option>
              </select></td>
            <td></td>
            <td><select class="form-control" style="display: inline-block" [(ngModel)]="filters.status"
                (ngModelChange)="getFilteredResults()" [ngModelOptions]="{standalone: true}">
                <option></option>
                <option *ngFor="let status of statuses" [value]="status">
                  {{status}}</option>
              </select></td>
              <td></td>
            <td><button *ngIf="anyFiltersApplied()" type="button" class="btn btn-sm btn-primary" (click)="clearFilters()">Clear Filters</button></td>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- <form class="form-inline mt-2 mb-1 mt-md-0">
      <input name="receivingString" [(ngModel)]="receivingString" (ngModelChange)="filterReceiving()"
        class="form-control mr-sm-2" type="text" placeholder="Type to filter" aria-label="Filter">
    </form> -->
  <br />
  <div class="table-responsive" *ngIf="unexpectedShipments && !loading">
    <table class="table table-striped">
      <thead>
        <th scope="col" style="width:4%">View</th>
        <th scope="col" style="width:7%">Action</th>
        <th scope="col" i19n style="width:7%">
          <div (click)="sortByNumberColumn('receivingId')">Receiving ID<span class="fa fa-sort"></span></div>
        </th>
        <th scope="col" i19n style="width:9%">
          <div (click)="sortByStringColumn('action')">Status<span class="fa fa-sort"></span></div>
        </th>
        <th scope="col" i18n style="width:4%">
          <div (click)="sortByNumberColumn('age')">Age<span class="fa fa-sort"></span></div>
        </th>
        <th scope="col" i18n style="width:10%">
          <div (click)="sortByStringColumn('companyName')">Company Name<span class="fa fa-sort"></span></div>
        </th>
        <th scope="col" i18n style="width:6%">
          <div (click)="sortByStringColumn('wearer')">Wearer<span class="fa fa-sort"></span></div>
        </th>
        <th scope="col" i18n style="width:8%">
          <div (click)="sortByDateColumn('createDate')">Date Added<span class="fa fa-sort"></span></div>
        </th>
        <th scope="col" i18n style="width:10%">
          <div (click)="sortByStringColumn('poNumber')">PO<span class="fa fa-sort"></span></div>
        </th>
        <th scope="col" i18n style="width:4%">
          <div (click)="sortByNumberColumn('totalPieces')">Qty<span class="fa fa-sort"></span></div>
        </th>
        <th scope="col" i19n style="width:8%">
          <div (click)="sortByStringColumn('warehouseCode')">Location<span class="fa fa-sort"></span></div>
        </th>
        <th scope="col" i18n style="width:27%">Notes</th>
      </thead>
      <tbody>
        <tr *ngFor="let item of unexpectedShipments">
          <td><a class="btn btn-sm btn-primary" [routerLink]="['/csgrequests/unexpected', item.id]">View</a></td>
          <td>
            <a *ngIf="item.action == 'Order Found'" class="btn btn-sm btn-primary"
              [routerLink]="['/csgrequests', item.csgRequestId]">Receive</a>
            <a *ngIf="item.action == 'Return'" class="btn btn-sm btn-primary"
              [routerLink]="['/unexpectedshipments/' + item.id + '/return']">Return</a>
            <a *ngIf="item.action == 'Dispose'" class="btn btn-sm btn-primary" [routerLink]=""
              (click)="disposeUnex(item.id)">Dispose</a>
            <a *ngIf="item.action == 'Return In Progress'" class="btn btn-sm btn-primary" [routerLink]=""
              (click)="printReturnLabel(item.id)">Print Label</a>
          </td>
          <td>{{ item.receivingId || 'N/A'}}</td>
          <td>{{ item.action }}</td>
          <td>{{ item.age }}</td>
          <td>{{ item.companyName || 'N/A'}}</td>
          <td>{{ item.wearer || 'N/A'}}</td>
          <td>{{ item.createDateString || 'N/A' }}</td>
          <td>{{ item.poNumber || 'N/A'}}</td>
          <td>{{ item.totalPieces || 'N/A'}}</td>
          <td>{{ item.warehouseCode || 'N/A'}}</td>
          <td>{{ item.internalNotes || 'N/A'}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="loading" class="progress">
    <div i18n class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100"
      aria-valuemin="0" aria-valuemax="100" style="width: 100%">Loading</div>
  </div>
  <div *ngIf="noMore" class="alert alert-warning" i18n>
    There are no more requests
  </div>
  <div *ngIf="!loading && !noMore" class="mt-2 mb-2">
    <button i18n (click)="loadMoreUnex()" type="button" class="btn btn-primary btn-sm">Load More Requests</button>
  </div>
</div>