<div>
    <a *ngIf="model && !model.cleared" [routerLink]="['..']">Back to list</a>
    <a *ngIf="model && model.cleared" [routerLink]="['../history']">Back to Import Monitor History</a>
    <h1 i18n>Import Monitor Entry</h1>
    <div *ngIf="model">
      <div class="row" *ngIf="!model.cleared">
          <div class="col-auto">
            <button class="btn btn-warning" (click)="clearItem()">Clear</button>
          </div>
          <div class="col-auto">
            
                <button [attr.disabled]="isClaiming ? 'disabled' : undefined" class="btn btn-primary" (click)="startClaim()">Claim</button>
                <div class="alert alert-info mt-1" *ngIf="model.claimedBy && model.claimedBy !== ''">
                  Claimed by {{ model.claimedBy }}
                </div>
            
          </div>
      </div>
      <div class="row mt-1 mb-1" *ngIf="isClaiming">
        <form name="claim" id="claim" (ngSubmit)="claimItem()">
          <div class="col-auto">
            <input name="claimer" id="claimer" class="form-control" placeholder="Enter your name here" [(ngModel)]="this.claimer">
          </div>
          <div class="col-auto">
            <button class="btn btn-success" type="submit">Claim!</button>
          </div>
        </form>
      </div>
      <div class="row" *ngIf="model.cleared">
        <div class="col-sm-2 event-detail-label">
          Cleared By
        </div>
        <div class="col">
          {{ model.clearedBy }}
        </div>
      </div>
      <div class="row" *ngIf="model.cleared">
        <div class="col-sm-2 event-detail-label">
          Cleared Date
        </div>
        <div class="col">
          {{ model.clearedDate }}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2 event-detail-label">
          Event ID
        </div>
        <div class="col">
          {{ model.id }}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2 event-detail-label">
          Order ID
        </div>
        <div class="col">
          <a *ngIf="model.OrderId != null && model.orderId.startsWith('SO')" [routerLink]="['/orders', model.orderId]">{{ model.orderId }}</a>
          <span *ngIf="model.OrderId == null || !model.orderId.startsWith('SO')">{{ model.orderId }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2 event-detail-label">
          Event Date
        </div>
        <div class="col">
          {{ longDateWithSeconds(model.eventDate, true) }}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2 event-detail-label">
          Received
        </div>
        <div class="col">
          {{ longDateWithSeconds(model.whenReceived, true) }}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2 event-detail-label">
          Name
        </div>
        <div class="col">
          {{ model.eventName }}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2 event-detail-label">
          Source
        </div>
        <div class="col">
          {{ model.source }}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2 event-detail-label">
          Version
        </div>
        <div class="col">
          {{ model.version }}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-2 event-detail-label">
          Content
        </div>
        <div class="col">
          <code><pre>{{ getEventContent() }}</pre></code>
        </div>
      </div>
    </div>
  </div>
  