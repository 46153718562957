import { CsgLabelComponent } from './route_components/csg-label/csg-label.component';
import { CsgrequestAddComponent } from './route_components/csg/csgrequest-add/csgrequest-add.component';
import { DesignUpdateComponent } from './route_components/designs/design-update/design-update.component';
import { NonTranslatedThreadsComponent } from './route_components/reports/non-translated-threads/non-translated-threads.component';
import { CustomerComponent } from './route_components/customer/customer.component';
import { CustomerNotificationsComponent } from './route_components/customer/customer-notifications/customer-notifications.component';
import { CustomerListComponent } from './route_components/customer/customer-list/customer-list.component';
import { SettingsComponent } from './route_components/settings/settings.component';
import { RedirectorComponent } from './route_components/redirector/redirector.component';
import { OpenOrdersComponent } from './route_components/reports/open-orders/open-orders.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import {
  AdminCreateDesignRequestComponent,
} from './route_components/admin/admin-create-design-request/admin-create-design-request.component';
import {
  DesignRequestDetailComponent,
} from './route_components/design-requests/design-request-detail/design-request-detail.component';
import { DesignRequestsComponent } from './route_components/design-requests/design-requests.component';
import { PendingDesignComponent } from './route_components/pending-design/pending-design.component';
import { EventLogDetailsComponent } from './route_components/event-log/event-log-details/event-log-details.component';
import { EventLogComponent } from './route_components/event-log/event-log.component';
import { DeadLetterLogComponent } from './route_components/dead-letter-log/dead-letter-log.component';
import { DeadLetterLogDetailsComponent }
  from './route_components/dead-letter-log/dead-letter-log-details/dead-letter-log-details.component';
import { DashboardComponent } from './route_components/dashboard/dashboard.component';
import { LoginComponent } from './route_components/login/login.component';
import { SalesOrderDetailComponent } from './route_components/sales-orders/sales-order-detail/sales-order-detail.component';
import { SalesOrderRemakeComponent } from './route_components/sales-orders/sales-order-remake/sales-order-remake.component';
import { SalesOrdersComponent } from './route_components/sales-orders/sales-orders.component';
import { SalesOrderChangeComponent } from './route_components/sales-orders/sales-order-change/sales-order-change.component';
import { ImportMonitorComponent } from './route_components/order-monitor/order-monitor.component';
import { ImportMonitorHistoryComponent } from './route_components/order-monitor/order-monitor-history/order-monitor-history.component';
import { ImportMonitorDetailComponent } from './route_components/order-monitor/order-monitor-detail/order-monitor-detail.component';
import { IndexComponent } from './route_components/admin/index/index.component';
import { AdminEventComponent } from './route_components/admin/admin-event/admin-event.component';
import { AliasLookupsComponent } from './route_components/alias-lookups/alias-lookups.component';
import { DesignViewerComponent } from './route_components/designs/design-viewer/design-viewer.component';
import { CsgrequestDetailComponent } from './route_components/csgrequest-detail/csgrequest-detail.component';
import { CsgrequestSearchComponent } from './route_components/csgrequest-search/csgrequest-search.component';
import { CsgUnexpectedDetailComponent } from './route_components/csg-unexpected/csg-unexpected-detail.component';
import { CsgUnexpectedShipmentsComponent } from './route_components/csg-unexpected-shipments/csg-unexpected-shipments.component';
import { CsgUnexpectedShipmentEmailComponent } from './route_components/csg-unexpected-shipments/email/csg-unexpected-shipment-email.component';
import { CsgUnexpectedShipmentsReturnsComponent } from './route_components/csg-unexpected-shipments/return/csg-unexpected-shipments-returns.component';
import { MeComponent } from './route_components/me/me.component';
import { EpiserverDrDetailComponent } from './route_components/design-requests/episerver-dr-detail/episerver-dr-detail.component';
import { unexpected } from './services/csg-requests.service';
import { ActionRequiredComponent } from './route_components/unexpected-csg-action-required/unexpected-csg-action-required.component';
import { CsgRequestsComponent } from './route_components/csgrequests/csgrequests.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'landing' },
  { path: 'landing', component: DashboardComponent, canActivate: [AuthGuard]  },
  { path: 'login', component: LoginComponent },
  { path: 'pendingrequests', component: PendingDesignComponent, canActivate: [AuthGuard] },
  { path: 'requests', component: DesignRequestsComponent, canActivate: [AuthGuard] },
  { path: 'requests/:requestId', component: DesignRequestDetailComponent, canActivate: [AuthGuard] },
  { path: 'eprequests/:requestId', component: EpiserverDrDetailComponent, canActivate: [AuthGuard]},
  { path: 'events', component: EventLogComponent, canActivate: [AuthGuard] },
  { path: 'events/:eventId', component: EventLogDetailsComponent, canActivate: [AuthGuard] },
  { path: 'deadletterlogs', component: DeadLetterLogComponent, canActivate: [AuthGuard] },
  { path: 'deadletterlogs/:deadLetterLogId', component: DeadLetterLogDetailsComponent, canActivate: [AuthGuard] },
  { path: 'orders', component: SalesOrdersComponent, canActivate: [AuthGuard] },
  { path: 'orders/:orderId', component: SalesOrderDetailComponent, canActivate: [AuthGuard] },
  { path: 'orders/edit/:orderId', component: SalesOrderChangeComponent, canActivate: [AuthGuard] },
  { path: 'remake/:orderId', component: SalesOrderRemakeComponent, canActivate: [AuthGuard] },
  { path: 'monitor', component: ImportMonitorComponent, canActivate: [AuthGuard] },
  { path: 'monitor/history', component: ImportMonitorHistoryComponent, canActivate: [AuthGuard] },
  { path: 'monitor/:eventId', component: ImportMonitorDetailComponent, canActivate: [AuthGuard] },
  // { path: 'monitor', component: OrderMonitorComponent, canActivate: [AuthGuard] },
  // { path: 'monitor/:eventId', component: OrderMonitorDetailComponent, canActivate: [AuthGuard] },
  { path: 'unexpected-csg-action-required', component: ActionRequiredComponent, canActivate: [AuthGuard]},
  { path: 'csgrequests', component: CsgRequestsComponent, canActivate: [AuthGuard]},
  { path: 'unexpected-csg-action-required/add', component: CsgrequestAddComponent, canActivate: [AuthGuard] },
  { path: 'search/csgrequests', component: CsgrequestSearchComponent, canActivate: [AuthGuard] },
  { path: 'csgrequests/:csgRequestId', component: CsgrequestDetailComponent, canActivate: [AuthGuard] },
  { path: 'csgrequests/:csgRequestId/label', component: CsgLabelComponent, canActivate: [AuthGuard] },
  { path: 'csgrequests/unexpected/:csgRequestId', component: CsgUnexpectedDetailComponent, canActivate: [AuthGuard] },
  { path: 'unexpectedshipments', component: CsgUnexpectedShipmentsComponent, canActivate: [AuthGuard] },
  { path: 'unexpectedshipments/:unexpectedId/email', component: CsgUnexpectedShipmentEmailComponent, canActivate: [AuthGuard] },
  { path: 'unexpectedshipments/:unexpectedId/return', component: CsgUnexpectedShipmentsReturnsComponent, canActivate: [AuthGuard] },

  { path: 'customers', component: CustomerListComponent, canActivate: [AuthGuard] },
  { path: 'customers/:customerId', component: CustomerComponent, canActivate: [AuthGuard] },
  { path: 'customers/:customerId/notifications', component: CustomerNotificationsComponent, canActivate: [AuthGuard] },
  { path: 'customers/:customerId/notifications/:orderId', component: CustomerNotificationsComponent, canActivate: [AuthGuard] },

  { path: 'admin/design-requests', component: AdminCreateDesignRequestComponent, canActivate: [AuthGuard] },
  { path: 'admin', component: IndexComponent, canActivate: [AuthGuard] },
  { path: 'admin/event/:eventId', component: AdminEventComponent, canActivate: [AuthGuard] },
  { path: 'admin/deadletter/:deadLetterEventId', component: AdminEventComponent, canActivate: [AuthGuard] },
  { path: 'admin/alias', component: AliasLookupsComponent, canActivate: [AuthGuard] },

  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
  { path: 'designs', redirectTo: 'designs/' },
  { path: 'designs/:designId', component: DesignViewerComponent, canActivate: [AuthGuard] },
  { path: 'designs/:designId/update', component: DesignUpdateComponent, canActivate: [AuthGuard] },
  { path: 'open-orders', component: OpenOrdersComponent, canActivate: [AuthGuard] },
  { path: 'redirector', component: RedirectorComponent, canActivate: [AuthGuard] },
  { path: 'me', component: MeComponent, canActivate: [AuthGuard] },
  { path: 'report/nontranslatedthreads', component: NonTranslatedThreadsComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
