<div *ngIf="!loading">
    <h2 *ngIf="shipment.emailSentCount === 0" i18n>Send Email Reminder For Unexpected Shipment</h2>
    <h2 *ngIf="shipment.emailSentCount > 0" i18n>Resend Email Reminder For Unexpected Shipment</h2>

    <div class="table-responsive mb-2" *ngIf="!loading && shipment.emailSentCount > 0">
        <div class="row">
            <div class="col">
                <div class="row mb-2">
                    <div class="col">
                        Previously Sent Email Count : {{shipment.emailSentCount}}
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        Last Email Sent : {{shortDate(shipment.lastEmailSentDate, true)}}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="table-responsive">
        <div class="row">
            <div class="col-sm-1 data-label">
                Email Address
            </div>
            <div class="col-sm-2">
                <input type="email" class="form-control " [(ngModel)]="emailModel.emailAddress" />
            </div>
        </div>
    </div>
    <br />
    <div class="table-responsive" *ngIf="!loading">
        <div class="row">
            <div class="col-sm-1 data-label">
                Email Text
            </div>
            <div class="col-sm-9">
                <textarea disabled class="form-control bigger-text-area" [(ngModel)]="shipment.emailText"></textarea>
            </div>
        </div>
    </div>
    <br />
    <div class="table-responsive" *ngIf="!loading">
        <div class="row">
            <div class="col-sm-1 data-label">
                Notes
            </div>
            <div class="col-sm-9">
                <textarea class="form-control medium-text-area" [(ngModel)]="emailModel.emailNotes"></textarea>
            </div>
        </div>
    </div>
    <br />
    <div class="table-responsive" *ngIf="!loading">
        <div class="row">
            <div class="col-sm-1">
            </div>
            <div class="col-sm-1">
                <button class="btn btn-sm btn-primary" (click)="sendEmail()">Send Email</button>
            </div>
        </div>
    </div>
</div>