import { Subject, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { Injectable, Output } from '@angular/core';
import * as uuid from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  notifications = new Subject<{id: string, alertClass: string, message: string,
    title: string, isRead: boolean, persist: boolean, allowClose?: boolean}>();

  @Output()
  closed = new Subject<string>();

  constructor(public authService: AuthService) {

  }

  showPopup(message: string, alertClass = 'info', title = 'Notification', isRead = false, persist = false, allowClose = true): string {
    const id = uuid.v4();
    this.notifications.next({ id , alertClass, message, title, isRead, persist, allowClose });
    return id;
  }

  hidePopup(id: string) {
    this.closed.next(id);
  }
}

