<main role="main" class="container">
  <form (ngSubmit)="login()">
    <!-- <fieldset>
      <legend>Login</legend>

      <label for="username">Username</label>
      <input class="form-control" [(ngModel)]="model.username" name="username" id="username" type="text" placeholder="Username">
      <label for="password">Password</label>
      <input class="form-control" [(ngModel)]="model.password" name="password" id="password" type="password" placeholder="Password">

      <button type="submit" class="btn btn-primary">Sign in</button>
    </fieldset> -->
    <div class="alert alert-primary" *ngIf="isLoggingIn">
      Please wait, signing in...
    </div>
    <fieldset *ngIf="!isLoggingIn">
      <legend>
        World Emblem Single Sign On
      </legend>
      <a class="btn btn-primary" [attr.href]="oAuthLoginUrl">Login using your WEI username and password</a>
    </fieldset>
    <div class="alert alert-danger" *ngIf="model.loginFailed">
      The email address or password is incorrect, please try again.
    </div>
    <div class="alert alert-danger" *ngIf="model.status === 'expired'">
      Your login has expired, please login again.
    </div>
    <div class="alert alert-danger" *ngIf="model.status === 'not-logged-in'">
      You must login to access this page.
    </div>
    <div class="alert alert-danger" *ngIf="model.status === 'error'">
      {{ model.error }}
      {{ model.errorDescription }}
    </div>
  </form>
</main>