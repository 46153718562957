<div>
  <h1 i18n>Sales Orders</h1>

  <form class="form-inline mt-2 mb-1 mt-md-0">
    <input name="filterString"
      [(ngModel)]="model.filterString" (ngModelChange)="filterStringChanged()"
      class="form-control mr-sm-2" type="text" placeholder="Type to filter" aria-label="Filter">
    <div *ngIf="loading" class="progress">
      <div i18n class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 75px">Loading</div>
    </div>
  </form>
  <input type="checkbox" class="checkbox" id="onlyOpenCheckbox" (ngModelChange)="toggleClosed()" [(ngModel)]="onlyOpen">Show Only Open Orders
  <input type="checkbox" class="checkbox" id="onlyClosedCheckbox" (ngModelChange)="toggleOpen()" [(ngModel)]="onlyClosed">Show Only Closed Orders
  <div class="table-responsive">
    <table class="table wei-table-striped">
      <thead>
          <th scope="col"></th>
          <th scope="col" i18n>Sales Order ID (NAV SON)</th>
          <th scope="col" i18n>Source</th>
          <th scope="col" i18n>Date Added</th>
          <th scope="col" i18n>Order Type</th>
          <th scope="col" i18n>NAV Customer No</th>
          <th scope="col" i18n>Legacy Customer No</th>
          <th scope="col" i18n>Customer</th>
          <th scope="col" i18n>Customer Type</th>
          <!-- <th scope="col" i18n>NAV Line Count</th>-->
          <!-- <th scope="col" i18n>Nav Item Nos.</th> -->
          <th scope="col" i18n>Design Ids</th>
          <th scope="col" i18n>&nbsp;</th>
          <th scope="col" i18n>Posted</th>
          <th scope="col" i18n>PO</th>
      </thead>
      <tbody>
        <tr *ngFor="let item of model.salesOrders" [ngClass]="{'alert': (item.latestStatus == 'Not Released in NAV'), 'alert-info': (item.latestStatus == 'Not Released in NAV'), 'exception-row': (item.latestStatus == 'Exception'), 'closedOrder': (item.posted !== null), 'openOrder':(item.posted == null), 'deleted-row': (item.isDeleted)}">
          <td>
              <div class="btn-group btn-group-sm" role="group" aria-label="Actions">
                  <a class="btn btn-sm btn-primary" [routerLink]="['/orders', item.id]">View</a>
                  <a *ngIf="!item.isDeleted" class="btn btn-sm btn-secondary" [routerLink]="['/remake', item.id]">Remake</a>
              </div>
          </td>
          <td>{{ item.id }}</td>
          <td>{{ item.orderSource }}</td>
          <td>{{ calendarDate(item.addDate) }}</td>
          <td>{{ item.orderType }}</td>
          <td>{{ item.navCustomerNumber }}</td>
          <td>{{ item.legacyCustomerNo }}</td>
          <td>{{ item.customerName }}</td>
          <td>{{ item.customerType }}</td>
          <!-- <td>{{ item.lineCount }}</td>
          <td>{{ item.navItemNos }}</td>-->
          <td>{{ item.designIds }}</td>
          <td>
            <span *ngIf="hasCsg(item)">
              <i class="fa fa-tshirt"></i>
            </span>
            <span *ngIf="item.isSplitOrder">
              <i class="fa fa-code-branch"></i>
            </span>
          </td>
          <td>{{ (item.posted && item.posted !== '') ? calendarDate(item.posted) : '' }}</td>
          <td>{{item.poNo}}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="loading" class="progress">
    <div i18n class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100"
      aria-valuemin="0" aria-valuemax="100" style="width: 100%">Loading</div>
  </div>
  <div *ngIf="noMore" class="alert alert-warning" i18n>
    There are no older orders
  </div>
  <div *ngIf="!loading && !noMore" class="mt-2 mb-2">
    <button i18n (click)="loadOlder()" type="button" class="btn btn-primary btn-sm">Load older orders</button>
  </div>

</div>
